import { negate } from 'lodash'
import React, { CSSProperties } from 'react'
import Form from 'react-bootstrap/Form'
import { Label } from '../Label'
import * as constant from '../../../shared/util/constant'

interface Props {
  /** Defines the type of the input. Defaults to 'text' if not specified. */
  type?: 'text' | 'number' | 'email' | 'password' | 'search' | 'tel' | 'url'
  /** Defines the size of the input. Defaults to 'lg' */
  size?: 'sm' | 'lg'
  /** The value of the input */
  value?: string | number | string[] | number[]
  /** Handles the `onChange1` event for the input */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /** The name of the input */
  name?: string
  /** The id value of the input */
  id?: string
  /** The placeholder inside of the text input */
  placeholder?: string
  /** Defines whether the input should be disabled or not. Defaults to false. */
  disabled?: boolean
  /** Defines the custom error message of the input. */
  feedback?: string
  /** Defines whether the input should display as invalid. Defaults to false. */
  isInvalid?: boolean
  /** Defines whether the input should display as valid. Defaults to false */
  isValid?: boolean
  /** Defines the class of the input. */
  className?: string
  /** Defines the style of the input. */
  style?: CSSProperties
  /** Defines the custom style of the input. */
  inputDefaultStyle?: Record<string, any>

  maxLength?: number
  tabIndex?: number
  isEmpty?: boolean
  isTooShort?: boolean
  isTooLong?: boolean
  pattern?:string
  isSame?:boolean
  errorText?:string
  invalidWarning?:string
  title?:string
  invalidLableClass? : string
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onBlur?:(e: React.FocusEvent<HTMLInputElement>) => void
}

/**
 * A flexible text input as a wrapper around the React Bootstrap Form Control.
 */

const TextInput = (props: Props) => {
  const {
    type,
    name,
    id,
    placeholder,
    onChange,
    disabled,
    feedback,
    isValid,
    isInvalid,
    value,
    size,
    className,
    style,
    inputDefaultStyle,
    maxLength,
    tabIndex,
    isEmpty,
    isTooShort,
    isTooLong,
    pattern,
    isSame,
    errorText,
    title,
    invalidWarning,
    invalidLableClass,
    onKeyPress,
    onBlur
  } = props

  let invalidLable = `cst-Feedback-Linebreak${invalidLableClass ? ' ' + invalidLableClass : ''}`;

  return (
    <>
      <Form.Group>
        <Form.Control
          as="input"
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          isInvalid={isInvalid}
          isValid={isValid}
          tabIndex={tabIndex}
          value={value}
          // defaultValue={value}
          size={size}
          className={className}
          style={Object.assign({}, style, ...[inputDefaultStyle])}
          maxLength={maxLength}
          pattern={pattern}
          title={title}
          onKeyPress={onKeyPress}
          onBlur={onBlur} />
         <Form.Control.Feedback
      className={`text-left ml-3 mt-1 text-small ${
        isValid ? 'text-success' : isInvalid ? 'text-danger' : undefined
      }`}
      type={isValid ? 'valid' : 'invalid'}
    >
      {feedback}
    </Form.Control.Feedback> 
      </Form.Group>
    
      <div  className={isTooShort||isEmpty||isTooLong||isSame?"upmargin20":"" }>
        {isTooShort  && <Label className='cst-Feedback-Linebreak'
          text={errorText==""?constant.FIELD_MIN_CHAR.replace('{}','5'):errorText==null?constant.FIELD_MIN_CHAR.replace('{}','5'):errorText}
          style={{ color: "red"}} />}
          {isTooShort && <br/>}
          
        {isEmpty && <Label className='cst-Feedback-Linebreak'
          text={errorText==""?constant.EMPTY_SELECT:errorText==null?constant.EMPTY_SELECT:errorText}
          style={{ color: "red"}} />}
           {isEmpty && <br/>}

        {isTooLong && <Label className='cst-Feedback-Linebreak'
          text={errorText==""?constant.FIELD_MAX_CHAR.replace('{}','15'):errorText==null?constant.FIELD_MAX_CHAR.replace('{}','15'):errorText}
          style={{ color: "red" }} />}
        {isTooLong && <br/>}
        {isSame && <Label className='cst-Feedback-Linebreak'
        text={errorText==""?constant.VALUE_NOT_CHANGED:errorText==null?constant.VALUE_NOT_CHANGED:errorText}
        style={{ color: "red"}} />}
        {isInvalid &&
            <div 
            className='upmargin20'
            // style={{marginTop:-25}}
            >
                 <Label
                  className={invalidLable}
                  text={invalidWarning}
                  style={{ color: "red" }} />
              </div>}
      </div>
    </>
  )
}

TextInput.defaultProps = {
  type: 'text',
}

export { TextInput }
