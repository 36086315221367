import { useEffect, useState } from "react";
import { Column, Icon, Label, Panel, Row, Select } from "../../components";
import DocuMateRefiService, {
  getCarrierDropdownOptions,
  getImpoundsDropdownOptions,
  getLoanPositionDropdownOptions,
  getRefiDocument,
  getRequestTypeDropdownOptions,
} from "./DocuMateRefiService";
import { useHistory, useLocation } from "react-router-dom";
import { RefiDocument } from "../model/RefiDocument";
import DocViewer from "../shared/components/DocViewer";
import { SelectOption } from "../../shared/components/input";
import * as documateLabel from "../shared/util/docuMateLabel";
import * as constant from "../shared/util/docuMateConstant";
import "./RefiProcessing.scss";
import { toast } from "react-toastify";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const sampleDocument: RefiDocument = {
  RefiRequestId: "9b1deb4d-3b7d-4bad-9bdd-2bbbbbbbbbd",
  UploadedFileName: "CS_RM_10152024_1000",
  DocumentDescription: "Document 100 Description",
  dateInitiated: "10-12-2024 12:04:56 PM",
  dateAvailable: "10-12-2024 12:04:56 PM",
  isLocked: false,
  RequestType: "R",
  Vesting: "A Married Couple",
  PolicyNbr: "12556",
  CarrierName: "ASI",
  CustFirstName: "John",
  CustLastName: "Doe",
  WritingCompanyName: "ABC",
  VestingAddressLine1: "123 Main St",
  VestingAddressLine2: "Apt 2",
  VestingAddrCity: "San Diego",
  VestingAddrState: "CA",
  VestingAddrZipCode: "92101",
  LoanNumber: "1256",
  LoanPosition: "1",
  Impound: "Y",
  MortgageeName: "ABC Mortgage",
  MortgageeClause: "Mortgagee Clause",
  MortgageeNameAddressLine1: "123 Main St",
  MortgageeNameAddressLine2: "Apt 2",
  MortgageeNameAddrCity: "San Diego",
  MortgageeNameAddrState: "CA",
  MortgageeNameAddrZipCode: "92101",
  RequestorName: "John Doe",
  RequestorEmail: "john.doe@ymail.com",
};

const RefiProcessing = () => {
  const location = useLocation();
  const state = location.state as { id: string; fileName: string, lockedDocs: []};
  console.log(state);
  const urlParam = new URLSearchParams(location.search);
  const [fileName, setFileName] = useState(state?.fileName);
  const [id, setId] = useState(state?.id || urlParam.get("id"));
  const refiService = new DocuMateRefiService();
  const [currentDocument, setCurrentDocument] = useState<RefiDocument>(
    sampleDocument
  );
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [requestTypeOptions] = useState<SelectOption[]>(
    getRequestTypeDropdownOptions()
  );
  const [vestingOptions, setVestingOptions] = useState<SelectOption[]>([]);
  const [carrierOptions] = useState<SelectOption[]>(
    getCarrierDropdownOptions()
  );
  const [statesList, setStatesList] = useState<SelectOption[]>([]);
  const [impoundsOptions] = useState<SelectOption[]>(
    getImpoundsDropdownOptions()
  );
  const [loanPositionOptions] = useState<SelectOption[]>(
    getLoanPositionDropdownOptions()
  );
  const [formValidation, setFormValidation] = useState<any>({});
  const [docViewerOpen, setDocViewerOpen] = useState(true);
  const [showDropdownButtons, setShowDropdownButtons] = useState(false);

  /// Method to intialize component.
  let initializeComponent = async (id: string) => {
    let states = await refiService.getStatesList();
    setStatesList(states);
    let vesting = await refiService.getVestingList();
    setVestingOptions(vesting);
  };

  useEffect(() => {
    getRefiDocument(id).then((res) => {
      initializeComponent(res.RefiRequestId);
      setCurrentDocument(res);
    });
  }, []);

  const navigateToTodoList = () => {
    history.push("/documate-refi");
  };

  const onEntityFieldChange = (
    propName: string,
    value: string | Date | boolean
  ) => {
    setCurrentDocument({
      ...currentDocument,
      [propName]: value,
    });
  };

  const onApproveDocument = async (id: string) => {
    // save state to the db
    try {
      let validationError = refiService.validateRefiDocument(currentDocument);
      console.log(validationError);
      setFormValidation(validationError);
      if (Object.keys(validationError).length > 0) {
        toast.warn(constant.DOCUMENT_FORM_VALIDATION_ERROR_MESSAGE, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else {
        toast.success(constant.DOCUMENT_APPROVED, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error);
    }
    // approveDocument(id).then((data) => {
    //         console.log(data);
    //         // setCurrentDocument(data);
    //         // console.log(currentDocument.scanId);
    //         if(data) {
    //             history.push(`/documate-refi/processing/${data.id}`, { scanId: data.scanId, id: data.id });
    //             setIsLoading(false);
    //         }
    //         else {
    //             navigateToTodoList();
    //             setIsLoading(false);
    //         }
    //     });
  };

  const handleDocViewerClose = () => {
    // DocViewerOpen(false);
  };

  const toggleDropwdownButtons = (event) => {
    event.preventDefault();
    setShowDropdownButtons(!showDropdownButtons);
  };

  const CloseButtonWithDropdownOptions = (
    <>
      <div className="close-btn-div">
        <Button
          className="btn btn-primary bt-main-action"
          key="filter"
          onClick={() => navigateToTodoList()}
          tabIndex={21}
          id="closeRefi"
          disabled={false}
          outlined={true}
          // icon="down-arrow"
          // iconLocation="right"
        >
          {documateLabel.CLOSE_DOCUMENT}
        </Button>
        <Button
          className="btn btn-primary bt-main-action"
          key="filter"
          onClick={(event) => toggleDropwdownButtons(event)}
          tabIndex={21}
          id="toggleCloseButtons"
          disabled={false}
          outlined={true}
        >
          {showDropdownButtons ? (
            <span>
              <Icon icon="up-arrow" />
            </span>
          ) : (
            <span>
              <Icon icon="down-arrow" />
            </span>
          )}
        </Button>
      </div>
      <div className="close-btn-div2">
        {showDropdownButtons && (
          <div className="dropdown-btn-grp">
            <Button
              className=""
              key="filter"
              onClick={() => navigateToTodoList()}
              tabIndex={21}
              id="closeAndNextRefi"
              disabled={false}
              // outlined={true}
            >
              {documateLabel.CLOSE_NEXT_DOCUMENT}
            </Button>
            <Button
              className=""
              key="filter"
              onClick={() => navigateToTodoList()}
              tabIndex={21}
              id="closeAndEndRefi"
              disabled={false}
              // outlined={true}
            >
              {documateLabel.CLOSE_END_AUTOPROCESS}
            </Button>
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      {/* <Label title="DocuMateRefiProcessing" text={scanId} className='documateHeader'></Label> */}
      {!isLoading && (
        <div className="parent-container">
          <div className="refiPanel">
            <Panel title={documateLabel.REFI_PROCESSING_LABELS.reiPanel}>
              <Row>
                <Column lg={6} md={6}>
                  <Select
                    id="RequestType"
                    controlId="RequestType"
                    className="mb-3"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.requestType}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    options={requestTypeOptions}
                    sortOptions={false}
                    defaultSelected={requestTypeOptions.filter(
                      (option) => option.value === currentDocument.RequestType
                    )}
                    onChange={(values) =>
                      onEntityFieldChange("RequestType", values[0] as string)
                    }
                    tabIndex={6}
                    isInvalid={formValidation["RequestType"]?.isValid == false}
                    invalidWarning={formValidation["RequestType"]?.warningText}
                    isRequired={true}
                  />
                </Column>
                <Column lg={6} md={6}>
                  <Select
                    id="Vesting"
                    controlId="Vesting"
                    className="mb-3"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.vesting}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    options={vestingOptions}
                    defaultSelected={vestingOptions.filter(
                      (option) => option.value === currentDocument.Vesting
                    )}
                    onChange={(values) =>
                      onEntityFieldChange("Vesting", values[0] as string)
                    }
                    tabIndex={6}
                    isInvalid={formValidation["Vesting"]?.isValid == false}
                    invalidWarning={formValidation["Vesting"]?.warningText}
                    isRequired={true}
                  />
                </Column>
              </Row>
              <Row>
                <Column lg={6} md={6}>
                  <FloatingLabel
                    controlId="PolicyNbr"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.policyNumber}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="policyNumber"
                      value={currentDocument.PolicyNbr ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "PolicyNbr",
                          event.currentTarget.value
                        )
                      }
                      // disabled={!isEditable}
                      required={true}
                      tabIndex={1}
                      maxLength={60}
                      isInvalid={formValidation["PolicyNbr"]?.isValid == false}
                    />
                    {formValidation["PolicyNbr"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["PolicyNbr"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
                <Column lg={6} md={6}>
                  <Select
                    id="CarrierName"
                    controlId="CarrierName"
                    className="mb-3"
                    label={documateLabel.REFI_PROCESSING_LABELS.carrier}
                    options={carrierOptions}
                    defaultSelected={carrierOptions.filter(
                      (option) => option.value === currentDocument.CarrierName
                    )}
                    onChange={(values) =>
                      onEntityFieldChange("CarrierName", values[0] as string)
                    }
                    tabIndex={6}
                    isInvalid={formValidation["CarrierName"]?.isValid == false}
                    invalidWarning={formValidation["CarrierName"]?.warningText}
                    isRequired={true}
                  />
                </Column>
              </Row>
            </Panel>
            <Panel title={documateLabel.REFI_PROCESSING_LABELS.customerPanel}>
              <Row>
                <Column lg={6} md={6}>
                  <FloatingLabel
                    controlId="firstName"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.firstName}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="CustFirstName"
                      value={currentDocument.CustFirstName ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "CustFirstName",
                          event.currentTarget.value
                        )
                      }
                      // disabled={!isEditable}
                      required={true}
                      tabIndex={1}
                      maxLength={60}
                      isInvalid={formValidation["CustFirstName"]?.isValid == false}
                    />
                    {formValidation["CustFirstName"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["CustFirstName"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
                {/* <Column lg={4} md={4}>
                  <FloatingLabel
                    controlId="middleName"
                    label={documateLabel.REFI_PROCESSING_LABELS.middleName}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="middleName"
                      value={currentDocument.middleName1 ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "middleName1",
                          event.currentTarget.value
                        )
                      }
                      tabIndex={1}
                    />
                  </FloatingLabel>
                </Column> */}
                <Column lg={6} md={6}>
                  <FloatingLabel
                    controlId="CustLastName"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.lastName}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="CustLastName"
                      value={currentDocument.CustLastName ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "CustLastName",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      maxLength={60}
                      isInvalid={formValidation["CustLastName"]?.isValid == false}
                    />
                    {formValidation["CustLastName"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["CustLastName"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
              </Row>
              <Row>
                <Column lg={8} md={8}>
                  <FloatingLabel
                    controlId="WritingCompanyName"
                    label={
                    <>
                    {documateLabel.REFI_PROCESSING_LABELS.companyName}
                    <span className="text-danger ms-1">*</span>
                    </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="WritingCompanyName"
                      value={currentDocument.WritingCompanyName ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "WritingCompanyName",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      maxLength={60}
                      isInvalid={
                        formValidation["WritingCompanyName"]?.isValid == false
                      }
                    />
                    {formValidation["WritingCompanyName"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["WritingCompanyName"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
              </Row>
              <Row>
                <Column lg={8} md={8}>
                  <FloatingLabel
                    controlId="VestingAddressLine1"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.propAddress}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="VestingAddressLine1"
                      value={currentDocument.VestingAddressLine1 ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "VestingAddressLine1",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      maxLength={100}
                      isInvalid={formValidation["VestingAddressLine1"]?.isValid == false}
                    />
                    {formValidation["VestingAddressLine1"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["VestingAddressLine1"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
                <Column lg={4} md={4}>
                  <FloatingLabel
                    controlId="VestingAddressLine2"
                    label={"Address 2"}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="VestingAddressLine2"
                      value={currentDocument.VestingAddressLine2 ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "VestingAddressLine2",
                          event.currentTarget.value
                        )
                      }
                      tabIndex={1}
                    />
                  </FloatingLabel>
                </Column>
              </Row>
              <Row>
                <Column lg={3} md={3}>
                  <FloatingLabel
                    controlId="VestingAddrCity"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.city}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="VestingAddrCity"
                      value={currentDocument.VestingAddrCity ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "VestingAddrCity",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      maxLength={60}
                      isInvalid={formValidation["VestingAddrCity"]?.isValid == false}
                    />
                    {formValidation["VestingAddrCity"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["VestingAddrCity"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
                <Column lg={5} md={5}>
                  <Select
                    id="VestingAddrState"
                    controlId="VestingAddrState"
                    className="mb-3"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.state}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    options={statesList}
                    defaultSelected={statesList.filter(
                      (option) => option.value === currentDocument.VestingAddrState
                    )}
                    onChange={(values) =>
                      onEntityFieldChange("VestingAddrState", values[0] as string)
                    }
                    tabIndex={6}
                    isInvalid={formValidation["VestingAddrState"]?.isValid == false}
                    invalidWarning={formValidation["VestingAddrState"]?.warningText}
                    isRequired={true}
                  />
                </Column>
                <Column lg={4} md={4}>
                  <FloatingLabel
                    controlId="VestingAddrZipCode"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.zip}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="VestingAddrZipCode"
                      value={currentDocument.VestingAddrZipCode ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "VestingAddrZipCode",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      maxLength={9}
                      tabIndex={1}
                      isInvalid={formValidation["VestingAddrZipCode"]?.isValid == false}
                    />
                    {formValidation["VestingAddrZipCode"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["VestingAddrZipCode"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
              </Row>
            </Panel>
            <Panel title={documateLabel.REFI_PROCESSING_LABELS.mortgageePanel}>
              <Row>
                <Column lg={3} md={3}>
                  <FloatingLabel
                    controlId="LoanNumber"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.loanNumber}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="LoanNumber"
                      value={currentDocument.LoanNumber ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "LoanNumber",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      isInvalid={formValidation["LoanNumber"]?.isValid == false}
                    />
                    {formValidation["LoanNumber"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["LoanNumber"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
                <Column lg={5} md={5}>
                  <Select
                    id="LoanPosition"
                    controlId="LoanPosition"
                    className="mb-3"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.loanPosition}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    options={loanPositionOptions}
                    defaultSelected={loanPositionOptions.filter(
                      (option) => option.value === currentDocument.LoanPosition
                    )}
                    onChange={(values) =>
                      onEntityFieldChange("LoanPosition", values[0] as string)
                    }
                    tabIndex={6}
                    isInvalid={formValidation["LoanPosition"]?.isValid == false}
                    invalidWarning={formValidation["LoanPosition"]?.warningText}
                    isRequired={true}
                  />
                </Column>
                <Column lg={4} md={4}>
                  <Select
                    id="Impound"
                    controlId="Impound"
                    className="mb-3"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.impounds}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    options={impoundsOptions}
                    sortOptions={false}
                    defaultSelected={impoundsOptions.filter(
                      (option) => option.value === currentDocument.Impound
                    )}
                    onChange={(values) =>
                      onEntityFieldChange("Impound", values[0] as string)
                    }
                    tabIndex={6}
                    isInvalid={formValidation["Impound"]?.isValid == false}
                    invalidWarning={formValidation["Impound"]?.warningText}
                    isRequired={true}
                  />
                </Column>
              </Row>
              <Row>
                <Column lg={8} md={8}>
                  <FloatingLabel
                    controlId="MortgageeName"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.mortagageeName}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="MortgageeName"
                      value={currentDocument.MortgageeName ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "MortgageeName",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      maxLength={60}
                      isInvalid={
                        formValidation["MortgageeName"]?.isValid == false
                      }
                    />
                    {formValidation["MortgageeName"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["MortgageeName"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
              </Row>
              <Row>
                <Column lg={8} md={8}>
                  <FloatingLabel
                    controlId="MortgageeClause"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.mortgageeClause}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className={"mb-3"}
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="MortgageeClause"
                      value={currentDocument.MortgageeClause ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "MortgageeClause",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      maxLength={60}
                      isInvalid={
                        formValidation["MortgageeClause"]?.isValid == false
                      }
                    />
                    {formValidation["MortgageeClause"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["MortgageeClause"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
              </Row>
              <Row>
                <Column lg={8} md={8}>
                  <FloatingLabel
                    controlId="MortgageeNameAddressLine1"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.mortgageeAddress}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="MortgageeNameAddressLine1"
                      value={currentDocument.MortgageeNameAddressLine1 ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "MortgageeNameAddressLine1",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      maxLength={100}
                      isInvalid={
                        formValidation["MortgageeNameAddressLine1"]?.isValid == false
                      }
                    />
                    {formValidation["MortgageeNameAddressLine1"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["MortgageeNameAddressLine1"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
                <Column lg={4} md={4}>
                  <FloatingLabel
                    controlId="MortgageeNameAddressLine2"
                    label={"Address 2"}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="MortgageeNameAddressLine2"
                      value={currentDocument.MortgageeNameAddressLine2 ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "MortgageeNameAddressLine2",
                          event.currentTarget.value
                        )
                      }
                      tabIndex={1}
                    />
                  </FloatingLabel>
                </Column>
              </Row>
              <Row>
                <Column lg={3} md={3}>
                  <FloatingLabel
                    controlId="MortgageeNameAddrCity"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.city}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="MortgageeNameAddrCity"
                      value={currentDocument.MortgageeNameAddrCity ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "MortgageeNameAddrCity",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      maxLength={60}
                      isInvalid={
                        formValidation["MortgageeNameAddrCity"]?.isValid == false
                      }
                    />
                    {formValidation["MortgageeNameAddrCity"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["MortgageeNameAddrCity"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
                <Column lg={5} md={5}>
                  <Select
                    id="MortgageeNameAddrState"
                    controlId="MortgageeNameAddrState"
                    className="mb-3"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.state}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    options={statesList}
                    defaultSelected={statesList.filter(
                      (option) =>
                        option.value === currentDocument.MortgageeNameAddrState
                    )}
                    onChange={(values) =>
                      onEntityFieldChange("MortgageeNameAddrState", values[0] as string)
                    }
                    tabIndex={6}
                    isInvalid={
                      formValidation["MortgageeNameAddrState"]?.isValid == false
                    }
                    invalidWarning={
                      formValidation["MortgageeNameAddrState"]?.warningText
                    }
                    isRequired={true}
                  />
                </Column>
                <Column lg={4} md={4}>
                  <FloatingLabel
                    controlId="MortgageeNameAddrZipCode"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.zip}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="MortgageeNameAddrZipCode"
                      value={currentDocument.MortgageeNameAddrZipCode ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "MortgageeNameAddrZipCode",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      isInvalid={
                        formValidation["MortgageeNameAddrZipCode"]?.isValid == false
                      }
                      maxLength={9}
                    />
                    {formValidation["MortgageeNameAddrZipCode"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["MortgageeNameAddrZipCode"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
              </Row>
            </Panel>
            <Panel title={documateLabel.REFI_PROCESSING_LABELS.requestorPanel}>
              <Row>
                <Column lg={5} md={5}>
                  <FloatingLabel
                    controlId="RequestorName"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.requestorName}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="RequestorName"
                      value={currentDocument.RequestorName ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "RequestorName",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      maxLength={60}
                      isInvalid={
                        formValidation["RequestorName"]?.isValid == false
                      }
                    />
                    {formValidation["RequestorName"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["RequestorName"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
                <Column lg={7} md={7}>
                  <FloatingLabel
                    controlId="RequestorEmail"
                    label={
                      <>
                        {documateLabel.REFI_PROCESSING_LABELS.email}
                        <span className="text-danger ms-1">*</span>
                      </>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder=" "
                      name="RequestorEmail"
                      value={currentDocument.RequestorEmail ?? ""}
                      onChange={(event) =>
                        onEntityFieldChange(
                          "RequestorEmail",
                          event.currentTarget.value
                        )
                      }
                      required={true}
                      tabIndex={1}
                      isInvalid={
                        formValidation["RequestorEmail"]?.isValid == false
                      }
                    />
                    {formValidation["RequestorEmail"]?.isValid == false && (
                      <Form.Control.Feedback type="invalid">
                        {formValidation["RequestorEmail"]?.warningText}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Column>
              </Row>
            </Panel>
            {CloseButtonWithDropdownOptions}
          </div>
          <div className="doc-view-container">
            <div className="button-container">
              <Button
                className="approve-btn"
                key="filter"
                onClick={() => onApproveDocument(id)}
                tabIndex={21}
                id="approveRefi"
                disabled={false}
              >
                <FontAwesomeIcon icon="check" className="me-2" />
                {documateLabel.APPROVE_DOCUMENT}
              </Button>
              <Button
                className="reject-btn"
                key="filter"
                tabIndex={21}
                id="rejectRefi"
                disabled={false}
              >
                <FontAwesomeIcon icon="times" className="me-2" />
                {documateLabel.REJECT_DOCUMENT}
              </Button>
            </div>
            {docViewerOpen && (
              <DocViewer
                docId=""
                scanId={fileName}
                handleDocViewerClose={handleDocViewerClose}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RefiProcessing;
