import React,{InputHTMLAttributes, useEffect, useState} from 'react'
import { Highlighter, Token, Typeahead, TypeaheadInputSingle, TypeaheadLabelKey, Hint } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
// import Form from 'react-bootstrap/Form'
import { Label } from '../Label'
import * as constant from '../../../../src/shared/util/constant'
import { useRef } from 'react'
import { ChangeEvent } from 'react'
import { Form, FloatingLabel } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectOption } from '../../../shared/components/input'

// interface SelectOption<T> {
//   label: string
//   value: T
//   isInactive:boolean
//   count?:number
// }

interface Props<T> {
  id: string
  label?: string | React.ReactNode;
  controlId?: string;
  floatingLabelClass?: string;
  options: SelectOption[]
  sortOptions?: boolean
  defaultSelected?: SelectOption[]
  onChange?: (values: T[]) => void
  placeholder?: string
  multiple?: boolean
  disabled?: boolean
  isValid?: boolean
  isInvalid?: boolean
  feedback?: string
  inputProps?:InputHTMLAttributes<HTMLInputElement>,
  tabIndex?:number
  invalidWarning?:string
  invalidLableClass?:string
  labelKey?:TypeaheadLabelKey<string>
  isRequired?:boolean
  isEmpty?:boolean
  errorText?:string
  className?:string
  errorLableDivClassName? : string
  dropDownIconClassName? : string
  selectMainDivInvalidClassName? : string
  dropup? : boolean
  showAsFloatingLabel?: boolean
}

function Select<T>(props: Props<T>) {
  const {
    id,
    label,
    controlId,
    floatingLabelClass,
    options,
    sortOptions,
    defaultSelected,
    onChange,
    placeholder,
    multiple,
    disabled,
    isValid,
    isInvalid,
    feedback,
    inputProps,
    tabIndex,
    invalidWarning,
    invalidLableClass,
    labelKey,
    isRequired,
    className,
    errorLableDivClassName,
    dropDownIconClassName,
    selectMainDivInvalidClassName,
    dropup,
    showAsFloatingLabel = true
    // isEmpty,
    // errorText
  } = props
  const ref = useRef<Typeahead>(null);

  let invalidLable = `cst-Feedback-Linebreak${invalidLableClass ? ' ' + invalidLableClass : ''}`;

  const [inputValue, setInputValue] = useState('');
  const [typeaheadKey, setTypeaheadKey] = useState(0);
  
  const [isExpanded, setIsExpanded] = useState(false);

  // useEffect(() => {
  //   console.log('isExpanded changed:', isExpanded);
  // }, [isExpanded]);

  let renderTokenTemplate = multiple ? (option, { onRemove }, index) => {
    return (
      <>
      
      {multiple &&<Token key={index} onRemove={onRemove} option={option} className={option.isInactive?"highlight-inactive":""}>
       {`${option.isInactive?"*"+option.label:option.label}`}
     </Token>}
     </>
   )} : null;

  let renderInputTemplate = multiple ? null : ({ inputRef, referenceElementRef, ...inputProps }) => {
    //console.log('defaultSelected:',defaultSelected[0]?.label,inputProps)
    let a = inputProps.value !== "" && defaultSelected !== undefined && !multiple ? (defaultSelected[0]?.isInactive ? "*" + defaultSelected[0]?.label : defaultSelected[0]?.label) : defaultSelected.length === 0 ? '' : [];


    return (
    <>
        <Hint>
          <FloatingLabel controlId="floatingLabel" label={label}>
            <Form.Control
              {...inputProps}
              className='custom-dropdown'
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
            />
          </FloatingLabel>
        </Hint>
        </>
  )};

  let iconClassName = `dropdown-autocomplete-arrow${dropDownIconClassName ? ' ' + dropDownIconClassName : ''}`;
  let mainSelectDivClassName = `custom-dropdown ${selectMainDivInvalidClassName && isInvalid ? ' ' + selectMainDivInvalidClassName : ''}`;
  return (
    <>
      <div title={inputProps?.title ?? ''} className={mainSelectDivClassName} >
        <FontAwesomeIcon
          // onClick={(e) => {
          //   e.stopPropagation();
           
          //     ref.current?.toggleMenu();
            
          //   // setIsExpanded( !ref.current?.state.showMenu);
          //   // isExpanded ? setIsExpanded(false) : setIsExpanded(true);
            
          // }}
          icon="angle-down"
          rotation={isExpanded ? 180 : undefined}
          style={{
            textAlign: "center",
            width: "10px",
            lineHeight: "10px",
            zIndex: 1,
            cursor: "pointer",
            transition: "transform 0.2s ease",
            pointerEvents: "none" // Make icon non-interactive
          }}
        />
        <Typeahead<SelectOption>
          key={typeaheadKey}
          id={id}
          ref={ref}
          dropup={dropup ? dropup : false}
          options={sortOptions === false ? options : options.sort((a, b) => (Number(b.isInactive === false) - Number(a.isInactive === false)) || a.label.localeCompare(b.label))}
          selected={defaultSelected}
          renderInput={showAsFloatingLabel !== false && renderInputTemplate}
          renderToken={renderTokenTemplate}

          className={className}
          caseSensitive={false}
          onChange={(selected: SelectOption[]) => {
            if (onChange !== undefined) {
              onChange(selected.map((option) => option.value as T))
            }
          }}
          renderMenuItemChildren={(option, props, idx) => (
            <>
              {/* <span className={option.isInactive?'highlight-inactive':''}>
          {option.isInactive?"*"+option.label:option.label}
          </span> */}
              <span className={option.isInactive ? 'highlight-inactive' : ''}>
                <Highlighter search={props.text}>
                  {option.isInactive ? "*" + option.label : option.label}
                </Highlighter>
              </span>
              {option.count && <span className='count'>{option.count}</span>}
            </>
          )}

          placeholder={label}
          positionFixed={true}
          flip={true}
          multiple={multiple}
          disabled={disabled}
          isInvalid={isInvalid}
          inputProps={inputProps}
          tabIndex={tabIndex}
          labelKey={labelKey}
          onInputChange={(text) => setInputValue(text)}
          onFocus={() => {
              setIsExpanded(true);
          }}
          onMenuToggle={(isOpen: boolean) => {
            console.log('onMenuToggle fired:', isOpen);
            setIsExpanded(isOpen);
          }}
          onBlur={() => {
            // setIsExpanded(false);
            const match = options.some((option) => 
              option.label.toLowerCase() === inputValue.toLowerCase()
            );
            if (!match) {
              setInputValue('');
              setTypeaheadKey((prevKey) => prevKey + 1);
            }

            // if (ref.current?.state.showMenu && !isExpanded) {
            //   setIsExpanded(false);
            // } 
            setIsExpanded(false);
            console.log(ref.current.state.showMenu);
            console.log(isExpanded);
            console.log('---');
          }}
          // onMenuToggle={(isOpen) => {
          //   console.log('onMenuToggle fired:', isOpen);
          //   setIsExpanded(isOpen);
          // }}
          filterBy={(option: SelectOption, selectProps: any) => {
            // change component default behavior
            // multiple - filter-out current selections
            const isMatch = option.label ? option.label.toLowerCase().indexOf(selectProps.text.toLowerCase()) !== -1 : false

            if (selectProps.selected.length && selectProps.multiple) {
              return selectProps.selected.every(
                (selected: any) => selected.label !== option.label && isMatch,
              )
            }
            // single (custom)- display all options
            if (selectProps.selected.length) {
              return true
            }
            // default filter as normal
            return isMatch
          }}
        />
        {/* {defaultSelected[0]?.isInactive && <span className='highlight-inactive'>{defaultSelected[0]?.label}</span>} */}
        {/* <div className={isValid ? 'is-valid' : isInvalid ? 'is-invalid' : undefined} /> */}
        <Form.Control.Feedback
          className={`text-left ml-3 mt-1 text-small cst-error ${isValid ? 'text-success' : isInvalid ? 'text-danger' : undefined
            }`}
          type={isValid ? 'valid' : 'invalid'}
        >
          {feedback}
        </Form.Control.Feedback>
      </div>
      <div className={(isInvalid ? `mt-n1 ${errorLableDivClassName ? errorLableDivClassName : ''}` : '')} >
        {(isInvalid) && <Label className={invalidLable}
          text={invalidWarning}
          style={{ color: "red" }} />}
      </div>

    </>
  )
}

Select.defaultProps = {
  defaultSelected: [],
  onChange: undefined,
  placeholder: '-- Choose --',
  multiple: false,
  disabled: false,
  isInvalid: false,
}

export { Select }
