import React, { useState, useRef, useEffect } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import './InfoDisplay.scss';

interface InfoDisplayProps {
  caption: string; // the title of the info display
  value: string; // the value to be displayed
  icon?: IconProp; // if an icon is provided, it will be displayed on the left side of the caption
  onClick?: () => void; // if a function is provided, the value will be clickable
  bordered?: boolean; // if true, the info display will have a dashed border
  copyable?: boolean; // if true, a copy button will be displayed on the right side of the value
  multiline?: boolean; // if true, the value will be displayed as multiline and parsed by '\n'
  isExternal?: boolean; // if true, the value will be opened in a new tab
}

export const InfoDisplay: React.FC<InfoDisplayProps> = ({
  caption,
  value,
  icon,
  onClick,
  bordered = false,
  multiline = false,
  copyable = false,
  isExternal = false
}) => {

  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const copyButtonRef = useRef(null);
  let tooltipTimeout: NodeJS.Timeout;

  useEffect(() => {
    return () => {
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
  }, []);

  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    setShowCopyTooltip(true);
    tooltipTimeout = setTimeout(() => {
      setShowCopyTooltip(false);
    }, 500);
  };

  const ValueWrapper = onClick ? 'a' : 'span';

  return (
    <div className={`info-display ${bordered ? 'bordered' : ''}`}>
      {icon && (
        <div className="info-icon text-muted">
          <FontAwesomeIcon icon={icon} size="2x" />
        </div>
      )}
      <div className="info-content">
        <div className="info-caption text-muted">{caption}</div>
        <div className="info-value-wrapper">
          <ValueWrapper
            className={`info-value ${onClick ? 'clickable' : ''}`}
            onClick={onClick}
            href={onClick ? '#' : undefined}
            target={isExternal ? '_blank' : undefined}
            rel={isExternal ? 'noopener noreferrer' : undefined}
          >
            {multiline
              ? value.split('\n').map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  {i !== value.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))
              : value}
            {isExternal && onClick && (
              <FontAwesomeIcon
                icon="arrow-up-right-from-square"
                className="ms-1 external-link-icon"
                size="sm"
              />
            )}
          </ValueWrapper>
        </div>
      </div>
      {copyable && (
        <button
          ref={copyButtonRef}
          className="copy-button"
          onClick={handleCopy}
          title="Copy to clipboard"
        >
          <FontAwesomeIcon size='lg' icon={faCopy} />
        </button>
      )}
      <Overlay target={copyButtonRef.current} show={showCopyTooltip} placement="top">
        {(props) => (
          <Tooltip {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};