import { Select, Label, Row, Column,Panel,Spinner, TextInput, Icon } from '../../components'
import React, { useContext, useEffect, useState } from 'react'
import { SelectOption } from '../../shared/components/input/SelectOption'
import '../customer.css'
import TextInputWithLabelInline from '../../shared/components/input/TextInputWithLabelInline'
import{getPolicyStatusList, getStateCodeList} from '../hooks/useLookUpOptions'
import CustomerFilterRequest from '../model/CustomerFilterRequest'
import {QuickSearchContext} from '../../customer/customer'
import {showGridContext} from '../filter/CustomerFilter'
import { ErrorBoundary } from 'react-error-boundary'
import _ from 'lodash'
import Logger from '../../shared/services/Logger'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import * as constant from '../../shared/util/constant'
import * as label from '../../shared/util/label'
import CustomerStandardSearchService from './CustomerStandardSearchService'
import FormattedInputWithLableInline, { InputFormatType } from '../../shared/components/input/formatted-input/FormattedInputWithLableInline'
import Container  from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form';
import { AbilityContext } from '../../shared/abilities/AbilityContext'

import { Button } from 'primereact/button';
import { FloatLabel } from 'primereact/floatlabel'
import { InputText } from 'primereact/inputtext'
import { InputMask } from 'primereact/inputmask';
import { Col, FloatingLabel, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// const updateTitle = useUpdateTitle()
//   useEffect(() => {
//     updateTitle('Customer Filter')
//   })
// interface Props {
//   onSearching: (filterRequest: CustomerFilterRequest) => void,
//   onAllowingSearching: (isSearchingAllowed: boolean) => void
// }
// interface Props{
//   onSearch:Function
// }


  const CustomerStandardFilterForm = (props) => {
    //const {onSearching, onAllowingSearching } = props

  //   const [state, setState] = useState({
  //   isPaneOpen: false,
  //   isPaneOpenLeft: false
  // });

  const custSearchService = new CustomerStandardSearchService();

  const [collapse, SetisCollapse]=useState(true) // Address / Mail Search
  const [mailCollapse, SetMailCollapse]=useState(false)
  const [applicantSearch, SetApplicantSearch]=useState(true)
  const [coapplicantSearch, SetCoApplicantSearch]=useState(false)
  const [quickSearchStatus, SetquickSearchStatus]=useState(false)
  
  

  const initalFilterState: CustomerFilterRequest = {
    firstName: '',
    lastName: '',
    eMail : '',
    homePhone: '',
    address1: '',
    address2: '',
    city: '',
    county: '',
    zipCode: '',
    state : '',
    isPropertySearch : null
    /* Uncommenting for now - enable back when advance serach is enabled.
    ApplicantOccupation: '',
    ApplicantSelfemployed: '',
    ApplicantDateofBirthFrom: null,
    ApplicantDateofBirthTo: null,
    CoApplicantFirstName: '',
    CoApplicantLastName: '',
    CoApplicantEmail : '',
    CoApplicantPhone: '',
    CoApplicantOccupation: '',
    CoApplicantSelfemployed: '',
    CoApplicantDateofBirthFrom: null,
    CoApplicantDateofBirthTo: null,
    LastDateSolicited:null,
    SolicitedCampaignsTimeframe:'',
    DaySinceLastSolicited:'',
    ByCampaigns:'',
    Unsubscribe:'',
    DoNotEmail:'',
    DoNotMail:'',
    EDD:'',
    SMSText:'',
    PropertyAppSuit:'',
    PropertyCity:'',
    PropertyCountry:'',
    PropertyState:'',
    PropertyZip:'',
    MailingStreetAddress:'',
    MailingAppSuit:'',
    MailingCity:'',
    MailingCountry:'',
    MailingState:'',
    MailingZip:'',
    RoofUpdateYearFrom:null,
    RoofUpdateYearTo:null,
    RoofType:'',
    RoofShape:'',
    RoofMaterial:'',
    ConstructionType:'',
    Pool:'',
    Trampoline:'',
    DogBreed:'',
    Carrier:'',
    WritingCompany:'',
    YearAsCustomer:'',
    Agency:'',
    PolicyType:'',
    PremiumFrom:'',
    PremiumTo:'',
    LoanNumber:'',
    NotationCode:'',
    CoverageARangeFrom:'',
    CoverangeARangeTo:'',
    YearBuiltRangeFrom:'',
    YearBuiltRangeTo:'',
    IntialPolicyEffectiveDateFrom:null,
    InitialPolicyEffectiveDateTo:null,
    CurrentPolicyEffectiveDateFrom:null,
    CurrentPolicyEffectiveDateTo:null,
    PolicyStatus:'',
    LastNoteEnteredBy:'',
    AllPerilDeductiblesFrom:null,
    AllPerilDeductibleTo:null,
    WindHailDeductiblesFrom:null,
    WindHailDeductiblesTo:null,
    HurricaneDeductiblesFrom:null,
    HurricaneDeductiblesTo:null,
    DwellingCoverageFrom:null,
    DwellingCoverageTo:null,
    PolicyNumber:'',
    AMSNumber:'',
    IsApplicantSearch:applicantSearch,
    IsCoApplicantSearch:coapplicantSearch,
    IsPropertySearch:collapse,
    IsMailingSearch:mailCollapse,
    IsQuickSearch:quickSearchStatus,
    QuickSearchString:''
    */
  }

   
  const [isSearchingAllowed, setIsSearchingAllowed] = useState(false);
  const context = useContext(showGridContext);
  //const [GridContex,SetGridContext]=useContext(showGridContext);
  
  const {GridVisibility,InputFilter, searchResultData }=useContext(showGridContext);
  const [showGrid, setShowGrid]=GridVisibility
  const [searchFilter, setInputFilter]=InputFilter
  const {searchResult, setsearchResult} = searchResultData
  
  const [filter, setSearchFilter] = useState<CustomerFilterRequest>(initalFilterState);
  const {quickFilterData}=useContext(QuickSearchContext);
  //const[quickFilterContextData, setquickFilterContextData]=quickFilterData
  const[quickSearch, setQuickSearch]=useState(quickFilterData);

  const ability = useContext(AbilityContext);
  const canUserCreateCustomer = ability.can('create',"Customer",constant.ScreenName.CUSTOMER);
  

  
  let addressStates = []

  //console.log("Standard Search"+ filter);
    useEffect(() => {
    if (custSearchService.IsFilterInputValid(filter)) {
      setIsSearchingAllowed(true);
      //setShowGrid(true)
    }
    else {
      //onAllowingSearching(false);
      setIsSearchingAllowed(false);
     // setShowGrid(false)

    }

  }, [filter]);



    const onSearch = async () => {
      /*
      //console.log('Standard Search')
      if(quickSearch!= null && quickSearch!=undefined)
      {
      filter.IsQuickSearch=quickSearch[0].IsQuickSearch;
      filter.QuickSearchString=quickSearch[0].QuickSearchString
      setQuickSearch(quickFilterData)
      SetquickSearchStatus(filter.IsQuickSearch)
      }
      */
      //setSearchFilter(filter);
      //setInputFilter(filter)

      // GridContex.GridVisibility=showGrid
      // GridContex.InputFilter=filter
      // SetGridContext(GridContex)

      try {
        let customerSearchData = await custSearchService.FetchCustomers(filter);
        setsearchResult(customerSearchData);

        if (!customerSearchData || customerSearchData.length == 0) {
          toast.warn(constant.CUSTOMER_STANDARD_SEARCH_NO_RESULT_FOUND, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
      catch(ex)
      { 
        if (ex.status == 400 && ex.data.length > 0 && ex.data[0].errorMessage) {
            toast.warn(ex.data[0].errorMessage, {
              position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          toast.error(constant.CUSTOMER_STANDARD_SEARCH_ERROR_WHILE_SEARCHING, {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        Logger.logError(ex);
      }
      //setShowGrid(true);
    }

    useEffect(()=>{
      if(quickFilterData!=null &&quickFilterData[0].QuickSearchString!==undefined && quickFilterData[0].QuickSearchString.length>3 && quickFilterData[0].IsQuickSearch===true  )
      {
        onSearch();
      }
      },[quickFilterData]);

  const onFieldChange = (name: string, value: string | Date|boolean) => {
    setSearchFilter({
      ...filter,

      [name]: value,
    })
  }

 //const [PolicyOption, setPolicyOptions] = useState<SelectOption[]>([{ label: "", value: "" }]);

 const manageCollapse=(event)=>{
  if(event.target.checked)
  {
    SetisCollapse(true)
   onFieldChange("IsPropertySearch",event.target.checked)
  }
  else{
   SetisCollapse(false)
   onFieldChange("IsPropertySearch",event.target.checked)
  }

}
useEffect(() => {
//
},[collapse])

const manageMailCollapse=(event)=>{
  if(event.target.checked)
  {
    SetMailCollapse(true)
    onFieldChange("IsMailingSearch",event.target.checked)
  }
  else{
    SetMailCollapse(false)
    onFieldChange("IsMailingSearch",event.target.checked)
  }

}
useEffect(() => {
//
},[mailCollapse]);

const setPropertySearch = () => {
  setSearchFilter({
    ...filter,
    isPropertySearch : true
  });
  SetisCollapse(true);
};

const setMailingSearch = () => {
  setSearchFilter({
    ...filter,
    isPropertySearch : false
  });
  SetisCollapse(true);
};

 useEffect(() => {
//
},[collapse])


useEffect(() => {
//
},[mailCollapse]);

const manageApplicantSearch=(event)=>{
  if(event.target.checked)
  {
    SetApplicantSearch(true)
    onFieldChange("IsApplicantSearch",event.target.checked)
  }
  else{
    SetApplicantSearch(false)
    onFieldChange("IsApplicantSearch",event.target.checked)
    
  }

}


const manageCoApplicantSearch=(event)=>{
  if(event.target.checked)
  {
    SetCoApplicantSearch(true)
    onFieldChange("IsCoApplicantSearch",event.target.checked)
  }
  else{
    SetCoApplicantSearch(false)
    onFieldChange("IsCoApplicantSearch",event.target.checked)
  }

}

const onEnterClick =(event) => {
  if(isSearchingAllowed && event.target.id!=="btnStandardSearchClear" && event.key === 'Enter'){
    onSearch()
  }
}

useEffect(() => {
  window.addEventListener("keydown", onEnterClick);
    return () => {
        window.removeEventListener("keydown", onEnterClick);
    };

  },[onEnterClick]);

//#region Policy Status list
//const [filter, setFilter] = useState<CustomerFilterRequest>(initalFilterState);
const { data: policyStatus, isLoading: isLoadingPolicyStatus } = getPolicyStatusList();
      const [PolicyStatusOption, setPolicyStatusOptions] = useState<SelectOption[]>([{ label: "", value: "" }]);
/* enable when policy base search is enabled.
 useEffect(() => {
   if (policyStatus === undefined || isLoadingPolicyStatus) {

     <Spinner type="DotLoader" loading />
   }
   else {
     setPolicyStatusOptions(policyStatus.map((data) => {
       return {
         value: data.PolicyStatusCode,
         label: data.PolicyStatusName
       }
     }
     )
     )
   }

 }, [policyStatus])
 */
//#endregion Policy Status list
const FallbackComponent = ({ error, resetErrorBoundary }) => {
  console.log("An error occurred:");
  console.log(error.message);
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const onClearFilters = () => {
  setSearchFilter(initalFilterState);
  SetisCollapse(false);
  setsearchResult([]);
}

const onAddNewCustomer = () => {
  props.navigateTo("/customer-new");
}

    return (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Container fluid>
          <Row className="">

            <Column lg={7} md={7} className='border-end pe-3'>
              <Row className='mb-3'>
                <Column>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='left d-flex'>
                      <h6 className='poppins-bold me-3 mb-0' style={{ lineHeight: '34px' }}>Customer Search</h6>
                      <div className='mb-2 mt-1'>
                        <Form.Check
                          id='SearchApplicant'
                          tabIndex={11}
                          inline>
                          <Form.Check.Input
                            type="checkbox"
                            checked={applicantSearch}
                            onChange={manageApplicantSearch}
                          />
                          <Form.Check.Label>
                            {label.StandardSearchLable.SEARCH_INSURED}
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                      <div className='mb-2 mt-1'>
                        <Form.Check
                          id='SearchCoApplicant'
                          tabIndex={12}
                          inline
                        >
                          <Form.Check.Input
                            type="checkbox"
                            checked={coapplicantSearch}
                            onChange={manageCoApplicantSearch}
                          />
                          <Form.Check.Label>
                            {label.StandardSearchLable.SEARCH_CO_INSURED}
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                    </div>
                    <div className='right ms-auto'>
                      <Button onClick={onAddNewCustomer} size='small'>
                        <FontAwesomeIcon icon={'plus'} className='me-2'></FontAwesomeIcon>
                        Add New Customer
                      </Button>

                    </div>


                  </div>
                </Column>
              </Row>
              <Row>
                <Column md={4} className='border-end pe-3'>
                  <div className='mb-4'>
                    <FloatLabel>
                      <InputText
                        id="Policy"
                        value={filter.policyNumber}
                        onChange={(e) => onFieldChange('policyNumber', e.currentTarget.value)}
                        size={25}
                        tabIndex={4}
                        placeholder={label.StandardSearchLable.POLICY_NUMBER}
                      />
                      <label htmlFor="Policy">{label.StandardSearchLable.POLICY_NUMBER}</label>
                    </FloatLabel>
                  </div>
                  <div className='mb-0'>
                    <FloatLabel>
                      <InputText
                        id="custNumber"
                        name="custNumber"
                        value={filter.custNumber?.toString()}
                        onChange={(e) => onFieldChange('custNumber', e.currentTarget.value)}
                        maxLength={25}
                        tabIndex={5}
                        placeholder={label.StandardSearchLable.ACCOUNT}
                      />
                      <label htmlFor="custNumber">
                        {label.StandardSearchLable.ACCOUNT}
                      </label>
                    </FloatLabel>
                  </div>
                </Column>
                <Column md={8} className='ps-3'>
                  <Row>
                    <Column md={6}>
                      <div className='mb-4'>
                        <FloatLabel>
                          <InputText
                            id="FirstName"
                            value={filter.firstName}
                            onChange={(e) => onFieldChange('firstName', e.currentTarget.value)}
                            size={25}
                            tabIndex={7}
                            placeholder={label.StandardSearchLable.FIRST_NAME}
                          />
                          <label htmlFor="Policy">{label.StandardSearchLable.FIRST_NAME}</label>
                        </FloatLabel>
                      </div>

                      <div className='mb-0'>
                        <FloatLabel>
                          <InputText
                            id="LastName"
                            value={filter.lastName}
                            onChange={(e) => onFieldChange('lastName', e.currentTarget.value)}
                            size={25}
                            tabIndex={8}
                            placeholder={label.StandardSearchLable.LAST_NAME}
                          />
                          <label htmlFor="LastName">{label.StandardSearchLable.LAST_NAME}</label>
                        </FloatLabel>
                      </div>
                    </Column>
                    <Column md={6}>
                      <div className='mb-4'>
                        <div className="input-group has-validation mb-3">
                          <span className="input-group-text remove-right-radius">
                            <Icon icon="email" className="text-muted fa-fw" />
                          </span>
                          <FloatingLabel
                            controlId="mobile"
                            label={label.StandardSearchLable.EMAIL}
                          >
                            <Form.Control
                              type="email"
                              placeholder={label.StandardSearchLable.EMAIL}
                              name="mobile"
                              value={filter.eMail}
                              onChange={(e) => onFieldChange('eMail', e.currentTarget.value)}
                              tabIndex={20}
                              maxLength={15}
                            // isInvalid={formValidation['Phone']?.isValid == false}
                            />
                            {/* {formValidation['Phone']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['Phone']?.warningText}
                                                    </Form.Control.Feedback>
                                                )} */}
                          </FloatingLabel>
                        </div>

                      </div>

                      <div className='mb-0'>
                        <div className="input-group has-validation">
                          <span className="input-group-text remove-right-radius mb-3">
                            <Icon icon="mobile" className="text-muted fa-fw" />
                          </span>
                          <FloatLabel>
                            <InputMask
                              id="Phone"
                              value={filter.homePhone}
                              onChange={(newVal) => onFieldChange('homePhone', newVal.value)}
                              mask="999-999-9999"
                              tabIndex={10}
                              placeholder={label.StandardSearchLable.PHONE} />
                            <label htmlFor="Email">{label.StandardSearchLable.PHONE}</label>
                          </FloatLabel>
                        </div>

                      </div>

                    </Column>
                  </Row>

                </Column>
                <Column md={4}>
                </Column>
              </Row>
            </Column>

            <Column lg={5} md={5}>
              <Row >
                <Column className='ps-3'>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='left d-flex'>
                      <h6 className='poppins-bold me-3 mb-0' style={{ lineHeight: '34px' }}>Property Search</h6>
                      <div className='mb-2 mt-1'>
                        <Form.Check
                          id='SearchProperty'
                          inline
                          type="radio"
                          checked={collapse}
                            onChange={manageCollapse}
                          name="searchType"
                          tabIndex={13}
                          label={label.StandardSearchLable.SEARCH_PROPERTY}
                        />
                          
                      </div>
                      <div className='mb-2 mt-1'>
                        <Form.Check
                          id='SearchMailing'
                          inline
                          type="radio"
                          name="searchType"
                          tabIndex={14}
                          label={label.StandardSearchLable.SEARCH_MAILING}
                          checked={mailCollapse}
                            onChange={manageMailCollapse}
                        />
                      </div>
                    </div>
                  </div>

                  <Row>
                    {/* Street Address */}
                    <Column lg={8} md={8}>
                      <div className='mb-4'>
                        <FloatLabel>
                          <Form.Control
                            type="text"
                            id="address1"
                            name="address1"
                            value={filter.address1}
                            onChange={(e) => onFieldChange('address1', e.target.value)}
                            placeholder={label.StandardSearchLable.STREET_ADDRESS}
                            tabIndex={6}
                            maxLength={100}
                          />
                          <label htmlFor="address1">{label.StandardSearchLable.STREET_ADDRESS}</label>
                        </FloatLabel>
                      </div>

                    </Column>

                    {/* Unit/Apt */}
                    <Column lg={4} md={4}>
                      <div className='mb-4'>
                        <FloatLabel>
                          <Form.Control
                            type="text"
                            id="address2"
                            name="address2"
                            value={filter.address2}
                            onChange={(e) => onFieldChange('address2', e.target.value)}
                            placeholder={label.StandardSearchLable.APRARTMENT}
                            tabIndex={7}
                            maxLength={100}
                          />
                          <label htmlFor="address2">{label.StandardSearchLable.APRARTMENT}</label>
                        </FloatLabel>
                      </div>

                    </Column>
                  </Row>

                  <Row>
                    {/* City */}
                    <Column lg={5} md={5}>
                      <FloatLabel>
                        <Form.Control
                          type="text"
                          id="city"
                          name="city"
                          value={filter.city}
                          onChange={(e) => onFieldChange('city', e.target.value)}
                          placeholder=" "
                          tabIndex={8}
                          maxLength={50}
                        />
                        <label htmlFor="city">{label.StandardSearchLable.CITY}</label>
                      </FloatLabel>
                    </Column>

                    {/* State */}
                    <Column lg={4} md={4}>
                      <FloatLabel>
                        <Form.Select
                          id="state"
                          name="state"
                          value={filter.state}
                          onChange={(e) => onFieldChange('state', e.target.value)}
                          tabIndex={9}
                        >
                          <option value="">{" "}</option>
                          {addressStates?.map(state => (
                            <option key={state.value} value={state.value}>
                              {state.label}
                            </option>
                          ))}
                        </Form.Select>
                        <label htmlFor="state">{label.StandardSearchLable.STATE}</label>
                      </FloatLabel>
                    </Column>

                    {/* ZIP */}
                    <Column lg={3} md={3}>
                      <FloatLabel>
                        <Form.Control
                          type="text"
                          id="zipCode"
                          name="zipCode"
                          value={filter.zipCode}
                          onChange={(e) => onFieldChange('zipCode', e.target.value)}
                          placeholder=" "
                          tabIndex={10}
                          maxLength={10}
                        />
                        <label htmlFor="zipCode">{label.StandardSearchLable.ZIP}</label>
                      </FloatLabel>
                    </Column>
                  </Row>

                </Column>
              </Row>
            </Column>

          </Row>

          <Row>

            <Column>
              <div className='d-flex justify-content-between mb-2 mt-3'>
                <Button
                  key="Clear"
                  icon="bi bi-x-circle"
                  onClick={onClearFilters}
                  tabIndex={21}
                  id="btnStandardSearchClear"
                  label={label.StandardSearchLable.CLEAR}
                  unstyled={true}
                  outlined={true}
                />

                <Button
                  key="Search"
                  icon="bi bi-search"
                  onClick={onSearch}
                  disabled={!isSearchingAllowed}
                  tabIndex={20}
                  id="btnStandardSearch"
                  label={label.StandardSearchLable.SEARCH}
                // keyDown={(event) => event.key === 'Enter'? onSearch: event.preventDefault}
                />



              </div></Column>
          </Row>
        </Container>

      </ErrorBoundary>
    )
  
  }

  export default CustomerStandardFilterForm