import React, { useEffect, useState } from "react";
import { CustomerDependent } from "../model/CustomerDependentModel";
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Column, Select } from "../../components";
import TextInputWithLabelInline from "../../shared/components/input/TextInputWithLabelInline";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
import { CustomerScreentLables as cstScrLables } from "../../shared/util/label";
import './CustomerDependentView.scss';
import { SelectOption } from "../../shared/components/input";
import { ConfirmModal } from "../../shared/components/confirmModal";
import * as label  from '../../shared/util/label';
import * as constant from '../../shared/util/constant';
import FormattedInputWithLableInline, { InputFormatType } from "../../shared/components/input/formatted-input/FormattedInputWithLableInline";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import Button from 'react-bootstrap/Button';

export interface ICustomerDependentView
{
    index: number,
    dependent : CustomerDependent,
    maritalStatuses : SelectOption[],
    industries : SelectOption[],
    occupancies : SelectOption[],
    relationships : SelectOption[],
    onFieldChange : (collectionPropName : string, collectionEntityIdentifierPropName : string, collectionEntityIdentifierValue : any, propName: string, value: string | Date|boolean) => void,
    onDelete : (dependent : CustomerDependent) => void,
    formValidations : any,
    isEditable : boolean,
    isReset : boolean,
    divToScroll : any
}


const CustomerDependentView : React.FunctionComponent<ICustomerDependentView> = (props) => {

    const dp = props.dependent;
    const formValidations = props.formValidations;
    const [dependent, setDependent] = useState<CustomerDependent>({});
    const [formValidation, setFormValidation] = useState<any>({});
    const [initialDependent, setInitialDependent] = useState<CustomerDependent>(dp);

    const modalContext = useConfirmationModalContext();

    // TODO : Remove once modal handler implemented
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const removeDependent = () => {
        setShowDeleteConfirmation(false);
        props.onDelete(dependent);
    }

 useEffect(() => {
        setDependent(initialDependent);
    }, [props.isReset]);

    useEffect(() => {
        setDependent(dp);
    }, []);

    useEffect(() => {
        setFormValidation(formValidations ? formValidations : {});
    },[formValidations]);
    
    const maritalStatuses = props.maritalStatuses??[];
    const industries = props.industries??[];
    const occupancies = props.occupancies??[];
    const relationships = props.relationships??[];
    
   // const isEditable = true;
    

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        setDependent({
            ...dependent,
            [propName]: value,
          });
       props.onFieldChange('customerDependents', 'depdId', dependent.depdId,  propName, value);
    }

    const onDependentDelete = async() => {
        const result = await modalContext.showConfirmation(constant.CONFIRM_DEPENDENT_DELETE)
        if(result){
            removeDependent();
        }
    }

    return (
        <div className="cd-main-container mb-2" ref={props.divToScroll}>
            <Card>
                <Card.Header>
                    <div className="panel-title d-flex align-items-center">{props.index + 1}. Additional Customer
                        <Button
                            onClick={onDependentDelete}
                            variant="light"
                            size="sm"
                            className="ms-auto"
                            disabled={!props.isEditable}
                            id="btnDepdDel"
                        >
                            <i className="bi bi-trash-fill"></i>
                        </Button>
                    </div>

                </Card.Header>
                <Card.Body>
                    <Row>
                        <Column lg={4} md={4}>
                            <Select
                                id="relationshipId"
                                controlId="relationshipId"
                                className='mb-3'
                                label={cstScrLables.RELATIONSHIP}
                                options={relationships}
                                defaultSelected={relationships.filter(option =>
                                    option.value === dependent.relationshipId
                                )}
                                onChange={(values) => onEntityFieldChange('relationshipId', values[0] as string)}
                                disabled={!props.isEditable}
                                tabIndex={9}
                                isInvalid={formValidation['relationshipId']?.isValid == false}
                                invalidWarning={formValidation['relationshipId']?.warningText}
                            />
                        </Column>
                        <Column lg={4} md={4}>
                        <Select
                                id="maritalStatusId"
                                controlId="maritalStatusId"
                                className='mb-3'
                                label={cstScrLables.RELATIONSHIP}
                                options={maritalStatuses}
                                defaultSelected={maritalStatuses.filter(option =>
                                    option.value === dependent.maritalStatusId
                                )}
                                onChange={(values) => onEntityFieldChange('maritalStatusId', values[0] as string)}
                                disabled={!props.isEditable}
                                tabIndex={9}
                                isInvalid={formValidation['maritalStatusId']?.isValid == false}
                                invalidWarning={formValidation['maritalStatusId']?.warningText}
                            />
                            
                        </Column>
                    </Row>
                    <Row>
                        <Column lg={4} md={4}
                            className={formValidation['firstName']?.isValid == false ? 'feedback-row' : ''}>
                            <FloatingLabel
                                controlId="firstName"
                                label={<>
                                    {cstScrLables.FIRST_NAME}
                                    <span className="text-danger">*</span>
                                </>}
                                className="mb-3"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder=" "
                                    name="firstName"
                                    value={dependent.firstName ?? ""}
                                    onChange={(event) => onEntityFieldChange('firstName', event.currentTarget.value)}
                                    disabled={!props.isEditable}
                                    required
                                    maxLength={60}
                                    isInvalid={formValidation['firstName']?.isValid == false}
                                />
                                {formValidation['firstName']?.isValid == false && (
                                    <Form.Control.Feedback type="invalid">
                                        {formValidation['firstName']?.warningText}
                                    </Form.Control.Feedback>
                                )}
                            </FloatingLabel>
                        </Column>

                        <Column lg={4} md={4}>
                            <FloatingLabel
                                controlId="midName"
                                label={cstScrLables.MIDDLE_NAME}
                                className="mb-3"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder=" "
                                    name="midName"
                                    value={dependent.midName ?? ""}
                                    onChange={(event) => onEntityFieldChange('midName', event.currentTarget.value)}
                                    disabled={!props.isEditable}
                                    maxLength={60}
                                />
                            </FloatingLabel>
                        </Column>

                        <Column lg={4} md={4}
                            className={formValidation['lastName']?.isValid == false ? 'feedback-row' : ''}>
                            <FloatingLabel
                                controlId="lastName"
                                label={<>
                                    {cstScrLables.LAST_NAME}
                                    <span className="text-danger">*</span>
                                </>}
                                className="mb-3"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder=" "
                                    name="lastName"
                                    value={dependent.lastName ?? ""}
                                    onChange={(event) => onEntityFieldChange('lastName', event.currentTarget.value)}
                                    disabled={!props.isEditable}
                                    required
                                    maxLength={60}
                                    isInvalid={formValidation['lastName']?.isValid == false}
                                />
                                {formValidation['lastName']?.isValid == false && (
                                    <Form.Control.Feedback type="invalid">
                                        {formValidation['lastName']?.warningText}
                                    </Form.Control.Feedback>
                                )}
                            </FloatingLabel>
                        </Column>


                    </Row>
                    <Row>
                        <Column lg={4} md={4}
                            className={formValidation['mobile']?.isValid == false ? 'feedback-row' : ''}>
                            <FloatingLabel
                                controlId="mobile"
                                label={cstScrLables.CELL_PHONE}
                                className="mb-3"
                            >
                                <Form.Control
                                    type="tel"
                                    placeholder=" "
                                    name="mobile"
                                    value={dependent.mobile ?? ""}
                                    onChange={(event) => onEntityFieldChange('mobile', event.target.value)}
                                    disabled={!props.isEditable}
                                    maxLength={15}
                                    isInvalid={formValidation['mobile']?.isValid == false}
                                />
                                {formValidation['mobile']?.isValid == false && (
                                    <Form.Control.Feedback type="invalid">
                                        {formValidation['mobile']?.warningText}
                                    </Form.Control.Feedback>
                                )}
                            </FloatingLabel>
                        </Column>
                        <Column lg={4} md={4}
                            className={formValidation['homePhone']?.isValid == false ? 'feedback-row' : ''}>
                            <FloatingLabel
                                controlId="homePhone"
                                label={cstScrLables.HOME_PHONE}
                                className="mb-3"
                            >
                                <Form.Control
                                    type="tel"
                                    placeholder=" "
                                    name="homePhone"
                                    value={dependent.homePhone ?? ""}
                                    onChange={(e) => onEntityFieldChange('homePhone', e.target.value)}
                                    disabled={!props.isEditable}
                                    maxLength={15}
                                    isInvalid={formValidation['homePhone']?.isValid == false}
                                />
                                {formValidation['homePhone']?.isValid == false && (
                                    <Form.Control.Feedback type="invalid">
                                        {formValidation['homePhone']?.warningText}
                                    </Form.Control.Feedback>
                                )}
                            </FloatingLabel>
                        </Column>
                        <Column lg={4} md={4}>
                            <FloatingLabel
                                controlId="workPhone"
                                label={cstScrLables.WORK_PHONE}
                                className="mb-3"
                            >
                                <Form.Control
                                    type="tel"
                                    placeholder=" "
                                    name="workPhone"
                                    value={dependent.workPhone ?? ""}
                                    onChange={(e) => onEntityFieldChange('workPhone', e.target.value)}
                                    disabled={!props.isEditable}
                                    maxLength={15}
                                    isInvalid={formValidation['workPhone']?.isValid == false}
                                />
                                {formValidation['workPhone']?.isValid == false && (
                                    <Form.Control.Feedback type="invalid">
                                        {formValidation['workPhone']?.warningText}
                                    </Form.Control.Feedback>
                                )}
                            </FloatingLabel>
                        </Column>
                    </Row>
                    <Row>
                        <Column lg={4} md={4}>
                        <Select
                                id="industryId"
                                controlId="industryId"
                                className='mb-3'
                                label={cstScrLables.INDUSTRY}
                                options={industries}
                                defaultSelected={industries.filter(option =>
                                    option.value === dependent.industryId
                                )}
                                onChange={(values) => onEntityFieldChange('industryId', values[0] as string)}
                                disabled={!props.isEditable}
                                tabIndex={9}
                                isInvalid={formValidation['industryId']?.isValid == false}
                                invalidWarning={formValidation['industryId']?.warningText}
                            />
                            
                        </Column>

                        <Column lg={4} md={4}>
                        <Select
                                id="occupationId"
                                controlId="occupationId"
                                className='mb-3'
                                label={cstScrLables.OCCUPATION}
                                options={occupancies}
                                defaultSelected={occupancies.filter(option =>
                                    option.value === dependent.occupationId
                                )}
                                onChange={(values) => onEntityFieldChange('occupationId', values[0] as string)}
                                disabled={!props.isEditable}
                                tabIndex={9}
                                isInvalid={formValidation['occupationId']?.isValid == false}
                                invalidWarning={formValidation['occupationId']?.warningText}
                            />
                           
                        </Column>

                        <Column lg={4} md={4}
                            className={formValidation['yearEmployed']?.isValid == false ? 'feedback-row' : ''}>
                            <FloatingLabel
                                controlId="yearEmployed"
                                label={cstScrLables.YEAR_EMPLOYED}
                                className="mb-3"
                            >
                                <Form.Control
                                    type="number"
                                    placeholder=" "
                                    name="yearEmployed"
                                    value={dependent.yearEmployed ?? ""}
                                    onChange={(e) => onEntityFieldChange('yearEmployed', e.target.value)}
                                    disabled={!props.isEditable}
                                    maxLength={4}
                                    isInvalid={formValidation['yearEmployed']?.isValid == false}
                                />
                                {formValidation['yearEmployed']?.isValid == false && (
                                    <Form.Control.Feedback type="invalid">
                                        {formValidation['yearEmployed']?.warningText}
                                    </Form.Control.Feedback>
                                )}
                            </FloatingLabel>
                        </Column>


                    </Row>
                </Card.Body>
            </Card>

        </div>
    );
}

export default CustomerDependentView;