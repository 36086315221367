import { Select, Label, Button, Panel, Spinner, Row, Column, Modal, DateTimePicker, TextInput, Checkbox, TextField } from '../../components'
import { useContext, useEffect, useState } from 'react'
import CampaignApprovalDetailsUtils from '../utils/campaignApprovalUtils'
import { getCampaignQueryFiltersSetup } from "../utils/campaignQueryFiltersSetupUtils"
import TextInputWithLabelInline from '../../shared/components/input/TextInputWithLabelInline'
import { ErrorBoundary } from 'react-error-boundary'
import '../campaign.scss'
import CampaignApprovalUI from '../model/CampaignApprovalUI';
import * as constant from '../../shared/util/constant'
import * as label from '../../shared/util/label'
import CampaignQueryFilterUI from '../model/CampaignQueryFilterUI';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { FallbackComponent } from '../../shared/components/FallbackComponent';
import TextFieldWithLabelInline from '../../shared/components/input/TextFieldWithLabelInline';
import Logger from '../../shared/services/Logger'
import SendTest from '../shared/components/SendTest';
import ApproveRejectCampaign from '../model/ApproveRejectCampaign'
import { Container } from 'react-bootstrap'
import { AbilityContext } from '../../shared/abilities/AbilityContext'
import { createMemoryHistory } from 'history'

interface Props {
  campaignId: string
}

const CampaignApproval = (props: Props) => {

  const initialCampaignApprovalDetails: CampaignApprovalUI = {
    id: '',
    campaignName: '',
    description: '',
    templateType: constant.TEMPLATE_TYPE_MARKETING_ID.toLowerCase(),
    templateCategory: '',
    status: '',
    sendOn: null,
    changedDate: '',
    changedBy: '',
    templateId: '',
    queryId: '',
    isCampaignInactive: false,
    campaignFilters: null,
    results: 0,
    approvedBy: '',
    approvalDate: '',
    queryName: '',
    templateName: '',
    fromEmailName:''
  }
  // toast.configure();

  const [campaignApprovalDetails, setCampaigncampaignApprovalDetails] = useState<CampaignApprovalUI>(initialCampaignApprovalDetails);

  const [campaignQueryFilterSetup, setCampaignQueryFilterSetup] = useState<CampaignQueryFilterUI[]>();

  const [zipNumberCurrecnyValues, setZipNumberCurrecnyValues] = useState<string[]>([]);

  const [isDetailsPageFirstLoad, setIsDetailsPageFirstLoad] = useState(true);

  const [showSendTestPopup, setShowSendTestPopup] = useState(false);

  //   //Disabling for Reject,Approve start

  const [isRejectDisabled, setIsRejectDisabled] = useState(false)

  const campaignStatusEligibleForReject = [constant.Waiting_Approval, constant.APPROVED]

  const [isApproveDisabled, setIsApproveDisabled] = useState(false)

  //Disabling for Reject,Approve end

  const [showRejectReasonPopup, setShowRejectReasonPopup] = useState(false)

  const [rejectionReason, setRejectionReason] = useState("")

  const history = createMemoryHistory()

  const ability = useContext(AbilityContext);
  const ab_subject = "Campaign Approval";
  const isUserPermitToViewSendTest = ability.can('view', ab_subject,constant.ApprovalPrivileges.SEND_TEST)
  const isSendTestDisabled = !ability.can('read', ab_subject,constant.ApprovalPrivileges.SEND_TEST)
  const isUserPermitToViewReject = ability.can('view', ab_subject,constant.ApprovalPrivileges.REJECT)
  const isRejectDisabledForUserPermission = !ability.can('read', ab_subject,constant.ApprovalPrivileges.REJECT)
  const isUserPermitToViewApprove = ability.can('view', ab_subject,constant.ApprovalPrivileges.APPROVE)
  const isApproveDisabledForUserPermission = !ability.can('read', ab_subject,constant.ApprovalPrivileges.APPROVE)

  const handleCloseSendTest = () => {
    setShowSendTestPopup(false);
  }

  // Get the current date
  const currentDate = new Date();

  // Create a new Date object for tomorrow
  const tomorrowDate = new Date();
  tomorrowDate.setDate(currentDate.getDate() + 1);



  const BackToCampaignList = () => {
    history.push(`/Campaign/Campaign`);
  }

  const getQueryFilterSetup = async (queryId: string) => {
    if (queryId) {
      try {
        let response = await getCampaignQueryFiltersSetup(queryId)
        setCampaignQueryFilterSetup(response)
      }
      catch (error) {
        Logger.logError(error.toString());
      }
    }
    else {
      setCampaignQueryFilterSetup(null)
    }
  }


  const initializeCampaignApprovalDetails = async () => {

    if (props.campaignId) {
      try {
        let response = await CampaignApprovalDetailsUtils.getCampaignApprovalDetails(props.campaignId)
        setCampaigncampaignApprovalDetails(response);
        await getQueryFilterSetup(response.queryId);
      }
      catch (error) {
        toast.error(constant.ERROR_MESSAGE_LOAD, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: constant.AUTO_CLOSE
        });
        Logger.logError(error.toString());
      }
    } else {
      setCampaigncampaignApprovalDetails({
        ...campaignApprovalDetails,
        templateType: constant.TEMPLATE_TYPE_MARKETING_ID.toLowerCase()
      })
    }

  }

  const onReject = () => {
    setShowRejectReasonPopup(true)
  }

  const onApprove = async () => {

    
    let approveCampaignDetails: ApproveRejectCampaign = {
      campaignId: campaignApprovalDetails.id,
      isApproved:true, 
      rejectionReason:""
    } 

    try {
      let response = await CampaignApprovalDetailsUtils.approveRejectCampaignUtils(approveCampaignDetails)
      if(response)
      {
        setCampaigncampaignApprovalDetails({
          ...campaignApprovalDetails,
          status: constant.APPROVED
        })
        BackToCampaignList();
      }
      else
      {
        toast.error(constant.ERROR_MESSAGE, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: constant.AUTO_CLOSE
        });
      }
    }
    catch (error) {
      toast.error(constant.ERROR_MESSAGE, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: constant.AUTO_CLOSE
      });
      Logger.logError(error.toString());
    }

  }

  const proceedWithReject =async() => {

    let rejectCampaignDetails: ApproveRejectCampaign = {
      campaignId: campaignApprovalDetails.id,
      isApproved:false,
      rejectionReason:rejectionReason
    }
    

    try {
      let response = await CampaignApprovalDetailsUtils.approveRejectCampaignUtils(rejectCampaignDetails)
      if(response)
      {
        setCampaigncampaignApprovalDetails({
          ...campaignApprovalDetails,
          status: constant.REJECTED
        })
        BackToCampaignList();
      }
      else
      {
        toast.error(constant.ERROR_MESSAGE, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: constant.AUTO_CLOSE
        });
      }
    }
    catch (error) {
      toast.error(constant.ERROR_MESSAGE, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: constant.AUTO_CLOSE
      });
      Logger.logError(error.toString());
    }
  } 
   

  useEffect(() => {
    initializeCampaignApprovalDetails();
  }, []);


  useEffect(() => {
    if (campaignQueryFilterSetup) {
      if (campaignApprovalDetails.id.length !== 0 && isDetailsPageFirstLoad) {
        setCampaigncampaignApprovalDetails({
          ...campaignApprovalDetails,
          campaignFilters: campaignApprovalDetails.campaignFilters,
        });
      }
      else {
        setCampaigncampaignApprovalDetails({
          ...campaignApprovalDetails,
          campaignFilters: campaignQueryFilterSetup.map((filter) => ({
            filterId: filter.filterId,
            filterLabel: filter.filterLabel,
            filterConditionSelectedId:
              filter.filterType.toLocaleLowerCase() === constant.Data_Type_Currency_Range || filter.filterType.toLocaleLowerCase() === constant.Data_Type_Date_Range
                || filter.filterType.toLocaleLowerCase() === constant.Data_Type_Number_Range ? filter.filterConditionOptions[0].campaignFilterConditionId : "",
            filterValue: [],
          }))
        })
      }

      setIsDetailsPageFirstLoad(false)

      const initialArrayLength = campaignQueryFilterSetup.length;
      const defaultZipNumberCurrencyValue = '';
      const initialZipNumberCurrencyValue = Array.from({ length: initialArrayLength }, () => defaultZipNumberCurrencyValue);
      setZipNumberCurrecnyValues(initialZipNumberCurrencyValue)
      if (campaignApprovalDetails.campaignFilters) {
        const updatedArrayForZipNumberCurrencyValue = initialZipNumberCurrencyValue
        var index = 0
        campaignApprovalDetails.campaignFilters.forEach(campaignFilter => {
          if (campaignApprovalDetails.campaignFilters[index].filterValue) {
            updatedArrayForZipNumberCurrencyValue[index] = campaignFilter.filterValue.join(';');
          }
          index += 1
        });
      }
    }
    else {
      setCampaigncampaignApprovalDetails({
        ...campaignApprovalDetails,
        campaignFilters: null
      })
    }

  }, [campaignQueryFilterSetup])

  useEffect(() => {
    setIsRejectDisabled(!(campaignStatusEligibleForReject.includes(campaignApprovalDetails.status)))
    setIsApproveDisabled(!(campaignApprovalDetails.status.toLocaleLowerCase()===constant.Waiting_Approval.toLocaleLowerCase() && campaignApprovalDetails.sendOn>=currentDate))
  }, [campaignApprovalDetails?.status])

  const getSelectedFilterCondition =(filter, index) => {
    return campaignApprovalDetails?.campaignFilters[index]?.filterConditionSelectedId !== null ?
      filter.filterConditionOptions.map((option) => ({
        label: option.campaignFilterConditionValue,
        value: option.campaignFilterConditionId,
        isInactive:option.isInactive
      }))?.filter(({ value }) => value === campaignApprovalDetails?.campaignFilters[index]?.filterConditionSelectedId
      )
      : null
    }

  const getSelectedFilterValue = (filter, index) => {
    return campaignApprovalDetails?.campaignFilters[index]?.filterValue !== null ?
      filter.filterOptions.map((option) => ({
        label: option.campaignFilterOptionsValue,
        value: option.campaignFilterOptionsId,
        isInactive:option.isInactive
      }))?.filter(({ value }) => campaignApprovalDetails?.campaignFilters[index]?.filterValue?.includes(value)
      )
      : null
  }


  return (
    <>
      <Row>
        <Column lg={12} md={12}>
          <Button icon='up-indication' outlined={true} onClick={BackToCampaignList} color="black">
          </Button>
          {campaignApprovalDetails.campaignName}</Column>
      </Row>
      <Row className="cstCampaignSetting">

        <Column lg={12} md={12}>
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <Panel
              collapsible={false}
              title={label.CAMPAIGN_APPROVAL}
              className={'cstPanel shadow-sm'}
              cardBodyClassName={'p-0'}
              collapsed={false}>
              <Column lg={12} md={12} className='cstDetailSection'>
                <Container fluid>
                  <Row>
                    <Column lg={12}>
                      <TextInputWithLabelInline
                        label={label.CAMPAIGN_NAME}
                        placeholder={label.NAME}
                        name="campaignName"
                        labelColClassName='bg-light'
                        className='camapigns-texbox-input my-2'
                        value={campaignApprovalDetails.campaignName}
                        isEditable={false}
                        lglabel={2}
                        mdlabel={2}
                        lgText={10}
                        mdText={10}
                        labelClass='inlineLable mt-3 mb-0'
                        maxLength={150}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column lg={12}>
                      <TextFieldWithLabelInline
                        label={label.DESCRIPTION}
                        title={campaignApprovalDetails.description}
                        name='campaignDescription'
                        className='camapigns-texbox-input my-2'
                        labelClass='inlineLable mt-3 mb-0'
                        value={campaignApprovalDetails.description}
                        isEditable={false}
                        placeholder={label.DESCRIPTION}
                        tabIndex={2}
                        lglabel={2}
                        mdlabel={2}
                        lgText={10}
                        mdText={10}
                        maxLength={250}
                      />
                    </Column>
                  </Row>

                  <Row>
                    <Column lg={2} md={2} className='bg-light'>
                      <Label className='inlineLable mt-3 mb-0' text={label.TEMPLATE_TYPE} />
                    </Column>
                    <Column lg={10} md={10}>
                      <Row>
                        <Column lg={4} md={4} className='-left'>
                          <TextInput
                            placeholder={label.PLEASE_SELECT}
                            name="campaignTemplateType"
                            className='camapigns-texbox-input my-2'
                            value={campaignApprovalDetails.templateType}
                            disabled={true}
                          />

                        </Column>
                        <Column lg={8} md={8} className='text-end'>
                          <TextInputWithLabelInline
                            label={label.TEMPLATE_CATEGORY}
                            placeholder={label.PLEASE_SELECT}
                            labelColClassName='bg-transparent'
                            name="campaignTemplateCategory"
                            className='camapigns-texbox-input my-2'
                            value={campaignApprovalDetails.templateCategory}
                            isEditable={false}
                            lglabel={4}
                            mdlabel={4}
                            lgText={8}
                            mdText={8}
                            labelClass='inlineLable mt-3 mb-0'
                          />
                        </Column>
                      </Row>
                    </Column>

                  </Row>
                  <Row>
                    <Column lg={12}>
                      <TextInputWithLabelInline
                        label={label.EXTERNAL_TEMPLATE}
                        labelColClassName='bg-light'
                        placeholder={label.PLEASE_SELECT}
                        name="campaignTemplate"
                        className='camapigns-texbox-input my-2'
                        value={campaignApprovalDetails.templateName}
                        isEditable={false}
                        lglabel={2}
                        mdlabel={2}
                        lgText={10}
                        mdText={10}
                        labelClass='inlineLable mt-3 mb-0'
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column lg={12}>
                      <TextInputWithLabelInline
                        label={label.QUERY_NAME}
                        labelColClassName='bg-light'
                        placeholder={label.PLEASE_SELECT}
                        name="campaignQuery"
                        className='camapigns-texbox-input my-2'
                        value={campaignApprovalDetails.queryName}
                        isEditable={false}
                        lglabel={2}
                        mdlabel={2}
                        lgText={10}
                        mdText={10}
                        labelClass='inlineLable mt-3 mb-0'
                      />
                    </Column>
                  </Row>


                  {/* Filter criteria start */}
                  {campaignQueryFilterSetup != null && campaignQueryFilterSetup != undefined && campaignApprovalDetails.campaignFilters != null && campaignQueryFilterSetup.length > 0 &&
                    <><Row
                      className={'filterRow'}
                      style={{ height: campaignQueryFilterSetup.length * 45 }}>
                      <Column lg={2} md={2} style={{ borderBottom: "0px" }}><Label text={label.FILTER_CRITERIA} /></Column>
                      <Column lg={10} md={10} style={{ borderBottom: "0px" }}>
                        {campaignQueryFilterSetup.map((filter, index) => (
                          <Row style={{ height: "45px" }}>
                            <Column lg={2} md={2} className='-left'>
                              <Label text={filter.filterLabel} />
                            </Column>
                            {/* Filter  conditions Dropdown started */}
                            {Array.isArray(filter.filterConditionOptions) && filter.filterConditionOptions.length > 0
                              && filter.filterType.toLocaleLowerCase() != constant.Data_Type_Currency_Range && filter.filterType.toLocaleLowerCase() != constant.Data_Type_Date_Range
                              && filter.filterType.toLocaleLowerCase() != constant.Data_Type_Number_Range &&
                              (
                                <Column lg={3} md={3} className='-left'>
                                  <Select
                                    id={`filter-condition-${index}`}
                                    placeholder={label.SELECT_CONDITION}
                                    isRequired={true}
                                    // isInvalid={isFilterConditionsEmpty[index]}
                                    options={filter.filterConditionOptions.map((option) => ({
                                      label: option.campaignFilterConditionValue,
                                      value: option.campaignFilterConditionId,
                                      isInactive: option.isInactive
                                    }))}
                                    defaultSelected={getSelectedFilterCondition(filter, index)}
                                    inputProps={{
                                      className: `form-control texbox-input cstCollection `, multiple: true,
                                      title: (
                                        getSelectedFilterCondition(filter, index)?.map((option) => option.label).toString().replaceAll(',', '\r\n')
                                      )
                                    }}
                                    disabled={true}
                                  />
                                </Column>
                              )}
                            {/* Filter  conditions Dropdown ended */}
                            {/* Filter values start */}
                            {/* Filter Dropdown start */}
                            {(filter.filterType.toLowerCase() === constant.Data_Type_Dropdown_SQL || filter.filterType.toLowerCase() === constant.Data_Type_Dropdown_List)
                              && (
                                <Column lg={6} md={6} className='-left mt-1'>
                                  <Select
                                    id={`filter-value-dropdown-${index}`}
                                    options={filter.filterOptions.map((option) => ({
                                      label: option.campaignFilterOptionsValue,
                                      value: option.campaignFilterOptionsId,
                                      isInactive: option.isInactive
                                    }))}
                                    placeholder={label.SELECT_VALUE}
                                    multiple={true}
                                    // isInvalid={isFilterValuesEmpty[index]}
                                    defaultSelected={getSelectedFilterValue(filter, index)}
                                    inputProps={{
                                      className: `form-control texbox-input cstCollection `, multiple: true,
                                      title: (
                                        getSelectedFilterValue(filter, index)?.map((option) => option.label).toString().replaceAll(',', '\r\n')
                                      )
                                    }}
                                    disabled={true}
                                  />
                                </Column>
                              )}

                            {/* Filter Dropdown end */}

                            {/* Filter currency/numeric start */}
                            {(
                              filter.filterType.toLowerCase() === constant.Data_Type_Number || filter.filterType.toLowerCase() === constant.Data_Type_ZipCode || filter.filterType.toLowerCase() === constant.Data_Type_Currency
                            )
                              && (
                                <><Column lg={6} md={6} className='-left'>
                                  <TextInput
                                    id={`filter-initial-value-text-${index}`}
                                    placeholder={constant.FILTER_CRITERIA_ZIP_NUMBER_CURRENCY_PLACEHOLDER}
                                    // isInvalid={!isFilterValuesEmpty[index] ? isInvalidZipNumbersCurrency[index] ? true : false : true}
                                    value={zipNumberCurrecnyValues[index]}
                                    title={zipNumberCurrecnyValues[index]}
                                    onKeyPress={(event) => {
                                      if (event.key === 'e' || event.key === '-' || event.key === '+') {
                                        event.preventDefault()
                                      }
                                      else {
                                        return true;
                                      }
                                      return false
                                    }}
                                    disabled={true}
                                  />
                                </Column>
                                </>
                              )}

                            {(filter.filterType.toLowerCase() === constant.Data_Type_Currency_Range ||
                              filter.filterType.toLowerCase() === constant.Data_Type_Number_Range)
                              && (
                                <>
                                  <Column lg={4} md={4} className='-left'>
                                    <TextInput
                                      id={`filter-initial-value-text-${index}`}
                                      type='number'
                                      value={
                                        campaignApprovalDetails.campaignFilters[index]?.filterValue[0]
                                      }
                                      onKeyPress={(event) => {
                                        if (event.key === 'e' || event.key === '-' || event.key === '+') {
                                          event.preventDefault()
                                        }
                                        else {
                                          return true;
                                        }
                                        return false
                                      }}
                                      disabled={true}
                                    />
                                  </Column>
                                  <Column lg={1} md={1} style={{ borderBottom: "0px" }} className="d-flex align-items-stretch justify-content-center">
                                    <Label text={label.TO.toLocaleUpperCase()} style={{ fontWeight: 'normal' }} />
                                  </Column><Column lg={4} md={4} className='-left'>
                                    <TextInput
                                      id={`filter-final-value-text-${index}`}
                                      type='number'
                                      value={campaignApprovalDetails.campaignFilters[index]?.filterValue != undefined ? campaignApprovalDetails.campaignFilters[index]?.filterValue[1] : 0}
                                      onKeyPress={(event) => {
                                        if (event.key === 'e' || event.key === '-' || event.key === '+') {
                                          event.preventDefault()
                                        }
                                        else {
                                          return true;
                                        }
                                        return false
                                      }}
                                      disabled={true}
                                    />
                                  </Column></>
                              )}

                            {/* Filter currency/numeric end */}
                            {/* Filter  Date Start */}

                            {filter.filterType == constant.Data_Type_Date
                              // filter.filterType == "Date" 
                              && (
                                <><Column lg={6} md={6} className='-left'>
                                  <DateTimePicker
                                    dateFormat="MM/dd/yyyy"
                                    dateFormatCalendar="LLLL yyyy"
                                    dropdownMode="scroll"
                                    selected={campaignApprovalDetails.campaignFilters[index]?.filterValue != null &&
                                      typeof (campaignApprovalDetails.campaignFilters[index]?.filterValue[0] instanceof Date)
                                      ? campaignApprovalDetails.campaignFilters[index]?.filterValue[0]
                                      : null}
                                    disabled={true}
                                  >
                                  </DateTimePicker>
                                </Column>
                                </>

                              )}

                            {/* Filter  Date end */}
                            {/* Filter  Date range start */}
                            {filter.filterType == constant.Data_Type_Date_Range && (

                              <>
                                <Column lg={4} md={4} className='-left'>
                                  <DateTimePicker
                                    dateFormat="MM/dd/yyyy"
                                    dateFormatCalendar="LLLL yyyy"
                                    dropdownMode="scroll"
                                    selected={campaignApprovalDetails.campaignFilters[index]?.filterValue &&
                                      campaignApprovalDetails.campaignFilters[index]?.filterValue[0] instanceof Date
                                      ? campaignApprovalDetails.campaignFilters[index]?.filterValue[0]
                                      : null}
                                    disabled={true}
                                  >
                                  </DateTimePicker>
                                </Column>

                                <Column lg={1} md={1} style={{ borderBottom: "0px" }} className="d-flex align-items-stretch justify-content-center">
                                  <Label text={label.TO.toLocaleUpperCase()} style={{ fontWeight: 'normal' }} />
                                </Column>
                                <Column lg={4} md={4} className='-left'>
                                  <DateTimePicker
                                    dateFormat="MM/dd/yyyy"
                                    dateFormatCalendar="LLLL yyyy"
                                    dropdownMode="scroll"
                                    selected={campaignApprovalDetails.campaignFilters[index]?.filterValue &&
                                      campaignApprovalDetails.campaignFilters[index]?.filterValue[1] instanceof Date
                                      ? campaignApprovalDetails.campaignFilters[index]?.filterValue[1]
                                      : null}
                                    disabled={true}
                                  >
                                  </DateTimePicker>
                                </Column>
                              </>
                            )}

                            {/* Filter  Date range end */}

                            {/* Free form start */}
                            {
                              (
                                filter.filterType?.toLowerCase() === constant.DATA_TYPE_FREEFORM
                              )
                              && (
                                <>
                                  <Column lg={6} md={6} className='-left'>
                                    <TextInput
                                      id={`filter-initial-value-text-${index}`}
                                      placeholder={''}
                                      style={{ width: '100%' }}
                                      title={campaignApprovalDetails.campaignFilters[index]?.filterValue.toLocaleString()}
                                      value={campaignApprovalDetails.campaignFilters[index]?.filterValue.toLocaleString()}
                                      disabled={true}
                                    />
                                  </Column>
                                </>
                              )}
                            {/* Free form end */}
                            {
                              index < campaignQueryFilterSetup.length - 1 &&
                              <Column lg={1} md={1} className='-left'>
                                <Label text={label.AND.toLocaleUpperCase()} style={{ fontWeight: 'normal' }} />
                              </Column>
                            }
                          </Row>

                        ))
                          // ) : ""
                        }
                      </Column>

                    </Row>
                    </>
                  }
                  {/* Filter values end */}

                  {/* Filter criteria end */}

                  <Row>
                    <Column lg={12}>
                      <Row>
                        <Column lg={2} md={2} className='bg-light'>
                          <Label className='inlineLable mt-3 mb-0' text={label.SEND_ON_DATE} />
                        </Column>
                        <Column lg={10} md={10} className='no-border-bottom'>
                          <Row>
                            <Column lg={4} md={4} className='-left'>
                              <DateTimePicker
                                name='sendOnDate'
                                selected={campaignApprovalDetails.sendOn}
                                disabled={true}
                                style={{ width: '95%' }}
                                minDate={tomorrowDate}
                              />
                            </Column>
                            <Column lg={8} md={8} className='text-end'>
                              <TextInputWithLabelInline
                                label={label.FROM_EMAIL}
                                placeholder={label.CAMPAIGN_FROM_EMAIL_PLACEHOLDER}
                                name="fromEmailForRequestCampaign"
                                className='camapigns-from-email-texbox-input my-2'
                                value={campaignApprovalDetails.fromEmailName}
                                isEditable={false}
                                lglabel={4}
                                mdlabel={4}
                                lgText={8}
                                mdText={8}
                                labelClass='inlineLable mt-3 mb-0'
                              />
                            </Column>
                          </Row>
                        </Column>
                      </Row>
                    </Column>
                  </Row>

                  <Row>
                    <Column lg={2} md={2} className='bg-light'><Label className='inlineLable mt-3 mb-0' text={label.UPDATED} /></Column>
                    <Column lg={10} md={10}>
                      <Row>
                        <Column lg={6} md={6} className={''}>
                          <Label text={label.UPDATED_BY + label.COLON} className={'inlineLable mt-3 mb-0'} />
                          &nbsp;
                          <Label text={campaignApprovalDetails.changedBy} className={'inlineLable mt-3 mb-0'} style={{ fontWeight: 'normal' }} />
                        </Column>
                        <Column lg={6} md={6}>
                          <Label text={label.UPDATED_ON + label.COLON} className={'inlineLable mt-3 mb-0'} />
                          &nbsp;
                          <Label text={campaignApprovalDetails.changedDate} className={'inlineLable mt-3 mb-0'} style={{ fontWeight: 'normal' }} />
                        </Column>
                      </Row>
                    </Column>
                  </Row>

                  <Row>
                    <Column lg={2} md={2} className='bg-light'><Label className='inlineLable mt-3 mb-0' text={label.APPROVAL_INFORMATION} /></Column>
                    <Column lg={10} md={10}>
                      <Row>
                        <Column lg={6} md={6} className={''}>
                          <Label text={label.APPROVED_BY + label.COLON} className={'inlineLable mt-3 mb-0'} />
                          &nbsp;
                          <Label text={campaignApprovalDetails.approvedBy} className={'inlineLable'} style={{ fontWeight: 'normal' }} />
                        </Column>
                        <Column lg={6} md={6}>
                          <Label text={label.APPROVAL_DATE + label.COLON} className={'inlineLable mt-3 mb-0'} />
                          &nbsp;
                          <Label text={campaignApprovalDetails.approvalDate} className={'inlineLable'} style={{ fontWeight: 'normal' }} />
                        </Column>
                      </Row>
                    </Column>
                  </Row>
                  <Row>
                    <Column lg={2} md={2} className='bg-light'>
                      <Label className='inlineLable mt-3 mb-0' text={label.RESULTS} />
                    </Column>
                    <Column lg={10} md={10}>
                      <Label className='-left mt-3 mb-0' text={campaignApprovalDetails.results?.toLocaleString()} />
                    </Column>
                  </Row>
                  <Row>
                    <Column lg={2} md={2} className='bg-light'>
                      <Label className='inlineLable mt-3 mb-0' text={label.STATUS} />
                    </Column>
                    <Column lg={10} md={10}>
                      <Row>
                        <Column lg={6} md={6} className='-left'>
                          <Label text={campaignApprovalDetails.status} className='mt-3 mb-0' />
                        </Column>
                      </Row>
                    </Column>
                  </Row>

                  <Row className='campaignSettings-footer mb-3'>
                  <Column lg={6} style={{ paddingLeft: "0" }}>
                    <Button color='primary' outlined={true} onClick={BackToCampaignList}>{label.CANCEL.toLocaleUpperCase()}</Button>
                  </Column>
                  <Column lg={6} className='right-align' style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.125)", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                  {isUserPermitToViewSendTest &&  <Button color='primary' outlined={true} disabled={isSendTestDisabled} onClick={() => { setShowSendTestPopup(true) }}>{label.SEND_TEST}</Button> }
                    {/* <div className="vertical-line"></div> */}
                   {isUserPermitToViewReject && <Button color='primary' outlined={true} disabled={isRejectDisabledForUserPermission || isRejectDisabled} onClick={event => onReject()}>{label.REJECT}</Button> }
                    {/* <div className="vertical-line"></div> */}
                   {isUserPermitToViewApprove &&  <Button color='primary' outlined={false} disabled={isApproveDisabledForUserPermission || isApproveDisabled} onClick={event => onApprove()} >{label.APPROVE}</Button> }
                  </Column>
                  </Row>
                </Container>
              </Column>
            </Panel>
          </ErrorBoundary>
        </Column>
      </Row>
      {showSendTestPopup && <SendTest handleClose={handleCloseSendTest} showSendTestPopup={showSendTestPopup} customerId={constant.DEFAULT_GUID} campaignId={props.campaignId} />}

      <Modal
        verticallyCentered={true}
        title={label.REJECT_REASON}
        show={showRejectReasonPopup}
        toggle={() => {
          setShowRejectReasonPopup(false)
        }}
        body={
          <TextField
            placeholder={label.REJECT_REASON_PLACEHOLDER}
            name="rejectReason"
            value={rejectionReason}
            disabled={false}
            onChange={(event) => {
              setRejectionReason(event.currentTarget.value)
            }}
          />
        }
        closeButton={{
          size: "small",
          icon: 'cancel',
          iconStyle: { color: "gray" },
          outlined: true,
          color: 'link',
          onClick() {
            setShowRejectReasonPopup(false)
            setRejectionReason("")
          },
          children: <span style={{ color: "gray" }}>{label.CANCEL.toUpperCase()}</span>
        }}
        successButton={{
          size: "small",
          icon: "check",
          iconStyle: { color: "177db8" },
          outlined: true,
          color: 'link',
          // disabled: sendTestEmails === '',
          onClick() {
            setShowRejectReasonPopup(false)
            proceedWithReject()
            setRejectionReason("")
          },
          children: <span style={{ color: "#177db8" }}>{label.SAVE.toUpperCase()}</span>
        }}
        buttonsAlignment={"right"} />
    </>

  )
}

export default CampaignApproval