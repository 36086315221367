
import { Link } from 'react-router-dom'
import { Button, Row, Column, Spinner, Label, DateTimePicker } from '../../../src/components'
import Grid from '../../../src/components/components/Grid/Grid'
//import { GetAllExternalTemplateList } from '../hooks/Manage3rdPartyTemplateOperations'
import React, { useState, useEffect, Suspense, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import * as label from '../../shared/util/label'
import * as constant from '../../shared/util/constant'
import { ErrorBoundary } from 'react-error-boundary'
import { campaignTemplatesUtils } from '../utils/3rdPartyTemplateUtils'
import Loading from '../../shared/components/Loading'
import ScrollTop from '../../shared/components/scrollTopButton'
import { FallbackComponent } from '../../shared/components/FallbackComponent';
import { AbilityContext } from '../../../src/shared/abilities/AbilityContext';
import { Container, Form, Nav, Navbar } from 'react-bootstrap'

//import { constant } from 'lodash'
//import * as constant from '../../shared/util/constant'

const ExternalTemplate = (props) => {
  const subject = props.subject;
  const ability = useContext(AbilityContext);
  const dispatch = useDispatch()
  const history = useHistory()
  const ab_subject = constant.ScreenName.Campaign_Template;
  const [highlightedMenu, setHighlightedMenu] = useState('3rdpartytemplate');
  const canViewNewCampaignTemplate=ability.can('create','Campaign Template',constant.ScreenName.CAMAPIGN_TEMAPLATE)
  
  //const { data, isLoading } = GetAllExternalTemplateList();
  const [data, setData] = useState([]);
//var data=[];
  const [showFilters, setShowFilters] = useState(true);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedColumnValue, setSelectedColumnValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [includeInactiveRecords, setIncludeInactiveStatus] = useState(false);
  const [activeRecord, setActiveRecord] = useState([])
  const[ErrorText,setErrorText]=useState('')
  const[isScrollTop, setScrollTop]=useState(false)
  const handleLoading = () => {
    setLoading(false);
  }
  useEffect(() => {
    window.addEventListener("load", handleLoading)
    return () => window.removeEventListener("load", handleLoading);
  }, [])

  async function fetchData() {
    //setLoading(true)
      await campaignTemplatesUtils.getAllThirdPartyTemplateList()
      .then(response =>setData(response))
      .catch(error => console.error(error))
      //.finally(()=> setLoading(false));
  }

  useEffect(() => {
    //alert('called')
    fetchData();
   // highlightMenu();
  },[]);

   //#region Call when there is error occur on the page
//  const FallbackComponent = ({ error, resetErrorBoundary }) => {
//   console.log("An error occurred:");
//   console.log(error.message);
//   return (
//       <div>
//           <h1>An error occurred: {error.message}</h1>
//           <button onClick={resetErrorBoundary}>Try again</button>
//       </div>
//   );
// };

 
  useEffect(() => {
    if (isRowSelected) {
      setSelectedColumnValue((prevValue) => {
        if (prevValue !== selectedColumnValue) {
          return selectedColumnValue;
        }
        return prevValue;
      });
    }
  }, [isRowSelected]);

  useEffect(() => {

  }, [isRowSelected]);

  const handleSelectedRow = (selected, columnValue) => {
    setIsRowSelected(selected);
    setSelectedColumnValue(columnValue);
  };

  const get3rdPartyGridCaption = () => {
    return (
      <Navbar className="bg-transparent">
        <Navbar.Brand>{label.EXTERNAL_TEMPLATE.toUpperCase()}</Navbar.Brand>
        <Nav className="me-auto">
          <Form className="">
            <Form.Check
              type="checkbox"
              id="include-inactive-checkbox"
              label={'Include inactive templates'}
              onChange={(event) => checkInactiveStatus(event)}
              className='mx-3'
            />
          </Form>
        </Nav>

        <Button disabled={!canViewNewCampaignTemplate} onClick={routeChange}>{label.NEW_EXTERNAL_TEMPLATE}</Button>

      </Navbar>
    );
  };
  
  //#region Scroll to Top function 
  const getScrollPostionStatus=()=>{
    var scrollPostion=window.pageYOffset;
    
    if(scrollPostion>= constant.SHOW_SCROLL_BUTTON_POSITION){
     // setScrollTop(true)
    }
    else{
      //setScrollTop(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', getScrollPostionStatus, { passive: true });

    return () => {
        window.removeEventListener('scroll', getScrollPostionStatus)
    };
}, []);
//#endregion Scroll to Top function
  

  const routeChange = () => {
    //highlightMenu('3rdpartytemplate')
    let path = '/Campaign/3rdPartyTemplateDetail';
    history.push(path);
  }

  const checkInactiveStatus = (event) => {
    if (event.target.checked) {
      setIncludeInactiveStatus(true)

    }
    else {
      setIncludeInactiveStatus(false)
    }
  }

  useEffect(() => {

  })

  return (
    <>
    <Column lg={12} md={12}>
      <span>{ErrorText}</span>
    </Column>
   
    <ErrorBoundary FallbackComponent={FallbackComponent}>
    
      
      <div className='outerPanel card shadow-sm'>
      <Container fluid>
        <Row>
          <Column lg={12}>
            {get3rdPartyGridCaption()}
          </Column>
        </Row>
        
        <Row className=''>
        {/* <Suspense fallback={<Loading />}> */}
        <Grid

            data={data !== undefined && data.length>0 && includeInactiveRecords ? data :
              data !== undefined && !includeInactiveRecords ? data.filter(item => item.status===null?'':item.status.toUpperCase() !== constant.INACTIVE.toUpperCase()) : []}
            showFilters={showFilters}
            grdTabelClass='table-striped table-hover table-sm'
            grdTableContainerClass='p-0'
            columns={[

              {
                Header: label.TEMPLATE_ID,
                accessor: 'templateId',
                Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>

              },
              {

                Header: label.NAME,
                accessor: 'templateName',
                dataType: 'string',
                sortType:'string',
                Cell: ({ row }) =>
                  <div>
                    <Link
                    to={{
                      pathname: '/Campaign/3rdPartyTemplateDetail',
                      state: { entityId: row.original.id, type: "3" },// Pass the row data as state
                      search :"?id="+row.original.id
                    }}
                    href='#'
                    //onClick={(event) => { routeChange() }}
                    title={row.original.templateName}
                    className='gridNavigateButton btn btn-link text-start text-decoration-none d-block p-2'
                    >
                      {data !== undefined ? row.original.templateName : ''}
                    </Link>
                    </div>,
                    width:250,
                    minWidth:150
                  },
                  
              {
                Header: label.STATUS,
                accessor: 'status',
                // useFilters:()=>{getInactiveRecords},
                Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                width:250,
                minWidth:150,
                dataType: 'string'
              },
              {
                Header: label.CampaignType,
                accessor: 'typeValue',
                // useFilters:()=>{getInactiveRecords},
                Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                width:250,
                minWidth:150,
                dataType: 'string',
              },
              {
                Header: label.CATEGORY,
                accessor: 'category',
                Cell: row => <div><span title={row.value}>{data !== undefined ? row.value : ''}</span></div>,
                width:250,
                minWidth:150,
                dataType: 'string',
              },
              
              {
                Header: label.UPDATED_ON,
                accessor:'changedDate',
                Cell: row => <div><span title={row.value}>{moment(row.value.toString()).format("MM/DD/YYYY")}</span></div>,
                dataType: 'date',
                sortType: (a, b) => {
                  return new Date(b.values.changedDate) - new Date(a.values.changedDate);
                },
                width:250,
                minWidth:150
              },
              {
                Header: label.UPDATED_BY,
                accessor: 'changedBy',
                Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                width:250,
                minWidth:150
              },
              {
                Header: label.DESCRIPTION,
                accessor: 'description',
                Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                width:250,
                minWidth:150,
              }
            ]}
            hiddenColumns={['templateId','selection']}
            selectedColumn={handleSelectedRow}
            sortDefault={[{ id:'typeValue', desc: false },{id:'category', desc: false}]}
            id={'tblExternalTemplate'}
            checkboxColumWidth='50'

          />
          {/* </Suspense> */}
          {/* {loading && <Spinner color="blue" loading size={[10, 25]} type="FadeLoader" />} */}

          <ScrollTop isDisplay={isScrollTop}/>
        </Row>
        </Container>

      </div>
        
      </ErrorBoundary>
      
    
    </>
  )
}
export default ExternalTemplate