
import { Navbar } from  '../components' 
import React, {useState, useEffect, useContext} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import './../../src/index.css'
import * as label from '../shared/util/label'
import * as constant from '../shared/util/constant'
import AppContext from '../shared/components/AppContext'
import { ConfirmModal } from '../shared/components/confirmModal'
import { Console } from 'console'
import { isTemplateExpression } from 'typescript'
import { AbilityContext } from '../shared/abilities/AbilityContext'
//import pageMap, { Page } from  '../../src/shared/components/navbar/pageMap'   

const CampaignNavbar = () => {
  const dispatch = useDispatch()
  const history = useHistory()
const myContext = useContext(AppContext);
  const [showExitConfirmation, setShowExitConfirmation] = useState(!myContext.noteSavedStatus||!myContext.formSavedStatus);
  const [clickedUrl, setClickedURL] = useState('');
  //console.log('navbar',myContext)
  const ability = useContext(AbilityContext);
  const canUserViewCampaign =ability.can('view','Campaign',constant.ScreenName.CAMPAIGN);
  const canUserReadCampaign =ability.can('read','Campaign',constant.ScreenName.CAMPAIGN);
  const canUserViewQuery = ability.can('view','Campaign Query',constant.ScreenName.Campaign_Query);
  const canUserReadQuery = ability.can('read','Campaign Query',constant.ScreenName.Campaign_Query);
  const canUserViewTemplate = ability.can('view','Campaign Template',constant.ScreenName.CAMAPIGN_TEMAPLATE);
  const canUserReadTemplate = ability.can('read','Campaign Template',constant.ScreenName.CAMAPIGN_TEMAPLATE);
  const canUserViewCalendar = ability.can('view','Campaign Calendar',constant.ScreenName.Campaign_Calendar);
  const canUserReadCalendar = ability.can('read','Campaign Calendar',constant.ScreenName.Campaign_Calendar);
  const canUserViewApprovalScreen = ability.can('view','Campaign Approval',constant.ScreenName.CAMPAIGN_APPROVAL);

  
  const [highlightedMenu,setHighlightedMenu] = useState('Dashboard');
 
  const navigateTo = (location: string, event) => {
    var tabLink = document.querySelectorAll(".nav-link");
    tabLink.forEach(function (item) {
        item.classList.remove("active");
      });
      event.currentTarget.classList.toggle('active');
    history.push(location)
    setHighlightedMenu(location)
    // highlightMenu();
  }

  const cancelPopUp=()=>{
    //console.log('cancelPopUp',myContext.showExitConfirmation)
   setShowExitConfirmation(false);
   myContext.showExitConfirmation=false
    //myContext.showExitConfirmation=false;
  }

    const handleInput=(e)=> {
      let clickEle=e.target.closest('.close')
      if(clickEle){
        myContext.showExitConfirmation=false;
        cancelPopUp()
      }
    }
    document.onclick=handleInput
 
  
  
  
  const routeFromPopUp=()=>{
   {setShowExitConfirmation(false)};
   myContext.formSavedStatus=true
   myContext.noteSavedStatus=true
    myContext.showExitConfirmation=false;
    history.push(clickedUrl)
    highlightMenu();
  }
  
  function highlightMenu(){
    var tabLink = document.querySelectorAll(".nav-link");
    tabLink.forEach(function (item) {
      item.classList.remove("active");
    });
    var activeMenu=location.pathname.slice(location.pathname.lastIndexOf('/')+1)
    var path = window.location.pathname;
    if(activeMenu==='CampaignDashboard')
    {
      activeMenu='Dashboard'
    }
    tabLink.forEach(function (item) { 
      if(item.id===activeMenu){
        item.classList.add("active");
      }
    });
    history.push(location)
    setHighlightedMenu(activeMenu)
  }

  useEffect(()=>{
    setTimeout(() => {
      highlightMenu()
  }, 2000)
    
  },[])
  useEffect(() => {
   // console.log('useeffect',showExitConfirmation)
    setShowExitConfirmation(myContext.showExitConfirmation)
  })

  return (
    <>
      <Navbar
        bg='light'
        variant='light'
        className='pb-2'
        title={''} id={''}
        navItems={[
          {
            type: 'header',
            alignRight: true,
            children: null,
            className: 'cstHeaderMenu nav-add-new d-none d-md-block nav-bar-item-width',
            iconClassName: 'align-bottom',
            label: 'Campaign',
            name: 'DashboardHeader',
            size: 'sm',
            onClick: (event) => {
              setClickedURL('/Campaign/CampaignDashboard')
              if (myContext.formSavedStatus === true && myContext.noteSavedStatus === true) {
                navigateTo('/Campaign/CampaignDashboard', event)
              }
              else {
                setShowExitConfirmation(true)
                myContext.showExitConfirmation = true
                event.preventDefault()
              }
            }
          },
          {
            type: 'link',
            alignRight: true,
            children: null,
            className: 'cstHeaderMenu nav-add-new d-none d-md-block nav-bar-item-width',
            iconClassName: 'align-bottom',
            label: 'Dashboard',
            name: 'Dashboard',
            size: 'sm',
            onClick: (event) => {
              setClickedURL('/Campaign/CampaignDashboard')
              if (myContext.formSavedStatus === true && myContext.noteSavedStatus === true) {
                navigateTo('/Campaign/CampaignDashboard', event)
              }
              else {
                setShowExitConfirmation(true)
                myContext.showExitConfirmation = true
                event.preventDefault()
              }
            }
          },
          {
            type: 'link',
            alignRight: true,
            children: null,
            className: `cstHeaderMenu nav-add-new d-none d-md-block nav-bar-item-width${canUserViewCampaign === false ? ' display-none' : ''}`,
            iconClassName: 'align-bottom',
            label: 'Campaigns',
            name: 'Campaign',
            disabled: !canUserReadCampaign,
            size: 'lg',
            onClick: (event) => {
              setClickedURL('/Campaign/Campaign')
              if (myContext.formSavedStatus === true && myContext.noteSavedStatus === true) {
                navigateTo('/Campaign/Campaign', event)
              }
              else {
                setShowExitConfirmation(true)
                myContext.showExitConfirmation = true
                event.preventDefault()
              }
            }
          },
          {
            type: 'link',
            alignRight: true,
            children: null,
            className: `cstHeaderMenu nav-add-new d-none d-md-block nav-bar-item-width ${canUserViewCalendar === false ? ' display-none' : ''}`,
            iconClassName: 'align-bottom',
            label: 'Calendar',
            name: 'Calendar',
            disabled: !canUserReadCalendar,
            size: '10x',
            onClick: (event) => {
              setClickedURL('/Campaign/Calendar')
              if (myContext.formSavedStatus === true && myContext.noteSavedStatus === true) {
                navigateTo('/Campaign/Calendar', event)
              }
              else {
                setShowExitConfirmation(true)
                myContext.showExitConfirmation = true
                event.preventDefault()
              }
            }
          },
          {
            type: 'link',
            alignRight: true,
            children: null,
            className: `cstHeaderMenu nav-add-new d-none d-md-block nav-bar-item-width ${canUserViewApprovalScreen=== false? ' display-none' : ''}`,
            iconClassName: 'align-bottom',
            label: 'Approval',
            name: 'Approval',
            size: '10x',
            disabled: true,
            // onClick:(event)=>{
            //   navigateTo('/Campaign/Approval',event)}
          },
          {
            type: 'link',
            alignRight: true,
            children: null,
            className: `cstHeaderMenu nav-add-new d-none d-md-block nav-bar-item-width ${canUserViewQuery === false ? ' display-none' : ''}`,
            iconClassName: 'align-bottom',
            label: 'Queries',
            name: 'Queries',
            disabled: !canUserReadQuery,
            size: '10x',
            onClick: (event) => {
              setClickedURL('/Campaign/Queries')
              if (myContext.formSavedStatus === true && myContext.noteSavedStatus === true) {
                navigateTo('/Campaign/Queries', event)
              }
              else {
                setShowExitConfirmation(true)
                myContext.showExitConfirmation = true
                event.preventDefault()
              }
            }
          },
          {
            type: 'link',
            alignRight: true,
            children: null,
            className: `cstHeaderMenu nav-add-new d-none d-md-block nav-bar-item-ex-width ${canUserViewTemplate === false ? ' display-none' : ''}`,
            iconClassName: 'align-bottom',
            label: '3rd Party Templates',
            name: '3rdpartytemplate',
            disabled: !canUserReadTemplate,
            size: '10x',
            onClick: (event) => {
              setClickedURL('/Campaign/3rdpartytemplate')
              if (myContext.formSavedStatus === true && myContext.noteSavedStatus === true) {
                navigateTo('/Campaign/3rdpartytemplate', event)
              }
              else {
                setShowExitConfirmation(true)
                myContext.showExitConfirmation = true
                event.preventDefault()
              }
            }
          }
        ]} children={''} />
      <ConfirmModal
        modaltitle={label.CONFIRM}
        closeModal={cancelPopUp}
        showConfirmation={showExitConfirmation}
        setConfirmation={() => setShowExitConfirmation(!showExitConfirmation)}
        body={constant.CONFIRM_EXIT}
        showHeaderCloseButton={true}
        closeButtonClick={cancelPopUp}
        successButtonClick={routeFromPopUp}
        closeButtonLabel={label.NO.toUpperCase()}
        successButtonLabel={label.YES.toUpperCase()}
      />
    </>
  )
}
export default CampaignNavbar
