import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Badge, Dropdown } from 'react-bootstrap';
import './PolicySummaryHeader.scss';
import { InfoDisplay } from '../InfoDisplay/InfoDisplay';

interface PolicySummaryHeaderProps {
  termValue?: string;
  polTermStatus?: string;
  lobCodeValue?: string;
  polTypeLobValue?: string;
  formTypeValue?: string;
  policyType?: string;
  policyStatusMap: Record<string, { backgroundColor: string }>;
  fullTermPremium?: number;
  // Button
  onActionSelect: (action: string) => void;
  disabledActions?: string[];
}

interface StatusConfig {
  icon: IconProp;
  variant: string;
}

interface PolicyTypeConfig {
  icon: IconProp | [string, string]; // Support both single string and array format
}

//menu configuration
const POLICY_ACTIONS: PolicyActionOption[] = [
  { key: 'new', icon: 'plus', label: 'New' },
  { key: 'rewrite', icon: 'pen-to-square', label: 'Rewrite' },
  { key: 'compare', icon: 'code-compare', label: 'Compare' },
  { key: 'endorse', icon: 'file-pen', label: 'Endorse' },
  { key: 'renew', icon: 'sync', label: 'Renew' },
  { key: 'cancellation', icon: 'ban', label: 'Cancellation' }
];

const POLICY_TYPE_CONFIG: Record<string, PolicyTypeConfig> = {
  'Homeowners': { icon: 'house' },
  'HOME': { icon: 'house-user' },
  'Auto': { icon: 'car' },
  'Personal': { icon: 'user' },
  'Commercial': { icon: 'building' },
  'Umbrella': { icon: 'umbrella' },
  'Special Form': { icon: 'shield-alt' },
  'Basic Form': { icon: 'file-alt' },
  'HO3': { icon: 'home' },
  'Default': { icon: 'file' }
};

const STATUS_CONFIG: Record<string, StatusConfig> = {
  'Active': {
    icon: 'check-circle',
    variant: 'success'
  },
  'Cancelled': {
    icon: 'ban',
    variant: 'danger'
  },
  'Expired': {
    icon: 'calendar-times',
    variant: 'danger'
  },
  'Include': {
    icon: 'plus-circle',
    variant: 'secondary'
  },
  'Non-Renewed': {
    icon: 'times-circle',
    variant: 'danger'
  },
  'Not Taken': {
    icon: 'exclamation-circle',
    variant: 'warning'
  },
  'Quote': {
    icon: 'file-invoice',
    variant: 'info'
  },
  'Renewed': {
    icon: 'sync',
    variant: 'success'
  },
  'Rewritten': {
    icon: 'pen',
    variant: 'success'
  },
  'Pending': {
    icon: 'clock',
    variant: 'warning'
  },
  'Default': {
    icon: 'circle-info',
    variant: 'secondary'
  }
};

// Add interface for menu options
interface PolicyActionOption {
  key: 'new' | 'rewrite' | 'compare' | 'endorse' | 'renew' | 'cancellation';
  icon: IconProp;
  label: string;
  disabled?: boolean;
}

export const PolicySummaryHeader: React.FC<PolicySummaryHeaderProps> = ({
  termValue,
  polTermStatus,
  lobCodeValue,
  polTypeLobValue,
  formTypeValue,
  policyType,
  policyStatusMap,
  disabledActions,
  fullTermPremium
}) => {

  const statusStyle = polTermStatus && policyStatusMap[polTermStatus]
    ? policyStatusMap[polTermStatus]
    : {};

  const onActionSelect = (action: string) => {
    console.log(`Action selected: ${action}`);
    // Implement the logic for each action here
    switch (action) {
      case 'new':
        // Handle new action
        break;
      case 'rewrite':
        // Handle rewrite action
        break;
      case 'compare':
        // Handle compare action
        break;
      case 'endorse':
        // Handle endorse action
        break;
      case 'renew':
        // Handle renew action
        break;
      case 'cancellation':
        // Handle cancellation action
        break;
      default:
        console.warn(`Unhandled action: ${action}`);
    }
  }

  return (
    <div className="policy-summary-header flex-grow-1">
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <div className="header-row">
            <h2 className="term-title">{termValue}</h2>
            {polTermStatus && (
              <Badge
                bg={STATUS_CONFIG[polTermStatus]?.variant || STATUS_CONFIG.Default.variant}
                className="status-badge"
              >
                <FontAwesomeIcon
                  icon={STATUS_CONFIG[polTermStatus]?.icon || STATUS_CONFIG.Default.icon}
                  className="me-1"
                />
                {polTermStatus}
              </Badge>
            )}
          </div>
          <div className="subtitle-row">
            {lobCodeValue && (
              <span className="subtitle-item">
                <FontAwesomeIcon
                  icon={POLICY_TYPE_CONFIG[lobCodeValue]?.icon as IconProp || STATUS_CONFIG.Default.icon}
                />
                <span>{lobCodeValue}</span>
              </span>
            )}
            {polTypeLobValue && (
              <span className="subtitle-item">
                <FontAwesomeIcon
                  icon={POLICY_TYPE_CONFIG[polTypeLobValue]?.icon as IconProp || STATUS_CONFIG.Default.icon}
                />
                <span>{polTypeLobValue}</span>
              </span>
            )}
            {formTypeValue && (
              <span className="subtitle-item">
                <FontAwesomeIcon
                  icon={POLICY_TYPE_CONFIG[formTypeValue]?.icon as IconProp || STATUS_CONFIG.Default.icon}
                />
                <span>{formTypeValue}</span>
              </span>
            )}
            {policyType && (
              <span className="subtitle-item">
                <FontAwesomeIcon
                  icon={POLICY_TYPE_CONFIG[policyType]?.icon as IconProp || STATUS_CONFIG.Default.icon}
                />
                <span>{policyType}</span>
              </span>
            )}
          </div>
        </div>

        <div className='d-flex align-items-center gap-3'>
          <InfoDisplay
            caption="Annual Premium" bordered
            value={fullTermPremium ? `$${fullTermPremium.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}` : 'N/A'}
          />
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="outline-primary"
              id="policy-actions-dropdown"
              className="btn-icon"
            >
              <FontAwesomeIcon icon="ellipsis-vertical" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {POLICY_ACTIONS.map(action => (
                <Dropdown.Item
                  key={action.key}
                  onClick={() => onActionSelect(action.key)}
                  disabled={disabledActions?.includes(action.key)}
                >
                  <FontAwesomeIcon
                    icon={action.icon}
                    className="me-2"
                    fixedWidth
                  />
                  {action.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

      </div>
    </div>
  );
};