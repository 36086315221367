import { PolicyDtoRead } from "../../../customer/model/PolicyDtoRead";
import { LookupDetailConstants } from "../../util/LookupDetailConstants";
import { InsuranceProduct } from "./InsuranceProductList.model";

export const policyStatusPriority = {
    [LookupDetailConstants.POLICY_STATUS.ACTIVE] : 1,
    [LookupDetailConstants.POLICY_STATUS.RENEWED] : 1,
    [LookupDetailConstants.POLICY_STATUS.REWRITTEN] : 1,
    [LookupDetailConstants.POLICY_STATUS.CANCELLED] : 2,
    [LookupDetailConstants.POLICY_STATUS.EXPIRED] : 2,
    [LookupDetailConstants.POLICY_STATUS.QUOTE] : 3, 
    [LookupDetailConstants.POLICY_STATUS.NOT_TAKEN] : 4,
    [LookupDetailConstants.POLICY_STATUS.INCLUDE] : 100, 
    [LookupDetailConstants.POLICY_STATUS.NON_RENEWED] : 100,
    ['00000000-0000-0000-0000-000000000000'] : 100 // Unknown
}

export const policyStatusColorMap = {
    1 : '#00FF00',    // Active, Renewed, Rewritten
    2 : '#FF0000',    // Expired, Cancelled
    3 : '#800080',    // Quote
    4 : '#FF69B4',    // Not Taken
    100 : '#808080'   // Others
}

export const policyTypeIconMapping = {
  [LookupDetailConstants.POLICY_TYPE.DP3Condo] : 'CON-001',
  [LookupDetailConstants.POLICY_TYPE.Earthquake] : 'EQK-001',
  [LookupDetailConstants.POLICY_TYPE.Flood] : 'FLD-001',
  [LookupDetailConstants.POLICY_TYPE.DP1] : 'DWF-001',
  [LookupDetailConstants.POLICY_TYPE.DP3] : 'DWF-001',
  [LookupDetailConstants.POLICY_TYPE.DP3Condo] : 'DWF-001',
  [LookupDetailConstants.POLICY_TYPE.HO1] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.HO2] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.HO3] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.HO4] : 'RNT-001',
  [LookupDetailConstants.POLICY_TYPE.HO5] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.HO6] : 'CON-001',
  [LookupDetailConstants.POLICY_TYPE.HO7] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.HO8] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.HOAHomeowners] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.HOBCondo] : 'CON-001',
  [LookupDetailConstants.POLICY_TYPE.HOBHomeowners] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.HOBRenters] : 'RNT-001',
  [LookupDetailConstants.POLICY_TYPE.HOCCondo] : 'CON-001',
  [LookupDetailConstants.POLICY_TYPE.HOCHomeowners] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.HOCRenters] : 'RNT-001',
  [LookupDetailConstants.POLICY_TYPE.MobileHome] : 'HOM-001',
  [LookupDetailConstants.POLICY_TYPE.PrivatePassengerAuto] : 'AUT-001',
  [LookupDetailConstants.POLICY_TYPE.RecreationalVehicles] : 'RCV-001',
  [LookupDetailConstants.POLICY_TYPE.UmbrellaP] : 'UMB-001',
}
  

class InsuranceProductService {

    public getPolicyIconsColors = (policies : PolicyDtoRead[]) => {

        let iconStatusMap = {}
        
        policies.forEach(pol => {
    
          if (pol.policyTypeId) {
            let iconId =  policyTypeIconMapping[pol.policyTypeId]
            if (iconId) {
              if (!iconStatusMap[iconId]) {
                iconStatusMap[iconId] = {statusList : []};
              }
    
              iconStatusMap[iconId].statusList.push(pol.policyTermStatusId)
            }
          }
        });
        
        Object.keys(iconStatusMap).forEach(itemKey => {
            iconStatusMap[itemKey].productStatus = iconStatusMap[itemKey].statusList.map(sts => {
            if (policyStatusPriority[sts])
            {
              return policyStatusPriority[sts];
            }
    
            return 100;
          });
    
          iconStatusMap[itemKey].selectedProductStatus = Math.min(...iconStatusMap[itemKey].productStatus);
          iconStatusMap[itemKey].color = policyStatusColorMap[iconStatusMap[itemKey].selectedProductStatus];
        });
    
        let productColorMapping = Object.keys(iconStatusMap).map(itemKey => {
          return { id : itemKey, 'color' : iconStatusMap[itemKey].color};
        })
    
        return productColorMapping;
      }

      public getPolicyProductsWithUpdatedColor = (policyProducts : InsuranceProduct[], productColorMapping : { id : string, color : string}[]) => {
        return  policyProducts.map(item => {
            let mappedPrd = productColorMapping.find(prdItem => prdItem.id == item.id);
            if (mappedPrd) {
              item.color = mappedPrd.color;
            }
            else {
              item.color = policyStatusColorMap[100];
            }
            return item;
          });
      }
}

export default InsuranceProductService;