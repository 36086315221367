export const LookupDetailConstants = {
    CUSTOMER_TASK_STATUS: {
        INCOMPLETE: 'e1198239-1ac6-4f13-b4f0-9a1afb89a10c',
        COMPLETED : '939350e4-74b4-475c-a97f-a8b02dd021b8',
    },
    CUSTOMER_TASK_PRIORITY : {
        NORMAL : '59b3a4f4-27d4-4bba-9b8b-1231c446b103',
        CRITICAL : 'b96ffdd4-30a5-4a43-a1dc-48caf75523d1',
        LOW : 'd8b20966-37d8-4fa9-955e-5a6104dfb1c4'
    },
    POLICY_STATUS : {
        ACTIVE : 'fc889e0f-0a0b-47e2-a686-67ef95116794',
        CANCELLED : '4199f133-a4f2-418a-8834-f77ca2b78a0a',
        EXPIRED : '9855aecf-3694-481a-8b51-ae5e13d19196',
        INCLUDE : '60dbdb01-1915-4de9-88ee-33072d06c743',
        NON_RENEWED : '3b7972c4-9b41-4644-9427-14cda6147210',
        NOT_TAKEN : 'e13f49db-9213-4793-8950-809cd3393f77',
        QUOTE : 'fc896037-baef-419c-a5e1-dfddab54395a',
        RENEWED : '0e1f4954-65d6-4079-aa33-6cbb4120277c',
        REWRITTEN : '4b6ae0be-12d8-4a9f-88fc-c481a3feaf92',
    },
    LOB_CODE : {
        HOMEOWNERS : 'be2b23b2-1bae-4c7e-8933-87627dfc89a1',
        PRIVATE_PASSENGER_AUTO : "b8ebc545-75ef-45ac-8ab8-625029e26b68",
        UMBRELLA_P : "001b57d6-f745-4f90-af19-a586721d9679"
    },
    POLICY_TYPE : {
        Boat : '72f71f96-293b-4ede-a068-2faa41ccbe19', 
        DP1 : 'c4bbfd9b-a8b1-4be5-9551-7096beb5f082', 
        DP3 : '3acc992b-aab9-4202-a98f-86cde5631c82', 
        DP3Condo : '21ab1e31-9433-4b11-9d53-9a2fea3b8319', 
        Earthquake : 'c511232a-ef18-4148-b9fd-e52fecd1ded3', 
        Flood : '13173dd0-08da-4b7c-9835-a773afb01e32', 
        HO1 : '1685282c-8e32-4257-829d-53071b2aeb05', 
        HO2 : '1a3abd3b-b3cd-4930-af21-1c6da9d396ed', 
        HO3 : '82d76c62-7a67-400d-ba5e-baa8a92d50c5', 
        HO4 : '3503888c-e0e9-4a63-a078-498f1e9a5ecd', 
        HO5 : '82d76c62-7a67-400d-ba5e-baa8a92d50c6', 
        HO6 : '9634ba6c-a26a-4823-b92f-9786e95bf1dd', 
        HO7 : 'b58fb492-e333-4c62-8fd0-0c3f1a6a40ab', 
        HO8 : '8c66092e-7218-41b2-9418-bc9191948d04', 
        HOAHomeowners : '75593e27-29ac-4e41-9823-993727e803fd', 
        HOBCondo : 'bc0a07ad-5798-4d59-88c3-ba61e706ba7a', 
        HOBHomeowners : '16d44cd6-c9b0-4611-80e7-e147d2d4e4d0', 
        HOBRenters : '37542780-5680-40d8-8804-06ad9998edce', 
        HOCCondo : '6ae8ee6f-51eb-4543-9eed-8182c63a5237', 
        HOCHomeowners : '3157eda9-802a-4ba8-a7d8-117a9c625a91', 
        HOCRenters : 'df9fe8b3-d0ba-415f-9f83-22f7eb2b95ff', 
        InlandMarine : 'e8bddd70-c210-4e15-bc30-f2737970b9f4', 
        MobileHome : 'a6e47dec-9604-4440-b342-51490f248c21', 
        Package : 'fa65893c-cbcb-4713-b5b1-94d89906df46', 
        PrivatePassengerAuto : 'ebed4427-84f5-4679-945a-28c500601a0f', 
        RecreationalVehicles : '69f7a0fa-1e56-4df6-9a29-9d118b86fcf0', 
        SpecialtyP : '4494a6d9-7dfe-4862-a626-3d14f4127ca8', 
        UmbrellaP : '5d368fec-12de-40e7-a46e-252aae5c9826', 
        Unknown : 'eb4b1443-8686-40a4-bc33-c743703d8678', 
        WindPolicy : '60c420b1-294b-4c81-9bfc-119067c35202'
    }
}