import { Column, Label, Row, Select } from '../../../components'
import { FallbackComponent } from '../../../shared/components/FallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'
import TextInputWithLabelInline from '../../../shared/components/input/TextInputWithLabelInline';
import TextFieldWithLabelInline from '../../../shared/components/input/TextFieldWithLabelInline';
import * as label from '../../../shared/util/label'
import './ScreenPrivilege.scss';
import RoleDetailsModel from '../../model/RoleDetailsModel'
import ScreenPrivilegeGrid from './ScreenPrivielgeGrid';
import ScreenElementPrivlegeModel from '../../model/ScreenElementPrivlegeModel';
import { useContext, useEffect, useState } from 'react';
import { SelectOption } from '../../../shared/components/input/SelectOption';
import ScreenPrivilegeService from './ScreenPrivilegeService';
import AppContext from '../../../shared/components/AppContext';
import * as constant from '../../../shared/util/constant'
import { useConfirmationModalContext } from "../../../shared/components/ModalConfirmationContext";
import AppRolePrivielge from '../../model/AppRolePrivielge';
import { toast } from 'react-toastify';
import Logger from '../../../shared/services/Logger';
import { Container, Button } from 'react-bootstrap';

interface Props {
    roleId: string
    onCancel: () => void;
    onSave: (value: String) => void;
}


const ScreenPrivilege = (props: Props) => {

    const roleId = props.roleId

    const [screenPrivilegeGrids, setScreenPrivilegeGrids] = useState<ScreenElementPrivlegeModel[][]>([]);

    const screenPrivilegeService = new ScreenPrivilegeService();

    const [screenOptions, setScreenOptions] = useState<SelectOption[]>([]);

    const [selectedScreenOptions, setSelectedScreenOptions] = useState<{ [key: number]: string }>({});

    const initialScreenPrivilegeGridData: ScreenElementPrivlegeModel[] = [];

    const initialRoleDetails: RoleDetailsModel = {
        id: '',
        roleName: '',
        roleDescription: ''
    }

    const initialScreenPrivilege: AppRolePrivielge = {
        roleDetails: initialRoleDetails,
        featurePrivielges: []
    };

    const intialValidationState = {
        roleName: { isValid: true, message: '' },
        roleDescription: { isValid: true, message: '' },
    };

    const [roleDetails, setRoleDetails] = useState<RoleDetailsModel>(initialRoleDetails)

    const [roleScreenDetails, setRoleScreenDetails] = useState<AppRolePrivielge>(initialScreenPrivilege);

    const modalContext = useConfirmationModalContext();

    const roleDetailsContext = useContext(AppContext);

    const [validations, setValidations] = useState(intialValidationState);

    useEffect(() => {
        initializeFeatures();
    }, []);

    useEffect(() => {
        setScreenPrivilegeGrids([]);
        setRoleDetails(initialRoleDetails)
        setValidations(intialValidationState)
        setSelectedScreenOptions([]);

        if (roleId) {
            getRoleDetails();
        }
    }, [roleId]);

    useEffect(() => {
        setRoleScreenDetails({
            ...roleScreenDetails,
            roleDetails: roleDetails
        })
    }, [roleDetails]);

    useEffect(() => {
        setRoleScreenDetails({
            ...roleScreenDetails,
            featurePrivielges: screenPrivilegeGrids.map(grid => ({
                elementPrivilege: grid,
                featureId: grid[0]?.featureId
            }))
        })

    }, [screenPrivilegeGrids]);

    const onRoleScreenDetailsUpdate = async (roleScreenDetails : AppRolePrivielge) => {
        setRoleDetails(roleScreenDetails.roleDetails)
        const existingScreenOptionsSelected = roleScreenDetails.featurePrivielges.map(fp => fp.featureId);
        setSelectedScreenOptions(existingScreenOptionsSelected.reduce((acc, curr, index) => {
            acc[index] = curr;
            return acc;
        }, {}));
        setScreenPrivilegeGrids(roleScreenDetails.featurePrivielges.map(fp => fp.elementPrivilege))
    }

    const initializeFeatures = async () => {
        try {
            let response = await screenPrivilegeService.getFeatures();
            setScreenOptions(response)
        }
        catch (error) {
            toast.error(constant.ERROR_MESSAGE_LOAD, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });
            Logger.logError(error.toString());
        }
    }

    const getRoleDetails = async () => {
        try {
            let response = await screenPrivilegeService.getRoleDetails(roleId);
            setRoleScreenDetails(response)
            onRoleScreenDetailsUpdate(response)
        }
        catch (error) {
            toast.error(constant.ERROR_MESSAGE_LOAD, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });
            Logger.logError(error.toString());
        }
    }

    const handleAddScreenPrivilege = () => {
        setScreenPrivilegeGrids([...screenPrivilegeGrids, initialScreenPrivilegeGridData])
        roleDetailsContext.formSavedStatus = false;
    };

    const handleScreenOptionChange = (selectedValue: string, gridIndex: number) => {
        setSelectedScreenOptions((prevSelected) => ({
            ...prevSelected,
            [gridIndex]: selectedValue,
        }));
        //  roleDetailsContext.formSavedStatus = false;
    };

    const getFilteredScreenoptions = (gridIndex: number) => {
        return screenOptions.filter(
            (option) =>
                !Object.values(selectedScreenOptions).includes(option.value) ||
                selectedScreenOptions[gridIndex] === option.value
        )
    }

    const handleScreenPrivilegeChange = (index: number, updatedData: ScreenElementPrivlegeModel[]) => {
        roleDetailsContext.formSavedStatus = false;
        setScreenPrivilegeGrids((prevGrids) => {
            const newGrids = [...prevGrids];
            newGrids[index] = updatedData;
            return newGrids;
        })
    }

    const onCancel = async () => {
        const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
        if (result) {
            setRoleDetails(initialRoleDetails)
            setScreenPrivilegeGrids([])
            roleDetailsContext.formSavedStatus = true;
            props.onCancel();
        }
    }

    const validateLength = (value: string, min: number, max: number) => {
        if (value.length === 0)
            return 'required';
        else if (value.length < min)
            return 'tooShort';
        else if (value.length > max)
            return 'tooLong';
        else
            return null
    }

    const getValidationMessage = (fieldName: string, errorType: string | null) => {
        if (!errorType)
            return '';
        else if (errorType === 'required')
            return constant.FIELD_BLANK
        else if (errorType === 'tooShort' || errorType === 'tooLong') {
            if (fieldName === 'roleName')
                return constant.NAME_LEN_ERROR
            else if (fieldName === 'roleDescription')
                return constant.DESCRIPTION_LEN_ERROR
            else return '';
        }
        else return '';
    }

    const validateForSave = () => {
        const roleNameError = validateLength(roleDetails.roleName, 3, 150);
        const roleDescriptionError = validateLength(roleDetails.roleDescription, 3, 250);
        setValidations({
            roleName: {
                isValid: !roleNameError,
                message: getValidationMessage('roleName', roleNameError),
            },
            roleDescription: {
                isValid: !roleDescriptionError,
                message: getValidationMessage('roleDescription', roleDescriptionError),
            },
        });

        return !roleNameError && !roleDescriptionError
    };

    const onSave = async () => {
        if (validateForSave()) {
            if (!roleScreenDetails.roleDetails.id) {
                try {
                    const saveRoleDetailsResposne = await screenPrivilegeService.saveRoleDetails(roleScreenDetails)
                    if (saveRoleDetailsResposne) {
                        toast.success(constant.RolePrivilege.ROLE_SAVED_SUCCESSFULLY, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        setRoleScreenDetails(saveRoleDetailsResposne)
                        roleDetailsContext.formSavedStatus = true;
                        props.onSave(saveRoleDetailsResposne.roleDetails.id)
                    }
                    else {
                        toast.error(constant.RolePrivilege.ROLE_ERROR_WHILE_SAVING, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        roleDetailsContext.formSavedStatus = false;
                    }
                } catch (error) {
                    toast.error(constant.RolePrivilege.ROLE_ERROR_WHILE_SAVING, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                    Logger.logError(error.toString());// Catch any additional errors
                    roleDetailsContext.formSavedStatus = false;
                }
            }
            else {
                try {
                    const updateRoleDetailsResposne = await screenPrivilegeService.updateRoleDetails(roleScreenDetails)
                    if (updateRoleDetailsResposne) {
                        toast.success(constant.RolePrivilege.ROLE_SAVED_SUCCESSFULLY, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        setRoleScreenDetails(updateRoleDetailsResposne)
                        onRoleScreenDetailsUpdate(updateRoleDetailsResposne)
                        roleDetailsContext.formSavedStatus = true;
                         props.onSave(updateRoleDetailsResposne.roleDetails.id)

                    }
                    else {
                        toast.error(constant.RolePrivilege.ROLE_ERROR_WHILE_SAVING, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        roleDetailsContext.formSavedStatus = false;
                    }
                } catch (error) {
                    toast.error(constant.RolePrivilege.ROLE_ERROR_WHILE_SAVING, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                    Logger.logError(error.toString()); // Catch any additional errors
                    roleDetailsContext.formSavedStatus = false;
                }
            }
        }
        else {
            toast.warn(constant.RolePrivilege.ROLE_VALIDATION_ERROR_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onFieldChange = (name: string, value: string) => {
        setRoleDetails({
            ...roleDetails,
            [name]: value
        })
        roleDetailsContext.formSavedStatus = false;
    }

    return (
        <Container fluid>
<Row className="screenPrivilegeDetails">
            <Column lg={12} md={12}>
                <ErrorBoundary FallbackComponent={FallbackComponent}>
                    <Column lg={12} md={12} className=''>
                        <Row>
                            <Column>
                                {roleId ?
                                    <Label text={`EDIT ${roleDetails.roleName.toLocaleUpperCase()}`} className='role-name-label'></Label>
                                    : null}
                            </Column>
                        </Row>
                        <Row className='cstPanel'>
                            <Column lg={4} className={validations.roleName.isValid ? '' : ''}>
                            <Select
  id="roleName"
  controlId="roleName"
  className="mb-3"
  label={label.RolePrivilegeSetupLabels.ROLE}
  options={[{ 
    label: roleDetails.roleName,
    value: roleDetails.roleName,
    isInactive: false
  }]}
  defaultSelected={[{
    label: roleDetails.roleName,
    value: roleDetails.roleName,
    isInactive: false
  }]}
  multiple={false}
  onChange={(values) => onFieldChange('roleName', values[0] as string)}
  placeholder={label.RolePrivilegeSetupLabels.ROLE_NAME}
  isInvalid={!validations.roleName.isValid}
  invalidWarning={validations.roleName.message}
  isRequired={true}
  showAsFloatingLabel={true}
/>
                                
                            </Column>
                            <Column lg={8} className={validations.roleDescription.isValid ? '' : ''}>
                            <Select
  id="roleDescription"
  controlId="roleDescription"
  className="mb-3"
  label={label.DESCRIPTION}
  options={[{ 
    label: roleDetails.roleDescription,
    value: roleDetails.roleDescription,
    isInactive: false
  }]}
  defaultSelected={[{
    label: roleDetails.roleDescription,
    value: roleDetails.roleDescription,
    isInactive: false
  }]}
  multiple={false}
  onChange={(values) => onFieldChange('roleDescription', values[0] as string)}
  placeholder={label.DESCRIPTION}
  isInvalid={!validations.roleDescription.isValid}
  invalidWarning={validations.roleDescription.message}
  isRequired={true}
  tabIndex={2}
  showAsFloatingLabel={true}
/>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                {screenPrivilegeGrids.map((gridData, index) => (
                                    <ScreenPrivilegeGrid
                                        key={index}
                                        gridIndex={index}
                                        screenPrivilegeData={gridData}
                                        screenOptions={getFilteredScreenoptions(index)}
                                        onScreenDropDownChange={(selectedValue) => handleScreenOptionChange(selectedValue, index)}
                                        onGridDataChange={(updatedData) => handleScreenPrivilegeChange(index, updatedData)}
                                        zIndexHeader={10000 - index * 100} children={''}>
                                    </ScreenPrivilegeGrid>
                                ))}
                            </Column>
                        </Row>
                        
                        <Row className='screen-privelege-footer mt-3'>
                            <Column lg={12} className=''>
                            <div className='d-flex justify-content-between align-items-center'>
                            <Button color='primary' variant='outline-success' onClick={handleAddScreenPrivilege}>{label.RolePrivilegeSetupLabels.ADD_SCREEN_PRIVILEGE}</Button>

<Button color='primary' variant='outline-primary' onClick={onCancel} className='ms-auto'>{label.CANCEL.toUpperCase()}</Button>
<Button color='primary'  onClick={onSave} className='ms-2'>{label.SAVE.toUpperCase()} ROLE</Button>

                                </div>
                            </Column>
                        </Row>
                    </Column>
                </ErrorBoundary>
            </Column>
        </Row>
        </Container>
        

    );
}

export default ScreenPrivilege;