import CampaignNavbar from './campaignNavbar'
import CustomerFilterForm from './filter/CustomerSearch'
import customerSearch from './filter/CustomerFilter'
import { Select, Label, Button, Container, Row, Column, Panel, Spinner } from '../components'
import { Redirect, Route, Switch } from 'react-router-dom'
import PrivateRoute from '../shared/components/PrivateRoute'
import TextInputWithLabelInline from '../../src/shared/components/input/TextInputWithLabelInline'
import CampaignDashboard from './filter/CampaignDashboard'
import Campaigns from './filter/Campaign'
import Calendar from './filter/Calendar'
import Queries from './filter/Queries'
import ExternalTemplate from './filter/3rdPartyTemplate'
import QueryDetail from './filter/QueryDetail'
import ExternalTemplateDetail from './filter/3rdPartyTemplateDetail'
import { useEffect} from 'react'
import CampaignDetails from './filter/CampaignDetails'
import CampaignApprovalDetails from './filter/CampaignApprovalDetails'
import { AbilityContext } from '../../src/shared/abilities/AbilityContext';
import { useContext } from 'react';
import * as constant from '../shared/util/constant'
import MainDashboard from '../dashboard/main-dashboard'

interface Props {
  isSideBarCollapsed: boolean
}


const Campaign = (props: Props) => {
  const ability = useContext(AbilityContext);
  const canUserViewCampaign = ability.can('view', "Campaign", constant.ScreenName.CAMPAIGN)
  const canUserReadCampaign = ability.can('read', "Campaign", constant.ScreenName.CAMPAIGN)
  const canUserViewQuery = ability.can('view', "Campaign Query", constant.ScreenName.Campaign_Query)
  const canUserReadQuery = ability.can('read', 'Campaign Query', constant.ScreenName.Campaign_Query);
  const canUserViewCalendar = ability.can('view', 'Campaign Calendar', constant.ScreenName.Campaign_Calendar);
  const canUserReadCalendar = ability.can('read', 'Campaign Calendar', constant.ScreenName.Campaign_Calendar);
  const canUserViewTemplate = ability.can('view', "Campaign Template", constant.ScreenName.CAMAPIGN_TEMAPLATE)
  const canUserReadTemplate = ability.can('read', 'Campaign Template', constant.ScreenName.CAMAPIGN_TEMAPLATE);
  const canUserViewApproval = ability.can('view', "Campaign Approval", constant.ScreenName.CAMPAIGN_APPROVAL)
  const canUserReadApproval = ability.can('read', 'Campaign Approval', constant.ScreenName.CAMPAIGN_APPROVAL);


  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--marginLeftCustomerRow', props.isSideBarCollapsed ? '100px' : '250px')
  }, [props.isSideBarCollapsed]);



  return (

    //   <QuickSearchContext.Provider value={{quickFilterData:[quickFilter, setQuickFilter]}}>

    <div>


      <CampaignNavbar />

      {/* <Column lg={3} className='cstQuickSearch' >
        <InputGroup>
        <Column lg={10}>
        <TextInputWithLabelInline
          name='QuickSearch'
         value={quickFilter.QuickSearchString}
          isEditable
         type='text'
         onChange={(event) => onFieldChange('QuickSearchString', event.currentTarget.value)}
        />
        </Column>
        <Column lg={2}>
            <InputGroup.Append className='inputGroupSearchAppend'>
          <InputGroup.Text id="inputGroup-sizing-sm" className='inputGroupSearch' onClick={(event)=>onQuickSearch(event)}>
            <Icon icon="search" size="xs"/>
          </InputGroup.Text>
        </InputGroup.Append>
        </Column>
      </InputGroup>

           </Column> */}
      <div className='container-fluid bg-light px-4'>
        
            {/* <CustomerFilterForm/> */}

            <Switch>
                        <Route
                            exact
                            path="/Campaign/CampaignDashboard"
                            component={CampaignDashboard}
                        />
                        {canUserViewCampaign && canUserReadCampaign && <Route
                            exact
                            path="/campaign/Campaign"
                            component={Campaigns}
                        />}
                        {canUserViewCalendar && canUserReadCalendar &&
                            <Route
                                exact
                                path="/campaign/Calendar"
                                component={Calendar}
                            />}

                        {canUserViewApproval && canUserReadApproval && <Route
                            exact
                            path="/campaign/ApprovalDetails"
                            component={CampaignApprovalDetails}
                        />}

                        {canUserViewQuery && canUserReadQuery &&
                            <Route
                                exact
                                path="/campaign/Queries"
                                component={Queries}
                            />}
                        {(canUserViewTemplate && canUserReadTemplate) && <Route
                            exact
                            path="/campaign/3rdPartyTemplate"
                            render={(props) => <ExternalTemplate {...props} subject="CampaignTemplate" />}
                        />}
                        {(canUserViewTemplate && canUserReadTemplate) && <Route
                            exact
                            path="/campaign/3rdPartyTemplateDetail"
                            component={ExternalTemplateDetail}
                        />}
                        {canUserViewCampaign && canUserReadCampaign && <Route
                            exact
                            path="/campaign/CampaignDetails"
                            render={(props) => <CampaignDetails {...props} subject="Campaign" />}
                        //component={CampaignDetails}
                        />}
                        {canUserViewQuery && canUserReadQuery && <Route
                            exact
                            path="/campaign/QueryDetail"
                            component={QueryDetail}
                        />}
                        <Redirect to={"/"} >{MainDashboard}</Redirect>
            </Switch>

        {/* <div className="row">
        <div className="col-lg-12">
        <CustomerDashboardView/>
        </div>
    </div> */}
      </div>

    </div>

    //  </QuickSearchContext.Provider>

  )

}

export default Campaign