import { Column, Label, Row, Navbar} from '../components';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom'
import './DocuMateHeader.scss';
import { useEffect } from 'react';
import * as documateLabel from './shared/util/docuMateLabel';
import DocuMateScanPortal from './DocuMateScan/DocuMateScanPortal';
import DocuMateTodoList from './DocuMateIndexing/DocuMateTodoList';
import DocuMateIndexing from './DocuMateIndexing/DocuMateIndexing';
import RefiTodoList from './DocuMateRefi/RefiTodoList';
import RefiProcessing from './DocuMateRefi/RefiProcessing';

interface Props {
    isSideBarCollapsed: boolean
  }

const DocuMateHeader = (props: Props) => {

    useEffect (() => {
        const root =document.documentElement;
        root.style.setProperty('--marginLeftCustomerRow', props.isSideBarCollapsed ? '100px' : '250px')
      },[props.isSideBarCollapsed]);

      const renderNavbarWithLabel = (title: string, text: string, className: string) => (
        <Navbar
          bg='light'
          variant='light'
          className='py-0'
          title={''} id={''}
          navItems={[{
            type: 'header',
            alignRight: true,
            children: null,
            className: `${className} nav-add-new d-none d-md-block nav-bar-item-width`,
            iconClassName: 'align-bottom',
            label: text,
            name: title,
            size: 'sm',
          }]}
          children={''}
        />
      );
      
    return (
        <div className='bg-light'>
            <Switch>
                <Route path="/documate-scan">
                    {renderNavbarWithLabel('DashboardHeader', documateLabel.DOCUMATE_SCAN_TITLE, 'documateHeader')}
                    <DocuMateScanPortal />
                </Route>
                <Route exact path="/documate-indexing">
                    {renderNavbarWithLabel('DocuMateTodoList', documateLabel.DOCUMATE_SCAN_TITLE, 'documateHeader')}
                    <DocuMateTodoList />
                </Route>
                <Route exact path="/documate-indexing/processing/:id" render={() => (
                    <>
                        {renderNavbarWithLabel('DocuMateIndexing', documateLabel.DOCUMATE_INDEXING_TITLE, 'documateHeader')}
                        <DocuMateIndexing />
                    </>
                )} />
                <Route exact path="/documate-refi">
                    {renderNavbarWithLabel('DocuMateRefi', documateLabel.DOCUMATE_REFI_TITLE, 'documateHeader')}
                    <RefiTodoList />
                </Route>
                <Route exact path="/documate-refi/processing" render={() => (
                    <>
                        {renderNavbarWithLabel('DocuMateRefiProcessing', documateLabel.DOCUMATE_REFI_TITLE, 'documateHeader')}
                        <RefiProcessing />
                    </>
                )} />
            </Switch>
        </div>
    );
};

export default DocuMateHeader;