import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom'
import CustomerDetailService from "./CustomerDetailService";
import Customer from "../model/Customer";
import { Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { Checkbox, Column, Icon, Panel, Select } from "../../components";
import TextInputWithLabelInline from "../../shared/components/input/TextInputWithLabelInline";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
import { SelectOption } from "../../shared/components/input";
import CustomerDependentView from "./CustomerDependentView";
import { CustomerScreentLables as cstScrLables } from "../../shared/util/label";
import { toast } from "react-toastify";
import * as constant from '../../shared/util/constant';
import './CustomerDetail.scss';
import { useDispatch, useSelector } from "react-redux";
import { updateCustomers } from "../customer-slice/Customer-slice";
import { updateLookupData } from "../customer-slice/Customer-lookup-slice"
import { RootState } from "../../shared/store";
import { uuid } from "../../shared/util/uuid";
import { CustomerDependent } from "../model/CustomerDependentModel";
import FormattedInputWithLableInline, { InputFormatType } from "../../shared/components/input/formatted-input/FormattedInputWithLableInline";
import Logger from "../../shared/services/Logger";
import CustomerCompanyContactView from "./CustomerCompanyContactView";
import { CustomerCompanyContact } from "../model/CustomerCompanyContactModel";
import * as label from '../../shared/util/label';
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import AppContext from "../../shared/components/AppContext";
import { cleareTabsInputs } from "../customer-slider-content/CustomerSliderContext";
import { Button } from 'react-bootstrap';
import { AbilityContext } from "../../shared/abilities/AbilityContext";

export interface ICustomerDetailComponent {
    customerId: string,
    isCreatingNewCustomer: boolean
}

const CustomerDetail: React.FunctionComponent<ICustomerDetailComponent> = (props) => {
    const history = useHistory();
    const customerDetailService = new CustomerDetailService();
    const id = props.customerId;
    const isCreatingNewCustomer = props.isCreatingNewCustomer;

    // Customer details to show.
    const [customerDetail, setCustomerDetail] = useState<Customer>({});
    const [intialCustomerDetail, setInitialCustomerDetail] = useState<Customer>({});

    // Lookup data states.
    const [maritalStatuses, setMaritalStatuses] = useState<SelectOption[]>([]);
    const [industries, setIndustries] = useState<SelectOption[]>([]);
    const [occupancies, setOccupancies] = useState<SelectOption[]>([]);
    const [relationships, setRelationShips] = useState<SelectOption[]>([]);
    const [agencies, setAgencies] = useState<SelectOption[]>([]);
    const [genders] = useState<SelectOption[]>(customerDetailService.getGenderList());
    const [states, setStates] = useState<SelectOption[]>([]);
    const [countries, setCountries] = useState<SelectOption[]>([]);
    const [statesUS, setUSState] = useState<SelectOption[]>([]);
    const [statesCanada, setCanadaState] = useState<SelectOption[]>([]);
    const [isStateAsDropdown, setIsstateAsDropdown] = useState(false);
    const [formValidation, setFormValidation] = useState<any>({});
    const [isExpended, setIsExpended] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [reset, isReset] = useState(false);
    const [isFormSaved, setFormSaved] = useState(true);
    const [isBillingInfoVisible, setIsBillingInfoVisible] = useState(customerDetail?.isBillAddressSame);
    const modalContext = useConfirmationModalContext();
    const myContext = useContext(AppContext);
    const divToScroll = useRef(null)

    const ab_subject = "Customer";
    const ability = useContext(AbilityContext);
    const canUserEditCustomer = ability.can('update',ab_subject,constant.ScreenName.CUSTOMER);
    const canUserDeleteCustomer = ability.can('delete',ab_subject,constant.ScreenName.CUSTOMER);

    /// Get customer and lookup from redux slicer.
    const dispatch = useDispatch()
    let customerInfo = useSelector((state: RootState) => state?.customerSlice);
    let maritalStatusLookUp = useSelector((state: RootState) => state?.LookupState.maritalStatusList);
    let industryLookup = useSelector((state: RootState) => state?.LookupState.industryList);
    let occupationLookup = useSelector((state: RootState) => state?.LookupState.occupancyList);
    let relatioshipLookup = useSelector((state: RootState) => state?.LookupState.relationshipList);
    let agencyLookup = useSelector((state: RootState) => state?.LookupState.agencyList);
    let stateLookup = useSelector((state: RootState) => state?.LookupState.stateList);
    let countryLookup = useSelector((state: RootState) => state?.LookupState.countryList);
    let stateUSLookup = useSelector((state: RootState) => state?.LookupState.stateUSList);
    let stateCanadaLookup = useSelector((state: RootState) => state?.LookupState.stateCanadaList);

    useEffect(() => {
        setCustomerDetail({
            ...customerDetail,
            state: "",
            billState : "",          
        }) 
      }, [customerDetail.countryId]);

    useEffect(() => {
        if (!isCreatingNewCustomer && customerInfo.customer.id === id && (customerInfo.customer.id !== null && customerInfo.customer.id !== undefined)) {
            setCustomerDetail(customerInfo.customer)
        }
    }, [customerInfo]);

    useEffect(() => {
        initializeComponent(id);
    }, [id]);
    useEffect(() => {
        myContext.formSavedStatus = isFormSaved
    }, [isFormSaved])

    /// Method to intialize component.
    let initializeComponent = async (customerId: string) => {

        try {
            /// Check if customer object available in redux slicer, if not fetch it from service.
            let customerData = getNewCustomer();
            if (!isCreatingNewCustomer) {
                customerData = customerInfo.customer;
                if (customerInfo.customer.id !== id || customerInfo.customer.id === null || customerInfo.customer.id === undefined) {
                    customerData = await customerDetailService.fetchCustomerById(customerId);
                    dispatch(updateCustomers(customerData))
                }
            }
            else {
                customerData.countryId = constant.DATA_IDs.COUNTRIES.USA;
                EditCustomer();
            }

             let initData ={maritalStatusList:[], industryList :[], occupancyList :[], relationshipList : [], agencyList : [], stateList : [], countryList : [], stateUSList : [] , stateCanadaList : []};
             if(maritalStatusLookUp.length === 0 || 
                industryLookup.length === 0 || occupationLookup.length === 0 || 
                relatioshipLookup.length === 0 || agencyLookup.length == 0 ||stateLookup.length === 0 ||
                countryLookup.length === 0 || stateUSLookup.length === 0 || stateCanadaLookup.length === 0) {
              initData = await customerDetailService.fetchComponentInitData();
              dispatchAllLookup(initData)
             }
             else{
                initData = {maritalStatusList: [...maritalStatusLookUp], industryList : [...industryLookup], occupancyList : [...occupationLookup], relationshipList : [...relatioshipLookup], agencyList : [...agencyLookup], stateList : [...stateLookup], countryList : [...countryLookup], stateUSList : [...stateUSLookup], stateCanadaList : [...stateCanadaLookup] }
             }
           
            setMaritalStatuses(initData.maritalStatusList);
            setIndustries(initData.industryList);
            setOccupancies(initData.occupancyList)
            setRelationShips(initData.relationshipList); 
            setAgencies(initData.agencyList);
            setCountries(initData.countryList)
            setUSState(initData.stateUSList)
            setCanadaState(initData.stateCanadaList)
            setCustomerDetail(customerData);
            setInitialCustomerDetail(customerData)
            setIsstateAsDropdown( customerData.countryId ? customerData.countryId === constant.DATA_IDs.COUNTRIES.CANADA || customerData.countryId === constant.DATA_IDs.COUNTRIES.USA : true)
            setStates(customerData.countryId ? customerData.countryId === constant.DATA_IDs.COUNTRIES.USA ? initData.stateUSList : 
                customerData.countryId === constant.DATA_IDs.COUNTRIES.CANADA ? initData.stateCanadaList :
                [] : initData.stateUSList)            
        }
        catch (ex) {
            toast.error(constant.CUSTOMER_DETAIL_ERROR_WHILE_LOADING_INITIAL_DATA, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const getNewCustomer = (): Customer => {
        return {
            customerDependents: [],
            customerContacts: [],
        };
    }

    const onEntityFieldChange = (propName: string, value: string | Date | boolean) => {
        setCustomerDetail({
            ...customerDetail,
            [propName]: value,
        });

        if (propName == 'firstName' || propName == 'lastName') {
            setCustomerDetail(cst => {
                return {
                    ...cst,
                    displayName: (cst.firstName ? cst.firstName : '') + ' ' + (cst.lastName ? cst.lastName : ''),
                };
            });
        }
    }

    const toggleEntityField = (propName: string) => {

        setCustomerDetail({
            ...customerDetail,
            [propName]: !customerDetail[propName],
        });
    }
    const populateBillingAddress = (event) => {
        if (event.currentTarget.checked) {
            setCustomerDetail({
                ...customerDetail,
                isBillAddressSame: !customerDetail.isBillAddressSame,
                billAddress1: customerDetail.address1,
                billAddress2: customerDetail.address2,
                billCity: customerDetail.city,
                billCounty: customerDetail.county,
                billState: customerDetail.state,
                billZip: customerDetail.zipCode,

            })
        }
        else {
            setCustomerDetail({
                ...customerDetail,
                isBillAddressSame: !customerDetail.isBillAddressSame,
                billAddress1: "",
                billAddress2: "",
                billCity: "",
                billCounty: "",
                billState: "",
                billZip: "",
            })
        }
    }

const onCountrySelection = (countrySelected: string) => {

    if(countrySelected == constant.DATA_IDs.COUNTRIES.USA)
    {
        setIsstateAsDropdown(true)
        setStates(statesUS);
    }
    else if(countrySelected == constant.DATA_IDs.COUNTRIES.CANADA)
        {
            setIsstateAsDropdown(true)
            setStates(statesCanada)
        }
    else
    {
        setIsstateAsDropdown(false)
        setStates([])
    }
}

    // Update a collection property value. For example any of the dependent information changed in child component. 
    // param : collectionPropName                   -- Name of the navigation collection property. Example for customer.customerDependents, it's 'customerDependents'
    // param : collectionEntityIdentifierPropName   -- Name of the navigation collection property identifier. Example for customer.customerDependents, it's 'deptId'
    // param : collectionEntityIdentifierValue      -- Identifier for naviation collection prop. here it depdId value.
    // param : propName                             -- Name of navigation entity prop, which is changed. like 'firstName', 'lastName', 'relationshipId' etc.
    // param : value                                -- value changed.
    const onCollectionEntityFieldChange = (collectionPropName: string, collectionEntityIdentifierPropName: string, collectionEntityIdentifierValue: any, propName: string, value: string | Date | boolean) => {
        let subCollectionEntityIndex = customerDetail[collectionPropName].findIndex(item => item[collectionEntityIdentifierPropName] == collectionEntityIdentifierValue);
        let updatedSubEntity = {
            ...customerDetail[collectionPropName][subCollectionEntityIndex],
            [propName]: value,
            isModified: true
        };

        let collectionCopy = [...customerDetail[collectionPropName]];
        collectionCopy.splice(subCollectionEntityIndex, 1, updatedSubEntity);

        setCustomerDetail(cst => {
            var updatedObj = {
                ...cst,
                [collectionPropName]: collectionCopy
            };
            return updatedObj;
        });
    }
    const scrollToNewSection = () => {
        divToScroll.current && divToScroll.current.scrollIntoView({ behavior: 'smooth' })
    }
    const onAddDependentClick = (e) => {
        let panelHtml = e.currentTarget.closest('div') as HTMLDivElement
        panelHtml.click();
        setCustomerDetail(cust => {
            let custData = { ...cust };
            custData.customerDependents = [...custData.customerDependents, { depdId: uuid() }];
            return custData;
        });

        setTimeout(() => {
            scrollToNewSection();
        }, 500)

        e.stopPropagation()
    }

    const onAddCompanyContactClick = (e) => {
        let panelHtml = e.currentTarget.closest('div') as HTMLDivElement
        panelHtml.click();
        setCustomerDetail(cust => {
            let custData = { ...cust };
            custData.customerContacts = [...custData.customerContacts, { id: uuid() }];
            return custData;
        });
        setTimeout(() => {
            scrollToNewSection();
        }, 500)
        e.stopPropagation()
    }

    const onDependentDelete = (dependentToDelete: CustomerDependent) => {
        setCustomerDetail(cust => {
            let custData = { ...cust };
            custData.customerDependents = custData.customerDependents.filter(item => item.depdId != dependentToDelete.depdId);
            return custData;
        });
    }

    const onDeleteContact = (contactToDelete: CustomerCompanyContact) => {
        setCustomerDetail(cust => {
            let custData = { ...cust };
            custData.customerContacts = custData.customerContacts.filter(item => item.id != contactToDelete.id);
            return custData;
        });
    }

    const customerDependentsPanelHeaderItems = <><Button id={"btnAddDependent"} disabled={!isEditable} size="sm" variant="outlined" onClick={(e) => { onAddDependentClick(e); }}>{cstScrLables.ADD_ADDITIONAL_CUSTOMER_BUTTON}</Button></>;
    const customerCompanyContsctsPanelHeaderItems = <><Button id={"btnAddCntact"} disabled={!isEditable} size="sm" variant="outlined" onClick={(e) => { onAddCompanyContactClick(e); }}>{cstScrLables.ADD_COMPANY_CONTACT}</Button></>;

    const customerDependentView = customerDetail.customerDependents?.length ? (
        customerDetail.customerDependents.map((cd, index) => (
            <CustomerDependentView
                key={cd.depdId}
                index={index}
                dependent={cd}
                maritalStatuses={maritalStatuses}
                industries={industries}
                occupancies={occupancies}
                onFieldChange={onCollectionEntityFieldChange}
                onDelete={onDependentDelete}
                formValidations={formValidation['customerDependents'] ? formValidation['customerDependents'][cd.depdId] : {}}
                relationships={relationships}
                isEditable={isEditable}
                isReset={reset}
                divToScroll={divToScroll}
            />
        ))
    ) : (
        <div className="alert alert-light text-muted text-center p-3">No additional customers found</div>
    );

    const customerContactView = customerDetail.customerContacts?.length ? (
        customerDetail.customerContacts.map((ct, index) => (
            <CustomerCompanyContactView
                index={index}
                key={ct.id}
                contact={ct}
                state={statesUS}
                onFieldChange={onCollectionEntityFieldChange}
                onDelete={onDeleteContact}
                formValidations={formValidation['customerContacts'] ? formValidation['customerContacts'][ct.id] : {}}
                isEditable={isEditable}
                isReset={reset}
                divToScroll={divToScroll}
            />
        ))
    ) : (
        <div className="alert alert-light text-muted text-center p-3">No company contacts found</div>
    );


    const contactInoLg = 4;
    const contactInoMd = 4;

    const dispatchAllLookup = (initData) => {
        dispatch(updateLookupData({ data: initData.maritalStatusList, type: constant.SlicerLookUpConstant.MARITAL_STATUS }))
        dispatch(updateLookupData({ data: initData.industryList, type: constant.SlicerLookUpConstant.INDUSTRY }))
        dispatch(updateLookupData({ data: initData.occupancyList, type: constant.SlicerLookUpConstant.OCCUPATION }))
        dispatch(updateLookupData({ data: initData.agencyList, type: constant.SlicerLookUpConstant.AGENCY }))
        dispatch(updateLookupData({ data: initData.relationshipList, type: constant.SlicerLookUpConstant.RELATIONSHIP }))
        dispatch(updateLookupData({ data: initData.stateList, type: constant.SlicerLookUpConstant.STATE }))
    }
    const EditCustomer = () => {
        setIsEditable(true)
        setFormSaved(false);
        isReset(false)
    }

const deleteCustomer = async () => {
    try {

        let validationResp = await customerDetailService.canDeleteCustomer(id);

        if (validationResp != "CUSTOMER_CAN_BE_DELETED") {
            let errorMessage = validationResp;
            switch (validationResp) {
                case constant.CUSTOMER_DELETE_INCOMPLETE_TASKS_RESPONSE:
                    errorMessage = constant.CUSTOMER_DELETE_ACTIVE_TASK;
                    break;
                case constant.CUSTOMER_DELETE_ACTIVE_POLICIES_RESPONSE:
                    errorMessage = constant.CUSTOMER_DELETE_ACTIVE_POLICIES;
                    break;
                case constant.CUSTOMER_DELETE_INACTIVE_POLICIES_IN_LAST_ONE_YEAR_RESPONSE:
                    errorMessage = constant.CUSTOMER_DELETE_INACIVE_POLICY_IN_LAST_ONE_YEAR;
                    break;
                case constant.CUSTOMER_DELETE_ACTIVITY_EXISTS:
                        errorMessage = constant.CUSTOMER_DELETE_ACTIVITY_EXISTS_MESSAGE;
                        break;
                default:
                    errorMessage = constant.CUSTOMER_DELETE_ERROR;
                    break;
                }

            toast.warn(errorMessage, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let resp: any = await modalContext.showConfirmation(constant.CUSTOMER_DELETE_CONFIRM, constant.CUSTOMER_DELETE_CONFIRM_TITLE, constant.CUSTOMER_DELETE_CONFIRM_TEXT, constant.CUSTOMER_DELETE_CONFIRMATION_TEXT);

        if (!resp.result || resp.data?.toString() != constant.CUSTOMER_DELETE_CONFIRMATION_TEXT) {
            return;
        }

        await customerDetailService.deleteCustomer(id);

        toast.success(constant.CUSTOMER_DELETE_SUCCESS, {
            position: toast.POSITION.TOP_RIGHT
        });

        history.push('/customer/customerfilter');
    }
    catch(ex) {
        if (ex.status == 404) {
            toast.error(constant.CUSTOMER_NOT_FOUND, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(constant.CUSTOMER_DELETE_ERROR, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
}

    const CancelEdit = async () => {
        const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
        if (result) {
            setFormValidation(0)
            setCustomerDetail(intialCustomerDetail)
            isReset(true)
            setIsEditable(false)
            setFormSaved(true);

            if (isCreatingNewCustomer) {
                history.push('/customer/customerfilter');
            }

        }
    }


    const SaveCustomer = async () => {
        try {
            let validationError = customerDetailService.validateCustomer(customerDetail);
            setFormValidation(validationError);
            if (Object.keys(validationError).length > 0) {
                toast.warn(constant.CUSTOMER_FORM_VALIDATION_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            if (customerDetail.isBillAddressSame) {
                customerDetail.billAddress1 = customerDetail.address1,
                    customerDetail.billAddress2 = customerDetail.address2,
                    customerDetail.billCity = customerDetail.city,
                    customerDetail.billCounty = customerDetail.county,
                    customerDetail.billState = customerDetail.state,
                    customerDetail.billZip = customerDetail.zipCode
            }
            let updatedCustomer = await customerDetailService.saveCustomer(customerDetail);
            if (updatedCustomer) {
                dispatch(cleareTabsInputs());
                toast.success(constant.CUSTOMER_SAVED_SUCCESSFULLY, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setIsEditable(false)
                setFormSaved(true);
                setCustomerDetail(updatedCustomer)
                setInitialCustomerDetail(updatedCustomer)
                dispatch(updateCustomers(updatedCustomer));
                history.push(`/customer-view/customer/${updatedCustomer.id}`);
            }
            else {
                toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
        catch (e) {
            Logger.logError(constant.CUSTOMER_ERROR_WHILE_SAVING, e);
            if (e.status == 404) {
                toast.error(constant.CUSTOMER_NOT_FOUND, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error(constant.CUSTOMER_ERROR_WHILE_SAVING, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    /// JSX....
    return (
        <>
            <Container fluid>
                <Row>

                    <Column md={8}>
                        <Panel
                            title={cstScrLables.CUSTOMER_INFORMATION}
                            className={'customerPanel cstPanel customerOverview mt-2'}
                            cardBodyClassName="pt-0"
                            collapsible={false}>
                            <Row>
                                <Column lg={12} md={12} className="section-height">
                                    <Row>
                                        <Column lg={contactInoLg} md={contactInoMd}
                                            className={formValidation['firstName']?.isValid == false ? 'feedback-row' : ''}>
                                            <FloatingLabel
                                                controlId="firstName"
                                                label={cstScrLables.FIRST_NAME}
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder={cstScrLables.FIRST_NAME}
                                                    name="firstName"
                                                    value={customerDetail.firstName ?? ""}
                                                    onChange={(event) => onEntityFieldChange('firstName', event.currentTarget.value)}
                                                    disabled={!isEditable}
                                                    required={true}
                                                    tabIndex={1}
                                                    maxLength={60}
                                                    isInvalid={formValidation['firstName']?.isValid == false}
                                                />
                                                {formValidation['firstName']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['firstName']?.warningText}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FloatingLabel>
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                            <FloatingLabel
                                                controlId="midName"
                                                label={cstScrLables.MIDDLE_NAME}
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder=" "
                                                    name="midName"
                                                    value={customerDetail.midName ?? ""}
                                                    onChange={(event) => onEntityFieldChange('midName', event.currentTarget.value)}
                                                    disabled={!isEditable}
                                                    tabIndex={2}
                                                    maxLength={60}
                                                />
                                            </FloatingLabel>
                                        </Column>
                                        <Column lg={contactInoLg} md={contactInoMd}
                                            className={formValidation['lastName']?.isValid == false ? 'feedback-row' : ''}>
                                            <FloatingLabel
                                                controlId="lastName"
                                                label={cstScrLables.LAST_NAME}
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder={cstScrLables.LAST_NAME}
                                                    name="lastName"
                                                    value={customerDetail.lastName ?? ""}
                                                    onChange={(event) => onEntityFieldChange('lastName', event.currentTarget.value)}
                                                    disabled={!isEditable}
                                                    required
                                                    tabIndex={3}
                                                    maxLength={60}
                                                    isInvalid={formValidation['lastName']?.isValid == false}
                                                />
                                                {formValidation['lastName']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['lastName']?.warningText}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FloatingLabel>
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column lg={8} md={8}
                                            className={formValidation['displayName']?.isValid == false ? 'feedback-row' : ''}>
                                            <FloatingLabel
                                                controlId="displayName"
                                                label={cstScrLables.DISPLAY_NAME}
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder={cstScrLables.DISPLAY_NAME}
                                                    name="displayName"
                                                    value={customerDetail.displayName ?? ""}
                                                    onChange={(event) => onEntityFieldChange('displayName', event.currentTarget.value)}
                                                    disabled={!isEditable}
                                                    required
                                                    tabIndex={4}
                                                    maxLength={100}
                                                    isInvalid={formValidation['displayName']?.isValid == false}
                                                />
                                                {formValidation['displayName']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['displayName']?.warningText}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FloatingLabel>
                                        </Column>

                                        <Column lg={contactInoLg} md={contactInoMd}>
                                            <Select
                                                id="ddGenderList"
                                                controlId="ddGenderList"
                                                className='mb-3'
                                                label={cstScrLables.GENDER}
                                                options={genders}
                                                defaultSelected={genders.filter(option =>
                                                    option.value === customerDetail.sex
                                                )}
                                                onChange={(values) => onEntityFieldChange('sex', values[0] as string)}
                                                disabled={!isEditable}
                                                tabIndex={9}
                                                isInvalid={formValidation['sex']?.isValid == false}
                                                invalidWarning={formValidation['sex']?.warningText}
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column lg={contactInoLg} md={contactInoMd}>
                                            <Select
                                                id="ddIndustryId"
                                                controlId="ddIndustryId"
                                                className='mb-3'
                                                label={cstScrLables.INDUSTRY}
                                                options={industries}
                                                onChange={(values) => onEntityFieldChange('industryId', values[0] as string)}
                                                disabled={!isEditable}
                                                tabIndex={5}
                                                defaultSelected={industries.filter(option =>
                                                    option.value === customerDetail.industryId
                                                )}
                                                isInvalid={formValidation['industryId']?.isValid == false}
                                                invalidWarning={formValidation['industryId']?.warningText}
                                            />
                                        </Column>

                                        <Column lg={contactInoLg} md={contactInoMd}>
                                            <Select
                                                id="ddOccupation"
                                                controlId="ddOccupation"
                                                className='mb-3'
                                                label={cstScrLables.OCCUPATION}
                                                options={occupancies}
                                                defaultSelected={occupancies.filter(option =>
                                                    option.value === customerDetail.occupationId
                                                )}
                                                onChange={(values) => onEntityFieldChange('occupationId', values[0] as string)}
                                                disabled={!isEditable}
                                                tabIndex={6}
                                                isInvalid={formValidation['occupationId']?.isValid == false}
                                                invalidWarning={formValidation['occupationId']?.warningText}
                                            />
                                        </Column>

                                        <Column lg={contactInoLg} md={contactInoMd}
                                            className={formValidation['yearEmployed']?.isValid == false ? 'feedback-row' : ''}>
                                            <FloatingLabel
                                                controlId="yearEmployed"
                                                label={cstScrLables.YEAR_EMPLOYED}
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="number"
                                                    placeholder=" "
                                                    name="yearEmployed"
                                                    value={customerDetail.yearEmployed ?? ""}
                                                    onChange={(e) => onEntityFieldChange('yearEmployed', e.target.value)}
                                                    disabled={!isEditable}
                                                    tabIndex={7}
                                                    maxLength={4}
                                                    isInvalid={formValidation['yearEmployed']?.isValid == false}
                                                />
                                                {formValidation['yearEmployed']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['yearEmployed']?.warningText}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FloatingLabel>
                                        </Column>
                                    </Row>
                                    <Row className="cstErrorTopMargin">
                                    <Column lg={4} md={4}>
                                            <Select
                                                id="maritalStatusId"
                                                controlId="maritalStatusId"
                                                className='mb-3'
                                                label={cstScrLables.MARITAL_STATUS}
                                                options={maritalStatuses}
                                                defaultSelected={maritalStatuses.filter(option =>
                                                    option.value === customerDetail.maritalStatusId
                                                )}
                                                onChange={(values) => onEntityFieldChange('maritalStatusId', values[0] as string)}
                                                disabled={!isEditable}
                                                tabIndex={6}
                                                isInvalid={formValidation['maritalStatusId']?.isValid == false}
                                                invalidWarning={formValidation['maritalStatusId']?.warningText}
                                            />
                                        </Column>

                                        <Column lg={5} md={5}
                                            className={formValidation['agencyId']?.isValid == false ? 'feedback-row' : ''}>
                                            <Select
                                                id="agencyId"
                                                controlId="agencyId"
                                                className='mb-3'
                                                label={cstScrLables.AGENCY}
                                                options={agencies}
                                                defaultSelected={agencies.filter(option =>
                                                    option.value === customerDetail.agencyId
                                                )}
                                                onChange={(values) => onEntityFieldChange('agencyId', values[0] as string)}
                                                disabled={!isEditable}
                                                tabIndex={6}
                                                isInvalid={formValidation['agencyId']?.isValid == false}
                                                invalidWarning={formValidation['agencyId']?.warningText}
                                            />
                                        </Column>

                                        <Column lg={3} md={3}>
                                            <Form.Check
                                                id="chkVip"
                                                label={cstScrLables.VIP}
                                                checked={customerDetail.vip}
                                                onChange={(event) => toggleEntityField('vip')}
                                                disabled={!isEditable}
                                                className="mt-3"
                                            />
                                        </Column>
                                    </Row>

                                </Column>

                            </Row>
                        </Panel>

                        <Panel
                            title={'Address Information'}
                            className={'customerPanel cstPanel mt-2'}
                            cardBodyClassName="pt-0"
                        >
                            <div>
                                <div className="d-flex justify-content-between">
                                <h6 className="text-uppercase">Property Address</h6>

                                <Form.Check
                                            id="chkIsBillAddressSame"
                                            label={cstScrLables.SAME_AS_MAILING}
                                            checked={customerDetail.isBillAddressSame}
                                            onChange={(event) => populateBillingAddress(event)}
                                            disabled={!isEditable}
                                        />
                                    </div>

                                {/* Address Line 1 */}
                                <Row className="">
                                    <Column lg={5} md={5}>
                                        <Select
                                            id="country"
                                            controlId="country"
                                            label={cstScrLables.COUNTRY}
                                            options={countries}
                                            defaultSelected={countries?.filter(({ value }) => value === (customerDetail.countryId))}
                                            onChange={
                                                (values) => {

                                                    onEntityFieldChange('countryId', values[0] as string)
                                                     onCountrySelection(values[0] as string)
                                                }

                                            }
                                            disabled={!isEditable}
                                            tabIndex={13}
                                            className='mb-3'
                                        />
                                    </Column>
                                </Row>
                                <Row className="">
                                    <Column lg={8} md={8}
                                        className={formValidation['address1']?.isValid == false ? 'feedback-row' : ''}>
                                        <FloatingLabel
                                            controlId="address1"
                                            label={<>
                                                {cstScrLables.ADDRESS_1}
                                                <span className="text-danger">*</span>
                                            </>}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder=" "
                                                name="address1"
                                                value={customerDetail.address1 ?? ""}
                                                onChange={(event) => onEntityFieldChange('address1', event.currentTarget.value)}
                                                disabled={!isEditable}
                                                required
                                                tabIndex={10}
                                                maxLength={100}
                                                isInvalid={formValidation['address1']?.isValid == false}
                                            />
                                            {formValidation['address1']?.isValid == false && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formValidation['address1']?.warningText}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                    </Column>

                                    <Column lg={4} md={4}>
                                        <FloatingLabel
                                            controlId="address2"
                                            label={cstScrLables.ADDRESS_2}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder=" "
                                                name="address2"
                                                value={customerDetail.address2 ?? ""}
                                                onChange={(event) => onEntityFieldChange('address2', event.currentTarget.value)}
                                                disabled={!isEditable}
                                                tabIndex={11}
                                                maxLength={100}
                                            />
                                        </FloatingLabel>
                                    </Column>
                                </Row>

                                {/* Address Line 2 */}
                                <Row className="">
                                    <Column lg={5} md={5}
                                        className={formValidation['city']?.isValid == false ? 'feedback-row' : ''}>
                                        <FloatingLabel
                                            controlId="city"
                                            label={<>
                                                {cstScrLables.CITY}
                                                <span className="text-danger">*</span>
                                            </>}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder=" "
                                                name="city"
                                                value={customerDetail.city ?? ""}
                                                onChange={(event) => onEntityFieldChange('city', event.currentTarget.value)}
                                                disabled={!isEditable}
                                                required
                                                tabIndex={12}
                                                maxLength={100}
                                                isInvalid={formValidation['city']?.isValid == false}
                                            />
                                            {formValidation['city']?.isValid == false && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formValidation['city']?.warningText}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                    </Column>

                                    <Column lg={4} md={4}>
                                    {
                                        isStateAsDropdown ?
                                        <Select
                                            id="state"
                                            controlId="state"
                                            label={cstScrLables.STATE}
                                            options={states}
                                            defaultSelected={states.filter(option =>
                                                option.value === customerDetail.state
                                            )}
                                            onChange={(values) => onEntityFieldChange('state', values[0] as string)}
                                            disabled={!isEditable}
                                            tabIndex={13}
                                            isInvalid={formValidation['state']?.isValid == false}
                                            invalidWarning={formValidation['state']?.warningText}
                                            className='mb-3'
                                        />
                                        :
                                        <FloatingLabel
                                            controlId="txtState"
                                            label={<>
                                                {cstScrLables.STATE}
                                            </>}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder=" "
                                                name="txtState"
                                                value={customerDetail.state ?? ""}
                                                onChange={(event) => onEntityFieldChange('state', event.currentTarget.value)}
                                                disabled={!isEditable}                                               
                                                tabIndex={13}
                                                maxLength={100}
                                                isInvalid={formValidation['state']?.isValid == false}
                                            />
                                            {formValidation['state']?.isValid == false && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formValidation['state']?.warningText}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                        }
                                    </Column>

                                    <Column lg={3} md={3}
                                        className={formValidation['zipCode']?.isValid == false ? 'feedback-row' : ''}>
                                        <FloatingLabel
                                            controlId="zipCode"
                                            label={<>
                                                {cstScrLables.ZIP}
                                                <span className="text-danger">*</span>
                                            </>}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder=" "
                                                name="zipCode"
                                                value={customerDetail.zipCode ?? ""}
                                                onChange={(event) => onEntityFieldChange('zipCode', event.currentTarget.value)}
                                                disabled={!isEditable}
                                                required
                                                tabIndex={14}
                                                maxLength={9}
                                                isInvalid={formValidation['zipCode']?.isValid == false}
                                            />
                                            {formValidation['zipCode']?.isValid == false && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formValidation['zipCode']?.warningText}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                    </Column>
                                </Row>

                                <Row>
                                    <Column lg={12} md={12}>
                                        
                                    </Column>
                                </Row>

                                <div className={`${customerDetail.isBillAddressSame === true ? 'hidden' : ''}`}>
                                    <h6 className="text-uppercase">Billing Address</h6>

                                    {/* Billing Address Line 1 */}
                                    <Row>
                                        <Column lg={8} md={8}>
                                            <FloatingLabel
                                                controlId="billAddress1"
                                                label={cstScrLables.ADDRESS_1}
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder=" "
                                                    name="billAddress1"
                                                    value={customerDetail.billAddress1 ?? ""}
                                                    onChange={(event) => onEntityFieldChange('billAddress1', event.currentTarget.value)}
                                                    disabled={customerDetail.isBillAddressSame === true ? true : !isEditable}
                                                    tabIndex={15}
                                                    maxLength={100}
                                                />
                                            </FloatingLabel>
                                        </Column>

                                        <Column lg={4} md={4}>
                                            <FloatingLabel
                                                controlId="billAddress2"
                                                label={cstScrLables.ADDRESS_2}
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder=" "
                                                    name="billAddress2"
                                                    value={customerDetail.billAddress2 ?? ""}
                                                    onChange={(event) => onEntityFieldChange('billAddress2', event.currentTarget.value)}
                                                    disabled={customerDetail.isBillAddressSame === true ? true : !isEditable}
                                                    tabIndex={16}
                                                    maxLength={100}
                                                />
                                            </FloatingLabel>
                                        </Column>
                                    </Row>

                                    {/* Billing Address Line 2 */}
                                    <Row>
                                        <Column lg={5} md={5}>
                                            <FloatingLabel
                                                controlId="billCity"
                                                label={cstScrLables.CITY}
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder=" "
                                                    name="billCity"
                                                    value={customerDetail.billCity ?? ""}
                                                    onChange={(event) => onEntityFieldChange('billCity', event.currentTarget.value)}
                                                    disabled={customerDetail.isBillAddressSame === true ? true : !isEditable}
                                                    tabIndex={17}
                                                    maxLength={100}
                                                />
                                            </FloatingLabel>
                                        </Column>

                                 <Column lg={4} md={4}>
                                    {
                                        isStateAsDropdown ?
                                        <Select
                                            id="billState"
                                            controlId="billState"
                                            label={cstScrLables.STATE}
                                            options={states}
                                            defaultSelected={states.filter(option =>
                                                option.value === customerDetail.billState
                                            )}
                                            onChange={(values) => onEntityFieldChange('billState', values[0] as string)}
                                            disabled={customerDetail.isBillAddressSame === true ? true : !isEditable}
                                            tabIndex={13}
                                            isInvalid={formValidation['billState']?.isValid == false}
                                            invalidWarning={formValidation['billState']?.warningText}
                                            className='mb-3'
                                        />
                                        :
                                        <FloatingLabel
                                            controlId="txtBillState"
                                            label={<>
                                                {cstScrLables.STATE}
                                            </>}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder=" "
                                                name="txtBillState"
                                                value={customerDetail.state ?? ""}
                                                onChange={(event) => onEntityFieldChange('billState', event.currentTarget.value)}
                                                disabled={customerDetail.isBillAddressSame === true ? true : !isEditable}
                                                tabIndex={13}
                                                maxLength={100}
                                                isInvalid={formValidation['billState']?.isValid == false}
                                            />
                                            {formValidation['billState']?.isValid == false && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formValidation['billState']?.warningText}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                        }
                                    </Column>

                                        <Column lg={3} md={3}>
                                            <FloatingLabel
                                                controlId="billZip"
                                                label={cstScrLables.ZIP}
                                                className="mb-3"
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder=" "
                                                    name="billZip"
                                                    value={customerDetail.billZip ?? ""}
                                                    onChange={(event) => onEntityFieldChange('billZip', event.currentTarget.value)}
                                                    disabled={customerDetail.isBillAddressSame === true ? true : !isEditable}
                                                    tabIndex={19}
                                                    maxLength={9}
                                                />
                                            </FloatingLabel>
                                        </Column>
                                    </Row>
                                </div>
                            </div>

                        </Panel>

                        <Panel title={cstScrLables.ADDITIONAL_CUSTOMER_INFORMATION}
                            className={'customerPanel cstPanel cstCustAlign clear-card-body mt-2'}
                            panelHeaderItems={customerDependentsPanelHeaderItems}
                            cardBodyClassName='dpd-crd-body'
                            collapsible={false}>
                            {customerDependentView}
                        </Panel>
                        <Panel title={constant.CompanyContact.COMPANY_CONATCT}
                            className={'customerPanel cstPanel cstCustAlign clear-card-body mt-2'}
                            panelHeaderItems={customerCompanyContsctsPanelHeaderItems}
                            cardBodyClassName='dpd-crd-body'
                            collapsible={false}>
                            {customerContactView}
                        </Panel>
                    </Column>

                    <Column md={4}>
                        <div className="contacts-sidebar sticky-top mt-2 me-2" style={{ top: '1rem' }}>
                            <Panel
                                title={'Contact Information'}
                                cardBodyClassName="pt-0"
                                className={'customerPanel cstPanel'}>
                                <Row className="mb-2">
                                    <Column lg={6} md={6}>
                                        <Checkbox
                                            id='chkmailOptOut'
                                            label={cstScrLables.DO_NOT_MAIL}
                                            checked={customerDetail.mailOptOut}
                                            onChange={(event) => toggleEntityField('mailOptOut')}
                                            disabled={!isEditable}
                                            checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                        />
                                    </Column>
                                    <Column lg={6} md={6}>
                                        <Checkbox
                                            id='chkcallOptOut'
                                            label={cstScrLables.DO_NOT_CALL}
                                            checked={customerDetail.callOptOut}
                                            onChange={(event) => toggleEntityField('callOptOut')}
                                            disabled={!isEditable}
                                            checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                        />
                                    </Column>
                                </Row>
                                <Row className="mb-2">
                                    <Column lg={6} md={6}>
                                        <Checkbox
                                            id='chkemailOptOut'
                                            label={cstScrLables.DO_NOT_EMAIL}
                                            checked={customerDetail.emailOptOut}
                                            onChange={(event) => toggleEntityField('emailOptOut')}
                                            disabled={!isEditable}
                                            checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                        />
                                    </Column>
                                    <Column lg={6} md={6}>
                                        <Checkbox
                                            id='chkeddOptIn'
                                            label={cstScrLables.EDD_OPT_IN}
                                            checked={customerDetail.eddOptIn}
                                            onChange={(event) => toggleEntityField('eddOptIn')}
                                            disabled={!isEditable}
                                            checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                        />
                                    </Column>
                                </Row>
                                <Row className="mb-2">
                                    <Column lg={6} md={6}>
                                        <Checkbox
                                            id='chksmsOptOut'
                                            label={cstScrLables.SMS_OPT_OUT}
                                            checked={customerDetail.smsOptOut}
                                            onChange={(event) => toggleEntityField('smsOptOut')}
                                            disabled={!isEditable}
                                            checkboxStyle={{ position: "relative", marginRight: "5px" }}
                                        />
                                    </Column>
                                </Row>
                                <hr />
                                {/* Cell Phone */}
                                <Row>
                                    <Column lg={12} md={12}
                                        className={formValidation['Phone']?.isValid == false ? 'feedback-row' : ''}>

                                        <div className="input-group has-validation mb-3">
                                            <span className="input-group-text remove-right-radius">
                                                <Icon icon="mobile" className="text-muted fa-fw" />
                                            </span>
                                            <FloatingLabel
                                                controlId="mobile"
                                                label={cstScrLables.CELL_PHONE}
                                            >
                                                <Form.Control
                                                    type="tel"
                                                    placeholder=" "
                                                    name="mobile"
                                                    value={customerDetail.mobile ?? ""}
                                                    onChange={(event) => onEntityFieldChange('mobile', event.currentTarget.value)}
                                                    disabled={!isEditable}
                                                    tabIndex={20}
                                                    maxLength={15}
                                                    isInvalid={formValidation['Phone']?.isValid == false}
                                                />
                                                {formValidation['Phone']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['Phone']?.warningText}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FloatingLabel>
                                        </div>
                                    </Column>
                                </Row>

                                {/* Home Phone */}
                                <Row>
                                    <Column lg={12} md={12}
                                        className={formValidation['homePhone']?.isValid == false ? 'feedback-row' : ''}>
                                        <div className="input-group has-validation mb-3">
                                            <span className="input-group-text remove-right-radius">
                                                <Icon icon="home" className="text-muted fa-fw" />
                                            </span>
                                            <FloatingLabel
                                                controlId="homePhone"
                                                label={cstScrLables.HOME_PHONE}
                                            >
                                                <Form.Control
                                                    type="tel"
                                                    placeholder=" "
                                                    name="homePhone"
                                                    value={customerDetail.homePhone ?? ""}
                                                    onChange={(event) => onEntityFieldChange('homePhone', event.currentTarget.value)}
                                                    disabled={!isEditable}
                                                    tabIndex={21}
                                                    maxLength={15}
                                                    isInvalid={formValidation['homePhone']?.isValid == false}
                                                />
                                                {formValidation['homePhone']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['homePhone']?.warningText}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FloatingLabel>
                                        </div>
                                    </Column>
                                </Row>

                                {/* Work Phone */}
                                <Row>
                                    <Column lg={12} md={12}
                                        className={formValidation['workPhone']?.isValid == false ? 'feedback-row' : ''}>
                                        <div className="input-group has-validation mb-3">
                                            <span className="input-group-text remove-right-radius">
                                                <Icon icon="building" className="text-muted fa-fw" />
                                            </span>
                                            <FloatingLabel
                                                controlId="workPhone"
                                                label={cstScrLables.WORK_PHONE}
                                            >
                                                <Form.Control
                                                    type="tel"
                                                    placeholder=" "
                                                    name="workPhone"
                                                    value={customerDetail.workPhone ?? ""}
                                                    onChange={(event) => onEntityFieldChange('workPhone', event.currentTarget.value)}
                                                    disabled={!isEditable}
                                                    tabIndex={22}
                                                    maxLength={15}
                                                    isInvalid={formValidation['workPhone']?.isValid == false}
                                                />
                                                {formValidation['workPhone']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['workPhone']?.warningText}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FloatingLabel>
                                        </div>
                                    </Column>
                                </Row>

                                {/* Email 1 */}
                                <Row>
                                    <Column lg={12} md={12}
                                        className={formValidation['eMail']?.isValid == false ? 'feedback-row' : ''}>

                                        <div className="input-group has-validation mb-3">
                                            <span className="input-group-text remove-right-radius">
                                                <Icon icon="email" className="text-muted fa-fw" />
                                            </span>
                                            <FloatingLabel
                                                controlId="email1"
                                                label={cstScrLables.EMAIL_1}
                                            >
                                                <Form.Control
                                                    type="email"
                                                    placeholder=" "
                                                    name="eMail"
                                                    value={customerDetail.eMail ?? ""}
                                                    onChange={(event) => onEntityFieldChange('eMail', event.currentTarget.value)}
                                                    disabled={!isEditable}
                                                    tabIndex={23}
                                                    maxLength={150}
                                                    isInvalid={formValidation['eMail']?.isValid == false}
                                                />
                                                {formValidation['eMail']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['eMail']?.warningText}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FloatingLabel>
                                        </div>
                                    </Column>
                                </Row>

                                {/* Email 2 */}
                                <Row>
                                    <Column lg={12} md={12}
                                        className={formValidation['eMail2']?.isValid == false ? 'feedback-row' : ''}>
                                        <div className="input-group has-validation mb-3">
                                            <span className="input-group-text remove-right-radius">
                                                <Icon icon="emailAt" className="text-muted fa-fw" />
                                            </span>
                                            <FloatingLabel
                                                controlId="email2"
                                                label={cstScrLables.EMAIL_2}
                                            >
                                                <Form.Control
                                                    type="email"
                                                    placeholder=" "
                                                    name="eMail2"
                                                    value={customerDetail.eMail2 ?? ""}
                                                    onChange={(event) => onEntityFieldChange('eMail2', event.currentTarget.value)}
                                                    disabled={!isEditable}
                                                    tabIndex={24}
                                                    maxLength={150}
                                                    isInvalid={formValidation['eMail2']?.isValid == false}
                                                />
                                                {formValidation['eMail2']?.isValid == false && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formValidation['eMail2']?.warningText}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FloatingLabel>
                                        </div>
                                    </Column>
                                </Row>

                            </Panel>
                        </div>
                    </Column>

                    <Row className="dv-cst-actions sticky-bottom bg-white p-2 shadow-lg border-top">
                        <Column lg={3} md={3}>
                            <Button
                                variant="outline-primary"
                                onClick={() => CancelEdit()}
                                tabIndex={20}
                                id="btnCancel"
                                disabled={!isEditable}
                                style={{ minWidth: "90px" }}
                                className="me-2"
                            >
                                {label.CANCEL.toLocaleUpperCase()}
                            </Button>

                            {!isCreatingNewCustomer && (
                                <Button
                                    variant="outline-danger"
                                    onClick={() => deleteCustomer()}
                                    tabIndex={20}
                                    id="btnDelete"
                                    style={{ minWidth: "90px" }}
                                    className="ms-2"
                                >
                                    {label.DELETE.toLocaleUpperCase()}
                                </Button>
                            )}
                        </Column>

                        <Column lg={6} md={6}></Column>

                        <Column lg={3} md={3} className="text-end">
                            <Button
                                variant="outline-primary"
                                onClick={() => EditCustomer()}
                                tabIndex={21}
                                id="btnEdit"
                                disabled={isEditable}
                                className="me-2"
                            >
                                {label.EDIT.toLocaleUpperCase()}
                            </Button>

                            <Button
                                variant="success"
                                onClick={() => SaveCustomer()}
                                tabIndex={21}
                                id="btnAddCustomer"
                                disabled={!isEditable}
                                className="ms-2"
                            >
                                {label.SAVE.toLocaleUpperCase()}
                            </Button>
                        </Column>
                    </Row>

                </Row>
            </Container>

        </>
    );
};

export default CustomerDetail;