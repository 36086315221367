import React from "react";
import './CustomerPolicySummary.scss';
import { PolicyDtoRead } from "../model/PolicyDtoRead";
import { Button, Column, Icon, Panel, Row } from "../../components";
// import TextInputWithLabelFormGroup from "../../shared/components/input/TextInputWithLabelFormGroup";
import MaterialLable, { MaterialLableDisplayType } from "../../shared/components/labels/material-label";
import moment from "moment";
import { CustomerPolicyListScreenLables } from "../../shared/util/label";
import { IconType } from "../../components/components/Icon/interfaces";
import { LookupDetailConstants } from "../../shared/util/LookupDetailConstants";
import { InfoDisplay } from "./InfoDisplay/InfoDisplay";
import { PolicySummaryHeader } from "./policy-summary-header/PolicySummaryHeader";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomerPolicy } from "../../shared/util/constant";
import { PolicyIconMapping } from "../../dashboard/tasks-dashboard/TaskSummaryItem";

export interface ICustomerPolicySummary
{
    policySummary : PolicyDtoRead
};

const policyStatusMap = {
    [LookupDetailConstants.POLICY_STATUS.ACTIVE] : { backgroundColor : '#5CB85C'},
    [LookupDetailConstants.POLICY_STATUS.CANCELLED] : { backgroundColor : '#FF0000'},
    [LookupDetailConstants.POLICY_STATUS.EXPIRED] : { backgroundColor : '#FF0000'},
    [LookupDetailConstants.POLICY_STATUS.INCLUDE] : { backgroundColor : '' },
    [LookupDetailConstants.POLICY_STATUS.NON_RENEWED] : { backgroundColor : '#FF0000'},
    [LookupDetailConstants.POLICY_STATUS.NOT_TAKEN] : { backgroundColor : '#FF69B4'},
    [LookupDetailConstants.POLICY_STATUS.QUOTE] : { backgroundColor : '#800080'},
    [LookupDetailConstants.POLICY_STATUS.RENEWED] : { backgroundColor : '#5CB85C'},
    [LookupDetailConstants.POLICY_STATUS.REWRITTEN] : { backgroundColor : '#5CB85C'},
};

const CustomerPolicySummary : React.FunctionComponent<ICustomerPolicySummary> = (props) => {

    const dateFormat = "MM/DD/YYYY"

    const policyTermValue = `${moment(props.policySummary.polEffDate).format(dateFormat)} - ${moment(props.policySummary.polExpDate).format(dateFormat)}`;

    const websiteLauncherIcon = <Icon icon={'arrow-right' as IconType} className="icn-website-launcher"/>;
    const aplusRefLink = <a href={props.policySummary.policyAPlusUrl} target="_blank" >{props.policySummary.aPlusReferenceNumber} {props.policySummary.aPlusReferenceNumber? websiteLauncherIcon : ''}</a>
    const insuranceCompanyLink = <a href="#" >{props.policySummary.companyName} {props.policySummary.companyName ? websiteLauncherIcon : ''}</a>

    const fullLg = 12;
    const fullMd = 12;

    let policyBarStyle = {};
    if (props.policySummary.policyTermStatusId && policyStatusMap[props.policySummary.policyTermStatusId]) {
        policyBarStyle = policyStatusMap[props.policySummary.policyTermStatusId];
    }

    function getProductIcon(lobCode: string): import("@fortawesome/fontawesome-svg-core").IconProp {
        switch (lobCode) {
            case 'AUTO':
                return 'car';
            case 'HOME':
                return 'home';
            case 'LIFE':
                return 'heartbeat';
            case 'HEALTH':
                return 'medkit';
            default:
                return 'question-circle';
        }
    }

    function handlePolicyClick(policyNumber: string): void {
        console.log(`Policy clicked: ${policyNumber}`);
        // You can add more logic here, such as navigating to a policy details page
        // For example, if using react-router:
        // history.push(`/policy-details/${policyNumber}`);
    }

    function handlePolicyAction(action: string): void {
        console.log(`Policy action selected: ${action}`);
        // Add more logic here if needed
    }

    return (
        <div className="panel-container">
            <Panel cardBodyClassName={'item-panel'}>
                <div className="container-summary-main">
                    <div className="background-lob-icon">
                        <FontAwesomeIcon
                            icon={getProductIcon(props.policySummary.lobCode)}
                        />
                    </div>
                    <div className="fixed-width"  style={policyBarStyle}></div>
                    <div className="fill-width p-4">
                        <Row>
                            <Column lg={12}>
                                <div className="d-flex justify-content-start gap-3">
                                    <InfoDisplay
                                        caption="Policy #"
                                        value={props.policySummary.policyNumber}
                                        icon={getProductIcon(props.policySummary.lobCode)}
                                        onClick={() => handlePolicyClick(props.policySummary.policyNumber)}
                                        copyable bordered />
                                    <PolicySummaryHeader
                                        termValue={policyTermValue}
                                        polTermStatus={props.policySummary.polTermStatus}
                                        lobCodeValue={props.policySummary.lobCode}
                                        polTypeLobValue={props.policySummary.polTypeLobValue}
                                        formTypeValue={props.policySummary.formTypeValue}
                                        policyType={props.policySummary.policyType}
                                        policyStatusMap={policyStatusMap}
                                        onActionSelect={(action) => handlePolicyAction(action)}
                                        disabledActions={['cancellation', 'renew']}
                                        fullTermPremium={props.policySummary.fullTermPremium}
                                    />
                                </div>
                            </Column>
                        </Row>
                        <Row className="mt-4">
                            <Column md={12}>
                                <div className="d-flex align-items-start gap-5">
                                    <InfoDisplay
                                        caption="Address"
                                        value={[
                                            props.policySummary.address1 + '\n',
                                            props.policySummary.address2 ? props.policySummary.address2 + '\n' : '',
                                            `${props.policySummary.city}, ${props.policySummary.state} ${props.policySummary.zipCode}`
                                        ].filter(Boolean).join('')}
                                        // icon="location-dot"
                                        copyable multiline
                                    />

                                    <InfoDisplay
                                        caption="Insurance Company"
                                        value={props.policySummary.companyName || 'N/A'}
                                        onClick={() => window.open(props.policySummary.companyId, '_blank')}
                                        isExternal
                                    />

                                    <InfoDisplay
                                        caption="A+ Reference"
                                        value={props.policySummary.aPlusReferenceNumber || 'N/A'}
                                        onClick={() => window.open(props.policySummary.policyAPlusUrl, '_blank')}
                                        isExternal
                                    />

                                    <InfoDisplay
                                        caption="Producer"
                                        value={props.policySummary.producerName || 'N/A'}
                                    />

                                    <InfoDisplay
                                        caption="Source"
                                        value={props.policySummary.source || 'N/A'}
                                    />
                                </div>
                            </Column>
                        </Row>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default CustomerPolicySummary;