import { Navbar,Row } from  '../../components' 
import './CutomerMainPortalHeader.scss';
import { Button, Column, Label } from '../../components';
import { CustomerScreenTabs } from '../../shared/util/constant';
import { NavLink, useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import CutomerMainPortalHeaderService from './CutomerMainPortalHeaderService';
import TextInputWithLabelInline from '../../shared/components/input/TextInputWithLabelInline';
import { CustomerFilterViewMoel } from '../model/CustomerFilterViewModel';
import useSlider from '../../shared/components/Slider/useSlider';
import * as constant from '../../shared/util/constant';
import AppContext from "../../shared/components/AppContext";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import CustomerSliderContainerService from '../customer-slider-content/CustomerSliderContainerService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/store';
import { cleareTabsInputs, SliderDataAction, updateSliderData } from '../customer-slider-content/CustomerSliderContext';
import CustomerNavbar from '../customernavbar';
import { Container, Form } from 'react-bootstrap';
import InsuranceProductList from '../../shared/components/insuranceProductList/InsuranceProductList';


export interface ICutomerMainPortalHeader {
    customerId? : string,
    viewName? : string,
    isCreatingNewCustomer?: boolean,
    navigateTo: (path: string) => void
}

const CutomerMainPortalHeader : React.FunctionComponent<ICutomerMainPortalHeader> = (props) => {
    const customerSliderContainerService = new CustomerSliderContainerService();
    const customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);
    const originalCustomerObj = useSelector((state: RootState) => state?.customerSlice).customer;
    const dispatch = useDispatch();

    const customerId = props.customerId;
    const viewName = props.viewName;
    const isCreatingNewCustomer = props.isCreatingNewCustomer;
    const history = useHistory()
    const modalContext = useConfirmationModalContext();
    const myContext = useContext(AppContext);

    const slider =  useSlider();

    let [customer, setCustomer] = useState<CustomerFilterViewMoel>({});

    const cutomerMainPortalHeaderService = new CutomerMainPortalHeaderService();
    const sliderContext = useSlider();

    const activityData = customerSliderState.activityPage.activity;

    useEffect(() => {
        initComponent();
    }, [isCreatingNewCustomer]);

    const initComponent = async () => {
        try {
            if (!isCreatingNewCustomer) {
                let cst = await cutomerMainPortalHeaderService.getCustomerSummary(customerId);
                setCustomer(cst ? cst : {});
            }
        }
        catch (ex) {
            if (ex.status == 404) {
                toast.error(constant.CUSTOMER_NOT_FOUND, {
                    position: toast.POSITION.TOP_RIGHT
                });
                props.navigateTo('/customer/customerfilter');
            }
        }
    }

    const redirectTo = async(path) =>{
        event.preventDefault();

        const changes = customerSliderContainerService.getUnSavedChanges(customerSliderState, originalCustomerObj);
        if (Object.keys(changes).length > 0) {
            const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
            if(!result){
                return;
            }

            dispatch(cleareTabsInputs());
            sliderContext.hideSlider();
        }


        if(!myContext.formSavedStatus){
        const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
        if(result){
            history.push({
                pathname: path,
            });
            }
        }
        else{
            history.push({
                pathname: path,
            });
        }
    }
    const customerAddress = `${customer?.address1 ? customer?.address1 + ', ':''}${customer?.address2 ? customer?.address2 + ', ' : ''}${customer?.city ? customer?.city + ', ' : ''}${customer?.state ? customer?.state + ' ' : ''}${customer?.zipCode ? customer?.zipCode : ''}`
    let secondColumnLg = 3;
    let secondColumnMd = 3;

    let lastColumnLg = 4;
    let lastColumnMd = 4;
     let propMarginLeft =false;
    if (slider.isSliderVisible && slider.isSliderPinned) {
        secondColumnLg = 4;
        secondColumnMd = 4;

        lastColumnLg = 3;
        lastColumnMd = 3;

        propMarginLeft =true
    }

    let customerSummary = <></>;
    if (!props.isCreatingNewCustomer) {
        customerSummary = <>
            <div>
                <Row className=''>
                    <Column md={12} className='p-0'>
                    <div className='d-flex justify-content-between align-items-center ps-4 pb-0 '>
                        <div className='cmph-number-name'>
                            <span className='cmph-highlighted-lbl'>#{customer?.custNumber}</span>
                            &nbsp;
                            <span className='cmph-highlighted-lbl'>{customer?.displayName}</span>
                        </div>

                        <span >{customer.agencyDisplay}</span>

                        <div className='customer-attribute-tags'>
                            <InsuranceProductList />
                        </div>
                    </div>
                    </Column>
                    
                </Row>
                {/* <Row>
                    <Column md={12} className='p-0'>
                        <div className='d-flex ps-4 mb-2'>
                            <span className='me-3' >Address</span>
                            <span >
                                {AddressLine1}
                                {(customer?.address1 || customer?.address2) && <br />}
                                {AddressLine2}
                            </span>

                        </div>
                    </Column>

                </Row> */}
            </div>

        </>;
    }

    const onSliderMenuClick = () => {
        if (!sliderContext.isSliderVisible) {
            sliderContext.showSlider();
        }

        resetSlider();
    }

    const resetSlider = () => {
        dispatch(updateSliderData({ type : SliderDataAction.ActivityUpdate, data : {...activityData, activityEffDate : new Date()}}));
    }

    return (
        <div className='bg-light container-fluid cmph-main-container'>
            <Row>
                <Column md={12} className='p-0'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <CustomerNavbar />

                        <Form.Control
                            id="txtFilterCustomer"
                            className='mb-0'
                            style={{ maxWidth: '300px' }}
                            type="text"
                            placeholder="Search Phone, Name or Email"
                            tabIndex={1}
                        />

                        <Button
                            className='btn-md btn-primary'
                            key="menu"
                            icon="menu"
                            id="btnSliderMenu"
                            disabled={props.isCreatingNewCustomer}
                            onClick={onSliderMenuClick}
                        >
                        </Button>
                    </div>

                </Column>

            </Row>
            {customerSummary}
        </div>
    );
}

export default CutomerMainPortalHeader;
