import { Link } from 'react-router-dom'
import {Button,Row,Column,Spinner,Label} from '../../../src/components'
import Grid from '../../../src/components/components/Grid/Grid'
import {campaignQueryUtils} from '../utils/campaignQueryUtils'
import React, {useState,useEffect, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as label from '../../shared/util/label'
import { ErrorBoundary } from 'react-error-boundary'
import * as constant from '../../shared/util/constant'
import ScrollTop from '../../shared/components/scrollTopButton'
import { FallbackComponent } from '../../shared/components/FallbackComponent';
import { AbilityContext } from '../../shared/abilities/AbilityContext'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import { Container } from 'react-bootstrap'

const Queries = () => {
   
    const dispatch = useDispatch()
    const history = useHistory()
    const [highlightedMenu,setHighlightedMenu] = useState('Queries');
    
    //const { data, isLoading } = campaignQueryUtils.GetAllQueryList()
    const[queryList, setQueryList]=useState([])
    const [showFilters,setShowFilters] = useState(true);
    const[ErrorText,setErrorText]=useState('')
    const [includeInactiveRecords, setIncludeInactiveStatus] = useState(false);
    const[isScrollTop, setScrollTop]=useState(false)
    const ab_subject = "Campaign Query";
    const ability = useContext(AbilityContext);
    const canClickNewQuery=ability.can('create',ab_subject,constant.ScreenName.Campaign_Query)
    
  
  
    async function fetchData() {
      //setLoading(true)
        await campaignQueryUtils.GetAllQueryList()
        .then(response =>setQueryList(response))
        .catch(error => console.error(error))
        //.finally(()=> setLoading(false));
    }
  
    useEffect(() => {
      //alert('called')
      fetchData();
    },[]);

    const routeChange = () =>{ 
      //highlightMenu('Queries')
      let path = '/Campaign/QueryDetail'; 
      history.push(path);
    }
   //#region Scroll to Top function 
    const getScrollPostionStatus=()=>{
      var scrollPostion=window.pageYOffset;
      //console.log(scrollPostion)
      if(scrollPostion>= constant.SHOW_SCROLL_BUTTON_POSITION){
        //setScrollTop(true)
      }
      else{
        //setScrollTop(false)
      }
    }
    useEffect(() => {
      window.addEventListener('scroll', getScrollPostionStatus, { passive: true });
  
      return () => {
          window.removeEventListener('scroll', getScrollPostionStatus)
      };
  }, []);
  //#endregion Scroll to Top function

    //#region Call when there is error occur on the page
//  const FallbackComponent = ({ error, resetErrorBoundary }) => {
//   console.log("An error occurred:");
//   console.log(error.message);
//   return (
//       <div>
//           <h1>An error occurred: {error.message}</h1>
//           <button onClick={resetErrorBoundary}>Try again</button>
//       </div>
//   );
// };
//#endregion

const getQueriesCaption = () => {
  return (
    <Navbar className="bg-transparent">
      <Navbar.Brand>{label.CAMPAIGN_QUERIES.toUpperCase()}</Navbar.Brand>
      <Nav className="me-auto">
        <Form>
          <Form.Check
            type="checkbox"
            id="include-inactive-checkbox"
            label={label.INCLUDE_INACTIVE_QUERIES}
            onChange={(event) => checkInactiveStatus(event)}
            className='mx-3'
          />
        </Form>
      </Nav>
      
      <Button 
        disabled={!canClickNewQuery} 
        onClick={routeChange}
      >
        {label.NEW_QUERY}
      </Button>
    </Navbar>
  );
};

const checkInactiveStatus = (event) => {
  if (event.target.checked) {
    setIncludeInactiveStatus(true)

  }
  else {
    setIncludeInactiveStatus(false)
  }
}

useEffect(() => {

})
  return (
    <>
      <Column lg={12} md={12}>
        <span>{ErrorText}</span>
      </Column>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <div className='outerPanel card shadow-sm pb-3'>
          <Container fluid>
            <Row>
              <Column lg={12} >
                {getQueriesCaption()}
              </Column>
            </Row>
            <Row>

              <Grid
              grdTableContainerClass='p-0'
                data={queryList !== undefined && queryList.length > 0 && includeInactiveRecords ? queryList :
                  queryList !== undefined && !includeInactiveRecords ? queryList.filter(item => item.statusName === null ? '' : item.statusName.toUpperCase() !== constant.INACTIVE.toUpperCase()) : []}
                showFilters={showFilters}
                grdTabelClass='table-striped table-hover table-sm'
                id={'tblQueryList'}
                columns={[

                  {
                    Header: label.QUERY_ID,
                    accessor: 'queryId',
                    Cell: row => <div><span title={row.value}>{queryList !== undefined ? row.value : ''}</span></div>

                  },
                  {

                    Header: label.NAME,
                    accessor: 'queryName',
                    sortType: 'string',
                    Cell: ({ row }) =>
                      <div><Link
                        to={{
                          pathname: '/Campaign/QueryDetail',
                          state: { entityId: row.original.id, type: "1" },// Pass the row data as state
                          search: "?id=" + row.original.id
                        }}
                        href='#'
                        onClick={(event) => { routeChange() }}
                        className='gridNavigateButton btn btn-link text-start text-decoration-none d-block p-2'
                        title={row.original.queryName}>{queryList !== undefined ? row.original.queryName : ''}</Link></div>,
                    width: 350,
                    minWidth: 150
                  },
                  {
                    Header: label.STATUS,
                    accessor: 'statusName',
                    // minWidth: 270,
                    Cell: row => <div><span title={row.value}>{queryList !== undefined ? row.value : ''}</span></div>,
                    width: 250,
                    minWidth: 150,
                    sortType: 'string'
                  },
                  {
                    Header: label.UPDATED_ON,
                    accessor: 'changedDate',
                    Cell: row => <div><span title={row.value}>{queryList !== undefined ? row.value : ''}</span></div>,
                    sortType: (a, b) => {
                      return new Date(b.values.changedDate) - new Date(a.values.changedDate);
                    },
                    width: 250,
                    minWidth: 150
                  },
                  {
                    Header: label.UPDATED_BY,
                    accessor: 'changedBy',
                    Cell: row => <div><span title={row.value}>{queryList !== undefined ? row.value : ''}</span></div>,
                    width: 250,
                    minWidth: 150,
                    sortType: 'string'
                  },
                  {
                    Header: label.DESCRIPTION,
                    accessor: 'description',
                    Cell: row => <div><span title={row.value}>{queryList !== undefined ? row.value : ''}</span></div>,
                    width: 450,
                    minWidth: 150,
                    sortType: 'string'
                  }
                ]}
                hiddenColumns={['queryId', 'selection']}
                // onSelectedRowsChange={handleSelectedRowsChange}
                sortDefault={[{ id: 'queryName', desc: false }]}
              />

              <ScrollTop isDisplay={isScrollTop} />
            </Row>
          </Container>

        </div>
      </ErrorBoundary>
    </>
  )
}

 export default Queries