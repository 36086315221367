import { Row, Column, TextField, Icon } from '../../../../components';
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import NoteDetail from '../model/NotesDetail';
import { notesUtils } from '../hooks/ManageNotesOperations'
import { toast } from 'react-toastify';
import * as constant from '../../../util/constant'
// import moment from 'moment';
import moment from 'moment-timezone';
// import { parse } from 'json2csv';
// import { constants } from 'buffer';
import { useContext } from 'react';
import AppContext from '../../../components/AppContext'
import { ConfirmModal } from '../../confirmModal';
import * as label from '../../../util/label';
import { AbilityContext } from '../../../abilities/AbilityContext';
import { Button, ListGroup } from 'react-bootstrap';

//import { NoteContext } from '../../../../Campaign/filter/QueryDetail';
interface Props {
    addNewStatus: boolean,
    setNoteCardForm: (boolean) => void,
    isNoteEmpty: boolean,
    setAddNewButtonActive: (boolean) => void,
    subject?: any,
    // triggerSaveFromParent: boolean,
    // isSaveAsEvent: boolean
}
const NoteCard = (props: Props) => {
    const { addNewStatus, setNoteCardForm, isNoteEmpty, setAddNewButtonActive, subject } = props
    const notedetails: NoteDetail = {
        noteId: '',
        entityId: '',
        note: '',
        createdOn: new Date(),
        createdBy: 'Test User',
        changedDate: new Date(),
        changedBy: 'Test User',
        entityType: 0
    }
    const noteDetailList = [notedetails];
    const location = useLocation()
    const state = location.state as { entityId: string, type: string };
    const stateCampaign = useLocation<{ dataToPassToDetails: { campaignId: string, id: string, type: string } }>();
    const urlParam = new URLSearchParams(location.search)
    const queryId = state?.entityId ?? stateCampaign.state?.dataToPassToDetails.id?? urlParam.get('id');
    const type = state?.type ?? stateCampaign.state?.dataToPassToDetails.type;
    const [queryNotes, getQueryNotes] = useState([]);
    const [addBtnStatus, setaddBtnStatus] = useState(addNewStatus);
    const [isNoteEditable, setNoteEditableStatus] = useState(false);
    const [noteId, setNoteId] = useState('');
    const [noteDesc, setNoteDescription] = useState('');
    const [noteInput, setNoteInput] = useState<NoteDetail>(null);
    const [isSearchVisible, setSearchVisible] = useState(queryId === ('1' || '2' || '3') ? false : true)
    const [isNoteDetailEmpty, setNoteDetailStatus] = useState(false)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isNoteDescChange, setNoteDecriptionChange] = useState(false)
    const [isCardVisible, setCardVisibility] = useState(true)
    const [cusrsorStatusEdit, setcusrsorStatusEdit] = useState(true)
    const [cusrsorStatusDelete, setcusrsorStatusDelete] = useState(true)
    const [canClickEdit, setClickEdit] = useState(true)
    const myContext = useContext(AppContext);
    const ability = useContext(AbilityContext);
    const ab_subject= subject;
    const isUserPermitToViewNote = ability.can('view',ab_subject,constant.ScreenName.NOTE)
    const isUserPermitToReadNote = ability.can('read',ab_subject,constant.ScreenName.NOTE)
    const isUserPermitToAddNote = ability.can('create',ab_subject,constant.ScreenName.NOTE)
  const isUserPermitToEditNote = ability.can('update',ab_subject,constant.ScreenName.NOTE)
  const isUserPermitToDeleteNote = ability.can('delete',ab_subject,constant.ScreenName.NOTE)
  

    //Set context status for note is saved or not.
    //If note is not in edit mode or in edit mode, but text not changed then true else false
    useEffect(() => {
        myContext.noteSavedStatus = !isNoteDescChange
        //myContext.showExitConfirmation = isNoteDescChange
    }, [isNoteDescChange])

    useEffect(() =>
        {
            if(!isUserPermitToEditNote){
                setcusrsorStatusEdit(false)
            }
            if(!isUserPermitToDeleteNote){
                setcusrsorStatusDelete(false)
            }
            if(!isUserPermitToAddNote){
                setAddNewButtonActive(false)
            }
           
        },[])
    //Reload notes if user click on save as from detail pane
    //Save note if user click on save to update the existing detail pane entity.
    // Both flags triggerSaveFromParent,isSaveAsEvent are coming from parent component
    useEffect(() => {
        //console.log(myContext)
        if (myContext!==undefined && myContext.isDetailWithNoteSave && isNoteDescChange) {
            saveNote()
        }
        if (myContext!==undefined && myContext.isSaveAsCliked) {
            //console.log('I am called')
            myContext.noteSavedStatus = true
            cancelAdd();
            GetNoteById()
        }
        // setTriggerStatus(false)
        // setSaveAsTriggerStatus(false)
       
    }, [myContext.isDetailWithNoteSave,myContext.isSaveAsCliked])

    //Get notes connected to entity by ID
    //Called on page load, on delete of note, on adding new note or update exiting note
    // Also called when user click on save a on detail pane to reload the note section.
    function GetNoteById() {
        //console.log(queryId)
        if (queryId !== undefined && queryId !== '' && queryId !== null) {
            notesUtils.GetNoteByQueryId(queryId).then((res) => {

                if (res.data !== undefined && res.data.length > 0) {
                    getQueryNotes((queryNotes) => [...res.data])
                }
                else if (res.data.length === 0) {
                    getQueryNotes([]);
                }

                if (res.status >= 200 && res.status < 300) {
                }
                else {
                    toast.error(constant.ERROR_MESSAGE_LOAD, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                }
                setShowDeleteConfirmation(false);
            }).catch(exception => {
                toast.error(constant.DEFAULT_ERROR, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: constant.AUTO_CLOSE
                });
            })
        }
        else {
            setaddBtnStatus(false)
            setAddNewButtonActive(false)
        }
    }

    //Create a new note and reload the new notes and called from Save note function
    const CreateNewNote = (note: NoteDetail) => {
        if (note.note !== '') {
            setNoteDetailStatus(false)
            notesUtils.SaveNoteDetails(note).then((res) => {
                GetNoteById();
                setNoteDecriptionChange(false)
                if (res.status >= 200 && res.status < 300) {
                    toast.success(constant.SAVE_NOTE_SUSSESS, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                }
                else {
                    toast.error(constant.ERROR_MESSAGE_LOAD, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                }
            }).catch(exception => {
                toast.error(constant.ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: constant.AUTO_CLOSE
                });
            })
        }
        else {
            setNoteDetailStatus(true)
        }

    }

    //update existing note and reload the notes and called from Save note function
    const UpdateNewNote = (note) => {
        notesUtils.UpdateNotesDetail(note).then((res) => {
            if (res.status >= 200 && res.status < 300) {
                GetNoteById();
                setNoteDecriptionChange(false)
                setNoteDetailStatus(false)
                toast.success(constant.UPDATE_NOTE_SUSSESS, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: constant.AUTO_CLOSE
                });

            }
            else {
                toast.error(constant.ERROR_MESSAGE_LOAD, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: constant.AUTO_CLOSE
                });
            }
        }).catch(exception => {
            toast.error(constant.ERROR_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });
        })

    }

    // Delete note and reload notes 
    const DeleteNewNote = (id) => {
        notesUtils.DeleteNoteById(id).then((res) => {
            if (res.status >= 200 && res.status < 300) {
                GetNoteById();
                toast.success(constant.DELETE_NOTE_SUSSESS, {
                    autoClose: constant.AUTO_CLOSE
                });

            }
            else {
                toast.error(constant.ERROR_MESSAGE_LOAD, {
                    autoClose: constant.AUTO_CLOSE
                });
            }
            setShowDeleteConfirmation(false);
        }).catch(exception => {
            toast.error(constant.ERROR_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });
        })

    }

    // This function is called when user click on Save button on note section or
    // when user click on detail pane Save button
    function saveNote() {
        //console.log(noteDesc)
        if (noteDesc.trim() !== "") {
            setNoteDetailStatus(false)
            if (noteId !== '' && noteId !== undefined) {
                notedetails.noteId = noteId;
                notedetails.note = noteDesc;
                notedetails.entityId = queryId;
                notedetails.changedDate = new Date();
                notedetails.changedBy = '';
                notedetails.entityType = parseInt(type);
                UpdateNewNote(notedetails);

            }
            else {
                notedetails.noteId = '';
                notedetails.note = noteDesc;
                notedetails.entityId = queryId;
                notedetails.changedDate = new Date();
                notedetails.changedBy = '';
                notedetails.createdBy = ''
                notedetails.createdOn = new Date();
                notedetails.entityType = parseInt(type);
                CreateNewNote(notedetails);
            }
            cancelAdd();
        }
        else {
            //console.log('this is called')
            setNoteDetailStatus(true)
        }
    }

    // Setting the status of add new button
    useEffect(() => {
        setaddBtnStatus(addNewStatus)
        GetNoteById();
        if (isNoteEmpty === true) {
            setNoteDescription('')
        }
    }, [])

    useEffect(()=>{
        if(addNewStatus){
            setClickEdit(false)
        }
        else{
            setClickEdit(true)
        }
    },[addNewStatus])
    //Called when user click on Cancel button in notes panel
    const cancelAdd = () => {
        myContext.noteSavedStatus = true
        setNoteDetailStatus(false)
        setNoteCardForm(true);
        setNoteEditableStatus(false)
        if (noteId !== undefined && noteId !== '') {
            let noteCard = document.getElementsByClassName(noteId) as unknown as HTMLInputElement | null;
            noteCard[0]?.classList.remove('hidden');
        }
        setClickEdit(true)
        var addBtn=document.getElementById('AddNew') as HTMLInputElement
        addBtn?.classList.remove('disabled')
        //setcusrsorStatus('pointer')
        setNoteId('');
        setNoteDescription('')

    }

    //Set the selected note in edit mode
    //Also hide the note from note panel once it move to edit node
    // Before removing the hide functionality,rethink of scenario,if user click on delete for the same note which is in edit mode
    const noteEdit = (id: string, description: string) => {
        setNoteEditableStatus(true)
        setNoteId(id);
        setNoteDescription(description)
        setClickEdit(false)
        //setcusrsorStatus('none')
        var noteCard = document.getElementsByClassName(id) as unknown as HTMLInputElement | null;
        noteCard[0].classList.add('hidden');
        var addBtn=document.getElementById('AddNew') as HTMLInputElement
        addBtn?.classList.add('disabled')
        setTimeout(() => {
            document.getElementById('txtNotes').focus()
        }, (200));
    }

    //Called when user click on bin icon on notes panel
    const deleteNote = (id: string) => {
        DeleteNewNote(id);
    }

    //Called when user click on edit icon on notes panel
    const onNoteChange = (index) => {

        const updatedNoteValue = document.getElementById('txtNotes') as HTMLInputElement | null;
        setNoteId(index);
        setNoteDescription(updatedNoteValue.value)
        setNoteDecriptionChange(true)
    }


    return (
        <>

            {(addNewStatus || isNoteEditable) &&
            <div>
                <TextField
                    id={'txtNotes'}
                    disabled={false}
                    size='large'
                    className='mt-2'
                    value={noteDesc}
                    onChange={() => onNoteChange(noteId)}
                    isEmpty={isNoteDetailEmpty}
                    isInvalid={isNoteDetailEmpty}
                    isRequired
                />

                   
                <div className='d-flex justify-content-between py-3'>
                    <Button
                        key="cancelAdd"
                        //icon="filter"
                        onClick={cancelAdd}
                        tabIndex={19}
                        id="btnNotesCancel"
                        variant="outline-secondary"
                    >CANCEL
                    </Button>
                    <Button
                        className='btn btn-primary'
                        key="saveNote"
                        //icon="filter"
                        onClick={saveNote}
                        tabIndex={20}
                        id="btnNotesAdd"
                        color='primary'
                    >SAVE
                    </Button>
                </div>
            </div>
            }

            {
                (!addNewStatus && !isNoteEditable && !isSearchVisible) &&
                <Row>

                    <Column lg={7} md={7} >
                        <input type="search" className='texbox-input search-input' placeholder="Search..." />
                        <span><Icon icon={"search"} className="fa fa-search search-icon"></Icon></span>
                    </Column>
                    <Column lg={5} md={5}>
                        <Button
                            className='btn btn-outline-tertiary'
                            key="filter"
                            icon="filter"
                            //onClick={alert('clicked')}
                            tabIndex={21}
                            id="btnNotesSearch"
                            color='tertiary'
                            outlined={true}
                        >FILTER
                        </Button>
                    </Column>

                </Row>
            }
            <ListGroup className='mt-2'>

                {
                    queryNotes.map((data, index) => {
                        return (
                            <ListGroup.Item
                                as="li" key={data.noteId}
                                className={`${data.noteId} `}
                            >
                                <div className="d-flex w-100 justify-content-between">
                                    <small className="font-bold mb-1">{data.changedBy}</small>
                                    <small title={moment.tz(data.changedDate.toString(), 'America/New_York')
                                        .local()
                                        .format("MMMM Do YYYY, h:mm:ss a z")}>
                                        {moment.tz(data.changedDate.toString(), 'America/New_York')
                                            .local()
                                            .fromNow()}
                                    </small>
                                </div>
                                <p className="mb-1">{data.note}</p>
                                <div className='d-flex justify-content-start mt-2'>
                                    <Button
                                        variant="outline-dark"
                                        size="sm"
                                        disabled={!cusrsorStatusEdit}
                                        className={`me-2`}
                                        style={{ borderColor: '#dee2e6' }}
                                        onClick={() => {
                                            if (canClickEdit) {
                                                noteEdit(data.noteId, data.note)
                                            }
                                            else {
                                                void (0);
                                            }
                                        }}
                                    >
                                        <Icon
                                            icon="edit"
                                            className="icon-color"
                                        />
                                    </Button>
                                    <Button
                                        variant="outline-dark"
                                        size="sm"
                                        disabled={!cusrsorStatusDelete}
                                        className={``}
                                        style={{ borderColor: '#dee2e6' }}
                                        onClick={() => {
                                            if (canClickEdit) {
                                                setNoteId(data.noteId)
                                                setShowDeleteConfirmation(true)
                                            }
                                            else {
                                                void (0);
                                            }
                                        }}
                                    >
                                        <Icon
                                            icon="trash"
                                            className="icon-color"
                                        />
                                    </Button>
                                </div>
                                {/* <small>{moment(data.changedDate.toString()).format("MMMM Do YYYY, h:mm:ss a")}</small> */}


                            </ListGroup.Item>
                        )
                    })
                }
            </ListGroup>

            <ConfirmModal
                modaltitle={label.DELETE_NOTE}
                showConfirmation={showDeleteConfirmation}
                setConfirmation={(showDeleteConfirmation) => setShowDeleteConfirmation(showDeleteConfirmation)}
                body={constant.CONFIRM_NOTE_DELETION}
                closeButtonClick={() => setShowDeleteConfirmation(false)}
                successButtonClick={() => deleteNote(noteId)}
                closeButtonLabel={label.NO.toUpperCase()}
                successButtonLabel={label.YES.toUpperCase()} />
        </>
    )
}
export default NoteCard