import { CustomerActivityModel, CustomerActivityModelExtended } from "../../customer/model/CustomerActivityModel";
import { CommonUtility } from "../../Services/CommonUtility";

class ActivityDashboardService {

    public enrichActivityItem = (item : CustomerActivityModel) : CustomerActivityModelExtended => {
        let custAct : CustomerActivityModelExtended  = {...item}
        
        custAct.activityEffDateFormatted = CommonUtility.formateDateTime(custAct.activityEffDate);

        custAct.descriptionFormatted = CommonUtility.truncateString(custAct.description, 50)

        return custAct;
    }
}

export default ActivityDashboardService;