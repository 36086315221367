import React, { useEffect, useRef, useState } from 'react';
import './DocuMateTodoList.scss';
import { Column, Label, Row, Select } from '../../components';
import DropDownWithLabelInline from '../../shared/components/input/DropDownWithLabelInline';
import './../../index.css';
import Grid from '../../components/components/Grid/Grid';
import * as docuMateConstant from '../shared/util/docuMateConstant';
import * as documateLabel from '../shared/util/docuMateLabel';
import { SelectOption } from '../../shared/components/input';
import DocuMateTodoListService from './DocuMateTodoListService';
import { DocuMateTodoListDto } from '../model/DocuMateTodoListDto';
import { useHistory } from 'react-router-dom';
import { FallbackComponent } from '../../shared/components/FallbackComponent';
import { ErrorBoundary} from 'react-error-boundary';
import { ConfirmModal } from '../../shared/components/confirmModal';
import { FloatLabel } from 'primereact/floatlabel';
import { Alert, Container, Form, Card, Button } from 'react-bootstrap';

export const sampleUserOptions = [
  {
    label: 'All',
    value: 'allUsers',
    status: 'active',
    isInactive: false
  },
  {
    label: 'John Doe',
    value: 'e8b5bbb6-65f1-4d3e-9c0b-c1f0f7b7a2b8',
    status: 'active',
    isInactive: false
  },
  {
    label: 'Jane Smith',
    value: 'a4f8c4a2-6c01-47f3-bd4f-5e1a2d56c4b1',
    status: 'inactive',
    isInactive: true
  },
  {
    label: 'Robert Johnson',
    value: 'b7d3f2c7-49b6-41c2-af46-ec8f0f33a2d2',
    status: 'active',
    isInactive: false
  },
  {
    label: 'Emily Davis',
    value: 'f9d6b2d1-4b4c-4ec4-88a9-6f013f622f61',
    status: 'inactive',
    isInactive: true
  },
  {
    label: 'Michael Brown',
    value: 'c5a3f9e9-5b4e-4d7c-8e6f-1a5e6d2f2f2a',
    status: 'active',
    isInactive: false
  },
  {
    label: 'Emma Miller',
    value: 'd9c7f5e8-6b3e-4c6d-8d6f-2e5d6e2f2f2b',
    status: 'active',
    isInactive: false
  },
  {
    label: 'James Wilson',
    value: 'e1b6f4d7-7b2f-4b5d-9d6e-3f5d6f2f2f2c',
    status: 'inactive',
    isInactive: true
  },
  {
    label: 'Mary Moore',
    value: 'a1b2c3d4-e5f6-4a7b-8c9d-0a1b2c3d4e5f',
    status: 'active',
    isInactive: false
  },
  {
    label: 'David Taylor',
    value: 'b2c3d4e5-f6a7-4b8c-9d0a-1b2c3d4e5f6a',
    status: 'active',
    isInactive: false
  },
  {
    label: 'Sophia Jackson',
    value: 'c3d4e5f6-a7b8-4c9d-0a1b-2c3d4e5f6a7b',
    status: 'inactive',
    isInactive: true
  },
  {
    label: 'Benjamin White',
    value: 'd4e5f6a7-b8c9-4d0a-1b2c-3d4e5f6a7b8c',
    status: 'active',
    isInactive: false
  },
  {
    label: 'Lucas Harris',
    value: 'e5f6a7b8-c9d0-4a1b-2c3d-4e5f6a7b8c9d',
    status: 'inactive',
    isInactive: true
  },
  {
    label: 'Mia Martin',
    value: 'f6a7b8c9-d0a1-4b2c-3d4e-5f6a7b8c9d0a',
    status: 'active',
    isInactive: false
  },
  {
    label: 'William Thompson',
    value: 'a7b8c9d0-a1b2-4c3d-4e5f-6a7b8c9d0a1b',
    status: 'active',
    isInactive: false
  },
  {
    label: 'Olivia Garcia',
    value: 'b8c9d0a1-b2c3-4d4e-5f6a-7b8c9d0a1b2c',
    status: 'inactive',
    isInactive: true
  }
];

  const sampleGroups = [
    { 
      label: 'Central Processing', 
      value: 'b8c9d0a1-b2c3-4d4e-5f6a-7b8c9d0a1b2c', 
      status: 'active',
      isInactive: false,
      count: 46
    },
    { 
      label: 'Customer Service', 
      value: 'b8c9d0a1-b2c3-4d4e-5f6a-7b8c9d0a1b2d', 
      status: 'active',
      isInactive: false,
      count: 24
    },
    { 
      label: 'Sales Group', 
      value: 'b8c9d0a1-b2c3-4d4e-5f6a-7b8c9d0a1b2e', 
      status: 'active',
      isInactive: false,
      count: 14
    }
  ]

  const sampleFolders = [
    {
      label: 'CP-Alarm Certificate',
      value: 'e13d35a8-b0f4-4a12-8e5a-e037c6eaa4c5',
      status: 'active',
      isInactive: false,
      count: 23
    },
    {
      label: 'CP-Cancellation Request',
      value: 'f1f45e9b-4d1e-4a43-a95f-bc93e7bfa15c',
      status: 'active',
      isInactive: false,
      count: 12
    },
    {
      label: 'CP-Check',
      value: 'd4e134c3-2b75-4a92-8a34-e205e17cfae0',
      status: 'active',
      isInactive: false,
      count: 8
    },
    {
      label: 'CP-Claim Notice',
      value: 'b0aee9b6-9319-47ae-ae2b-bddf77fbe7de',
      status: 'active',
      isInactive: false,
      count: 15
    },
    {
      label: 'CP-Claim Survey',
      value: 'e8a541f4-18c7-4f84-90b4-0c5e3bcfefb1',
      status: 'active',
      isInactive: false,
      count: 20
    },
    {
      label: 'CP-Customer Contact Info',
      value: 'a07c65af-0097-4d5a-bd2d-8f5cb4f5e4e6',
      status: 'active',
      isInactive: false,
      count: 5
    },
    {
      label: 'CP-Lender Change Request',
      value: 'c8e09c3e-6f56-4f7c-8aef-91d8a1e7a35e',
      status: 'active',
      isInactive: false,
      count: 17
    },
    {
      label: 'CP-Miscellaneous',
      value: 'b88d89c2-4f28-42b5-bdf7-7fcacb5b120b',
      status: 'active',
      isInactive: false,
      count: 9
    },
    {
      label: 'CP-Money Endorsement',
      value: 'c6c7f8c4-28f2-44e1-b3e1-9af5b642af1d',
      status: 'active',
      isInactive: false,
      count: 11
    },
    {
      label: 'CP-New Business',
      value: 'f89cc493-b45e-4c88-8a7d-83f4c6c29f82',
      status: 'active',
      isInactive: false,
      count: 14
    },
    {
      label: 'CP-Non-Money Endorsement',
      value: 'e31f3c3b-4f26-47e3-91da-3c6b2e5b99ec',
      status: 'active',
      isInactive: false,
      count: 6
    },
    {
      label: 'CP-Non-Pay NOC',
      value: 'd71a1c6a-b5d4-4d36-89a5-d7aeb635b6b2',
      status: 'active',
      isInactive: false,
      count: 4
    },
    {
      label: 'CP-Non-Renewal Notice',
      value: 'f8d45c4b-d1bc-4f18-b55c-3202eb0b5a0f',
      status: 'active',
      isInactive: false,
      count: 18
    },
    {
      label: 'CP-Parked',
      value: 'a5f8b82f-945e-41d5-8b62-5f6cd8c20ed4',
      status: 'active',
      isInactive: false,
      count: 10
    },
    {
      label: 'CP-Refi',
      value: 'b2c14fa0-1283-4b2c-8f63-d0d30edaf23f',
      status: 'active',
      isInactive: false,
      count: 3
    },
    {
      label: 'CP-Reinstatement',
      value: 'c3e70e73-5d4d-4e39-9723-4d7930a3679b',
      status: 'active',
      isInactive: false,
      count: 16
    },
    {
      label: 'CP-Renewal Offer',
      value: 'bc8771be-3b09-4c46-9729-f9e1c31b4a8c',
      status: 'active',
      isInactive: false,
      count: 2
    },
    {
      label: 'CP-Renewal',
      value: 'abf21da3-e3dc-4f94-8731-68c112c0c451',
      status: 'active',
      isInactive: false,
      count: 19
    },
    {
      label: 'CP-Return Mail',
      value: 'f7e90ae3-30f3-4c89-868e-1df99f99c4b2',
      status: 'active',
      isInactive: false,
      count: 1
    },
    {
      label: 'CP-Underwriting NOC',
      value: 'b7e9f7c4-2543-48ef-a8cc-89e6bc0831f2',
      status: 'active',
      isInactive: false,
      count: 7
    },
    {
      label: 'CS-Advanced Non-Renewal',
      value: 'f3b57270-c5cc-4939-8772-b2b897a2b173',
      status: 'active',
      isInactive: false,
      count: 13
    },
    {
      label: 'CS-Application/Questionnaire',
      value: 'd4b0c6a4-11a5-4f98-bb4f-d8f5f5bc15f8',
      status: 'active',
      isInactive: false,
      count: 22
    },
    {
      label: 'CS-Appraisal',
      value: 'e679c8bc-b40b-470b-b148-f68f1735cbd0',
      status: 'active',
      isInactive: false,
      count: 9
    },
    {
      label: 'CS-Authorization',
      value: 'd8e5c39e-cf2f-4b79-a6a3-22c7eaf6feda',
      status: 'active',
      isInactive: false,
      count: 14
    },
    {
      label: 'CS-Company Memo',
      value: 'd2a3fc63-ffbb-4d37-9e92-fcf0b302ff62',
      status: 'active',
      isInactive: false,
      count: 18
    },
    {
      label: 'CS-CSG Agent Follow Up',
      value: 'f76a1395-cb72-4e64-a94c-903f0531a1d3',
      status: 'active',
      isInactive: false,
      count: 5
    },
    {
      label: 'CS-Death Certificate',
      value: 'cbf27655-8e5a-43d1-93f5-33ac6e8cd6e2',
      status: 'active',
      isInactive: false,
      count: 11
    },
    {
      label: 'CS-Endorsement Authorization',
      value: 'ed7da2f7-11bc-44f2-b8cb-1a69f4c04c5a',
      status: 'active',
      isInactive: false,
      count: 16
    },
    {
      label: 'CP-Refi Duplicate',
      value: 'b4046c2b-6f0b-47c1-b3e3-1b0c3af5db6c',
      status: 'active',
      isInactive: false,
      count: 3
    },
    {
      label: 'CS-Inspection Report',
      value: 'bc55c4ae-8f07-4b60-b6c2-eac35c4e1504',
      status: 'active',
      isInactive: false,
      count: 8
    },
    {
      label: 'CS-Miscellaneous',
      value: 'd3c60c4f-925b-490c-9a77-68b69c7f5c93',
      status: 'active',
      isInactive: false,
      count: 12
    },
    {
      label: 'CS-Occupancy Letter',
      value: 'b69a9d0d-1684-4a1f-b78a-2c1b4a2d69a0',
      status: 'active',
      isInactive: false,
      count: 4
    },
    {
      label: 'CS-Parked',
      value: 'deaaedb4-c1e8-43b2-9f64-e4a404ccf5e1',
      status: 'active',
      isInactive: false,
      count: 10
    },
    {
      label: 'CS-Retention',
      value: 'b1e5a9e0-8720-45e8-bd7f-23b4237c8f54',
      status: 'active',
      isInactive: false,
      count: 19
    },
    {
      label: 'Sales-Auto Miscellaneous',
      value: 'a3676d39-9d54-48f9-8c99-4d84746f8e8f',
      status: 'active',
      isInactive: false,
      count: 15
    },
    {
      label: 'Sales-Home Miscellaneous',
      value: 'c8d0a2f0-80e6-45aa-8b68-9b0b8a49e515',
      status: 'active',
      isInactive: false,
      count: 7
    },
    {
      label: 'Sales-Return Mail',
      value: 'f1e6882e-19bc-47d5-9230-1fc4d7d3ac73',
      status: 'active',
      isInactive: false,
      count: 9
    },
    // {
    //   label: 'Sales-Email/Fax-Home',
    //   value: 'd1e7db0c-c6b9-4e90-9e55-5e8a2f12b889',
    //   status: 'active',
    //   isInactive: false,
    //   count: 11
    // },
    // {
    //   label: 'Sales-Email/Fax-Auto',
    //   value: 'e50b3e6a-3a59-4bcd-9cf4-64e700823c4e',
    //   status: 'active',
    //   isInactive: false,
    //   count: 14
    // },
    {
      label: 'Sales-Parked',
      value: 'c1b3b05b-b038-4e37-a1e5-ff77d6b56f48',
      status: 'active',
      isInactive: false,
      count: 6
    }
  ];

  interface IToDoListGridState {
    todoList : any[],
    gridColumns : any[],
    groupsList : SelectOption[],
    foldersList : SelectOption[],
    userList : SelectOption[],
    groupId : string,
    folderId : string
}

const sampleGridData: DocuMateTodoListDto[] = [
  {
    "id": "9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d",
    "scanId": "CS_RM_10152024_001",
    "onHold": false,
    "fileName": "file1.pdf",
    "cs2Id": "CS2_001",
    "advId": "ADV_001",
    "policyNumber": "POL_001",
    "docDescription": "Document 1 Description",
    "fromUser": "User1",
    "fromFolder": "Folder1",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 1",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": false,
    "isHardLocked": false,
    "canHardLock": true,
    "isWorking": true
  },
  {
    "id": "1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_005",
    "onHold": true,
    "fileName": "file2.pdf",
    "cs2Id": "CS2_002",
    "advId": "ADV_002",
    "policyNumber": "POL_002",
    "docDescription": "Document 2 Description",
    "fromUser": "User2",
    "fromFolder": "Folder2",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 2",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": false,
    "isHardLocked": false,
    "canHardLock": false,
    "isWorking": true
  },
  {
    "id": "2c9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_006",
    "onHold": false,
    "fileName": "file3.pdf",
    "cs2Id": "CS2_003",
    "advId": "ADV_003",
    "policyNumber": "POL_003",
    "docDescription": "Document 3 Description",
    "fromUser": "User3",
    "fromFolder": "Folder3",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 3",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": false,
    "isHardLocked": false,
    "canHardLock": false,
    "isWorking": true
  },
  {
    "id": "3d9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_007",
    "onHold": true,
    "fileName": "file4.pdf",
    "cs2Id": "CS2_004",
    "advId": "ADV_004",
    "policyNumber": "POL_004",
    "docDescription": "Document 4 Description",
    "fromUser": "User4",
    "fromFolder": "Folder4",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 4",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": false,
    "isHardLocked": true,
    "canHardLock": false,
    "isWorking": false
  },
  {
    "id": "4e9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_008",
    "onHold": false,
    "fileName": "file5.pdf",
    "cs2Id": "CS2_005",
    "advId": "ADV_005",
    "policyNumber": "POL_005",
    "docDescription": "Document 5 Description",
    "fromUser": "User5",
    "fromFolder": "Folder5",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 5",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": false,
    "isHardLocked": false,
    "canHardLock": true,
    "isWorking": false
  },
  {
    "id": "5f9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_009",
    "onHold": true,
    "fileName": "file6.pdf",
    "cs2Id": "CS2_006",
    "advId": "ADV_006",
    "policyNumber": "POL_006",
    "docDescription": "Document 6 Description",
    "fromUser": "User6",
    "fromFolder": "Folder6",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 6",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": false,
    "isHardLocked": false,
    "canHardLock": true,
    "isWorking": false
  },
  {
    "id": "6a9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_010",
    "onHold": false,
    "fileName": "file7.pdf",
    "cs2Id": "CS2_007",
    "advId": "ADV_007",
    "policyNumber": "POL_007",
    "docDescription": "Document 7 Description",
    "fromUser": "User7",
    "fromFolder": "Folder7",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 7",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": false,
    "isHardLocked": false,
    "canHardLock": true,
    "isWorking": false
  },
  {
    "id": "7b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_011",
    "onHold": true,
    "fileName": "file8.pdf",
    "cs2Id": "CS2_008",
    "advId": "ADV_008",
    "policyNumber": "POL_008",
    "docDescription": "Document 8 Description",
    "fromUser": "User8",
    "fromFolder": "Folder8",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 8",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": false,
    "isHardLocked": false,
    "canHardLock": true,
    "isWorking": false
  },
  {
    "id": "8c9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_012",
    "onHold": false,
    "fileName": "file9.pdf",
    "cs2Id": "CS2_009",
    "advId": "ADV_009",
    "policyNumber": "POL_009",
    "docDescription": "Document 9 Description",
    "fromUser": "User9",
    "fromFolder": "Folder9",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 9",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": true,
    "isHardLocked": false,
    "canHardLock": false,
    "isWorking": false
  },
  {
    "id": "9d9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_013",
    "onHold": true,
    "fileName": "file10.pdf",
    "cs2Id": "CS2_010",
    "advId": "ADV_010",
    "policyNumber": "POL_010",
    "docDescription": "Document 10 Description",
    "fromUser": "User10",
    "fromFolder": "Folder10",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 10",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": true,
    "isHardLocked": false,
    "canHardLock": false,
    "isWorking": false
  },
  {
    "id": "0e9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_014",
    "onHold": false,
    "fileName": "file11.pdf",
    "cs2Id": "CS2_011",
    "advId": "ADV_011",
    "policyNumber": "POL_011",
    "docDescription": "Document 11 Description",
    "fromUser": "User11",
    "fromFolder": "Folder11",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 11",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": true,
    "isHardLocked": false,
    "canHardLock": false,
    "isWorking": false
  },
  {
    "id": "1f9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_015",
    "onHold": true,
    "fileName": "file12.pdf",
    "cs2Id": "CS2_012",
    "advId": "ADV_012",
    "policyNumber": "POL_012",
    "docDescription": "Document 12 Description",
    "fromUser": "User12",
    "fromFolder": "Folder12",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 12",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": true,
    "isHardLocked": false,
    "canHardLock": false,
    "isWorking": false
  },
  {
    "id": "2g9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_016",
    "onHold": false,
    "fileName": "file13.pdf",
    "cs2Id": "CS2_013",
    "advId": "ADV_013",
    "policyNumber": "POL_013",
    "docDescription": "Document 13 Description",
    "fromUser": "User13",
    "fromFolder": "Folder13",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 13",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": true,
    "isHardLocked": false,
    "canHardLock": false,
    "isWorking": false
  },
  {
    "id": "3h9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_017",
    "onHold": true,
    "fileName": "file14.pdf",
    "cs2Id": "CS2_014",
    "advId": "ADV_014",
    "policyNumber": "POL_014",
    "docDescription": "Document 14 Description",
    "fromUser": "User14",
    "fromFolder": "Folder14",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 14",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": true,
    "isHardLocked": false,
    "canHardLock": false,
    "isWorking": false
  },
  {
    "id": "4i9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed",
    "scanId": "CP_RM_09122024_018",
    "onHold": false,
    "fileName": "file15.pdf",
    "cs2Id": "CS2_015",
    "advId": "ADV_015",
    "policyNumber": "POL_015",
    "docDescription": "Document 15 Description",
    "fromUser": "User15",
    "fromFolder": "Folder15",
    "assignedTo": "John Doe",
    "redirectReason": "Reason 15",
    "dateRedirected": "10-12-2024 12:04:56 PM",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "canDelete": true,
    "isHardLocked": false,
    "canHardLock": false,
    "isWorking": false
  }
];

const initialToDoListGridState : IToDoListGridState = {
  todoList : sampleGridData,
  gridColumns : [],
  groupsList : [],
  foldersList : [],
  userList : [],
  groupId : '',
  folderId : ''
}

export interface DocuMateTodoListprops {
  onRouteChange?: (route: string) => void;
}



const DocuMateTodoList: React.FC = () => {

    const [todoListGridState, setToDoListGridState] = useState(initialToDoListGridState);
    const {
        todoList,
        gridColumns,
        groupsList,
        foldersList,
        userList,
        groupId,
        folderId } = todoListGridState;
    const [UserSelectDropdown, setUserSelectDropdown] = useState(sampleUserOptions);
    const [UsersSelected, setUsersSelected] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedFolderId, setSelectedFolderId] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsCount, setSelectedRowsCount] = useState(0);
    const todoListService = new DocuMateTodoListService();
    const history = useHistory();
    const [docLimitToSelect, setDocLimitToSelect] = useState(10);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentDocument, setCurrentDocument] = useState<DocuMateTodoListDto>(null);
    const [modalBody, setModalBody] = useState(null);
    // var elementCounter = 4;
    const gridRef = useRef(null);

    useEffect(() => {
        initializeGrid();
    }, []);

    const initializeGrid = () => {
      const gridColumns = todoListService.getTodoListGridColumns(onDeleteDocument, onHardLockDocument, handleOnHoldChange, handleDocumentClick);
      setToDoListGridState((prevState) => ({
        ...prevState,
        gridColumns: gridColumns,
        groupsList: sampleGroups,
        foldersList: sampleFolders,
        userList: sampleUserOptions,
        todoList: sampleGridData
      }));
    }

    const onUserSelectChange = (selectedOption) => {
        setUsersSelected(selectedOption);
        console.log('onUserSelectChange:' + selectedOption.toString())
    };

    const onSelectAllUsers = () => {
        setUsersSelected(UserSelectDropdown);
    };

    const onGroupSelectChange = (selectedOption) => {
        setSelectedGroupId(selectedOption.toString());
        console.log('onGroupSelectChange:' + selectedOption.toString()) 
    }

    const onFolderSelectChange = (selectedOption) => {
        setSelectedFolderId(selectedOption.toString());
        console.log('onFolderSelectChange:' + selectedOption.toString())
    }

    const onDeleteDocument = (id) => {
      setToDoListGridState((prevState) => ({
        ...prevState,
        todoList: prevState.todoList.filter((doc) => doc.id !== id)
      }));
      console.log('onDeleteDocument:' + id);
    }

    const onHardLockDocument = (id) => {
      setToDoListGridState((prevState) => {
        const updatedTodoList = prevState.todoList.map((doc) =>
          doc.id === id ? { ...doc, canHardLock: false, isHardLocked: true } : doc
        );
        return {
          ...prevState,
          todoList: updatedTodoList
        };
        });
    }

    const handleGetSelectedRowsCount = (newCount: number) => {
      setSelectedRowsCount(newCount);
    }

    const getSelectedRows = () => {
      if (gridRef.current) {
        const selectedRows = gridRef.current.getSelectedRows();
        setSelectedRows(selectedRows);
      }
    }

    const handleOnHoldChange = (id) => {
      setToDoListGridState((prevState) => {
      const updatedTodoList = prevState.todoList.map((doc) =>
        doc.id === id ? { ...doc, onHold: !doc.onHold } : doc
      );
      return {
        ...prevState,
        todoList: updatedTodoList
      };
      });
    }

    const handleDocumentClick = (id) => {
      let document = todoListGridState.todoList.find(doc => doc.id === id);
      if (document) {
      setCurrentDocument(document);
      if (document.isWorking) {
        setModalBody(getConfirmModalBody(docuMateConstant.INDEXED, document.assignedTo, document.dateInitiated));
        setShowConfirmModal(true);
      }
      else if(document.isHardLocked) {
        setModalBody(getConfirmModalBody(docuMateConstant.HARD_LOCKED, document.assignedTo, document.dateInitiated));
        setShowConfirmModal(true);
      }
      else if(document.canHardLock) {
        setModalBody(getConfirmModalBody(docuMateConstant.SOFT_LOCKED, document.assignedTo, document.dateInitiated));
        setShowConfirmModal(true);
      }
      else {
        history.push({
          pathname: `documate-indexing/processing/${id}`,
          state: { id: id, scanId: document.scanId, docStatus: '' }
        });
      }
    }
  }

    const handleClosePopup = () => {
      setShowConfirmModal(false);
    }

    const handleConfirmModal = () => {
      if (currentDocument) {
        history.push({
          pathname: `documate-indexing/processing/${currentDocument.id}`,
          state: { id: currentDocument.id, scanId: currentDocument.scanId, docStatus: currentDocument.isWorking ? 'working' : currentDocument.isHardLocked ? 'hardLocked' : currentDocument.canHardLock ? 'softLocked' : '' }
        });
      }
      setShowConfirmModal(false);
    }

    const getConfirmModalBody = (lockType, userName, dateTime) => (
      <>   
      The image is <strong>{lockType}</strong> by user(s): <strong>"{userName}"</strong> on <strong>"{dateTime}."</strong> Would you like to open?
      </>
    );

  const handleSearchClick = () => {
    setToDoListGridState((prevState) => ({
      ...prevState,
      todoList: sampleGridData
    }));
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Container fluid>
          <div className='documate-todoList-main-div'>
            <Row>
              <Column lg={3} md={3}>
                <DropDownWithLabelInline
                  id='todoListGroups'
                  label={documateLabel.TODO_LIST_GROUPS}
                  showAsFloatingLabel={false}
                  options={sampleGroups}
                  defaultSelected={sampleGroups?.filter(({ value }) => value === selectedGroupId)}
                  onChange={
                    (value) => onGroupSelectChange(value)
                  }
                  labelSize={2}
                  dropDownSize={9}
                  labelClass='inlineLable entry-form-inlineLable tsk-grd-first-filter-lable'
                  placeholder={"Please Select"}
                  multiple={false}
                  disabled={false}
                  tabIndex={1}
                  dropdownDivClass='todoList-dropdowns' />
              </Column>
              <Column lg={5} md={5}>
                <DropDownWithLabelInline
                  id='todoListFolders'
                  label={documateLabel.TODO_LIST_FOLDERS}
                  showAsFloatingLabel={false}
                  options={sampleFolders}
                  defaultSelected={sampleFolders?.filter(({ value }) => value === selectedFolderId)}
                  onChange={
                    (value) => onFolderSelectChange(value)
                  }
                  labelSize={3}
                  dropDownSize={9}
                  labelClass='inlineLable entry-form-inlineLable tsk-grd-first-filter-lable'
                  placeholder={"Please Select"}
                  multiple={false}
                  disabled={false}
                  tabIndex={1}
                  labelColClassName=''
                  dropdownDivClass='todoList-dropdowns' />
              </Column>
              <Column lg={4} md={4}>

                <div className='d-flex justify-content-between'>
                  <Label text={documateLabel.TODO_LIST_USERS} htmlFor={'userDropdown'} isRequired={false} className={'inlineLable entry-form-inlineLable tsk-grd-first-filter-lable'} />
                  <Select
                    id={'userSelectDropdown'}
                    tabIndex={1}
                    options={UserSelectDropdown}
                    placeholder={docuMateConstant.PLEASE_SELECT}
                    inputProps={{
                      multiple: true,
                      className: '',
                      title: (UserSelectDropdown
                        .filter((option) => UsersSelected.map((option) => option.value).includes(option.value)) // Filter the selected options
                        .map((option) => option.label) // Extract the label (name) of each user
                        .toString() // Convert the array of labels to a string
                        .replaceAll(',', '\r\n')
                      )
                    }}
                    onChange={(values) => {
                      if (values.includes('allUsers')) {
                        // If "Select All" is selected, select all options except the "Select All" itself
                        // const allUsers = UserSelectDropdown.filter(option => option.value !== 'selectAll').map(option => option.value);

                        setUsersSelected(UserSelectDropdown.filter(option => option.value !== 'allUsers'));
                        onUserSelectChange(UserSelectDropdown.filter(option => option.value !== 'allUsers'));
                      } else {
                        // Handle normal selection
                        const selectedOption = UserSelectDropdown.filter((option) =>
                          values.includes(option.value)
                        );
                        setUsersSelected(selectedOption);
                        onUserSelectChange(selectedOption);
                      }
                    }}
                    defaultSelected={UsersSelected.map(({ label, value, isInactive }) => ({
                      label,
                      value,
                      isInactive
                    }))}
                    multiple={true}
                  // isInvalid={data.isLogicEmpty}
                  // invalidWarning={data.logicFieldError}
                  // isEmpty={data.isLogicEmpty}
                  //errorText={data.logicFieldError}
                  />

                  <Button variant="primary" key="filter"
                    onClick={handleSearchClick}
                    tabIndex={21}
                    id="searchTodoList"
                    disabled={false}>Search</Button>
                </div>
              </Column>
            </Row>
            <Alert variant="info" className="p-2 mt-2">
              Selected {selectedRowsCount} of {docLimitToSelect} documents of {todoList.length} Images
            </Alert>
            <div className="">
              <Card className='p-0'>
                <Grid
                  ref={gridRef}
                  data={todoList}
                  showFilters={true}
                  id={'documateToDoListGrid'}
                  retainGridState={true}
                  columns={gridColumns}
                  hiddenColumns={['selection']}
                  sortDefault={[]}
                  grdTableContainerClass="p-0 grd-table-container"
                  grdTabelClass="grd-table table-striped table-hover table-sm"
                  zindexHeader={10000}
                  onSelectionChange={handleGetSelectedRowsCount}
                />
              </Card>

              <ConfirmModal
                modaltitle={documateLabel.CONFIRM}
                showConfirmation={showConfirmModal}
                setConfirmation={(isModalOpen) => setShowConfirmModal(isModalOpen)}
                body={modalBody}
                closeButtonClick={() => handleClosePopup()}
                successButtonClick={() => handleConfirmModal()}
                closeButtonLabel={documateLabel.CONFIRM_NO.toUpperCase()}
                successButtonLabel={documateLabel.CONFIRM_YES.toUpperCase()}
              />
            </div>
          </div>
        </Container>

      </ErrorBoundary>
    </>
  );
};

export default DocuMateTodoList;