import { useEffect, useState, useRef } from 'react'
import { Button, Panel, Row, Column, Modal, Label } from '../../components'
import CampaignDetailUI from '../model/CampaignDetailUI'
import Grid from '../../components/components/Grid/Grid'
import format from 'date-fns/format'
import { DownloadLink, getCSV } from '../../shared/util/DataHelpers'
import '../campaign.scss'
import  campaignResultsUtils  from '../utils/campaignResultsUtils'
import CampaignsWithinSendOnDate from '../model/CampaignsWithinSendOnDate'
import SendTest from '../shared/components/SendTest'
import CampaignResult from '../model/CampaignResult'
import { toast } from 'react-toastify'
import * as constant from '../../shared/util/constant'
import * as label from '../../shared/util/label'
import RequestCampaignApiRequest from '../model/RequestCampaignApiRequest'
import CampaignDetailsUtils from '../utils/campaignDetailsUtils'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { toggleFormSaved } from '../../shared/components/formSaved-slice'
import  { useHistory } from 'react-router-dom';

interface Props {
  campaignFilters: CampaignDetailUI
  isDetailsChangedAfterResults: boolean,
  isRequestValidated: boolean
  onRequestButtonClick: () => void,
  makeSendOnDateEmpty:(makeSendOnDateEmpty:boolean) => void
  requestButtonClickCounter:number
}

const CampaignResults = (props: Props) => {
  // toast.configure();

  const [campaignResults, setCampaignResults] = useState<CampaignResult[]>([]);

  const gridRef = useRef(null);

  const [showFilters, setShowFilters] = useState(false);

  const [showSendTestPopup, setShowSendTestPopup] = useState(false);

  const [showCampaignsWithinSendOnDate, setShowCampaignsWithinSendOnDate] = useState<boolean>(false);

  const initialCampaignsWithinSendOnDate: CampaignsWithinSendOnDate[] = [];

  const prevRequestButtonClickCounter = useRef<number | null>(null);

  const [totalResultCount, setTotalResultCount] = useState(0);

  const history = useHistory();

  const dispatch = useDispatch();
  const [campaignsWithinSendOnDate, setCampaignsWithinSendOnDate] = useState<CampaignsWithinSendOnDate[]>(initialCampaignsWithinSendOnDate)

  const prevCampaignFiltersRef = useRef<CampaignDetailUI | null>(null);

  useEffect(() => {
    prevCampaignFiltersRef.current = props.campaignFilters;
  }, []);

  const handleCheckboxHit = () => {
    if (gridRef.current) {
      const selectedRows = gridRef.current.getSelectedRows();
      return selectedRows.map(x => x.values.custId);
    }
  };

  const [isRowSelected, setIsRowSelected] = useState(false);
  const [selectedColumnValue, setSelectedColumnValue] = useState("");

  useEffect(() => {
     setCampaignResults([])
      setSelectedColumnValue("")
      setIsRowSelected(false)
    if (props.campaignFilters.id !== null && props.campaignFilters.id !== undefined) {  
      try
      {
        campaignResultsUtils.getCampaignResults(props.campaignFilters.id).then(res => {
        setCampaignResults(res);
        if(res.length > 0)
          setTotalResultCount(res[0].totalCount)
      })
    } catch (error) {
      toast.error(constant.ERROR_MESSAGE_LOAD, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: constant.AUTO_CLOSE
      });
      console.log("error",error) // Catch any additional errors
    }
}
     
  }, [props.campaignFilters])



  useEffect(() => {
    if (isRowSelected) {
      setSelectedColumnValue((prevValue) => {
        if (prevValue !== selectedColumnValue) {
          return selectedColumnValue;
        }
        return prevValue;
      });
    }
  }, [isRowSelected]);

//   useEffect(() => {
//  if(prevRequestButtonClickCounter.current !== props.requestButtonClickCounter)
//  {
//   prevRequestButtonClickCounter.current = props.requestButtonClickCounter
//  }
// },[props.requestButtonClickCounter])

useEffect(() => {
  if (props.isRequestValidated) {
      const fetchCampaignDetails = async() => {
        proceedForcedRequestForCampaign(false);
      }
      fetchCampaignDetails();
    // const requestCampaignResponse = campaignResultsUtils.requestCampaignDetailsUtils(props.campaignFilters);
    //here we need to plan for getting campaign details as response if there is no campaingswithinSendOnDate
    //and also if it is there but user clicks yes for forced
  }
},[props.requestButtonClickCounter, props.isRequestValidated])

  const handleSelectedRow = (selected, columnValue) => {
    setIsRowSelected(selected);
    setSelectedColumnValue(columnValue);
  };


  const downloadCSV =  async () => {
    const selectedCustomerNumbers = handleCheckboxHit();
    if (campaignResults != undefined) {
      var csv;
      if (selectedCustomerNumbers.length === 0) {
        var testQueryRecords = await CampaignDetailsUtils.getcsvResultFromId(props.campaignFilters.id);
        const campaignResultData = constant.CAMPAIGN_REPORT
        const filename = campaignResultData
        .concat('-')
        .concat(format(new Date(Date.now()), 'yyyy-MM-dd--hh-mma'))
        .concat('.csv')
        const url = window.URL.createObjectURL(new Blob([testQueryRecords]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
      }
      else {
        csv = getCSV(campaignResults.filter(campaignResult => selectedCustomerNumbers.includes(campaignResult.custId)))
        const campaignResultData = constant.CAMPAIGN_REPORT

      const filename = campaignResultData
        .concat('-')
        .concat(format(new Date(Date.now()), 'yyyy-MM-dd--hh-mma'))
        .concat('.csv')

      DownloadLink(csv, filename)
      }
      
    }
  }

  const onRequest =  async () => {
    if (!prevCampaignFiltersRef.current || !isEqual(props.campaignFilters, prevCampaignFiltersRef.current)) {
      const updatedDetails = await CampaignDetailsUtils.updateCampaignDetailsUtils(props.campaignFilters)
      prevCampaignFiltersRef.current = updatedDetails;
      dispatch(toggleFormSaved())
    }
    props.onRequestButtonClick();
      // props.makeSendOnDateEmpty(false)
      // call campaign detials input(campaign settings for api calling via utility class)
      // #88 says as soon as you emnter request info entered on screen along with results will be saved to campaign
  }


  const BackToCampaignList = () => {
    history.push(`/Campaign/Campaign`);
  }


  const proceedForcedRequestForCampaign = async (isForcedRequest: boolean) => {
    // here call the api in utility folder to send to db for forced request for campaign
    //and get campaign details as repsonse
    let requestCampaign: RequestCampaignApiRequest = {
      campaignId: props.campaignFilters.id,
      proceedForcedRequest: isForcedRequest
    }
    try
    {
      campaignResultsUtils.requestCampaign(requestCampaign).then(res => {
        if(!res.data.isEmailSent && res.data.requestCampaignDetailsList.length > 0) {
          // show campaign details on popup
          setCampaignsWithinSendOnDate(res.data.requestCampaignDetailsList);
          setShowCampaignsWithinSendOnDate(true);
        }
        else if(res.data.isEmailSent && res.data.requestCampaignDetailsList.length == 0){
          // processed forced request and sent notifications
          setShowCampaignsWithinSendOnDate(false);
          toast.success(constant.REQUEST_CAMPAIGN_SUCCESS_MESSAGE, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: constant.AUTO_CLOSE
        })
          // back to campaign List -->
          BackToCampaignList();
        }
        else {
          toast.error(constant.REQUEST_CAMPAIGN_ERROR_MESSAGE, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: constant.AUTO_CLOSE
          })
        }
    })
  } catch (error) {
    toast.error(constant.ERROR_MESSAGE_LOAD, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: constant.AUTO_CLOSE
    });
    // console.log("status code for forced success of cmapaing request")

  }
}

  const handleCloseSendTest = () => {
    setShowSendTestPopup(false);
  }

  return (
    <><>
      <Panel className='col-lg-12' collapsed={false} collapsible={false}>
        {campaignResults != undefined ?
          <>
            <Row className='pb-2'>
              <Label text={label.CAMPAIGN+" "+ label.RESULTS} style={{fontWeight:'bold'}}>
              </Label>
              <Button
                key="exportCSV"
                color="info"
                onClick={downloadCSV}
                className={"campaignResultsgridButtons btnLabelStyle"}
                icon='export'
                iconLocation='left'
                disabled={!(campaignResults.length >= 1)}
              >
                {label.EXPORT}
              </Button>

              <Button
                key="filterCSV"
                color="info"
                onClick={() => setShowFilters(!showFilters)}
                className={"btnLabelStyle"}
                icon='filter'
                iconLocation='left'
                disabled={!(campaignResults.length >= 1)}
              >
                {showFilters ? label.HIDE_FILTER : label.SHOW_FILTER}
              </Button>

            </Row>
            <Row className='gridrow'>
              <Grid
                key={JSON.stringify(campaignResults)}
                ref={gridRef}
                data={campaignResults}
                showFilters={showFilters}
                columns={[
                  {
                    Header: '',
                    accessor: 'custId',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.CUSTOMER_NUMBER,
                    accessor: 'custNo',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.POLICY_NUMBER,
                    accessor: 'polNo',
                  },
                  {
                    Header: label.FIRST_NAME,
                    accessor: 'firstName',
                    // minWidth: 270,
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.LAST_NAME,
                    accessor: 'lastName',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.ADDRESS,
                    accessor: 'addr1',
                    // width:223,
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.CITY,
                    accessor: 'city',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.STATE,
                    accessor: 'state',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.COUNTY,
                    accessor: 'county',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  
                  {
                    Header: label.ZIP,
                    accessor: 'zipcode',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.AGENCY,
                    accessor: 'agency',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.SOURCE,
                    accessor: 'source',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.STATUS,
                    accessor: 'status',
                    // width: 70,
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.PHONE_NUMBER,
                    accessor: 'phoneNo',
                    // width: 70,
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.EMAIL,
                    accessor: 'eMail',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                  {
                    Header: label.RATED_CARRIER,
                    accessor: 'carrier',
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                  },
                ]}
                hiddenColumns={['custId']}
                sortDefault={[{ id: 'custNo', desc: true }]}
                highlightColumn='custId'
                selectedColumn={handleSelectedRow} />
              <div>

              </div>
              <div>
                <h6>{label.TOTAL}: {totalResultCount}</h6>
              </div>
            </Row>

            <Row className='campaignSettings-footer'>
              <Column lg={6} style={{paddingLeft:"0px"}}>
                <Button color='primary' outlined={true}
                  onClick={BackToCampaignList}
                >{label.CANCEL.toUpperCase()}</Button>
              </Column>
              <Column lg={6} className='right-align' style={{paddingRight:"0px"}}>
                <Button color='primary' outlined={false}
                  onClick={onRequest}
                  disabled={!(campaignResults.length >= 1 && (props.campaignFilters.status === '' || props.campaignFilters.status === constant.SAVED || props.campaignFilters.status === constant.REJECTED) && props.isDetailsChangedAfterResults === false)}
                >{label.REQUEST}</Button>
                <Button color='primary' outlined={true}
                  disabled={!(campaignResults.length >= 1 && (props.campaignFilters.status === '' || props.campaignFilters.status === constant.SAVED) && props.isDetailsChangedAfterResults === false && selectedColumnValue != "")}
                  onClick={() => { setShowSendTestPopup(true) }}
                >{label.SEND_TEST}</Button>
              </Column>
            </Row>
          </>
          : "No record found"}

      </Panel>
      {showSendTestPopup && <SendTest handleClose={handleCloseSendTest} showSendTestPopup={showSendTestPopup} customerId={selectedColumnValue} campaignId={props.campaignFilters.id} />}     
      <Modal
        verticallyCentered={true}
        title={"Proceed with "+props.campaignFilters.campaignName+ " ?" }
        show={showCampaignsWithinSendOnDate}
        toggle={() => {
          setShowCampaignsWithinSendOnDate(false)
          // props.makeSendOnDateEmpty(true)
        }}
        body={
          <><div>
            <Label 
            text={label.FORCED_CAMPAIGN_REQUEST}
            style={{ fontWeight: 'bold' }}></Label>
            <br></br>
            <br></br>
          </div><div>
              {campaignsWithinSendOnDate.map((campaign, index) => (
                <div key={index}>
                  <div>
                    <Label text={`${label.CAMPAIGN}: ${campaign.campaignName}`} />
                  </div>
                  <div>
                    <Label text={`${label.REQUESTED_BY}: ${campaign.requestedBy}`} />
                  </div>
                  <div>
                    <Label text={`${label.HAS_SEND_ON}  : ${campaign.sendOnDate}`} />
                  </div>
                  <br></br>
                </div>
              ))}
            </div>
            <div>
              <br></br>
              <Label 
              text={label.CONFIRM_CONTINUE}
              style={{ fontWeight: 'bold' }}></Label>
            </div>
          </>
        }
        closeButton={{
          size: "small",
          icon: 'cancel',
          iconStyle: { color: "gray" },
          outlined: true,
          color: 'link',
          onClick() {
            setShowCampaignsWithinSendOnDate(false);
            // props.makeSendOnDateEmpty(true)

          },
          children: <span style={{ color: "gray" }}>{label.NO}</span>
        }}
        successButton={{
          size: "small",
          icon: "check",
          iconStyle: { color: "177db8" },
          outlined: true,
          color: 'link',
          onClick() {
            setShowCampaignsWithinSendOnDate(false)
            proceedForcedRequestForCampaign(true);
            // props.makeSendOnDateEmpty(false)

          },
          children: <span style={{ color: "#177db8" }}>{label.YES}</span>
        }}
        buttonsAlignment={"right"} />
    </></>

  )
}
export default CampaignResults
