import { Row, Column, Spinner, Panel, Label, Modal, Button, Select, TextField } from '../../../src/components';
import { ErrorBoundary } from 'react-error-boundary'
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import ExternalTemplate from '../model/3rdPartyTemplate'
import TextInputWithLabelInline from '../../shared/components/input/TextInputWithLabelInline';
import DropDownWithLabelInline from '../../shared/components/input/DropDownWithLabelInline';
import { campaignTemplatesUtils } from '../utils/3rdPartyTemplateUtils'
import { getSendgridTemplateOptions, getDropdownOptionsList } from '../utils/dropdownUtils';
import * as constant from '../../shared/util/constant'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import NotesPanel from '../../shared/components/Notes&History/filters/NotesPanel';
import moment from 'moment';
import * as label from '../../shared/util/label'
import * as table from '../../shared/util/tableConstant'
import { useContext } from 'react';
import AppContext from '../../shared/components/AppContext';
import { ConfirmModal } from '../../shared/components/confirmModal';
import { useDispatch } from 'react-redux';
import { toggleFormSaved } from '../../shared/components/formSaved-slice';
import { FallbackComponent } from '../../shared/components/FallbackComponent';
import { SelectOption } from '../../shared/components/input/SelectOption';
import { AbilityContext } from '../../shared/abilities/AbilityContext';
import { Container } from 'react-bootstrap';




const ExternalTemplateDetail = () => {
    //#region -----------------------------Constant Declaration-------------------
    const externalTemplate: ExternalTemplate = {
        id: constant.DEFAULT_GUID,
        templateName: '',
        description: '',
        //statusId: '',
        status: '',
        typeName: '',
        type: constant.DEFAULT_GUID,
        sendGridTemplateId: null,
        sendgridName: '',
        //categoryId: '',
        category: constant.DEFAULT_GUID,
        subCategory: '',
        summary: '',
        enteredBy: '',
        enteredDate: null,
        changedBy: '',
        changedDate: null,
        isLinked: false
    }
    const history = useHistory();
    const location = useLocation();
    const myContext = useContext(AppContext);
    var state = location.state as { entityId: string, type: string };
    const urlParam = new URLSearchParams(location.search)
    var externalTemplateId = state?.entityId ??urlParam.get('id')?? constant.DEFAULT_GUID;
    const [exTemplateID, setExTemplateID] = useState(externalTemplateId);
    const type = location.state as { type: string };
    const noteType = state?.type;
    const [noteTp, setNoteType] = useState('3')
    const [externalTemplateInputs, setTemplateDetail] = useState(externalTemplate);
    const [loading, setLoading] = useState(false);
    const [isTemplateNameEmpty, validateTemplateNameEmpty] = useState(false);
    const [isTemplateNameShort, validateTemplateNameShort] = useState(false);
    const [isTemplateNameLong, validateTemplateNameLong] = useState(false);
    const [isTemplateNameSame, validateTemplateNameSame] = useState(false)
    const [isDescriptionEmpty, validateDescriptionEmpty] = useState(false);
    const [isDescriptionShort, validateDescriptionShort] = useState(false);
    const [isDescriptionLong, validateDescriptionLong] = useState(false);
    const [isStatusValid, validateStatus] = useState(false);
    const [isTemplateTypeEmpty, validateTemplateType] = useState(false);
    const [isSendgridTemplateSelected, setSendgridTemplateSelected] = useState(false);
    const [isCategorySelected, validateCategorySelected] = useState(false)
    const [templateStatusTxt, setTemplateStatusTxt] = useState('');
    const [isRequired, setIsRequired] = useState(templateStatusTxt === constant.SAVED ? false : true);

    const [isTemplatesaved, setTemplateSaved] = useState();
    const [templateSubmittedStatus, setTemplateSubmitted] = useState(null)
    const [isResponseAvailable, setResponseStatus] = useState(false)
    const [isFormValid, setFormValid] = useState(false);

    const [previousTemplateName, setPrevTemplateName] = useState(externalTemplateInputs === (undefined || null) ? '' : externalTemplateInputs.templateName === undefined ? '' : externalTemplateInputs.templateName);
    const [typeId, setTypeId] = useState(externalTemplateInputs === undefined ? constant.DEFAULT_GUID : externalTemplateInputs.type === null ? constant.DEFAULT_GUID : externalTemplateInputs.type === constant.TEMPLATE_TYPE_MARKETING_ID ? constant.TEMPLATE_TYPE_MARKETING : externalTemplateInputs.type === constant.TEMPLATE_TYPE_TRANSCTIONAL_ID ? constant.TEMPLATE_TYPE_TRANSCTIONAL : constant.DEFAULT_GUID);
    const [sendgridTemplateOptions, setSelectedOptions] = useState<SelectOption[]>([]);//getSendgridTemplateOptions(typeId);
    const [templateStatusOptions, setStatusOption] = useState<SelectOption[]>([]);//getTemplateStatusOptions()
    const [templateType, setTemplateType] = useState<SelectOption[]>([]);//getExternalTemplateTypesOptions();
    const [campaignCategoriesOptions, setCategories] = useState<SelectOption[]>([]); //getExternalTemplateCategoriesOptions();
    const [isCampaignAttached, setCampaignAttached] = useState(false);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [strTemplateNameError, setTemplateNameErrorText] = useState('');
    const [strDescError, setDescErrorText] = useState('');
    const [stStatusError, setStatusErrorText] = useState('');
    const [stTemplateTypeError, setTemplateTypeErrorText] = useState('');
    const [stSendgridTemplateError, setSendgridTemplateErrorText] = useState('');
    const [stCategoryError, setTemplateCategoryErrorText] = useState('');
    const [inactiveButtonText, setinactiveButtonText] = useState('');
    const [isformEditable, setFormEditable] = useState(true);
    const [highlightedMenu, setHighlightedMenu] = useState('3rdpartytemplate');
    const [isFormSaved, setFormSaved] = useState(true);
    const [showExitConfirmation, setShowExitConfirmation] = useState(false);
    const [isCompleteSave, setIsCompleteSave] = useState(false)
    const [isSaveAsTriggered, setSaveAsTriggered] = useState(false)
    myContext.isDetailWithNoteSave = isCompleteSave;
    myContext.isSaveAsCliked = isSaveAsTriggered;

    const ability = useContext(AbilityContext);
    const ab_subject ="Campaign Template" ;
    const isUserPermitToCreateCampaignTemplate = ability.can('create', ab_subject,constant.ScreenName.CAMAPIGN_TEMAPLATE)
    const isUserPermitToUpdateCampaignTemplate = ability.can('update',ab_subject,constant.ScreenName.CAMAPIGN_TEMAPLATE)
    const isUserPermitToDeleteCampaignTemplate = ability.can('delete', ab_subject,constant.ScreenName.CAMAPIGN_TEMAPLATE)

    const isUserPermitToCopyCampaignTemplate = ability.can('read', ab_subject,constant.TemplatePrivileges.SAVE_AS)
    const isUserPermitToViewCopyCampaignTemplate = ability.can('view', ab_subject,constant.TemplatePrivileges.SAVE_AS)
    
    
    const userPermitToSave = (isUserPermitToCreateCampaignTemplate && exTemplateID == constant.DEFAULT_GUID?true:
        isUserPermitToUpdateCampaignTemplate && exTemplateID  != constant.DEFAULT_GUID?true:false);
    const userRestrictToDelete =(isUserPermitToDeleteCampaignTemplate && exTemplateID === constant.DEFAULT_GUID?false:
        isUserPermitToDeleteCampaignTemplate && exTemplateID !== constant.DEFAULT_GUID?true:false);


    const dispatch = useDispatch();

    //#endregion--------------------------------------------------------------------

    //Call when there is error occur on the page
    // const FallbackComponent = ({ error, resetErrorBoundary }) => {
    //     console.log("An error occurred:");
    //     console.log(error.message);
    //     return (
    //         <div>
    //             <h1>An error occurred: {error.message}</h1>
    //             <button onClick={resetErrorBoundary}>Try again</button>
    //         </div>
    //     );
    // };



    //--------------------#region Populate Dropdown------------------------------------------ 
    useEffect(() => {
        console.log('externalTemplateId',!externalTemplateId, isUserPermitToCreateCampaignTemplate)
        getDropdownOptionsList(table.TEMPLATE_STATUS).then(res => {
            //console.log("status",res);
            setStatusOption(res);
        })
    }, [])
    useEffect(() => {
        getDropdownOptionsList(table.TEMPLATE_TYPE).then(res => {
            //console.log("status",res);
            setTemplateType(res);
        })
    }, [])
    useEffect(() => {
        getDropdownOptionsList(table.CAMPAIGN_CATEGORY).then(res => {
            //console.log("status",res);
            setCategories(res);
        })
    }, [])

    useEffect(() => {
        if (typeId !== constant.DEFAULT_GUID && typeId.trim() !== '' && typeId !== undefined) {
            getSendgridTemplateOptions(typeId).then(res => {
                setSelectedOptions(res)
            })
        }
        else {
            setSelectedOptions([])
        }
        //
    }, [typeId])

    //-----------------------#endregion Populate Dropdown----------------------------------
    
    useEffect(() => {
        myContext.formSavedStatus = isFormSaved
    }, [isFormSaved])

    //Called every time, if there is change occur on controls on parent form
    const onFieldChange = (name: string, value: string | Date | boolean) => {
        setTemplateDetail({
            ...externalTemplateInputs,

            [name]: value,
        })
        setFormSaved(false)
    }
 

    //Get template info by ID populate data on detail 
    //section when user redirect from list on click on template link
    async function get3rdPartyTemplateInfo() {
        if (externalTemplateId !== undefined && externalTemplateId !== constant.DEFAULT_GUID) {
            await campaignTemplatesUtils.getThirdPartyTemplate(externalTemplateId)
                .then((res) => {
                    if (res !== undefined && res !== null) {
                        setTemplateDetail(res);
                        setTypeId(res.type === undefined ? '' : res.type === null ? '' : res.type.toUpperCase() === constant.TEMPLATE_TYPE_MARKETING_ID ? constant.TEMPLATE_TYPE_MARKETING : constant.TEMPLATE_TYPE_TRANSCTIONAL)
                        setIsRequired(res.status.toUpperCase() === constant.STATUS_SAVED ? false : true)
                        setTemplateStatusTxt(res.status.toUpperCase() === constant.STATUS_SAVED ? constant.SAVED :
                            res.status.toUpperCase() === constant.STATUS_ACTIVE ? constant.ACTIVE :
                                res.status.toUpperCase() === constant.STATUS_INACTIVE ? constant.INACTIVE : constant.SAVED)
                        setPrevTemplateName(res.templateName)
                        setCampaignAttached(res.isLinked)
                        setFormSaved(true)
                    }
                    else {
                        toast.error(constant.ERROR_MESSAGE_LOAD, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        setFormSaved(false)
                    }
                })
        }
        else {
            setTemplateStatusTxt(constant.SAVED)
            setIsRequired(false)
            highlightMenu();
        }
    }
   

    const InactiveTemplate = (event) => {
        console.log(event.target.id)
        if (event.target.innerText.toLowerCase() === constant.INACTIVATE.toLowerCase()) {
            console.log('if')
            setinactiveButtonText(label.ACTIVATE.toUpperCase())
            setFormEditable(false)
        }
        else {
            console.log('else')
            setinactiveButtonText(label.INACTIVATE.toUpperCase())
            setFormEditable(true)
        }
    }
    //Check validation before submit
    const validateForm = (event) => {

        var formStatus = false;
        setFormValid(false)
        validateTemplateNameEmpty(false);
        const isTemplateNameEmpty = !externalTemplateInputs.templateName || externalTemplateInputs.templateName.length === 0;
        const isSameTemplateName = previousTemplateName === "" ? false : externalTemplateInputs.templateName.toLocaleLowerCase() === previousTemplateName.toLocaleLowerCase();
        const isTemplateNameLong = externalTemplateInputs.templateName.length > 150;
        const isTemplateNameShort = !isTemplateNameEmpty && externalTemplateInputs.templateName.length < 3
        const isDescEmpty = !externalTemplateInputs.description || externalTemplateInputs.description.length === 0;
        const isDescShort = !isDescEmpty && externalTemplateInputs.description.length < 3;
        const isDescLong = !isDescEmpty && externalTemplateInputs.description.length > 250;
        const isSendgridTemplateTypeEmpty = externalTemplateInputs.type === constant.DEFAULT_GUID ? true : externalTemplateInputs.type === "" ? true : externalTemplateInputs.type === undefined ? true : externalTemplateInputs.type === null ? true : false
        const sendgridLable = sendgridTemplateOptions.find((option) => option.value === externalTemplateInputs.sendGridTemplateId) === undefined ? '' : sendgridTemplateOptions.find((option) => option.value === externalTemplateInputs.sendGridTemplateId).label
        const isSendgridTemplateEmpty = externalTemplateInputs.sendGridTemplateId === constant.DEFAULT_GUID ? true : sendgridLable === '' ? true : externalTemplateInputs.sendGridTemplateId === "" ? true : externalTemplateInputs.sendGridTemplateId === undefined ? true : externalTemplateInputs.sendGridTemplateId === null ? true : false
        const isStatusEmpty = !externalTemplateInputs.status || externalTemplateInputs.status.length === 0;
        const isCategoryEmpty = externalTemplateInputs.category === constant.DEFAULT_GUID ? true : externalTemplateInputs.category === "" ? true : externalTemplateInputs.category === undefined ? true : externalTemplateInputs.category === null ? true : false
        var isFilterValid = false;
        var checkTemplateNameValidity = true;
        var checkDescriptionValidity = true;
        validateTemplateNameEmpty(isTemplateNameEmpty);
        validateTemplateNameShort(isTemplateNameShort);
        validateTemplateNameLong(isTemplateNameLong);
        validateDescriptionEmpty(templateStatusTxt !== constant.SAVED && isDescEmpty);
        validateDescriptionShort(isDescShort);
        validateDescriptionLong(isDescLong);
        validateStatus(isStatusEmpty)
        validateTemplateType(templateStatusTxt !== constant.SAVED && isSendgridTemplateTypeEmpty)
        setSendgridTemplateSelected(templateStatusTxt !== constant.SAVED && isSendgridTemplateEmpty);
        validateCategorySelected(templateStatusTxt !== constant.SAVED && isCategoryEmpty)
        validateTemplateNameSame(event === constant.SAVE_AS && isSameTemplateName);

        if (isTemplateNameEmpty || isTemplateNameLong || isTemplateNameShort) {
            setTemplateNameErrorText(isTemplateNameEmpty ? constant.FIELD_BLANK : isTemplateNameLong ? constant.MAX_TEMPLATE_LEN_ERROR : isTemplateNameShort ? constant.MIN_TEMPLATE_LEN_ERROR : '')
            checkTemplateNameValidity = false;
        };
        if (event === constant.SAVE_AS && isSameTemplateName) {
            setTemplateNameErrorText(isSameTemplateName ? constant.UNIQUE_TEMPLATE_NAME : '')
            checkTemplateNameValidity = false;
        }
        if ((isDescEmpty || isDescShort || isDescLong)) {
            if (templateStatusTxt.toLowerCase().trim() !== constant.SAVED.toLowerCase() && isDescEmpty) {
                setDescErrorText(isDescEmpty ? constant.FIELD_BLANK : '')
                checkDescriptionValidity = false;
            }
            else if (!isDescEmpty && (isDescShort || isDescLong)) {
                setDescErrorText(isDescShort ? constant.MIN_DESC_LEN_ERROR : isDescLong ? constant.MAX_DESC_LEN_ERROR : '')
                checkDescriptionValidity = false;
            }


        }
        if (isStatusEmpty) {
            setStatusErrorText(isStatusEmpty ? constant.FIELD_BLANK : '')
        }
        if (templateStatusTxt.toLowerCase().trim() !== constant.SAVED.toLowerCase() && isSendgridTemplateTypeEmpty) {
            setTemplateTypeErrorText(isSendgridTemplateTypeEmpty ? constant.FIELD_BLANK : '')
        }
        if (templateStatusTxt.toLowerCase().trim() !== constant.SAVED.toLowerCase() && isSendgridTemplateEmpty) {
            setSendgridTemplateErrorText(isSendgridTemplateEmpty ? constant.FIELD_BLANK : '')
        }
        if (templateStatusTxt.toLowerCase().trim() !== constant.SAVED.toLowerCase() && isCategoryEmpty) {
            setTemplateCategoryErrorText(isCategoryEmpty ? constant.FIELD_BLANK : '')
        }

        if ((templateStatusTxt.toLowerCase().trim() === constant.SAVED.toLowerCase()) && checkTemplateNameValidity && checkDescriptionValidity && !isTemplateNameEmpty && !isStatusEmpty) {
            setFormValid(true);
            formStatus = true;
        }
        else if (templateStatusTxt.toLowerCase().trim() !== constant.SAVED.toLowerCase() && checkTemplateNameValidity
            && !isStatusEmpty && checkDescriptionValidity
            && !isSendgridTemplateTypeEmpty && !isSendgridTemplateEmpty && !isCategoryEmpty) {
            setFormValid(true);
            formStatus = true;
        }

        else if (event === constant.SAVE_AS && isSameTemplateName) {
            setFormValid(false);
            formStatus = false;
        }
        else {
            setFormValid(false);
            formStatus = false;
        }
        //console.log(formStatus)
        return formStatus
    }
    //

    //Submit/Update/Save As 3rdparty template details
    const SubmitExternalTemplateDetail = (action: string) => {
        var formStatus = false;
        formStatus = validateForm(action);
        if (formStatus === true) {
            var status: any
            if (action === 'Save As') {
                externalTemplateInputs.id = constant.DEFAULT_GUID;
            }
            if (externalTemplateInputs.id === undefined || externalTemplateInputs.id === null || externalTemplateInputs.id === '' || externalTemplateInputs.id === constant.DEFAULT_GUID) {
                status = campaignTemplatesUtils.submitThirdPartyTemplate(externalTemplateInputs).then((res) => {
                    var templateData = campaignTemplatesUtils.setTemplateDataModel(res)
                    setTemplateSubmitted(res)
                    setTemplateDetail(templateData)
                    externalTemplateId = res.data._id
                    if (res.status >= 200 && res.status < 300) {
                        externalTemplateInputs.id = res.data._id
                        setPrevTemplateName(externalTemplateInputs.templateName)
                        setExTemplateID(res.data._id)
                        dispatch(toggleFormSaved());
                        toast.success(constant.TEMPLATE_SUCCESS_MESSAGE, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        history.replace({ pathname: '', state: { entityId: res.data._id, type: '1' } })
                        if (action === constant.SAVE_AS) {
                            setSaveAsTriggered(true)
                        }

                        setFormSaved(true)
                        setTimeout(() => {
                            setIsCompleteSave(false);
                            setSaveAsTriggered(false);
                        }, 5000)
                    }
                    else {
                        setFormSaved(false)
                        toast.error(constant.ERROR_MESSAGE, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                    }

                }).catch(exception => {
                    setFormSaved(false)
                    if (exception !== undefined && (exception.data !== undefined || exception.data !== '')) {
                        if (exception.status === 400) {
                            if (exception.data.includes(label.UNIQUE_KEY)) {
                                toast.error(constant.UNIQUE_TEMPLATE_NAME, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: constant.AUTO_CLOSE
                                })
                            }
                            else {
                                toast.error(exception.data, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: constant.AUTO_CLOSE
                                })
                            };
                        }
                    }
                })
            }
            else {
                status = campaignTemplatesUtils.updateThirdPartyTemplate(externalTemplateInputs).then((res) => {
                    setTemplateSubmitted(res)
                    var templateData = campaignTemplatesUtils.setTemplateDataModel(res)
                    setIsCompleteSave(true);
                    setFormSaved(true)
                    setTemplateDetail(templateData)
                    if (res.status >= 200 && res.status < 300) {
                        setPrevTemplateName(externalTemplateInputs.templateName)
                        setExTemplateID(externalTemplateInputs.id)
                        dispatch(toggleFormSaved());
                        toast.success(constant.TEMPLATE_UPDATE_SUCCESS_MESSAGE, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                        setIsCompleteSave(false);
                        setSaveAsTriggered(false);

                    }
                    else {
                        setFormSaved(false)
                        toast.error(constant.ERROR_MESSAGE, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });

                    }
                }).catch(exception => {
                    setFormSaved(false)
                    if (exception !== undefined && (exception.data !== undefined || exception.data !== '')) {
                        if (exception.status === 400) {
                            if (exception.data.includes(label.UNIQUE_KEY)) {
                                toast.error(constant.UNIQUE_TEMPLATE_NAME, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: constant.AUTO_CLOSE
                                })
                            }
                            else {
                                toast.error(exception.data, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: constant.AUTO_CLOSE
                                })
                            };
                        }
                    }
                })
            }
        }
    }
 


    //Delete query, only by user if he has access for delete the query.
    function allowDelete() {
        if (externalTemplateId !== undefined && parseInt(externalTemplateId) !== NaN) {
            campaignTemplatesUtils.deleteThirdPartyTemplate(externalTemplateId).then((res) => {
                setShowDeleteConfirmation(false)
                if (res.status !== undefined && (res.status >= 200 && res.status < 300)) {
                    toast.success(constant.TEMPLATE_DELETE_SUCCESS_MESSAGE, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                    routeChange();

                }

                else {
                    toast.error(constant.ERROR_MESSAGE_LOAD, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                }

            }).catch(exception => {
                setShowDeleteConfirmation(false)
                if (exception !== undefined && (exception.data !== undefined || exception.data !== '')) {
                    if (exception.status === 400) {
                        toast.error(exception.data, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                    }
                    else {
                        setShowDeleteConfirmation(false)
                        toast.error(constant.DEFAULT_ERROR, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: constant.AUTO_CLOSE
                        });
                    }
                }
                else {
                    toast.error(constant.DEFAULT_ERROR, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: constant.AUTO_CLOSE
                    });
                }
                setShowDeleteConfirmation(false)
            })

        }
        else {
            setShowDeleteConfirmation(false)
            toast.error(constant.CAN_DELETE_TEMPLATE, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });
        }
    }
   
    //Called  when page redirect
    const redirect = () => {
        let path = '/Campaign/3rdpartytemplate';
        history.push(path);
        myContext.formSavedStatus = true;
        myContext.noteSavedStatus = true
    }

    const routeChange = () => {
        if (isFormSaved && myContext.noteSavedStatus) {
            redirect();
        }
        else {
            setShowExitConfirmation(true)
        }
    }

    //Get query info function to load query detail
    useEffect(() => {
        get3rdPartyTemplateInfo();
        highlightMenu();
    }, [window.onloadeddata])
 

    function highlightMenu() {
        var tabLink = document.querySelectorAll(".cstHeaderMenu");
        tabLink.forEach(function (item) {
            item.classList.remove("active");
        });

        tabLink.forEach(function (item) {
            if (item.id === '3rdpartytemplate') {
                item.classList.add("active");
            }
        });
        var activeMenu = '3rdpartytemplate';
        setHighlightedMenu(activeMenu)
    }


    return (

        <>
            <Row>
                <Column lg={12} md={12}>
                    <Button icon='up-indication' outlined={true} onClick={routeChange} color="black" >
                    </Button>
                    {externalTemplateInputs !== undefined ? externalTemplateInputs.templateName : ''}
                </Column>
            </Row>
            <Row className="bg-light">
                <Column lg={12} md={12}>

                    <ErrorBoundary FallbackComponent={FallbackComponent}>
                        <Column lg={12}>
                            <Row>
                                <Column lg={9} md={9}>
                                    <Panel collapsible={false}
                                        id={'QueryDetails'}
                                        title={label.TEMPLATE_DETAILS}
                                        className={'cstPanel'}
                                        cardBodyClassName={'p-0'}
                                        collapsed={false}>
                                        <Column lg={12} md={12}>
                                            <Container fluid>
                                            <Row>
                                                <Column lg={12} className='cstDetailSection'>
                                                    <Row>
                                                        <Column lg={12} className={isTemplateNameEmpty || isTemplateNameShort || isTemplateNameLong || isTemplateNameSame ? 'feedback-row' : ''}>
                                                            <TextInputWithLabelInline
                                                                name='txtTemplateName'
                                                                label={label.TEMPLATE_NAME}
                                                                placeholder={label.NAME}
                                                                value={externalTemplateInputs !== undefined ? externalTemplateInputs.templateName : ''}
                                                                isEditable={isformEditable}
                                                                type='text'
                                                                labelClass='inlineLable mt-3 mb-0'
                                                                labelColClassName='bg-light'
                                                                onChange={(event) => onFieldChange('templateName', event.currentTarget.value)}
                                                                tabIndex={1}
                                                                lglabel={2}
                                                                mdlabel={2}
                                                                lgText={10}
                                                                mdText={10}
                                                                isRequired
                                                                isInvalid={isTemplateNameEmpty || isTemplateNameShort || isTemplateNameLong || isTemplateNameSame}
                                                                isEmpty={isTemplateNameEmpty}
                                                                isSame={isTemplateNameSame}
                                                                isTooLong={isTemplateNameLong}
                                                                isTooShort={isTemplateNameShort}
                                                                errorText={strTemplateNameError}
                                                                className=' my-2'
                                                                maxLength={150}
                                                            />
                                                        </Column>
                                                    </Row>

                                                    <Row className='' style={!isDescriptionEmpty && !isDescriptionShort ? {} : {}} >
                                                        <Column lg={2} md={2} className='bg-light'><Label text={label.TEMPLATE_DESCRIPTION} isRequired={isRequired} className={'inlineLable mt-3 mb-0'} /></Column>
                                                        <Column lg={10} md={10} className={isDescriptionShort || isDescriptionEmpty ? '-left feedback-row' : '-left'}>
                                                            <TextField
                                                                name='txtDescription'
                                                                title={externalTemplateInputs.description}
                                                                value={externalTemplateInputs.description}
                                                                disabled={false}
                                                                style={{ fontSize: "14px" }}
                                                                placeholder={label.DESCRIPTION}
                                                                onChange={(event) => onFieldChange('description', event.currentTarget.value)}
                                                                tabIndex={2}
                                                                isInvalid={isDescriptionShort || isDescriptionEmpty}
                                                                className='cstCollection my-2'
                                                                isTooShort={isDescriptionShort}
                                                                isEmpty={isDescriptionEmpty}
                                                                errorText={strDescError}
                                                                maxLength={250}
                                                                isRequired={isRequired}
                                                            />
                                                        </Column>
                                                    </Row>

                                                    <Row>
                                                        <Column lg={12} className={isStatusValid ? 'feedback-row' : ''}>


                                                           <DropDownWithLabelInline
                                                                id='txStatus'
                                                                label={label.STATUS}
                                                                options={templateStatusOptions !== undefined ? templateStatusOptions : []}
                                                                labelClass='inlineLable mt-3 mb-0'
                                                                inputProps={{ className: 'inputStyle cstFilterInput texbox-input' }}
                                                                defaultSelected={templateStatusOptions !== undefined ? templateStatusOptions.filter(({ value }) => value === (externalTemplateInputs === undefined ? '' : externalTemplateInputs.status === undefined ? '' : externalTemplateInputs.status === null ? '' : externalTemplateInputs.status.toUpperCase())) : []}
                                                                onChange={(values) => {
                                                                    // if (values[0] !== constant.EXTERNAL_TEMPLATE_INACTIVE) {
                                                                    var statusLabel = templateStatusOptions.find((option) => option.value === values[0]) === undefined ? '' : templateStatusOptions.find((option) => option.value === values[0]).label
                                                                    setTemplateStatusTxt(statusLabel)
                                                                    setIsRequired(statusLabel === constant.SAVED ? false : true)
                                                                    onFieldChange('status', values[0])
                                                                    // }
                                                                }}

                                                                labelSize={2}
                                                                dropDownSize={4}
                                                                dropdownDivClass={''}
                                                                tabIndex={3}
                                                                isRequired
                                                                isInvalid={isStatusValid}
                                                                invalidWarning={stStatusError}
                                                                placeholder={label.PLEASE_SELECT}
                                                            />
                                                        </Column>
                                                    </Row>
                                                    <Row >
                                                        <Column lg={12} className={isTemplateTypeEmpty || isSendgridTemplateSelected ? 'cst-error-row-style' : ''}>
                                                            <Row>
                                                                <Column sm={2} className={'-left bg-light'}>
                                                                    <Label className="inlineLable mt-3 mb-0 form-label" isRequired={isRequired} title='Template Type' htmlFor='txType' text={constant.TYPE_SENDGRID_TEMPLATE} />
                                                                </Column>
                                                                <Column lg={10} md={10} className={'no-boder-bottom'} >
                                                                    <Row>
                                                                        <Column lg={5} className={'-left no-boder-bottom'}>
                                                                            <Select
                                                                                id='txType'
                                                                                className='my-2'
                                                                                options={templateType !== undefined ? templateType : []}
                                                                                inputProps={{ className: 'inputStyle cstFilterInput texbox-input' }}
                                                                                defaultSelected={templateType !== undefined ? templateType.filter(({ value }) => value === (externalTemplateInputs === undefined ? '' : externalTemplateInputs.type === undefined ? '' : externalTemplateInputs.type === null ? '' : externalTemplateInputs.type.toUpperCase())) : []}
                                                                                disabled={!isformEditable}
                                                                                tabIndex={5}
                                                                                onChange={(values) => {
                                                                                    var typeLabel = templateType.find((option) => option.value === values[0]) === undefined ? '' : templateType.find((option) => option.value === values[0]).label
                                                                                    setTypeId(typeLabel === undefined ? '' : typeLabel)
                                                                                    onFieldChange('type', values[0])
                                                                                    //getSendgridTemplateonType(typeLabel)
                                                                                    //onFieldChange('typeName',typeLabel)
                                                                                    //getSendgridTemplateonType(typeLabel)
                                                                                }}
                                                                                multiple={false}
                                                                                isInvalid={isTemplateTypeEmpty}
                                                                                placeholder={label.PLEASE_SELECT}
                                                                                invalidWarning={stTemplateTypeError}
                                                                            />
                                                                        </Column>
                                                                        <Column lg={5} className={'no-boder-bottom'}>
                                                                            <Select
                                                                                id="ddlSendgridTemplate"
                                                                                className='my-2'
                                                                                options={sendgridTemplateOptions !== undefined ? sendgridTemplateOptions : []}
                                                                                inputProps={{ className: 'inputStyle cstFilterInput texbox-input' }}
                                                                                defaultSelected={sendgridTemplateOptions !== undefined ? sendgridTemplateOptions.filter(({ value }) => value === (externalTemplateInputs === undefined ? '' : externalTemplateInputs.sendGridTemplateId === undefined ? '' : externalTemplateInputs.sendGridTemplateId === null ? '' : externalTemplateInputs.sendGridTemplateId)) : []}
                                                                                onChange={(values) => {
                                                                                    onFieldChange('sendGridTemplateId', values[0])
                                                                                }}
                                                                                isInvalid={isSendgridTemplateSelected}
                                                                                tabIndex={5}
                                                                                placeholder={label.PLEASE_SELECT}
                                                                                invalidWarning={stSendgridTemplateError}
                                                                                disabled={!isformEditable}
                                                                            />
                                                                        </Column>
                                                                    </Row>
                                                                </Column>
                                                            </Row>
                                                        </Column>
                                                    </Row>

                                                    <Row>
                                                        <Column lg={12} className={isCategorySelected ? 'feedback-row' : ''}>
                                                            <DropDownWithLabelInline
                                                                id='txCategory'
                                                                label={label.CATEGORY}
                                                                options={campaignCategoriesOptions !== undefined ? campaignCategoriesOptions : []}
                                                                labelClass='inlineLable mt-3 mb-0'
                                                                labelColClassName='bg-light'
                                                                inputProps={{ className: 'inputStyle cstFilterInput texbox-input' }}
                                                                defaultSelected={campaignCategoriesOptions !== undefined ? campaignCategoriesOptions.filter(({ value }) => value.toUpperCase() === (externalTemplateInputs === undefined ? '' : externalTemplateInputs.category === undefined ? '' : externalTemplateInputs.category === null ? '' : externalTemplateInputs.category.toUpperCase())) : []}
                                                                onChange={(values) => onFieldChange('category', values[0])}
                                                                tabIndex={6}
                                                                labelSize={2}
                                                                dropDownSize={4}
                                                                dropdownDivClass={''}
                                                                isInvalid={isCategorySelected}
                                                                multiple={false}
                                                                placeholder={label.PLEASE_SELECT}
                                                                invalidWarning={stCategoryError}
                                                                isRequired={isRequired}
                                                                disabled={!isformEditable}
                                                            />
                                                        </Column>
                                                    </Row>

                                                    <Row>
                                                        <Column lg={2} md={2} className='bg-light'><Label text={label.UPDATED} className={'inlineLable mt-3 mb-0'} /></Column>
                                                        <Column lg={10} md={10} className='-left'>
                                                            <Row>
                                                                <Column lg={6} md={6}>
                                                                    <Label text={label.UPDATED_BY + label.COLON} className={'inlineLable mt-3 mb-0 -left'} />&nbsp;
                                                                    <Label text={externalTemplateInputs !== undefined ? externalTemplateInputs.changedBy : ''} className={'inlineLable mt-3 mb-0 querylabel'} />
                                                                </Column>
                                                                <Column lg={6} md={6}>
                                                                    <Label text={label.UPDATED_ON + label.COLON} className={'inlineLable mt-3 mb-0'} />&nbsp;
                                                                    <Label text={externalTemplateInputs.changedDate !== null ? moment(externalTemplateInputs.changedDate).format("MM/DD/YYYY") : ''} className={'inlineLable mt-3 mb-0 querylabel'} />
                                                                </Column>
                                                            </Row>
                                                            {/* </Column>
                                                        </Row> */}
                                                        </Column>
                                                    </Row>

                                                    <Row className='my-3'>
                                                        <Column lg={12}>

                                                            <div className='d-flex justify-content-between'>
                                                                <div className='left'>
                                                                    <button className="btn btn-outline-primary" disabled={!userPermitToSave} onClick={(event) => { SubmitExternalTemplateDetail('Save') }}>{label.SAVE.toUpperCase()}</button>&nbsp; &nbsp;
                                                                    {isUserPermitToViewCopyCampaignTemplate && <button className="btn btn-outline-tertiary" disabled={!isUserPermitToCopyCampaignTemplate} onClick={(event) => { SubmitExternalTemplateDetail('Save As') }}>{label.SAVE_AS.toUpperCase()}</button>} &nbsp; &nbsp;
                                                                    <button className="btn btn-outline-tertiary" disabled={!userRestrictToDelete} type='button' onClick={() => { setShowDeleteConfirmation(true) }} >{label.DELETE.toUpperCase()}</button> &nbsp; &nbsp;
                                                                </div>
                                                                <div className='right'>
                                                                    <button className="btn btn-outline-tertiary" onClick={routeChange} >{label.CANCEL.toUpperCase()}</button> &nbsp;
                                                                    {/* <button id={'btnInactive'} className="btn btn-outline-tertiary" onClick={(event) => { InactiveTemplate(event) }} >{inactiveButtonText === '' ? label.INACTIVATE.toUpperCase() : inactiveButtonText}</button> &nbsp; &nbsp; */}
                                                                </div>
                                                            </div>
                                                        </Column>
                                                    </Row>


                                                </Column>


                                            </Row>
                                            </Container>
                                            
                                        </Column>
                                        {/* </Form>   */}
                                    </Panel>
                                </Column>
                                <Column lg={3} className='cstNotePanel'>
                                    <NotesPanel collapsible selectId={externalTemplateInputs.id} subject={ab_subject}>

                                    </NotesPanel>
                                </Column>
                            </Row>
                        </Column>
                        <br />
                    </ErrorBoundary>
                    {/* : <p>You are not authorised user for this application. Please contact admin</p> */}
                </Column>

            </Row>
            <ConfirmModal
                modaltitle={label.CONFIRM}
                showConfirmation={showExitConfirmation}
                setConfirmation={(showExitConfirmation) => setShowExitConfirmation(showExitConfirmation)}
                body={constant.CONFIRM_EXIT}
                closeButtonClick={() => setShowExitConfirmation(false)}
                successButtonClick={() => redirect()}
                closeButtonLabel={label.NO.toUpperCase()}
                successButtonLabel={label.YES.toUpperCase()}
            />
            <ConfirmModal
                modaltitle={label.DELETE_TEMPLATE}
                showConfirmation={showDeleteConfirmation}
                setConfirmation={(showDeleteConfirmation) => setShowDeleteConfirmation(showDeleteConfirmation)}
                body={constant.CONFIRM_TEMPLATE_DELETION}
                closeButtonClick={() => setShowDeleteConfirmation(false)}
                successButtonClick={() => allowDelete()}
                closeButtonLabel={label.NO.toUpperCase()}
                successButtonLabel={label.YES.toUpperCase()}
            />
            {/* <Modal
                verticallyCentered={true}
                title={"Delete Template"}
                show={showDeleteConfirmation}
                toggle={() => { setShowDeleteConfirmation(!showDeleteConfirmation) }}
                body={constant.CONFIRM_TEMPLATE_DELETION}
                closeButton={{
                    size: "small",
                    icon: 'cancel',
                    iconStyle: { color: "gray" },
                    outlined: true,
                    color: 'link',
                    onClick() { setShowDeleteConfirmation(false) },
                    children: <span style={{ color: "gray" }}>{label.CANCEL.toLocaleUpperCase()}</span>
                }}
                successButton={{
                    size: "small",
                    icon: "check",
                    iconStyle: { color: "177db8" },
                    outlined: true,
                    color: 'link',
                    onClick() { allowDelete() },
                    children: <span style={{ color: "#177db8" }}>{label.DELETE.toLocaleUpperCase()}</span>
                }}
                buttonsAlignment={"right"} /> */}
        </>

    )
}

export default ExternalTemplateDetail