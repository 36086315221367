import React, { useEffect, useState } from "react";
import { CustomerCompanyContact } from "../model/CustomerCompanyContactModel";
import { Card, FloatingLabel, Form, Row } from "react-bootstrap";
import { Column, Select } from "../../components";
import TextInputWithLabelInline from "../../shared/components/input/TextInputWithLabelInline";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
import { ConfirmModal } from "../../shared/components/confirmModal";
import * as label  from '../../shared/util/label';
import * as constant from '../../shared/util/constant';
import FormattedInputWithLableInline, { InputFormatType } from "../../shared/components/input/formatted-input/FormattedInputWithLableInline";
import { SelectOption } from "../../shared/components/input";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import Button from 'react-bootstrap/Button';

export interface ICustomerCompanyContactView
{
    index: number,
    contact : CustomerCompanyContact,
    state : SelectOption[],
    onFieldChange : (collectionPropName : string, collectionEntityIdentifierPropName : string, collectionEntityIdentifierValue : any, propName: string, value: string | Date|boolean) => void,
    onDelete : (dependent : CustomerCompanyContact) => void,
    formValidations : any
    isEditable : boolean,
    isReset : boolean,
    divToScroll : any
}

const CustomerCompanyContactView : React.FunctionComponent<ICustomerCompanyContactView> = (props) => {

    const cont = props.contact;
    const formValidations = props.formValidations;
    const [contact, setContact] = useState<CustomerCompanyContact>({});
    const [formValidation, setFormValidation] = useState<any>({});
    const state = props.state??[];
    // TODO : Remove once modal handler implemented
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [initialContact, setInitialDependent] = useState<CustomerCompanyContact>(cont);
    const modalContext = useConfirmationModalContext();
    const removeContact = () => {
        setShowDeleteConfirmation(false);
        props.onDelete(contact);
    }

    useEffect(() => {
        setContact(initialContact);
    }, [props.isReset]);

    useEffect(() => {
        setContact(cont);
    }, []);

    useEffect(() => {
        setFormValidation(formValidations ? formValidations : {});
    },[formValidations]);
    
    const states = props.state??[];

    //const isEditable = true;
    

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        if(propName === 'workPhoneExt'){
            if(value.toString().length > 5)
            return false;
            else{
            value= value.toString().replace(/[^0-9]/g, "")
            }
        }
        
        setContact({
            ...contact,
            [propName]: value,
          });
       props.onFieldChange('customerContacts', 'id', contact.id,  propName, value);
    }

    const onDeleteContact = async() => {
        const result = await modalContext.showConfirmation(constant.CONFIRM_CONTACT_DELETE)
        if(result){
            removeContact();
        }

    }

    return (
        <>
            <div className="cd-main-container mb-2" ref={props.divToScroll}>
                <Card>
                    <Card.Header>
                        <div className="panel-title d-flex align-items-center">
                        {props.index + 1}. Company Contact
                            <Button
                                onClick={onDeleteContact}
                                variant="light"
                                size="sm"
                                className="ms-auto"
                                disabled={!props.isEditable}
                                id="btnContactDel"
                            >
                                <i className="bi bi-trash-fill"></i>
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Column lg={8} md={8}
                                className={formValidation['contactName']?.isValid == false ? 'feedback-row' : ''}>
                                <FloatingLabel
                                    controlId="contactName"
                                    label={<>
                                        {label.CustomerScreentLables.NAME}
                                        <span className="text-danger">*</span>
                                    </>}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                        name="contactName"
                                        value={contact.contactName ?? ""}
                                        onChange={(event) => onEntityFieldChange('contactName', event.currentTarget.value)}
                                        disabled={!props.isEditable}
                                        required
                                        maxLength={75}
                                        isInvalid={formValidation['contactName']?.isValid == false}
                                    />
                                    {formValidation['contactName']?.isValid == false && (
                                        <Form.Control.Feedback type="invalid">
                                            {formValidation['contactName']?.warningText}
                                        </Form.Control.Feedback>
                                    )}
                                </FloatingLabel>
                            </Column>

                            <Column lg={4} md={4}
                                className={formValidation['title']?.isValid == false ? 'feedback-row' : ''}>
                                <FloatingLabel
                                    controlId="title"
                                    label={<>
                                        {label.CustomerScreentLables.TITLE}
                                        <span className="text-danger">*</span>
                                    </>}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                        name="title"
                                        value={contact.title ?? ""}
                                        onChange={(event) => onEntityFieldChange('title', event.currentTarget.value)}
                                        disabled={!props.isEditable}
                                        required
                                        maxLength={75}
                                        isInvalid={formValidation['title']?.isValid == false}
                                    />
                                    {formValidation['title']?.isValid == false && (
                                        <Form.Control.Feedback type="invalid">
                                            {formValidation['title']?.warningText}
                                        </Form.Control.Feedback>
                                    )}
                                </FloatingLabel>
                            </Column>


                        </Row>

                        <Row>
                            <Column lg={6} md={6}
                                className={formValidation['email']?.isValid == false ? 'feedback-row' : ''}>
                                <FloatingLabel
                                    controlId="email"
                                    label={label.CustomerScreentLables.EMAIL}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder=" "
                                        name="email"
                                        value={contact.email ?? ""}
                                        onChange={(event) => onEntityFieldChange('email', event.currentTarget.value)}
                                        disabled={!props.isEditable}
                                        maxLength={100}
                                        isInvalid={formValidation['email']?.isValid == false}
                                    />
                                    {formValidation['email']?.isValid == false && (
                                        <Form.Control.Feedback type="invalid">
                                            {formValidation['email']?.warningText}
                                        </Form.Control.Feedback>
                                    )}
                                </FloatingLabel>
                            </Column>
                            <Column lg={6} md={6}>
                                <FloatingLabel
                                    controlId="webAddr"
                                    label={label.CustomerScreentLables.WEB_ADDRESS}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                        name="webAddr"
                                        value={contact.webAddr ?? ""}
                                        onChange={(event) => onEntityFieldChange('webAddr', event.currentTarget.value)}
                                        disabled={!props.isEditable}
                                        maxLength={150}
                                    />
                                </FloatingLabel>
                            </Column>
                        </Row>

                        <Row>
                            {/* Left side - 5 columns */}
                            <Column lg={5} md={5}
                                className={formValidation['workPhone']?.isValid == false ? 'feedback-row' : ''}>
                                <div className="d-flex gap-0">
                                    <div className="flex-grow-1">
                                        <FloatingLabel
                                            controlId="workPhone"
                                            label={<>
                                                {label.CustomerScreentLables.WORK_PHONE}
                                                <span className="text-danger">*</span>
                                            </>}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="tel"
                                                placeholder=" "
                                                name="workPhone"
                                                className="remove-right-radius"
                                                value={contact.workPhone ?? ""}
                                                onChange={(event) => onEntityFieldChange('workPhone', event.currentTarget.value)}
                                                disabled={!props.isEditable}
                                                required
                                                maxLength={15}
                                                isInvalid={formValidation['workPhone']?.isValid == false}
                                            />
                                            {formValidation['workPhone']?.isValid == false && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formValidation['workPhone']?.warningText}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                    </div>

                                    <div style={{ width: '100px' }}>
                                        <FloatingLabel
                                            controlId="workPhoneExt"
                                            label={label.CustomerScreentLables.EXT}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder=" "
                                                name="workPhoneExt"
                                                className="remove-left-radius"
                                                value={contact.workPhoneExt ?? ""}
                                                onChange={(event) => onEntityFieldChange('workPhoneExt', event.currentTarget.value)}
                                                disabled={!props.isEditable}
                                                maxLength={5}
                                            />
                                        </FloatingLabel>
                                    </div>
                                </div>
                            </Column>
                            {/* Right side - 7 columns */}
                            <Column lg={7} md={7}>
                                <Row>
                                    <Column lg={6} md={6}>
                                        <FloatingLabel
                                            controlId="phone"
                                            label={label.CustomerScreentLables.CELL_PHONE}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="tel"
                                                placeholder=" "
                                                name="phone"
                                                value={contact.mobile ?? ""}
                                                onChange={(event) => onEntityFieldChange('phone', event.currentTarget.value)}
                                                disabled={!props.isEditable}
                                                maxLength={15}
                                            />
                                        </FloatingLabel>
                                    </Column>

                                    <Column lg={6} md={6}
                                        className={formValidation['homePhone']?.isValid == false ? 'feedback-row' : ''}>
                                        <FloatingLabel
                                            controlId="homePhone"
                                            label={label.CustomerScreentLables.HOME_PHONE}
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                type="tel"
                                                placeholder=" "
                                                name="homePhone"
                                                value={contact.homePhone ?? ""}
                                                onChange={(event) => onEntityFieldChange('homePhone', event.currentTarget.value)}
                                                disabled={!props.isEditable}
                                                maxLength={15}
                                                isInvalid={formValidation['homePhone']?.isValid == false}
                                            />
                                            {formValidation['homePhone']?.isValid == false && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formValidation['homePhone']?.warningText}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                        {/* Address Line 1 */}
                        <Row>
                            <Column lg={8} md={8}>
                                <FloatingLabel
                                    controlId="address1"
                                    label={label.CustomerScreentLables.ADDRESS_1}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                        name="address1"
                                        value={contact.address1 ?? ""}
                                        onChange={(event) => onEntityFieldChange('address1', event.currentTarget.value)}
                                        disabled={!props.isEditable}
                                        maxLength={75}
                                    />
                                </FloatingLabel>
                            </Column>

                            <Column lg={4} md={4}>
                                <FloatingLabel
                                    controlId="address2"
                                    label={label.CustomerScreentLables.ADDRESS_2}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                        name="address2"
                                        value={contact.address2 ?? ""}
                                        onChange={(event) => onEntityFieldChange('address2', event.currentTarget.value)}
                                        disabled={!props.isEditable}
                                        maxLength={75}
                                    />
                                </FloatingLabel>
                            </Column>
                        </Row>

                        {/* Address Line 2 */}
                        <Row>
                            <Column lg={5} md={5}>
                                <FloatingLabel
                                    controlId="city"
                                    label={label.CustomerScreentLables.CITY}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                        name="city"
                                        value={contact.city ?? ""}
                                        onChange={(event) => onEntityFieldChange('city', event.currentTarget.value)}
                                        disabled={!props.isEditable}
                                        maxLength={30}
                                    />
                                </FloatingLabel>
                            </Column>

                            <Column lg={3} md={3}>
                            <Select
                                id="state"
                                controlId="state"
                                className='mb-3'
                                label={label.CustomerScreentLables.STATE}
                                options={states}
                                defaultSelected={states.filter(option =>
                                    option.value === contact.state
                                )}
                                onChange={(values) => onEntityFieldChange('state', values[0] as string)}
                                disabled={!props.isEditable}
                                tabIndex={9}
                                isInvalid={formValidation['state']?.isValid == false}
                                invalidWarning={formValidation['state']?.warningText}
                            />
                                
                            </Column>

                            <Column lg={2} md={2}
                                className={formValidation['zip']?.isValid == false ? 'feedback-row' : ''}>
                                <FloatingLabel
                                    controlId="zip"
                                    label={label.CustomerScreentLables.ZIP}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                        name="zip"
                                        value={contact.zip ?? ""}
                                        onChange={(event) => onEntityFieldChange('zip', event.currentTarget.value)}
                                        disabled={!props.isEditable}
                                        maxLength={9}
                                        isInvalid={formValidation['zip']?.isValid == false}
                                    />
                                    {formValidation['zip']?.isValid == false && (
                                        <Form.Control.Feedback type="invalid">
                                            {formValidation['zip']?.warningText}
                                        </Form.Control.Feedback>
                                    )}
                                </FloatingLabel>
                            </Column>

                            <Column lg={2} md={2}>
                                <FloatingLabel
                                    controlId="county"
                                    label={label.CustomerScreentLables.COUNTY}
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                        name="county"
                                        value={contact.county ?? ""}
                                        onChange={(event) => onEntityFieldChange('county', event.currentTarget.value)}
                                        disabled={!props.isEditable}
                                        maxLength={30}
                                    />
                                </FloatingLabel>
                            </Column>
                        </Row>
                    </Card.Body>
                </Card>
               
            </div>
        </>
    );
}

export default CustomerCompanyContactView;