import { useEffect, useState } from "react";
import { getRefiTodoList } from "./DocuMateRefiService";
import { getRefiGridColumns } from "./RefiTodoListService";
import { FallbackComponent } from "../../shared/components/FallbackComponent";
import { ErrorBoundary} from 'react-error-boundary';
import Grid from "../../components/components/Grid/Grid";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ConfirmModal } from "../../shared/components/confirmModal";
import * as documateLabel from '../shared/util/docuMateLabel';
import { Alert, Card, Container, Row } from "react-bootstrap";
import { Column } from "../../components";
import { RefiTodoListDto } from "../model/RefiiTodoListDto";

const RefiTodoList = () => {
    const [todoList, setTodoList] = useState<RefiTodoListDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalBody, setModalBody] = useState(null);
    const [currentId, setCurrentId] = useState('');
    const [LIMIT] = useState(10);
    const [currLocked, setCurrLocked] = useState(0);
    const [lockedDocs, setLockedDocs] = useState<RefiTodoListDto[]>([]);

    useEffect(() => {
        setIsLoading(true);
        getRefiTodoList().then((data) => {
            setTodoList(data);
            setCurrLocked(data.filter((doc) => doc.isLocked).length);
            setLockedDocs(data.filter((doc) => doc.isLocked));
            setIsLoading(false);
        });
    }, []);

    const toggleLockDocument = (id: string, isLocked: boolean) => {
        if(currLocked >= LIMIT && !isLocked) {
            toast.warn('You have reached the maximum number of documents to be reviewed for Refi Automated Process.');
            return;
        }
        todoList.find((doc) => doc.refiRequestId === id).isLocked = !isLocked;
        setCurrLocked(todoList.filter((doc) => doc.isLocked).length);
        setLockedDocs(fetchLockedDocuments());
    }

    const fetchLockedDocuments = () => {
        const lockedDocuments = todoList.filter((doc) => doc.isLocked);
        return lockedDocuments;
    };

    const handleClosePopup = () => {
        setShowConfirmModal(false);
    }

    const handleConfirmModal = () => {
        if(currentId) {
            history.push({
                pathname: `/documate-refi/processing`,
                state: { id: currentId, fileName: todoList.find((doc) => doc.refiRequestId === currentId)?.fileName, lockedDocs: lockedDocs }
            });
        }
        setShowConfirmModal(false);
      }

      const getConfirmModalBody = (dateTime) => (
        <>   
        The image is <strong>locked</strong> by user(s): <strong>"{'John Doe'}"</strong> on <strong>"{dateTime}."</strong> Would you like to open?
        </>
      );

    const handleDocumentClick = (id: string) => {
        let currentDocument = todoList.find((doc) => doc.refiRequestId === id);
        if( currentDocument && currentDocument?.isLocked) {
            setModalBody(getConfirmModalBody(currentDocument?.dateInitiated));
            setCurrentId(id);
            setShowConfirmModal(true);
        }
        else {
            history.push({
                pathname: `/documate-refi/processing`,
                state: { id: id, fileName: todoList.find((doc) => doc.refiRequestId === id)?.fileName, lockedDocs: lockedDocs }
        });
    }
    }

    const gridColumns = getRefiGridColumns(handleDocumentClick, toggleLockDocument);
    
    return (
        <>
            <ErrorBoundary FallbackComponent={FallbackComponent}>
                
                <Container fluid>
                    <Row>
                        <Column lg={12} md={12}>
                        <Alert variant="info" className="p-2">
                    Selected {currLocked} of {LIMIT} documents of {todoList.length} records
                </Alert>
                            <Card>
                                <div className='documate-todoList-main-div'>
                                    {!isLoading &&
                                        <Grid
                                            data={todoList}
                                            showFilters={true}
                                            id={'refiToDoListGrid'}
                                            retainGridState={false}
                                            defaultPageSize={10}
                                            columns={gridColumns}
                                            hiddenColumns={['selection']}
                                            sortDefault={[]}
                                            grdTableContainerClass="p-0 grd-table-container"
                                            grdTabelClass="grd-table table-striped table-hover table-sm"
                                            zindexHeader={10000} />
                                    }
                                </div>
                            </Card>

                        </Column>
                    </Row>
                </Container>

                <ConfirmModal
                    modaltitle={documateLabel.CONFIRM}
                    showConfirmation={showConfirmModal}
                    setConfirmation={(isModalOpen) => setShowConfirmModal(isModalOpen)}
                    body={modalBody}
                    closeButtonClick={() => handleClosePopup()}
                    successButtonClick={() => handleConfirmModal()}
                    closeButtonLabel={documateLabel.CONFIRM_NO.toUpperCase()}
                    successButtonLabel={documateLabel.CONFIRM_YES.toUpperCase()}
                />
            </ErrorBoundary>
        </>
    );
}
export default RefiTodoList;