import { LookupData } from "../../Models/LoookupData";
import LookupService from "../../Services/LookupService";
import { SelectOption } from "../../shared/components/input";
import Customer from "../model/Customer";
import * as constant from '../../shared/util/constant';
import { canDeleteCustomer, createCustomer, deleteCustomer, fetchCustomer, updateCustomer } from "../services/CustomerAPI";
import validator from 'validator';

class CustomerDetailService 
{
    private static genderList = [{
        label : 'Male',
        value : 'M'
    },
    {
        label : 'Female',
        value : 'F'
    }];

    public fetchCustomerById = async (id : string) => {
        var customerData= await fetchCustomer( id );
        customerData.customerNotes = customerData.customerNotes.sort((a,b) =>  b.changedDate.localeCompare(a.changedDate));
        return customerData;
    }

    public saveCustomer = async (customerData : Customer) : Promise<Customer> => {
        let custToUpdate = this.getCustomerToUpdate(customerData);
        
        let apiResponse : Customer = null;
        if (this.isExistingCustomer(custToUpdate)) {
            apiResponse = await updateCustomer(custToUpdate);
        }
        else {
            apiResponse = await createCustomer(custToUpdate);
        }
        
        return apiResponse;
    }

    public canDeleteCustomer = async (id: string): Promise<string> => {
        let apiResponse = await canDeleteCustomer(id);
        return apiResponse;
    }

    public deleteCustomer = async (id : string) : Promise<string> => {
        let apiResponse  = await deleteCustomer(id);
        return apiResponse;
    }

    public isExistingCustomer = (customer : Customer) : boolean => {
        if (!customer) {
            return false;
        }

        if (!customer.id) {
            return false;
        }

        return true;
    }

    public fetchComponentInitData = async () => {
        let maritalStatusList = await LookupService.fetchMaritalStatuses();
        let industryList = await LookupService.fetchIndustries();
        let occupancyList = await LookupService.fetchOccupancies();
        let relationshipList = await LookupService.fetchRelationships();
        let countryList = await LookupService.fetchCountries();
        let stateUSList = await LookupService.fetchUSStates();
        let stateUSOptions = stateUSList ? stateUSList.map(item => {
            return {
                label: item.lookupValue,
                value: item.lookupCode,
                isInactive: false
            }
        })  : [];
        let stateCanadaList = await LookupService.fetchCanadaStates();
        let stateCanadaOptions = stateCanadaList ? stateCanadaList.map(item => {
            return {
                label: item.lookupValue,
                value: item.lookupCode,
                isInactive: false
            }
        })  : [];
        let stateList = await LookupService.fetchState();
        let stateOptions = stateList ? stateList.map(item => {
            return {
                label: item.lookupValue,
                value: item.lookupCode,
                isInactive: false
            }
        })  : [];
        let agencyList = await LookupService.fetchAgencies();
        let agencyOptions = agencyList ? agencyList.map(item => {
                return {
                    label: item.lookupValue,
                    value: item.id,
                    isInactive: false
                }
            })  : [];

        return {
            maritalStatusList : this.mapToSelectOptions(maritalStatusList),
            industryList : this.mapToSelectOptions(industryList),
            occupancyList : this.mapToSelectOptions(occupancyList),
            relationshipList : this.mapToSelectOptions(relationshipList),
            stateList :stateOptions,
            agencyList : agencyOptions,
            countryList: this.mapToSelectOptions(countryList),
            stateUSList: stateUSOptions,
            stateCanadaList: stateCanadaOptions
        };
    }

    public getGenderList = () : SelectOption[] => {
        return CustomerDetailService.genderList;
    }

    public validateCustomer = (customer : Customer) : any => {
        let validationErrors = {};

        if (!customer) {
            validationErrors['rootObject'] = {isValid : false, warningText : constant.CUSTOMER_SPECIFY_CUSTOMER_DETAILS }
        }

        if ((!customer.firstName || customer.firstName?.trim().length == 0) 
            && (!customer.lastName || customer.lastName?.trim().length == 0)
            && (!customer.displayName || customer.displayName?.trim().length == 0)) {
            validationErrors['firstName'] = {isValid : false, warningText : constant.CUSTOMER_MANDATORY_FIELDS_ERROR };
            validationErrors['lastName'] = {isValid : false, warningText : constant.CUSTOMER_MANDATORY_FIELDS_ERROR };
            validationErrors['displayName'] = {isValid : false, warningText : constant.CUSTOMER_MANDATORY_FIELDS_ERROR };
        }

        if (customer.firstName?.trim().length > 0 && customer.firstName?.trim().length < 2) {
            validationErrors['firstName'] = {isValid : false, warningText : constant.CUSTOMER_FIRST_NAME_MIN_LENGTH };
        }

        if (customer.lastName?.trim().length > 0 && customer.lastName?.trim().length < 2) {
            validationErrors['lastName'] = {isValid : false, warningText : constant.CUSTOMER_LAST_NAME_MIN_LENGTH };
        }
        if (customer.displayName?.trim().length > 0 && customer.displayName?.trim().length < 2) {
            validationErrors['displayName'] = {isValid : false, warningText : constant.CUSTOMER_DISPLAY_NAME_MIN_LENGTH };
        }

        if (!customer.agencyId || customer.agencyId?.length == 0) {
            validationErrors['agencyId'] = {isValid : false, warningText : constant.CUSTOMER_AGENCY_MANDATORY };
        }

        if (customer.yearEmployed && customer.yearEmployed?.length > 0) {
            let yearEmployedValue = parseFloat(customer.yearEmployed);
            if (isNaN(yearEmployedValue)) {
                validationErrors['yearEmployed'] = {isValid : false, warningText : constant.CUSTOMER_YEAR_EMPLOYED_NUMERIC };
            }
            else if (yearEmployedValue < 1990) {
                validationErrors['yearEmployed'] = {isValid : false, warningText : constant.CUSTOMER_YEAR_EMPLOYED_RANGE };
            }
        }

        if (customer.eMail?.trim().length > 0 && !validator.isEmail(customer.eMail?.trim())) {
            validationErrors['eMail'] = {isValid : false, warningText : constant.CUSTOMER_INVALID_EMAIL };
        }

        if (customer.eMail2?.trim().length > 0 && !validator.isEmail(customer.eMail2?.trim())) {
            validationErrors['eMail2'] = {isValid : false, warningText : constant.CUSTOMER_INVALID_EMAIL };
        }
        if (!customer.address1 || customer.address1?.length == 0) {
            validationErrors['address1'] = {isValid : false, warningText : constant.FIELD_BLANK };
        }
        if (!customer.city || customer.city?.length == 0) {
            validationErrors['city'] = {isValid : false, warningText : constant.FIELD_BLANK };
        }
        if (!customer.zipCode || customer.zipCode?.length == 0) {
            validationErrors['zipCode'] = {isValid : false, warningText : constant.FIELD_BLANK };
        }

        if (customer.address1?.trim().length > 0 && customer.address1?.trim().length < 2) {
            validationErrors['address1'] = {isValid : false, warningText : constant.CUSTOMER_ADDRESS1_MIN_LENGTH };
        }

        if (customer.city?.trim().length > 0 && customer.city?.trim().length < 2) {
            validationErrors['city'] = {isValid : false, warningText : constant.CUSTOMER_CITY_MIN_LENGTH };
        }
        if (customer.zipCode?.trim().length > 0 && customer.zipCode?.trim().length < 2) {
            validationErrors['zipCode'] = {isValid : false, warningText : constant.CUSTOMER_ZIP_MIN_LENGTH };
        }
        
        if (customer.homePhone && customer.homePhone?.trim().length <10) {
            validationErrors['homePhone'] = {isValid : false, warningText : constant.CONTACT_NO_RANGE };
        }

        if (customer.mobile && customer.mobile?.trim().length <10) {
            validationErrors['Phone'] = {isValid : false, warningText : constant.CONTACT_NO_RANGE };
        }
        if (customer.workPhone && customer.workPhone?.trim().length <10) {
            validationErrors['workPhone'] = {isValid : false, warningText : constant.CONTACT_NO_RANGE };
        }

        customer.customerDependents?.forEach(item => {
            if ((!item.firstName || item.firstName?.trim().length == 0) && (!item.lastName  || item.lastName?.trim().length == 0)) {
                this.setSubEntityValidationError(validationErrors, 'customerDependents', item.depdId, 'firstName', constant.CUSTOMER_MANDATORY_DEPEDENT_FIELDS_ERROR);
                this.setSubEntityValidationError(validationErrors, 'customerDependents', item.depdId, 'lastName', constant.CUSTOMER_MANDATORY_DEPEDENT_FIELDS_ERROR);
            }

            if (item.yearEmployed && item.yearEmployed?.length > 0) {
                let dpYearEmployedValue = parseFloat(item.yearEmployed);
                if (isNaN(dpYearEmployedValue)) {
                    this.setSubEntityValidationError(validationErrors, 'customerDependents', item.depdId, 'yearEmployed', constant.CUSTOMER_YEAR_EMPLOYED_NUMERIC);
                }
                else if (dpYearEmployedValue < 1990) {
                    this.setSubEntityValidationError(validationErrors, 'customerDependents', item.depdId, 'yearEmployed', constant.CUSTOMER_YEAR_EMPLOYED_RANGE);
                }
            }
            if (item.homePhone && item.homePhone?.trim().length <10) {
                this.setSubEntityValidationError(validationErrors, 'customerDependents', item.depdId, 'homePhone', constant.CONTACT_NO_RANGE);
            }

            if (item.mobile && item.mobile?.trim().length <10) {
                this.setSubEntityValidationError(validationErrors, 'customerDependents', item.depdId, 'mobile', constant.CONTACT_NO_RANGE);
            }
            if (item.workPhone && item.workPhone?.trim().length <10) {
                this.setSubEntityValidationError(validationErrors, 'customerDependents', item.depdId, 'workPhone', constant.CONTACT_NO_RANGE);
            }

        });

        customer.customerContacts?.forEach(item => {
            console.log('validation')
            if ((!item.contactName || item.contactName?.trim().length == 0)) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'contactName', constant.CompanyContact.BLANK_FIELD);
            }

            if ((item.contactName?.trim().length > 0 && item.contactName?.trim().length < 2)) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'contactName', constant.CompanyContact.NAME_LENGTH_RANGE);
            }

            if (!item.title || item.title?.trim().length == 0) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'title', constant.CompanyContact.BLANK_FIELD);
            }

            if ((item.title?.trim().length > 0 && item.title?.trim().length < 2)) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'title', constant.CompanyContact.TITLE_LENGTH_RANGE);
            }
            if (item.homePhone && item.homePhone?.trim().length <10) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'homePhone', constant.CompanyContact.WORK_PHONE_RANGE);
            }

            if (item.mobile && item.mobile?.trim().length <10) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'mobile', constant.CompanyContact.WORK_PHONE_RANGE);
            }

            if (!item.workPhone || item.workPhone?.trim().length === 0) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'workPhone', constant.CompanyContact.BLANK_FIELD);
            }
            
            if (item.workPhone && item.workPhone?.trim().length <10) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'workPhone', constant.CompanyContact.WORK_PHONE_RANGE);
            }

            if ((item.zip?.trim().length > 0 && item.zip?.trim().length < 5)) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'zip', constant.CompanyContact.ZIP_RANGE);
            }

            if (item.email?.trim().length > 0 && !validator.isEmail(item.email?.trim())) {
                this.setSubEntityValidationError(validationErrors, 'customerContacts', item.id, 'email', constant.CUSTOMER_INVALID_EMAIL);
            }
        });

        return validationErrors;
    }

    private setSubEntityValidationError = (validationErrors : any, subEntityPropName : string ,  depdId : string, fieldName : string, errorMessage : string) => {
        if (!validationErrors[subEntityPropName]) {
            validationErrors[subEntityPropName] = {};
        }

        if (!validationErrors[subEntityPropName][depdId]) {
            validationErrors[subEntityPropName][depdId] = {};
        }

        validationErrors[subEntityPropName][depdId][fieldName] = {isValid : false, warningText : errorMessage };
    }

    private getCustomerToUpdate = (customer : Customer) : Customer => {
        let customerToUpdate : Customer = {};

        customerToUpdate.id = customer.id;
        customerToUpdate.custNumber = customer.custNumber;
        customerToUpdate.firstName = customer.firstName?.trim();
        customerToUpdate.midName = customer.midName?.trim();
        customerToUpdate.lastName = customer.lastName?.trim();
        customerToUpdate.displayName = customer.displayName?.trim();
        customerToUpdate.agencyId = customer.agencyId;
        customerToUpdate.industryId = customer.industryId;
        customerToUpdate.occupationId = customer.occupationId;
        customerToUpdate.yearEmployed = customer.yearEmployed;
        customerToUpdate.sex = customer.sex;
        customerToUpdate.maritalStatusId = customer.maritalStatusId;
        customerToUpdate.vip = customer.vip;
        customerToUpdate.address1 = customer.address1?.trim();
        customerToUpdate.address2 = customer.address2?.trim();
        customerToUpdate.countryId = customer.countryId;
        customerToUpdate.city = customer.city?.trim();
        customerToUpdate.state = customer.state?.trim();
        customerToUpdate.zipCode = customer.zipCode?.trim();
        customerToUpdate.billAddress1 = customer.billAddress1?.trim();
        customerToUpdate.billAddress2 = customer.billAddress2?.trim();
        customerToUpdate.billCity = customer.billCity?.trim();
        customerToUpdate.billState = customer.billState?.trim();
        customerToUpdate.billZip = customer.billZip?.trim();
        customerToUpdate.isBillAddressSame = customer.isBillAddressSame;
        customerToUpdate.mailOptOut = customer.mailOptOut;
        customerToUpdate.emailOptOut = customer.emailOptOut;
        customerToUpdate.callOptOut = customer.callOptOut;
        customerToUpdate.smsOptOut = customer.smsOptOut;
        customerToUpdate.eddOptIn = customer.eddOptIn;
        customerToUpdate.mobile = customer.mobile?.trim();
        customerToUpdate.workPhone = customer.workPhone?.trim();
        customerToUpdate.homePhone = customer.homePhone?.trim();
        customerToUpdate.eMail = customer.eMail?.trim();
        customerToUpdate.eMail2 = customer.eMail2?.trim();

        customerToUpdate.customerDependents = [];
        customerToUpdate.customerContacts = [];
        customer.customerDependents?.forEach(item => {
            let depd = {
                custId : item.custId,
                depdId : item.custId ? item.depdId : null, // Copy depdId only if custId is present as for newly added dependents custid would be null.
                relationshipId : item.relationshipId,
                firstName : item.firstName?.trim(),
                midName : item.midName?.trim(),
                lastName : item.lastName?.trim(),
                industryId : item.industryId,
                occupationId : item.occupationId,
                yearEmployed : item.yearEmployed,
                maritalStatusId : item.maritalStatusId,
                mobile : item.mobile?.trim(),
                workPhone : item.workPhone?.trim(),
                homePhone : item.homePhone?.trim(),
                isModified : item.isModified
            };

            customerToUpdate.customerDependents.push(depd);
        });
        customer.customerContacts?.forEach(item => {
           let contactinfo ={ 
            id : item.id,
            custId : item.custId,
            contactName : item.contactName?.trim(),
            title: item.title?.trim(),
            address1 : item.address1?.trim(),
            address2 : item.address2?.trim(),
            city : item.city?.trim(),
            state : item.state?.trim(),
            zip : item.zip?.trim(),
            county :item.county?.trim(),
            homePhone : item.homePhone?.trim(),
            workPhone : item.workPhone?.trim(),
            mobile : item.mobile?.trim(),
            email : item.email?.trim(),
            webAddr : item.webAddr?.trim(),
            formalSalutation : item.formalSalutation?.trim(),
            WorkPhoneExt : item.workPhoneExt?.trim(),
            isModified : item.isModified
           }
           customerToUpdate.customerContacts.push(contactinfo);
        })
        return customerToUpdate;
    }

    private mapToSelectOptions = (lookupData : LookupData[]) : SelectOption[] => {
        return lookupData?.map(item => {
            return {
                label: item.lookupValue,
                value: item.id,
                isInactive: item.inactive
            };
        });
    }
}

export default CustomerDetailService;