import LookupService from "../../Services/LookupService";
import { SelectOption } from "../../shared/components/input";
import { RefiApprovalDropdownDto } from "../model/RefiApprovalDropdownDto";
import { RefiDocument } from "../model/RefiDocument";
import { RefiTodoListDto } from "../model/RefiiTodoListDto";
import { fetchListOfVestingDropdown, fetchRefiTodoList } from "../services/DocuMateAPI";
import * as constant from '../shared/util/docuMateConstant';
import validator from 'validator';

let nextDocument: RefiDocument = {
    "RefiRequestId": "9b1deb4d-3b7d-4bad-9bdd-2bbbbbbbbbd",
    "UploadedFileName": "CS_RM_10152024_1000",
    "DocumentDescription": "Document 100 Description",
    "dateInitiated": "10-12-2024 12:04:56 PM",
    "dateAvailable": "10-12-2024 12:04:56 PM",
    "isLocked": false,
    RequestType: "R",
    Vesting: "A Married Couple",
    PolicyNbr: "12556",
    CarrierName: "ASI",
    CustFirstName: "John",
    CustLastName: "Doe",
    WritingCompanyName: "ABC",
    VestingAddressLine1: "123 Main St",
    VestingAddressLine2: "Apt 2",
    VestingAddrCity: "San Diego",
    VestingAddrState: "CA",
    VestingAddrZipCode: "92101",
    LoanNumber: "1256",
    LoanPosition: "1",
    Impound: "1",
    MortgageeName: "ABC Mortgage",
    MortgageeClause: "Mortgagee Clause",
    MortgageeNameAddressLine1: "123 Main St",
    MortgageeNameAddressLine2: "Apt 2",
    MortgageeNameAddrCity: "San Diego",
    MortgageeNameAddrState: "CA",
    MortgageeNameAddrZipCode: "92101",
    RequestorName: "John Doe",
    RequestorEmail: "john.doe@ymail.com"
}

export const requestTypeList: SelectOption[] = [{
    label : 'Refinance',
    value : 'R'
},
{
    label : 'Lender Change Request',
    value : 'L'
},
{
    label : 'Evidence of Insurance',
    value : 'E'
}];

export const carrierList: SelectOption[] = [{
    label : 'ASI',
    value : 'ASI'
}];

export const impoundsList: SelectOption[] = [
{
    label : 'Yes',
    value : '1'
},
{
    label : 'No',
    value : '0'
}];

export const loanPositionList: SelectOption[] = [{
    label : '1',
    value : '1'
},
{
    label : '2',
    value : '2'
},
{
    label : '3',
    value : '3'
}];

export const getRefiTodoList = async() : Promise<RefiTodoListDto[]> =>  {
    let refiList = await fetchRefiTodoList();
    console.log('refiList', refiList);
    let refiTodoList = refiList ? refiList.map(item => {
        return {
            refiRequestId: item.refiRequestId,
            fileName: item.fileName,
            docDesc: item.docDesc,
            dateAvailable: item.dateAvailable,
            dateInitiated: item.dateInitiated,
            isLocked: false,
            status: "Pending"
        }
    }) : [];
    return refiTodoList;
}

export const getRefiDocument = async(id: string) => {
    // make api call to get current document
    const refiDocument = nextDocument;
    return refiDocument;
}

export const handleToggleLockDocument = async(id: string) => {
    // make api call to toggle lock on the document
    // update flag to db
    return;
}

// export const approveDocument = async(id: string) => {
//     // make api call to approve the document
//     const refiDocument = todoList.find((doc) => doc.refiRequestId === id);
//     if (refiDocument) {
//         refiDocument.status = 'Approved';
//     }
//     return nextDocument;
// }

export const getRequestTypeDropdownOptions = () => {
    return requestTypeList;
}

export const getCarrierDropdownOptions = () => {
    return carrierList;
}

export const getImpoundsDropdownOptions = () => {
    return impoundsList;
}

export const getLoanPositionDropdownOptions = () => {
    return loanPositionList;
}

export const getStatesList = async () => {
    let stateList = await LookupService.fetchState();
    let stateOptions = stateList ? stateList.map(item => {
        return {
            label: item.lookupValue,
            value: item.lookupCode,
            isInactive: false
        }
    }) : [];
    return stateOptions;
}

class DocuMateRefiService {
    public validateRefiDocument = (refiDocument: RefiDocument) : any => {
        // console.log('refiDocument to be validated', refiDocument);
        let validationErrors = {};
        // if(!refiDocument) {
        //     validationErrors['rootObject'] = {isValid: false, warningText: constant.DOCUMENT_NOT_FOUND};
        // }
        if(!refiDocument.RequestType || refiDocument.RequestType?.length === 0) {
            validationErrors['RequestType'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.Vesting || refiDocument.Vesting?.length === 0) {
            validationErrors['Vesting'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.PolicyNbr || refiDocument.PolicyNbr?.length === 0) {
            validationErrors['PolicyNbr'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.CarrierName || refiDocument.CarrierName?.length === 0) {
            validationErrors['CarrierName'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if((!refiDocument.WritingCompanyName || refiDocument.WritingCompanyName?.length === 0)) {
            if((!refiDocument.CustFirstName || refiDocument.CustFirstName?.length === 0))
            {
                validationErrors['CustFirstName'] = {isValid: false};
                validationErrors['WritingCompanyName'] = {isValid: false, warningText: constant.CUSTOMER_NAME_MANDATORY_FIELDS_ERROR};
            }
            else if (refiDocument.CustFirstName?.trim().length > 0 && refiDocument.CustFirstName?.trim().length < 2) {
                validationErrors['CustFirstName'] = {isValid : false, warningText : constant.CUSTOMER_NAME_MIN_LENGTH };
            }
            if ((!refiDocument.CustLastName || refiDocument.CustLastName?.length === 0)) {
                validationErrors['CustLastName'] = {isValid: false};
                validationErrors['WritingCompanyName'] = {isValid: false, warningText: constant.CUSTOMER_NAME_MANDATORY_FIELDS_ERROR};
            }
            else if (refiDocument.CustLastName?.trim().length > 0 && refiDocument.CustLastName?.trim().length < 2) {
                validationErrors['CustLastName'] = {isValid : false, warningText : constant.CUSTOMER_NAME_MIN_LENGTH };
            }
        }
        if (!refiDocument.VestingAddressLine1 || refiDocument.VestingAddressLine1?.length == 0) {
            validationErrors['VestingAddressLine1'] = {isValid : false, warningText : constant.MANDATORY_FIELDS };
        }
        if (!refiDocument.VestingAddrCity || refiDocument.VestingAddrCity?.length == 0) {
            validationErrors['VestingAddrCity'] = {isValid : false, warningText : constant.MANDATORY_FIELDS };
        }
        if(!refiDocument.VestingAddrState || refiDocument.VestingAddrState?.length === 0) {
            console.log('refiDocument.VestingAddrState', refiDocument.VestingAddrState);
            validationErrors['VestingAddrState'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if (!refiDocument.VestingAddrZipCode || refiDocument.VestingAddrZipCode?.length == 0) {
            validationErrors['VestingAddrZipCode'] = {isValid : false, warningText : constant.MANDATORY_FIELDS };
        }
        if (refiDocument.VestingAddressLine1?.trim().length > 0 && refiDocument.VestingAddressLine1?.trim().length < 2) {
            validationErrors['VestingAddressLine1'] = {isValid : false, warningText : constant.CUSTOMER_ADDRESS1_MIN_LENGTH };
        }
        if (refiDocument.VestingAddrCity?.trim().length > 0 && refiDocument.VestingAddrCity?.trim().length < 2) {
            validationErrors['VestingAddrCity'] = {isValid : false, warningText : constant.CUSTOMER_CITY_MIN_LENGTH };
        }
        if (refiDocument.VestingAddrZipCode?.trim().length > 0 && (refiDocument.VestingAddrZipCode?.trim().length < 2 || refiDocument.VestingAddrZipCode?.trim().length > 9)) {
            validationErrors['VestingAddrZipCode'] = {isValid : false, warningText : constant.CUSTOMER_ZIP_MIN_LENGTH };
        }
        if(!refiDocument.LoanNumber || refiDocument.LoanNumber?.length === 0) {
            validationErrors['LoanNumber'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.LoanPosition || refiDocument.LoanPosition?.length === 0) {
            validationErrors['LoanPosition'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.Impound || refiDocument.Impound?.length === 0) {
            validationErrors['Impound'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.MortgageeName || refiDocument.MortgageeName?.length === 0) {
            validationErrors['MortgageeName'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.MortgageeClause || refiDocument.MortgageeClause?.length === 0) {
            validationErrors['MortgageeClause'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.MortgageeNameAddressLine1 || refiDocument.MortgageeNameAddressLine1?.length === 0) {
            validationErrors['MortgageeNameAddressLine1'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.MortgageeNameAddrCity || refiDocument.MortgageeNameAddrCity?.length === 0) {
            validationErrors['MortgageeNameAddrCity'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.MortgageeNameAddrState || refiDocument.MortgageeNameAddrState?.length === 0) {
            validationErrors['MortgageeNameAddrState'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if(!refiDocument.MortgageeNameAddrZipCode || refiDocument.MortgageeNameAddrZipCode?.length === 0) {
            validationErrors['MortgageeNameAddrZipCode'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if (refiDocument.MortgageeNameAddressLine1?.trim().length > 0 && refiDocument.MortgageeNameAddressLine1?.trim().length < 2) {
            validationErrors['MortgageeNameAddressLine1'] = {isValid : false, warningText : constant.CUSTOMER_ADDRESS1_MIN_LENGTH };
        }
        if (refiDocument.MortgageeNameAddrCity?.trim().length > 0 && refiDocument.MortgageeNameAddrCity?.trim().length < 2) {
            validationErrors['MortgageeNameAddrCity'] = {isValid : false, warningText : constant.CUSTOMER_CITY_MIN_LENGTH };
        }
        if (refiDocument.MortgageeNameAddrZipCode?.trim().length > 0 && (refiDocument.MortgageeNameAddrZipCode?.trim().length < 2 || refiDocument.MortgageeNameAddrZipCode?.trim().length > 9)) {
            validationErrors['MortgageeNameAddrZipCode'] = {isValid : false, warningText : constant.CUSTOMER_ZIP_MIN_LENGTH };
        }
        if(!refiDocument.RequestorName || refiDocument.RequestorName?.length === 0) {
            validationErrors['RequestorName'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        else if (refiDocument.RequestorName.trim().length > 0 && refiDocument.RequestorName.trim().length < 2) {
            validationErrors['RequestorName'] = {isValid : false, warningText : constant.CUSTOMER_NAME_MIN_LENGTH };
        }
        if(!refiDocument.RequestorEmail || refiDocument.RequestorEmail?.length === 0) {
            validationErrors['RequestorEmail'] = {isValid: false, warningText: constant.MANDATORY_FIELDS};
        }
        if (refiDocument.RequestorEmail?.trim().length > 0 && !validator.isEmail(refiDocument.RequestorEmail?.trim())) {
            validationErrors['RequestorEmail'] = {isValid : false, warningText : constant.REQUESTOR_INVALID_EMAIL };
        }
        // console.log('validationErrors', validationErrors);
    return validationErrors;

    }

    getVestingList = async (): Promise<SelectOption[]> => {
            var vestingList = await fetchListOfVestingDropdown();
            console.log('vestingList', vestingList);
            const vestingDropdownOptions = vestingList.map((item: RefiApprovalDropdownDto) => (
                {
                    label: item.lookupValue,
                    value: item.lookupValue,
                }));
    
            return vestingDropdownOptions;
        }

    getStatesList = async () => {
        let stateList = await LookupService.fetchState();
        console.log('stateList', stateList);
        let stateOptions = stateList ? stateList.map(item => {
            return {
                label: item.lookupValue,
                value: item.lookupCode,
                isInactive: false
            }
        }) : [];
        return stateOptions;
    }
}

export default DocuMateRefiService;