import { useEffect, useState, useContext, useRef } from 'react'
import { Spinner, Panel, Row, Label, Column } from '../../components'
 import CustomerFilterRequest from '../model/CustomerFilterRequest'
import Grid from '../../components/components/Grid/Grid'
import format from 'date-fns/format'
import { DownloadLink, getCSV } from '../../shared/util/DataHelpers'
import '../customer.css'
import GetCustomers from '../hooks/useCustomers'
import {showGridContext} from '../filter/CustomerFilter'
import { Link } from 'react-router-dom'
import * as label from '../../shared/util/label'
import { Button } from 'primereact/button'
import FormattedInputService from '../../shared/components/input/formatted-input/FormattedInputService'
import { Container } from 'react-bootstrap'
import { AbilityContext } from "../../shared/abilities/AbilityContext";
import * as constant from '../../shared/util/constant'

interface Props {
  filterRequest: CustomerFilterRequest
}

const ViewCustomerResults = (props: Props) => {

  const { filterRequest } = props
 
  const [showGoTop, setShowGoTop] = useState(false);
  const [showFilters,setShowFilters] = useState(false);
  const {FilterData, searchResultData}=useContext(showGridContext);
  const [showGrid, setShowGrid]=useState(true);
  const  data = searchResultData.searchResult;
  const gridRef = useRef(null);
  const ability = useContext(AbilityContext);
  const ab_subject = "Customer";
  const canUserViewCustomer = ability.can('view',ab_subject,constant.ScreenName.CUSTOMER);
  const canUserReadCustomer = ability.can('read',ab_subject,constant.ScreenName.CUSTOMER);
  
  const formattedInputService = new FormattedInputService();

  const handleCheckboxHit = () => {
    if (gridRef.current) {
      const selectedRows = gridRef.current.getSelectedRows();
      return selectedRows.map(x => x.values.referenceId);
    }
  };

  const handleVisibleButton = () => {
    setShowGoTop(window.pageYOffset > 50)
  }

  useEffect(() => {
    if (data!= undefined && data.length>0) {
      setShowGrid(true)
    }
    else {
      setShowGrid(false)
    }

  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);

  }, [])

  // if (data === undefined || isLoading) {
  //   return <Spinner type="DotLoader" loading />
  // }

  function downloadCSV() {
    const referenceIds= handleCheckboxHit();
    console.log(referenceIds)
    if (data != undefined) {
      const csv = getCSV(data)

      const recordingsData = 'Recordings Report'

      const filename = recordingsData
        .concat('-')
        .concat(format(new Date(Date.now()), 'yyyy-MM-dd--hh-mma'))
        .concat('.csv')

      DownloadLink(csv, filename)
    }
  }

  function formatZipCode(zipCode) {
    if (zipCode != undefined && zipCode.length === 9) {
      return zipCode.slice(0, 5) + '-' + zipCode.slice(5);
    }
    return zipCode;
  }


  return (
    <>
      <Panel className='mt-3' cardBodyClassName='p-0' >
        {data != undefined ?
          <>
            <Container fluid>
              <Row className=''>
                <Column md={12} className=''>

                  <div className='d-flex justify-content-between align-items-center'>
                    <Label text='Customer Results' className='fs-6 poppins-bold labelStyle labelPanelHeading align-self-center ms-0 mb-0 ' />
                    {/* Commenting as mentioned in story #35268 */}
                    {/* <Button
                key="mailCSV"
                // color="dark-blue"
                color="info"
                onClick={downloadCSV}
                className={"gridMailMerge btnLabelStyle"}
                icon='merge'
                iconLocation='left'
              // style={downloadButtonStyle}
              >
                {'Mail Merge'}
              </Button>

              <Button
                key="emailCSV"
                // color="dark-blue"
                color="info"
                onClick={downloadCSV}
                className={" gridButtons btnLabelStyle"}
                icon='email'
                iconLocation='left'
              // style={downloadButtonStyle}
              >
                {'Email'}
              </Button>

              <Button
                key="campaignCSV"
                // color="dark-blue"
                color="info"
                onClick={downloadCSV}
                className={"gridButtons btnLabelStyle"}
                icon='campaign'
                iconLocation='left'
              // style={downloadButtonStyle}
              >
                {'Campaign'}
              </Button>

              <Button
                key="exportCSV"
                // color="dark-blue"
                color="info"
                onClick={downloadCSV}
                className={"gridButtons btnLabelStyle"}
                icon='export'
                iconLocation='left'
              // style={downloadButtonStyle}
              >
                {'Export'}
              </Button> */}

                    <Button
                      key="filterCSV"
                      className='ms-auto m-2'
                      icon="bi bi-filter"
                      onClick={() => setShowFilters(!showFilters)}
                      id="filterCSV"
                      label={showFilters ? 'Hide Filter' : 'Show Filter'}
                    // keyDown={(event) => event.key === 'Enter'? onSearch: event.preventDefault}
                    />
                    {/* 
              <Button
                key="filterCSV"
                onClick={() => setShowFilters(!showFilters)}
                className={"btn-primary"}
                icon='filter'
                iconLocation='left'
              // style={downloadButtonStyle}
              >
                {showFilters?'Hide Filter':'Show Filter'}
              </Button> */}
                  </div>

                </Column>


              </Row>
              {/* {data.totalRecords>500?<><br></br><Label
            text='Total results are more than 500 ..This is top 500 which is displayed.'
            style={{ color: "red" }} /><br></br></> : null} */}
              {/* <div> */}
              <Row className=''>
                <Grid
                  grdTabelClass='table-striped table-hover table-sm'
                  grdTableContainerClass='p-0 mh-auto'
                  data={data}
                  showFilters={showFilters}
                  columns={[
                    {
                      Header: label.StandardSearchLable.ACCOUNT,
                      accessor: 'custNumber',
                      Cell: cell =>
                              <div> {(canUserViewCustomer && canUserReadCustomer) ? <Link
                                  to={{
                                      pathname: `/customer-view/customer/${cell.row?.original?.id}`
                                  }}
                                  title={cell.value}>{cell.value}</Link> : <span title={cell.value}>{cell.value}</span>}</div>,
                    },
                    {
                      Header: label.StandardSearchLable.POLICY_NUMBER,
                      accessor: 'policyNumber',
                      Cell: cell =>
                        <div>
                          <Link
                            to={{
                              pathname: `/customer-view/policies/${cell.row?.original?.id}`
                            }}
                            title={cell.value}
                            className='gridNavigateButton btn btn-link text-start text-decoration-none d-block p-2'
                          >
                            {cell.value}
                          </Link>
                        </div>,
                      width: 250,
                      minWidth: 150,
                    },
                    {
                      Header: label.StandardSearchLable.NAME,
                      accessor: 'displayName',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                      width: 250,
                      minWidth: 150,
                    },
                    {
                      Header: label.StandardSearchLable.AGENCY,
                      accessor: 'agencyDisplay',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                      width: 250,
                      minWidth: 150,
                    },
                    // {
                    //   Header: 'Other Name',
                    //   accessor: 'otherName',
                    //   // minWidth: 270,
                    //   Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    // },
                    //   {
                    //     Header: 'Home',
                    //     accessor: 'home',
                    //     Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    //   },
                    // {
                    //   Header: 'Auto',
                    //   accessor: 'auto',
                    //   Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    // },
                    {
                      Header: label.StandardSearchLable.ADDRESS,
                      accessor: 'address1',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                      width: 250,
                      minWidth: 150,
                    },
                    {
                      Header: label.StandardSearchLable.APRARTMENT,
                      accessor: 'address2',
                      // width:223,
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    },
                    {
                      Header: label.StandardSearchLable.CITY,
                      accessor: 'city',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    },
                    {
                      Header: label.StandardSearchLable.STATE,
                      accessor: 'state',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    },
                    {
                      Header: label.StandardSearchLable.ZIP,
                      // accessor: 'zipCode',
                      accessor: row => formatZipCode(row.zipCode),
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    },
                    {
                      Header: label.StandardSearchLable.PHONE,
                      accessor: 'homePhone',
                      Cell: row => <div><span title={row.value}>{formattedInputService.formatPhoneNumber(row.value)}</span></div>
                    },
                    {
                      Header: label.StandardSearchLable.EMAIL,
                      accessor: 'eMail',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>,
                      width: 235,
                      minWidth: 150,
                    },
                    // {
                    //   Header: 'Status',
                    //   accessor: 'policyStatus',
                    //   // width: 70,
                    //   Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    // },

                    // {
                    //   Header: 'Carrier',
                    //   accessor: 'carrier',
                    //   Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    // },
                  ]}
                  hiddenColumns={[]}
                  sortDefault={[{ id: 'referenceId', desc: true }]}
                // highlightColumn='customerNumber'
                // selectedColumn={handleSelectedRowsChange} 
                />
                {/* <div>
              <h3>Selected Rows:</h3> 
        <ul>
          {referenceIdSelected.map((rowId) => (
            <li key={rowId}>Row {rowId}</li>
          ))}
        </ul>
      </div> */}
                {/* <Button
                  className='topButton btn-sm'
                  key="BackToTopButton"
                  icon='up-arrow'
                  style={{
                    // position: 'fixed',
                    // bottom: '20px',
                    // right: '20px',
                    display: showGoTop ? 'block' : 'none',
                    // backgroundColor: 'cyan-blue',
                    // border: 'none'
                  }}
                  onClick={() => {
                    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
                  }}
                >
                </Button> */}
              </Row>
            </Container>
          </>
          : "No record found"}

      </Panel>
    </>
  )
}
export default ViewCustomerResults
