import React, { useState } from 'react';
import './DataTable.scss';
import DataTableService from './DataTableService';
import { Table } from 'react-bootstrap';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { Icon } from '../Icon';
import { IconType } from '../Icon/interfaces';

export interface ITableColumn {
  // Column header
  header? : string;

  // Field name
  field? : string;

  // Customer class for individual column header
  headerClassName? : string;

  // Column width
  width? : string;

  maxWidth? : string;

  // Class name for cell TD 
  cellTdClassName? : string;

  // Class name for cell td content
  cellTdContentClassName? : string;

  cellDataFormatter? : (cellData : any) => any;

  cellTemplate? : (rowData : any, column : ITableColumn, rowIndex : number, dataTableState? : DataTableState,  cellTemplateCallBackHanlder? : (dataTableEventArgs : DataTableEventArgs) => void) => JSX.Element;

  // cellTemplateActionHandler? : (dataTableEventArgs : DataTableEventArgs) => void;

  cellDataChangeHandler? : (dataTableEventArgs : DataTableEventArgs) =>  CellDataChangeArgs

  cellClickHandler? : (dataTableEventArgs : DataTableEventArgs) =>  void


}

export interface CellDataChangeArgs {
  dataTableRowsState? : DataTableRowsState
}

export interface DataTableEventArgs {
  rowData? : any;

  column? : ITableColumn;

  rowIndex? : number;

  dataTableState? : DataTableState;

  eventData? : any;
}

export interface ITable {
  // List of columns 
  columns : ITableColumn[];

  // Data to render in table.
  data : any[];

  keyColumnName? : string;

  tableClassName? : string

  // Class name for thead section
  theadClassName? : string

  // Default column width
  defaultColumnWidth? : string  

  /// Row detail template provider
  rowDetailTemplate? : (rowData : any, dataTableState : DataTableState, rowIndex : number) => JSX.Element; 

  isEditDisabled? : boolean

  isDeleteDisabled? : boolean
};

export interface RowState {
  expanded? : boolean
}

export interface DataTableRowsState {
  [rowId: string]: RowState; 
}

export interface DataTableState {
  dataTable : ITable;
  rowsState : DataTableRowsState
}

export class DataTableDefaultCellTemplates {
  static RowExpander = { 
            CellTemplate : (rowData : any, column : ITableColumn, rowIndex : number, dataTableState? : DataTableState,  cellTemplateCallBackHanlder? : (dataTableEventArgs : DataTableEventArgs) => void) => {
                              let isRowExpanded = false;
                              if (rowData && dataTableState.rowsState) {
                                let currentRowState = dataTableState.rowsState[rowData[dataTableState.dataTable.keyColumnName]];
                                if (currentRowState) {
                                  isRowExpanded = currentRowState.expanded;
                                }
                              }

                              let templateToRender = <></>;

                              let dataEventArgs : DataTableEventArgs = {
                                rowData, column, rowIndex, dataTableState, eventData : {}
                              };

                              if (isRowExpanded) {
                                dataEventArgs.eventData.expand = false;
                                templateToRender = <FaChevronDown className='cl-expander-icon' onClick={() => cellTemplateCallBackHanlder(dataEventArgs)}/>
                              }
                              else {
                                dataEventArgs.eventData.expand = true;
                                templateToRender = <FaChevronRight className='cl-expander-icon' onClick={() => cellTemplateCallBackHanlder(dataEventArgs)}/>;
                              }
                                
                              return templateToRender;
                          },
            CellTemplateEventHandler : (dataTableEventArgs : DataTableEventArgs) : CellDataChangeArgs => {
                            const {rowData, dataTableState, eventData} = dataTableEventArgs;
                            const {rowsState} = dataTableState;
                            let rowState = rowsState[rowData[dataTableState.dataTable.keyColumnName]];
                            if (!rowState) {
                              rowState = { expanded : eventData.expand}
                            }
                            else {
                              rowState = {...rowState, expanded : eventData.expand};
                            }

                            let updatedRowsState = {...rowsState, [rowData[dataTableState.dataTable.keyColumnName]] : rowState};

                            let cellDataChangeArgs  : CellDataChangeArgs = {
                              dataTableRowsState : updatedRowsState
                            };

                            return cellDataChangeArgs;
                        } 
        };
    static EditCellTemplate = {
      CellTemplate : (rowData : any, column : ITableColumn, rowIndex : number, dataTableState? : DataTableState) => {
            const dtEventArgs : DataTableEventArgs =  { rowData,  column, rowIndex, dataTableState, eventData : null };
            const isEditDisabled = dataTableState.dataTable.isEditDisabled;
            const iconClass = isEditDisabled ? 'disabled-icon' : ''
            return <Icon icon={'edit' as IconType} onClick={() => !isEditDisabled && column?.cellClickHandler(dtEventArgs)} className={iconClass} />;
      }
    };
    static DeleteCellTemplate = {
      CellTemplate : (rowData : any, column : ITableColumn, rowIndex : number, dataTableState? : DataTableState) => {
            const dtEventArgs : DataTableEventArgs =  { rowData,  column, rowIndex, dataTableState, eventData : null };
            const isDeleteDisabled = dataTableState.dataTable.isDeleteDisabled;
            const iconClass = isDeleteDisabled ? 'disabled-icon' : ''
            return <Icon icon={'trash' as IconType} onClick={() => !isDeleteDisabled && column?.cellClickHandler(dtEventArgs)} className={iconClass}/>;
      }
    };
};

const DataTable : React.FunctionComponent<ITable> = (props : ITable) => {
  const tableService = new DataTableService();

  const { columns
        , data
        , theadClassName
        , defaultColumnWidth
        , tableClassName
        , isEditDisabled
        , isDeleteDisabled
      } = props;

  
  const [rowsState, setRowsState ] = useState<any>({});

  const cellTemplateActionHanlder = (dataTableEventArgs : DataTableEventArgs) => {
    let updatedRowsState = (dataTableEventArgs.column.cellDataChangeHandler(dataTableEventArgs));
    setRowsState(updatedRowsState.dataTableRowsState);
  }

  const columnContent = tableService.getTableHeader(columns, defaultColumnWidth);

  const tableClass = tableService.getClassName(tableClassName, 'tbl-main');

  const theadClass = tableService.getClassName(theadClassName, 'tblHeader');

  let dataTableState : DataTableState = {dataTable : props, rowsState : rowsState};
  const bodyContent = tableService.getTableBody(columns, data, dataTableState, cellTemplateActionHanlder);

  return (
    <div>
      <Table className={tableClass}>
        <thead className={theadClass}>
          <tr>
            {columnContent}
          </tr>
        </thead>
        <tbody>
          {bodyContent}
        </tbody>
      </Table>
    </div>
  );
}

export default DataTable;