import React, { useEffect, useState } from "react";
import './CustomerPolicyList.scss';
import CustomerPolicySummary from "./CustomerPolicySummary";
import CustomerPolicyListService from "./CustomerPolicyListService";
import { PolicyDtoRead } from "../model/PolicyDtoRead";
import Logger from "../../shared/services/Logger";
import * as constant from '../../shared/util/constant';
import { useSelector } from "react-redux";
import { RootState } from "../../shared/store";

export interface ICustomerPolicyListComponent
{
    customerId : string
};

const CustomerPolicyList : React.FunctionComponent<ICustomerPolicyListComponent> = (props) => {
    const customerPolicyListService  = new CustomerPolicyListService();
    
    const [customerPolicies, setCustomerPolicies] = useState<PolicyDtoRead[]>([]);
    let customerInfo = useSelector((state: RootState) => state?.customerSlice);

    useEffect(() => {
        if (customerInfo && customerInfo.policies && customerInfo.policies.length > 0) {
            setCustomerPolicies(customerInfo.policies);
        }
    },[customerInfo.policies]);

    let policiesPanels = customerPolicies.map(item => <CustomerPolicySummary policySummary={item}></CustomerPolicySummary>);
    if (policiesPanels.length == 0 && !customerInfo.isFetchingPolicyList) {
        policiesPanels = [<div className="temp-component-place-holder">No Policies found for customer.</div>]
    }

    if (customerInfo.isFetchingPolicyList) {
        policiesPanels = [<div className="temp-component-place-holder">Fetching policy details.</div>]
    }

    return (
        <div className="p-3">
            {policiesPanels}
        </div>
    );
}

export default CustomerPolicyList;