import { IconPrefix, IconName, SizeProp,RotateProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { CSSProperties } from 'react'

import { IconType } from './interfaces'

const iconMap = {
  add: 'plus',
  imaging: 'images',
  admin: 'user-shield',
  appointment: 'calendar',
  'appointment-add': 'calendar-plus',
  'appointment-remove': 'calendar-minus',
  calendar: 'calendar-alt',
  billing: 'file-invoice-dollar',
  dashboard: 'fa-gauge',
  'down-arrow': 'chevron-down',
  'down-indication':'fa-arrow-down',
  edit: 'edit',
  image: 'camera',
  incident: 'file-alt',
  inventory: 'boxes',
  lab: 'microscope',
  'left-arrow': 'chevron-left',
  logout: 'sign-out-alt',
  medication: 'pills',
  menu: 'bars',
  patient: 'user',
  'patient-add': 'user-plus',
  'patient-remove': 'user-minus',
  patients: 'users',
  remove: 'minus',
  'right-arrow': 'chevron-right',
  save: 'save',
  setting: 'cog',
  'up-arrow': 'chevron-up',
  'up-indication':'fa-arrow-up',
  campaign:'fa-paper-plane', 
  'campaign-add':'plus',
  'campaign-dashboard':'gauge',
  'campaign-find':'magnifying-glass',
  maintenance:'toolbox',
  task:'fa-envelope',
  customer: 'user',
  'customer-dashboard': 'gauge',
  'customer-add': 'user-plus',
  'customer-find':'magnifying-glass',
  'burger-menu':'bars',
  circle:'circle',
  'trash':'fa-trash',
  'merge':'fa-copy',
  'email':'fa-envelope',
  'emailAt':'fa-at',
  'mobile' : 'fa-mobile-alt',
  'phone' : 'fa-phone',
  'home' : 'fa-home',
  'umbrella' : 'fa-umbrella',
  'export':'fa-file-export',
  'filter':'fa-filter',
  'cancel':'fa-ban',
  'check':'fa-check',
   pinned:'fa-thumbtack',
  'search':'fa-search',
  'fa-arrow-left':'fa-arrow-left',
  'arrow-right':'fa-arrow-right',
  'clear' : 'fa-broom',
  'close' : 'fa-times',
  'activity' : 'fa-line-chart',
  'tasks' : 'fa-tasks',
  'note' : 'fa-sticky-note',
  'contact' : 'fa-address-book',
  'pin' : 'fa-thumbtack',
  history : 'fa-history',
  documateScan : 'fa-barcode',
  documateSearch : 'fa-address-card',
  documateIndexing : 'fa-hand-point-up',
  folder: 'fa-folder',
  folderOpen: 'fa-folder-open',
  prevPage: 'fa-angle-left',
  nextPage: 'fa-angle-right',
  zoomIn: 'fa-magnifying-glass-plus',
  zoomOut: 'fa-magnifying-glass-minus',
  rotateClockwise: 'fa-rotate-right',
  rotateCounterClockwise: 'fa-rotate-left',
  userPen: 'fa-user-pen',
  lock: 'fa-lock',
  calendar2: 'fa-calendar',
  dollar: 'fa-dollar-sign',
  auto: 'fa-car',
  documateRefi: 'fa-house-chimney-window',
  approve: "fa-thumbs-up",
  reject: "fa-circle-xmark",
  'caret-up': 'fa-caret-up',
  'caret-down': 'fa-caret-down',
  'building': 'fa-building'
}

function getFontAwesomeIcon(icon: IconType): string {
  return iconMap[icon]
}

interface Props {
  /** The type of icon to display */
  icon: IconType
  size?: SizeProp
  /** Outline version or filled-in version. Note some icons may be missing outline version. */
  outline?: boolean
  /**
   * Defines the class of the icon.
   */
  className?: string
  /**
   * * Defines the name of the icon.
   */
  iconName?: string
  /**
   * Defines the style of the icon.
   */
  style?: CSSProperties
  /** Function to execute when user clicks on icon */
  onClick?: (event: React.MouseEvent<any>) => void

  title? : string
  /**
   * Defines the rotation of the icon
   */
  rotation?:RotateProp
}

/**
 * Icons provide contextual clues to users to make it easier to recognize functionality
 */
const Icon = (props: Props) => {
  const { icon, outline, className, iconName, style, onClick, size, rotation, title } = props
  const iconPrefix = (outline ? 'far' : 'fas') as IconPrefix
  const faIconName = getFontAwesomeIcon(icon) as IconName

  return (
    <FontAwesomeIcon
      onClick={onClick}
      icon={[iconPrefix, faIconName]}
      size={size}
      className={className}
      rotation={rotation}
      style={style}
      title={title ? title : ''}/>
  )
}

Icon.defaultProps = {
  outline: false,
  size: '1x',
}

export { Icon }
