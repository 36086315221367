import React, { useContext, useEffect, useState } from "react";
import MenuBar, { IMenuItem } from "../../shared/components/menubar/MenuBar";
import CustomerDetail from "../customer-details/CustomerDetail";
import './CustomerMainPortal.scss';
import CustomerPolicyList from "../customer-policy-list/CustomerPolicyList";
import { CustomerScreenTabs } from "../../shared/util/constant";
import CustomerNotes from "../customer-notes/CustomerNotes";
import CutomerMainPortalHeader from "./CutomerMainPortalHeader";
import { useHistory } from 'react-router-dom';
import CustomerActivity from "../customer-activity/CustomerActivity";
import CustomerLoss from "../customer-loss/CustomerLoss";
import  CustomerHistory  from "../customer-history/CustomerHistory";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import * as constant from '../../shared/util/constant';
import AppContext from "../../shared/components/AppContext";
import { Row, Column } from "../../components";
import useSlider from "../../shared/components/Slider/useSlider";
import Slider from "../../shared/components/Slider/Slider";
import CustomerSliderContainer from "../customer-slider-content/CustomerSliderContainer";
import { useDispatch } from "react-redux";
import { resetCustomerSliderState } from "../customer-slider-content/CustomerSliderContext";
import { startFetchingCustomerPolicies, storeCustomerPolicies, updateCustomers } from "../customer-slice/Customer-slice";
import { updateNoteList } from "../customer-slice/Customer-note-slice";
import CustomerTasksView from "../customer-tasks/CustomerTasksView";
import { Container } from "react-bootstrap";
import CustomerCampaignView from "../customer-campaigns/CustomerCampaignView";
import { AbilityContext } from "../../shared/abilities/AbilityContext";
import Logger from "../../shared/services/Logger";
import CustomerPolicyListService from "../customer-policy-list/CustomerPolicyListService";




const CustomerMainPortal : React.FunctionComponent<any> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const modalContext = useConfirmationModalContext();
    const myContext = useContext(AppContext);
    const sliderContext = useSlider();
    const customerId = props.match?.params?.id;   
    const [isNoteAccessible, setIsNoteAccessible] = useState(false);
    let viewName = props.match?.params?.view;
    if (!viewName) {
        viewName = CustomerScreenTabs.customer.key;
    }

    let isCreatingNewCustomer = false;
    if (!props.match?.params?.view) {
        isCreatingNewCustomer = true;
    }

    const navigateTo = (path) => {
        history.push(path);
    }

    const ability = useContext(AbilityContext);
    const canUserViewCustomer = ability.can('view',"Customer",constant.ScreenName.CUSTOMER);
    const canUserReadCustomer = ability.can('read',"Customer",constant.ScreenName.CUSTOMER); 
    const isUserPermitToViewCustomerNotes = ability.can('view', 'Customer Notes' ,constant.CustomerScreenName.CUSTOMER_NOTES)
    const isCustomerNoteMenuEnableForUser = ability.can('read', 'Customer Notes',constant.CustomerScreenName.CUSTOMER_NOTES)
    const canUserViewCustomerTask = ability.can('view',"Customer Task",constant.ScreenName.CUSTOMER_TASK);
    const canUserReadCustomerTask = ability.can('read',"Customer Task",constant.ScreenName.CUSTOMER_TASK); 
    const canUserViewCustomerHistory = ability.can('view',"Customer History",constant.ScreenName.CUSTOMER_HISTORY);
    const canUserReadCustomerHistory = ability.can('read',"Customer History",constant.ScreenName.CUSTOMER_HISTORY); 
    const canUserViewCustomerActivity = ability.can('view',"Customer Activity",constant.ScreenName.CUSTOMER_ACTIVITY);
    const canUserReadCustomerActivity = ability.can('read',"Customer Activity",constant.ScreenName.CUSTOMER_ACTIVITY); 
    const canUserViewCustomerCampaigns = ability.can('view',"Customer Campaigns",constant.ScreenName.CUSTOMER_CAMPAIGNS);
    const canUserReadCustomerCampaigns = ability.can('read',"Customer Campaigns",constant.ScreenName.CUSTOMER_CAMPAIGNS);
    const canUserViewCustomerLossHistory = ability.can('view',"Customer Loss History",constant.ScreenName.CUSTOMER_LOSS_HISTORY);
    const canUserReadCustomerLossHistory = ability.can('read',"Customer Loss History",constant.ScreenName.CUSTOMER_LOSS_HISTORY); 

    const menuItems : IMenuItem[] = [ 
        { menuKey : CustomerScreenTabs.customer.key, menuText : CustomerScreenTabs.customer.text, isVisible: canUserViewCustomer, isDisabled: !canUserReadCustomer },
        { menuKey : CustomerScreenTabs.policies.key, menuText : CustomerScreenTabs.policies.text },
        { menuKey : CustomerScreenTabs.tasks.key, menuText : CustomerScreenTabs.tasks.text, isVisible: canUserViewCustomerTask, isDisabled: !canUserReadCustomerTask  },
        { menuKey : CustomerScreenTabs.activity.key, menuText : CustomerScreenTabs.activity.text, isVisible: canUserViewCustomerActivity, isDisabled: !canUserReadCustomerActivity },
        { menuKey : CustomerScreenTabs.history.key, menuText : CustomerScreenTabs.history.text, isVisible: canUserViewCustomerHistory, isDisabled: !canUserReadCustomerHistory },
        { menuKey : CustomerScreenTabs.notes.key, menuText : CustomerScreenTabs.notes.text, isDisabled:!isCustomerNoteMenuEnableForUser , isVisible:isUserPermitToViewCustomerNotes},
        { menuKey : CustomerScreenTabs.documents.key, menuText : CustomerScreenTabs.documents.text },
        { menuKey : CustomerScreenTabs.campaigns.key, menuText : CustomerScreenTabs.campaigns.text, isVisible: canUserViewCustomerCampaigns, isDisabled: !canUserReadCustomerCampaigns },
        { menuKey : CustomerScreenTabs.losshistory.key, menuText : CustomerScreenTabs.losshistory.text, isVisible: canUserViewCustomerLossHistory, isDisabled: !canUserReadCustomerLossHistory }
    ];

    useEffect(() => {
        cleaCustomerRelatedDataFromStore();
    },[]);

    useEffect(() => {
        if(viewName === CustomerScreenTabs.customer.key && customerId)
            !(canUserReadCustomer && canUserViewCustomer) && history.push("/");
        else if(viewName === CustomerScreenTabs.tasks.key)
            !(canUserReadCustomerTask && canUserViewCustomerTask) && history.push("/");
        else if(viewName === CustomerScreenTabs.history.key)
            !(canUserReadCustomerHistory && canUserViewCustomerHistory) && history.push("/");
        else if(viewName === CustomerScreenTabs.activity.key)
            !(canUserReadCustomerActivity && canUserViewCustomerActivity) && history.push("/");
        else if(viewName === CustomerScreenTabs.campaigns.key)
            !(canUserReadCustomerCampaigns && canUserViewCustomerCampaigns) && history.push("/");
        else if(viewName === CustomerScreenTabs.losshistory.key)
            !(canUserReadCustomerLossHistory && canUserViewCustomerLossHistory) && history.push("/");
    },[viewName]);

    const cleaCustomerRelatedDataFromStore = () => {
        dispatch(resetCustomerSliderState());
        dispatch(updateCustomers({}));
        dispatch(storeCustomerPolicies([]));
        dispatch(updateNoteList([]));
        sliderContext.hideSlider();
        initializeCustomerMainPortal();
    }

    const initializeCustomerMainPortal = async () => {
        try {
            
            fetchCustomerPolicyList();
        }
        catch(ex) {
            Logger.logError(constant.CUSTOMER_MAIN_PORTAL_INITIALIZE_ERROR, ex);
        }
    }

    const fetchCustomerPolicyList = async () => {
        try {
            dispatch(startFetchingCustomerPolicies());
            const customerPolicyListService  = new CustomerPolicyListService();
            const cusotomerPolicies = await customerPolicyListService.fetchCustomerPolicyDetails(customerId);
            dispatch(storeCustomerPolicies(cusotomerPolicies));
        }
        catch(exx) {
            Logger.logError(constant.CUSTOMER_POLICY_LIST_ERROR_WHILE_FETCHING_POLICIES, exx);
            dispatch(storeCustomerPolicies([]));
        }
    }

    const [currentTab, setCurrentTab] = useState<string>(viewName);

    const onMenuItemClick = async (menuKey : string) => {
        if(!myContext.formSavedStatus){
        const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
            if(result){
                setCurrentTab(menuKey);
                history.push(`/customer-view/${menuKey}/${customerId}`);
                myContext.formSavedStatus =true
            }
        }
        else{
            setCurrentTab(menuKey);
                history.push(`/customer-view/${menuKey}/${customerId}`);
        }
       
    }
const accessDenied =(isAccessible:boolean) =>{
    if(isAccessible){
        setIsNoteAccessible(false)
        navigateTo('/')
    }
}

    let otherPages = <></>;
    let menusToRender : IMenuItem[] = menuItems.filter(item => item.menuKey == CustomerScreenTabs.customer.key);

    if (!isCreatingNewCustomer) {
        otherPages = <>
            {currentTab == CustomerScreenTabs.policies.key && <CustomerPolicyList customerId={customerId}></CustomerPolicyList>}
            {currentTab == CustomerScreenTabs.tasks.key && <CustomerTasksView customerId={customerId} onRouteChange={onMenuItemClick} navigateTo={navigateTo}></CustomerTasksView>}
            {currentTab == CustomerScreenTabs.activity.key && <CustomerActivity customerId={customerId} navigateTo={navigateTo}></CustomerActivity>}
            {currentTab == CustomerScreenTabs.history.key && <CustomerHistory customerId={customerId}></CustomerHistory>}
            {currentTab == CustomerScreenTabs.notes.key  && <CustomerNotes customerId={customerId} accessDenied = {accessDenied}></CustomerNotes>}
            {currentTab == CustomerScreenTabs.documents.key && <div className="temp-component-place-holder">Documents ---- Coming Soon.</div>}
            {currentTab == CustomerScreenTabs.campaigns.key && <CustomerCampaignView customerId={customerId}></CustomerCampaignView>}
            {currentTab == CustomerScreenTabs.losshistory.key && <CustomerLoss customerId={customerId}></CustomerLoss>}
        </>;

        menusToRender = menuItems;
    }

    let customerMainPortalContent = <>
        <CutomerMainPortalHeader customerId={customerId} viewName={currentTab} isCreatingNewCustomer={isCreatingNewCustomer} navigateTo={navigateTo}></CutomerMainPortalHeader>
                <MenuBar  menuItems={menusToRender} selectedMenuKey={currentTab} onMenuClick={(e) => onMenuItemClick(e)}>
                </MenuBar>
                {currentTab == CustomerScreenTabs.customer.key && <CustomerDetail customerId={customerId} isCreatingNewCustomer={isCreatingNewCustomer}></CustomerDetail>}
                {otherPages}
    </>;

    let sliderContent = <Slider sliderClassName="customer-slider"><CustomerSliderContainer customerId={customerId} navigateTo={navigateTo}></CustomerSliderContainer></Slider>;

    let mainCotentLayout = <></>;

    let mainContentLg = 12;
    let mainContentMd = 12;
    let sliderContentLg = 0;
    let sliderContentMd = 0;
    if (sliderContext.isSliderPinned) {
        mainContentLg = 9;
        mainContentMd = 9;
        sliderContentLg = 3;
        sliderContentMd = 3;
    }

    mainCotentLayout =
        <Container fluid>
            <Row no-gutters>
                <Column lg={mainContentLg} md={mainContentMd} className="cst-main-placeholder-first-column p-0">
                    {customerMainPortalContent}
                    {sliderContext.isSliderVisible && !sliderContext.isSliderPinned && sliderContent}
                </Column>
                <Column lg={sliderContentLg} md={sliderContentMd} className="cst-main-placeholder-second-column">
                    {sliderContext.isSliderVisible && sliderContext.isSliderPinned && sliderContent}
                </Column>
            </Row>
        </Container>


    return (
        <>
            {mainCotentLayout}
        </>
    );
}

export default CustomerMainPortal;