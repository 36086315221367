import {Row,Column} from '../../components'

import {campaignDashboardUtils} from '../utils/CampaignDashboardUtils'

import {useEffect, useState} from 'react'

import { FallbackComponent } from '../../shared/components/FallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'

import { Card } from 'primereact/card';
import SentonDateGraph from '../filter/SentonDateGraph'


const CampaignDashboard = () => {
  const [sendonDateData,setSenonDateDate]=useState([])
  const [rerender, setRerender] = useState(false);
  
  //Fetch data from API for campaign sent on date
  //This data contains count of sent campaign from current date-1 till last month
  //and Approved data count from today, till next month
  async function fetchSendonDateData() {
    let chartData = await campaignDashboardUtils.GetCampaignDataonSendonDate();
    setSenonDateDate(chartData)
    setRerender(true)
  }

  useEffect(() => {
    fetchSendonDateData();
  }, [rerender]);

  // Calculate the sum of the count property
  const totalCampaignsSent = sendonDateData.reduce((sum, data) => sum + data.count, 0);

  return (
    <Row>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Column lg={6} md={6} className={''}>

          <Card title={`${totalCampaignsSent}`} subTitle="Campaigns Sent" className="md:w-25rem">
            <SentonDateGraph sendonDateData={sendonDateData} />
          </Card>

        </Column>
        <Column lg={6} md={6}>&nbsp;</Column>
      </ErrorBoundary>
    </Row>
  )
}
export default CampaignDashboard