import React, { useEffect, useState } from 'react';
import './SliderTask.scss';
import SliderTaskService from './SliderTaskService';
import { useDispatch, useSelector } from 'react-redux';
import useSlider from '../../../shared/components/Slider/useSlider';
import { useConfirmationModalContext } from '../../../shared/components/ModalConfirmationContext';
import CustomerSliderContainerService from '../CustomerSliderContainerService';
import { RootState } from '../../../shared/store';
import { Button, Column, DateTimePicker, Label, Row } from '../../../components';
import { TaskScreenLables } from "../../../shared/util/label";
import * as lables from "../../../shared/util/label";
import { cleareTabsInputs, SliderDataAction, updateSliderData } from '../CustomerSliderContext';
import { toast } from 'react-toastify';
import Logger from '../../../shared/services/Logger';
import CustomerTaskViewService from '../../customer-tasks/CustomerTasksViewService';
import TextFieldWithLabelInline from '../../../shared/components/input/TextFieldWithLabelInline';
import DropDownWithLabelInline from '../../../shared/components/input/DropDownWithLabelInline';
import { updateLookupData } from '../../customer-slice/Customer-lookup-slice';
import { CUSTOMER_NOT_FOUND, CustomerTaskConstants, SlicerLookUpConstant } from '../../../shared/util/constant';
import { SelectOption } from '../../../shared/components/input';
import TextInputWithLabelInline from '../../../shared/components/input/TextInputWithLabelInline';
import CustomerActivityService from '../../customer-activity/CustomerActivityService';
import { AppEvenType, notifyAppEvent } from '../../../shared/store/component-notification/ComponentNotification';

export interface ISliderTask  {
    customerId? : string,
    navigateTo : (path : string) => void
}

const SlidterTask : React.FunctionComponent<ISliderTask>  = (props) => {
    const sliderTaskService = new SliderTaskService();
    const customerSliderContainerService = new CustomerSliderContainerService();
    const customerTaskService = new CustomerTaskViewService();
    const customerActivityService = new CustomerActivityService();

    const dispatch = useDispatch();
    const slider = useSlider();
    const modalContext = useConfirmationModalContext();

    let customerInfo = useSelector((state: RootState) => state?.customerSlice);
    let customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);

    const customerTask = customerSliderState.taskPage.customerTask;
    const formValidation = customerSliderState.taskPage.formValidation;
    const policiesForSelect = [...customerSliderState.taskPage.policiesForSelect];

    const taskPriorities = useSelector((state: RootState) => state?.LookupState.taskPriorityList);
    const taskTypes = useSelector((state: RootState) => state?.LookupState.activityTypeList);
    const taskStatuses = useSelector((state: RootState) => state?.LookupState.taskStatusList);
    const empList = useSelector((state: RootState) => state?.LookupState.employeeList);
    const companyList = useSelector((state: RootState) => state?.LookupState.companyList);

    const [taskPriorityList, setTaskPriorityList] = useState<SelectOption[]>([]);
    const [taskStatusList, setTaskStatusList] = useState<SelectOption[]>([]);
    const [taskTypeList, setTaskTypeList] = useState<SelectOption[]>([]);
    const [employeeList, setEmployeeList] = useState<SelectOption[]>([]);
    const [companiesList, setCompanyList] = useState<SelectOption[]>([]);

    useEffect(() => {
        initializeComponent();
    }, []);

    const initializeComponent =  async () => {

        let tskStatusList = [];
        let tskPriorityList = [];
        let tskTaskTypeList = [];
        let emplSelectList = [];
        let compSelectList = [];

        if (!taskPriorities || taskPriorities.length == 0) {
            tskPriorityList = await customerTaskService.getTaskPriorityList();
            dispatch(updateLookupData({data : tskPriorityList, type : SlicerLookUpConstant.TASK_PRIORITY}));
        }
        else {
            tskPriorityList = [...taskPriorities];
        }

        if (!taskTypes || taskTypes.length == 0) {
            tskTaskTypeList = await customerTaskService.getTaskTypeList();
            dispatch(updateLookupData({data : tskTaskTypeList, type : SlicerLookUpConstant.ACTIVITY_TYPE}));
        }
        else {
            tskTaskTypeList = [...taskTypes];
        }

        if (!taskStatuses || taskStatuses.length == 0) {
            tskStatusList = await customerTaskService.getTaskStatusList();
            dispatch(updateLookupData({data : tskStatusList, type : SlicerLookUpConstant.TASK_STATUS}));
        }
        else {
            tskStatusList = [...taskStatuses];
        }

        if (!empList || empList.length == 0) {
            emplSelectList = await customerTaskService.getEmployeeList();
            dispatch(updateLookupData({data : emplSelectList, type : SlicerLookUpConstant.EMPLOYEE_LIST}));
        }
        else {
            emplSelectList = [...empList];
        }

        if (!companyList || companyList.length == 0) {
            compSelectList = await customerTaskService.getCompanyList();
            dispatch(updateLookupData({data : compSelectList, type : SlicerLookUpConstant.COMPANY_LIST}));
        }
        else {
            compSelectList = [...companyList];
        }

        setTaskPriorityList(tskPriorityList);
        setTaskStatusList(tskStatusList);
        setTaskTypeList(tskTaskTypeList);
        setEmployeeList(emplSelectList);
        setCompanyList(compSelectList);
        
        /// Initialize custommer dto. 
        if (!customerTask.custId) {
            let updatedTaskData = {
                ...customerTask,
                custId: customerInfo?.customer?.id,
                dueDate : new Date()
              };
            dispatch(updateSliderData({ type : SliderDataAction.TaskUpdate, data : updatedTaskData}));
        }

        if (!policiesForSelect || policiesForSelect.length == 0) {
            let custPolicyData = await customerActivityService.getPoliciesForCustomer(customerInfo?.customer?.id);
            dispatch(updateSliderData({ type : SliderDataAction.TaskPolicyListUpdate, data : custPolicyData}));
        }
    }

    const onCancelClick = async () => {
        let isAnyChange = customerSliderContainerService.isAnyUnsavedChagneInTaskPage(customerSliderState);

        if (isAnyChange) {
            const result = await modalContext.showConfirmation(CustomerTaskConstants.ARE_YOU_SURE_TO_EXIT);
            if(!result){
                return;
            }
        }
        
        dispatch(cleareTabsInputs());
        slider.hideSlider();
    }
    
    const onSaveClick = async () => {
        try {

            let validationError = sliderTaskService.validateTask(customerTask);
            dispatch(updateSliderData({ type : SliderDataAction.TaskFormValidationUpdate, data : validationError}));

            if (Object.keys(validationError).length > 0) {
                toast.warn(CustomerTaskConstants.FORM_VALIDATION_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
            
            let taskPolicy = policiesForSelect?.find(item => item.value == customerTask.polId);
            let taskRequestDto = sliderTaskService.getTaskRequestDto(customerTask, taskPolicy?.originalData?.polEffDate);

            let newTask = await sliderTaskService.createTask(taskRequestDto);
            toast.success(CustomerTaskConstants.TASK_SAVED_SUCCESSFULLY);
            dispatch(notifyAppEvent({ eventType : AppEvenType.NewTaskCreated, eventData : newTask}));
            dispatch(cleareTabsInputs());
            slider.hideSlider();
        }
        catch (ex) {
            if ((ex.status == 422 && ex.data?.error == CustomerTaskConstants.CUSTOMER_DELETED) || ex.status == 404) {
                toast.error(CUSTOMER_NOT_FOUND);
                props.navigateTo('/customer/customerfilter');
                return;
            }
            toast.error(CustomerTaskConstants.ERROR_WHILE_INITIALIZING_TASK_SLIDER_COMPONENT);
            Logger.logError(CustomerTaskConstants.ERROR_WHILE_INITIALIZING_TASK_SLIDER_COMPONENT, ex);
        }
    }

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        let valueToUpdate = value;

        // Don't allow to remove customer id.
        if (propName == 'custId' && !value) {
            valueToUpdate = customerInfo?.customer?.id;
        }

        let updatedTaskData = {
            ...customerTask,
            [propName]: valueToUpdate,
          };
        dispatch(updateSliderData({ type : SliderDataAction.TaskUpdate, data : updatedTaskData}));
    }

    let lableSize = 4;
    let inputSize = 8;
    const isEditable = true;

    let mainContent = <>
         <Row>
            <Column lg={12} md={12} className="sld-col-section-header">
                <span className="sld-task-section-header">{TaskScreenLables.newTask}</span>
            </Column>
        </Row>

        <Row>
            <Column lg={12} md={12}>
                <TextFieldWithLabelInline
                    name='taskDescription'
                    label={TaskScreenLables.description}
                    placeholder={' '}
                    value={customerTask.description}
                    isRequired={true}
                    isEditable={isEditable}
                    onChange={(event) => onEntityFieldChange('description', event.currentTarget.value)}
                    type='text'
                    labelClass='inlineLable sld-inlineLable inlineLable-form'
                    tabIndex={1}
                    lglabel={lableSize}
                    mdlabel={lableSize}
                    lgText={inputSize}
                    mdText={inputSize}
                    maxLength={1000}
                    isInvalid={formValidation['description']?.isValid == false}
                    invalidWarning={formValidation['description']?.warningText}
                    invalidLableClass="lable-error sld-task-input-error"
                    className='texbox-input sld-task-descr-input'
                    errorLableDivClassName='sld-task-action-select-div'>
                </TextFieldWithLabelInline>
            </Column>
        </Row>

        <Row>
            <Column lg={12} md={12}>
                <Row>
                    <Column lg={lableSize} md={lableSize} className="sld-task-col-individual-lable">
                        <Label text={TaskScreenLables.dueDate} className="inlineLable sld-inlineLable" isRequired={true}/>
                    </Column>
                    <Column lg={6} md={6} className="sld-task-col-individual-lable">
                        <DateTimePicker
                            dateFormatCalendar="LLLL yyyy"
                            dropdownMode="scroll"
                            selected={customerTask.dueDate}
                            tabIndex={2}
                            onChange={(date) => onEntityFieldChange('dueDate', date)}
                            isInvalid={formValidation['dueDate']?.isValid == false}
                            invalidWarning={formValidation['dueDate']?.warningText}
                            invalidLableClass="lable-error sld-task-input-error"
                            errorLableDivClassName='sld-task-action-select-div'/>
                    </Column>
                </Row>
            </Column>
        </Row>

        <Row>
            <Column lg={12} md={12}>
                <DropDownWithLabelInline
                    id='ddTaskPriority'
                    label={TaskScreenLables.priority}
                    options={taskPriorityList}
                    defaultSelected={taskPriorityList?.filter(({ value }) => value === customerTask.priorityId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('priorityId', values[0])
                        }
                    }
                    isRequired={true}
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable  inlineLable-form'
                    placeholder={TaskScreenLables.pleaseSelect}
                    multiple={false}
                    isInvalid={formValidation['priorityId']?.isValid == false}
                    invalidWarning={formValidation['priorityId']?.warningText}
                    invalidLableClass="lable-error sld-act-input-error"
                    errorLableDivClassName="sld-task-action-select-div"
                    tabIndex={1}/>
            </Column>
        </Row>

        <Row>
            <Column lg={12} md={12}>
                <DropDownWithLabelInline
                    id='ddTaskStatus'
                    label={TaskScreenLables.status}
                    options={taskStatusList}
                    defaultSelected={taskStatusList?.filter(({ value }) => value === customerTask.statusId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('statusId', values[0])
                        }
                    }
                    isRequired={true}
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable  inlineLable-form'
                    placeholder={TaskScreenLables.pleaseSelect}
                    multiple={false}
                    isInvalid={formValidation['statusId']?.isValid == false}
                    invalidWarning={formValidation['statusId']?.warningText}
                    invalidLableClass="lable-error sld-act-input-error"
                    errorLableDivClassName="sld-task-action-select-div"
                    tabIndex={1}/>
            </Column>
        </Row>

        <Row>
            <Column lg={12} md={12}>
                <DropDownWithLabelInline
                    id='ddTaskTypes'
                    label={TaskScreenLables.taskType}
                    options={taskTypeList}
                    defaultSelected={taskTypeList?.filter(({ value }) => value === customerTask.taskTypeId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('taskTypeId', values[0])
                        }
                    }
                    isRequired={true}
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable  inlineLable-form'
                    placeholder={TaskScreenLables.pleaseSelect}
                    multiple={false}
                    isInvalid={formValidation['taskTypeId']?.isValid == false}
                    invalidWarning={formValidation['taskTypeId']?.warningText}
                    invalidLableClass="lable-error sld-act-input-error"
                    tabIndex={1}/>
            </Column>
        </Row>

        <Row>
            <Column lg={12} md={12}>
                <DropDownWithLabelInline
                    id='ddToUserId'
                    label={TaskScreenLables.assignedTo}
                    options={employeeList}
                    defaultSelected={employeeList?.filter(({ value }) => value === customerTask.toUserId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('toUserId', values[0])
                        }
                    }
                    isRequired={true}
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable  inlineLable-form'
                    placeholder={TaskScreenLables.pleaseSelect}
                    multiple={false}
                    isInvalid={formValidation['toUserId']?.isValid == false}
                    invalidWarning={formValidation['toUserId']?.warningText}
                    invalidLableClass="lable-error sld-act-input-error"
                    errorLableDivClassName="sld-task-action-select-div"
                    tabIndex={1}/>
            </Column>
        </Row>

        <Row>
            <Column lg={12} md={12}>
                <DropDownWithLabelInline
                    id='ddCCUserId'
                    label={TaskScreenLables.cc}
                    options={employeeList}
                    defaultSelected={employeeList?.filter(({ value }) => value === customerTask.ccUserId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('ccUserId', values[0])
                        }
                    }
                    isRequired={false}
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable  inlineLable-form'
                    placeholder={TaskScreenLables.pleaseSelect}
                    multiple={false}
                    isInvalid={formValidation['ccUserId']?.isValid == false}
                    invalidWarning={formValidation['ccUserId']?.warningText}
                    invalidLableClass="lable-error sld-act-input-error"
                    tabIndex={1}/>
            </Column>
        </Row>
       

        <Row>
            <Column lg={12} md={12}>
                <DropDownWithLabelInline
                    id='ddSldTaskPolicy'
                    label={TaskScreenLables.policyNumber}
                    options={policiesForSelect}
                    defaultSelected={policiesForSelect?.filter(({ value }) => value === customerTask.polId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('polId', values[0])
                        }
                    }
                    isRequired={false}
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable  inlineLable-form'
                    placeholder={TaskScreenLables.pleaseSelect}
                    multiple={false}
                    isInvalid={formValidation['polId']?.isValid == false}
                    invalidWarning={formValidation['polId']?.warningText}
                    invalidLableClass="lable-error sld-act-input-error"
                    tabIndex={1}/>
            </Column>
        </Row>

        <Row>
            <Column lg={12} md={12}>
                <TextInputWithLabelInline
                    name='ddTskSldClaimNumber'
                    label={TaskScreenLables.claimNumber}
                    placeholder={' '}
                    value={customerTask.claimId}
                    isEditable={isEditable}
                    onChange={(event) => onEntityFieldChange('claimId', event.currentTarget.value)}
                    type='text'
                    labelClass='inlineLable sld-inlineLable'
                    tabIndex={1}
                    lglabel={4}
                    mdlabel={4}
                    lgText={8}
                    mdText={8}
                    maxLength={60}
                    className='texbox-input'>
                </TextInputWithLabelInline>
            </Column>
        </Row>

        <Row>
            <Column lg={12} md={12}>
                <DropDownWithLabelInline
                    id='ddlTaskCustomerCompany'
                    label={TaskScreenLables.company}
                    options={companiesList}
                    defaultSelected={companiesList?.filter(({ value }) => value === customerTask.companyId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('companyId', values[0])
                        }
                    }
                    isRequired={false}
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable  inlineLable-form'
                    placeholder={TaskScreenLables.pleaseSelect}
                    multiple={false}
                    isInvalid={formValidation['companyId']?.isValid == false}
                    invalidWarning={formValidation['companyId']?.warningText}
                    invalidLableClass="lable-error sld-act-input-error"
                    tabIndex={1}/>
            </Column>
        </Row>

        {/* Action Row */}
        <Row className="mb-2 sld-task-action-row">
            <Column lg={6} md={6} className="sld-task-col">
                <Button
                    className='btn btn-outline-tertiary sld-action-btn-base'
                    key="btnSliderTaskCancel"
                    onClick={() => onCancelClick()}
                    id="btnSliderTaskCancel"
                    color='primary'
                    outlined={true}
                >{lables.CANCEL.toLocaleUpperCase()}
                </Button>

            </Column>
            <Column lg={6} md={6} className="sld-task-col text-right">
                <Button
                    className='sld-action-btn-base'
                    key="btnSliderTaskSave"
                    onClick={() => onSaveClick()}
                    tabIndex={21}
                    id="btnSliderTaskSave"
                    color='primary'
                    outlined={true}
                >{lables.SAVE.toLocaleUpperCase()}
                </Button>
            </Column>
        </Row>
    </>;

    return (
        <div className='slider-tab-content-main-div'>
            {mainContent}
        </div>
    );
}

export default SlidterTask;