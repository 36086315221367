import { TextInput, Label, Row, Column } from '../../../components'
import React from 'react'

interface Props {
  value: string
  label?: string
  name: string
  isEditable?: boolean
  type: 'text' | 'email' | 'number' | 'tel' | 'password'
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  isRequired?: boolean
  feedback?: string
  isInvalid?: boolean
  className?:string
  labelClass?:string
  labelColClassName?:string
  maxLength?:number
  tabIndex?:number
  lglabel?:number,
  mdlabel?:number,
  lgText?:number,
  mdText?:number,
  isEmpty?:boolean
  isTooShort?:boolean
  isTooLong?:boolean,
  isSame?:boolean
  errorText?:string
  invalidWarning?:string,
  invalidLableClass? : string
  textInputContainerClass? : string
}

const inputStyle = {
  // width:'100%',
  // height:'30px',
  //  fontSize:'small'
 }
 const labelStyle={
  //  fontSize:'small'

 }
const TextInputWithLabelInline = (props: Props) => {
  const {
    value,
    label,
    name,
    isEditable,
    onChange,
    placeholder,
    type,
    isRequired,
    feedback,
    isInvalid,
    className,
    labelClass,
    labelColClassName,
    maxLength,
    tabIndex,
    lglabel,
    mdlabel,
    lgText,
    mdText,
    isEmpty,
    isTooShort,
    isTooLong,
    isSame,
    errorText,
    invalidWarning,
    invalidLableClass,
    textInputContainerClass
  } = props
  const id = `${name}TextInput`
  return (
    <Row className=''>
      {label && <Column lg={lglabel} md={mdlabel} className={labelColClassName}>
        <Label text={label} htmlFor={id} isRequired={isRequired} className={labelClass} style={labelStyle} />
      </Column>}
      <Column lg={lgText} md={mdText} className={`${textInputContainerClass}`}>
        <TextInput
          feedback={feedback}
          id={id}
          isInvalid={isInvalid}
          value={value}
          disabled={!isEditable}
          onChange={onChange}
          type={type}
          placeholder={placeholder || label}
          className={className}
          style={inputStyle}
          maxLength={maxLength}
          tabIndex={tabIndex}
          isEmpty={isEmpty}
          isTooShort={isTooShort}
          isTooLong={isTooLong}
          isSame={isSame}
          errorText={errorText}
          invalidWarning={invalidWarning}
          invalidLableClass={invalidLableClass}
        />
      </Column>
    </Row>
  )
}

TextInputWithLabelInline.defaultProps = {
  value: '',
  type: 'text',
}


export default TextInputWithLabelInline
