export const TASK_PRIORITY_TABLE_ID = 'F00FFC74-9D5C-4577-BFA5-318F087B68AA'
export const TASK_STATUS_TABLE_ID = '019C55E0-E58B-43B4-B613-7D809915BD78'
export const TASK_TYPE_TABLE_ID = 'BA6F37C8-FFE7-44E1-8F54-6432E1D21B64'
export const CLAIM_STATUS_TABLE_ID = 'BCA0FEE3-09CE-4DCF-9A02-40AA868205C4'
export const LOSS_TYPE_TABLE_ID = 'AA3B0898-AD6D-40AC-B26A-C692B6BF8AB4'
export const MARITAL_STATUS_TABLE_ID = '61F869B6-9B34-4C2A-BDA9-682E977B1A89'
export const INDUSTRIES_TABLE_ID = 'DD4DC65D-E75C-457D-920B-41F0F67CAC4A'
export const OCCUPANCIES_TABLE_ID = 'CC1F06AF-B7BE-413A-AFBB-A9F380FE932E'
export const RELATIONSHIP_TABLE_ID = '64271395-2D25-422E-8C37-E2228B795FFE'
export const STATE_TABLE_ID = '6049E6EB-D7BF-4822-AD27-020CBAF14417'
export const TEMPLATE_STATUS='FFC39848-489B-4235-8851-42AB7EC6AB7C'
export const CAMPAIGN_CATEGORY='9A7408DB-142E-4A07-8305-20164F5E8928'
export const TEMPLATE_TYPE='C00FBB01-8DC5-4A48-A6F2-D75BAF42B870'
export const FIELD_TYPE='4A737673-94E8-4BB2-A376-971AF7A1A8B9'
export const FILTER_CONDITION='50FA4187-B83A-4576-83F9-5897B5FB775D'
export const QUERY_STATUS='F7223F1E-E17D-4877-ADE3-807B16A300BC'
export const CAMPAIGN_STATUS='D9B3B0C9-02F4-4933-9117-052F43A2FF64'
export const CAMPAIGN_FILTER_CONDITIONS ='50FA4187-B83A-4576-83F9-5897B5FB775D'
export const REQUEST_CAMPAIGN_FROM_EMAIL_ADDRESS='112FCF0D-5F8B-4BB1-BD9C-DE4E85DE08A0'
export const AGENCY_TABLE_ID='7A16F51A-5279-4B77-87A2-60B0B1743D41'
export const ROLE_APP_ID ='549CD2E9-2A3E-4E18-B23E-4AADA2A5A79C'
export const EMPLOYEE_STATUS ='749B4E40-18E0-4915-A70D-C068EB36689B'
export const EMPLOYEE_FULLTIMEIND ='8070FFAB-2A49-435E-9CDD-17FB6A7BC6EF'
export const COUNTRY_TABLE_ID = 'D41C76DF-6E41-4D74-B609-97DDB31C3D2B'
export const US_STATE_TABLE_ID = '68A16C92-4427-42F6-A8B0-06F18C60D38A'
export const CANADA_STATE_TABLE_ID = '930B8763-EB01-4173-BA6F-6BFD646BFDED '