import React, { useState } from 'react'
import { Card, Collapse, Row } from 'react-bootstrap'

import { ColorVariant } from '../../interfaces'
import { Icon } from '../Icon'
import { Column } from '../Layout'

interface Props {
  /** Defines the color of the panel */
  color?: ColorVariant
  /** The body for the panel */
  children?: React.ReactNode
  /** The title for the panel */
  title?: string
  /** The footer for the panel */
  footer?: string
  /** Determines if the panel can be collapsible */
  collapsible?: boolean
  /** Determines if the panel should be collapsed */
  collapsed?: boolean
  /** The className for the panel card parent element */
  className?: string
  // The classname for card body
  cardBodyClassName?: string
    /** The name for the panel */
    panelName?: string
    id?:string

    panelHeaderItems? : any
}

const Panel = (props: Props) => {
  const { color, children, footer, title, collapsible, collapsed, className, cardBodyClassName, panelName, id, panelHeaderItems } = props
  const [open, setOpen] = useState(!collapsed || !collapsible)
let isOpen= false;

  const collapseIcon = (
    <Icon
      icon={open||collapsed ? 'up-arrow' : 'down-arrow'}
      iconName={panelName}
      size='xs'
      className='me-2'
      onClick={() => cstOpen()}
      aria-controls="collapse-body"
      aria-expanded={open}
    />
  )
 const cstOpen =(status? : boolean) =>{
  if(status){
    setOpen(true);
    isOpen = true;
  }
  else{
    setOpen(!open)
    isOpen =false;
  }
 }
  let headerContent = <>{collapsible && collapseIcon}<span className="roboto-condensed-bold">{title}</span></>;
  if (panelHeaderItems)
    {
      headerContent =  <><div className='d-flex'><div className=''>
                      {collapsible && collapseIcon}</div><div className='roboto-condensed-bold panel-title flex-grow-1'>{title}</div><div className=''onClick={()=>cstOpen(true)}>{panelHeaderItems}</div>
                      </div></>
                      
                  // <><Row className='panel-header-row'><Column lg={10}> {headerContent}</Column><Column lg ={2}><div style={{display : 'flex', justifyContent : 'space-between'}}>{panelHeaderItems}</div></Column> </Row></>
                  
    }

  return (
    <Card className={`border ${className}`} border={color} id={id}>
      {title && (
        <Card.Header
          className='bg-transparent py-3 border-bottom-0'
          style={collapsible ? { cursor: 'pointer', textAlign: 'left',fontWeight:'bold' } : { textAlign: 'left',fontWeight:'bold' }}
          onClick={() => {collapsible && setOpen(isOpen?isOpen:!open)}}
        >
          {headerContent}
        </Card.Header>
      )}
      <Card.Body style={{ textAlign: 'left' }} className={cardBodyClassName}>
        {collapsible && !title && collapseIcon}
        <Collapse in={open || collapsed}>
          <div id="collapse-body">
            {children}
          </div>
        </Collapse>
      </Card.Body>
      {footer && (
        <Card.Footer style={{ textAlign: 'left', fontSize: 'smaller' }}>{footer}</Card.Footer>
      )}
    </Card>
  )
}

export { Panel }
