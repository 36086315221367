import { PolicyDtoRead } from "../model/PolicyDtoRead";
import PolicyApi from "../services/PolicyAPI";


class CustomerPolicyListService {
    policyAPI : PolicyApi = new PolicyApi();

    fetchCustomerPolicyDetails = async (customerId : string) : Promise<PolicyDtoRead[]> => {
        let policies = await this.policyAPI.fetchCustomerPolicies([customerId]);
        policies.forEach(pol => {
            this.processCustomerPolicies(pol);
        });
        return policies;
    }

    processCustomerPolicies = (policy : PolicyDtoRead) => {
        let policyAddressLine1 = [];
        let policyAddressLine2 = [];

        if (policy.address1) {
            policyAddressLine1.push(policy.address1);
        }

        if (policy.address2) {
            policyAddressLine1.push(policy.address2);
        }

        if (policy.city) {
            policyAddressLine2.push(policy.city);
        }

        if (policy.state) {
            policyAddressLine2.push(policy.state);
        }

        if (policy.zipCode) {
            policyAddressLine2.push(policy.zipCode);
        }

        let allAdress = [];
        if (policyAddressLine1.length > 0) {
            allAdress.push(policyAddressLine1.join(' '));
        }

        if (policyAddressLine2.length > 0) {
            allAdress.push(policyAddressLine2.join(' '));
        }

        policy.addressToDisplay = allAdress.join('</br>');
    }
}

export default CustomerPolicyListService;
