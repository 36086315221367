/* eslint-disable no-console */

import { Spinner } from './components'
import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import CS2 from './CS2'
import { TitleProvider } from './page-header/title/TitleContext' 
import Loading from './shared/components/Loading'
import AppContext from './shared/components/AppContext';
import ConfirmationModalContextProvider from './shared/components/ModalConfirmationContext'
import SliderProvider from './shared/components/Slider/sliderProvider'
import { QueryClient, QueryClientProvider } from 'react-query';

//import { getCurrentSession } from './user/user-slice'
const queryClient = new QueryClient();

const App: React.FC = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [isDataSaved, setDirtyReads] = useState(true);
  const [isNoteDataSaved, setNoteDirtyReads] = useState(true);
  const [isExit, setisExit] = useState(false);
const sessionValues={
  formSavedStatus:isDataSaved,
  noteSavedStatus:isNoteDataSaved,
  showExitConfirmation:isExit,
  isDetailWithNoteSave:false,
  isSaveAsCliked:false
}
//console.log('parent', sessionValues.formSavedStatus)
  // useEffect(() => {
  //     let cancelled = false
      
  //     //initalization of the app ...

  //     setLoading(false)
      
  //     return () => {
  //       cancelled = true
  //     }
  // }, [dispatch])

  // if (loading) {
  //   return null
  // }

  return (
    <>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            <TitleProvider>
            <AppContext.Provider value={sessionValues}>
            <ConfirmationModalContextProvider>
              <SliderProvider>
              <div id='LoadingDiv' className={''} style={{
               position: 'absolute',
               right: '0',
               top: '0',
               width: '100%',
              }}><Spinner loading={true} size={20} type="BarLoader" /></div>
              <Route path="/" component={CS2} />
              </SliderProvider>
            </ConfirmationModalContextProvider>
              </AppContext.Provider>
            </TitleProvider>
          </Switch>
        </Suspense>
      </BrowserRouter>
      </QueryClientProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}

      
    </>
  )
}

export default App
