import React, { useContext, useEffect, useState } from "react";
import './SliderActivity.scss';
import useSlider from "../../../shared/components/Slider/useSlider";
import { Button, Column, DateTimePicker, Label, Row } from "../../../components";
import DropDownWithLabelInline from "../../../shared/components/input/DropDownWithLabelInline";
import { SelectOption } from "../../../shared/components/input";
import TextFieldWithLabelInline from "../../../shared/components/input/TextFieldWithLabelInline";
import TextInputWithLabelInline from "../../../shared/components/input/TextInputWithLabelInline";
import CustomerActivityService from "../../customer-activity/CustomerActivityService";
import Logger from "../../../shared/services/Logger";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/store";
import { updateLookupData } from "../../customer-slice/Customer-lookup-slice";
import { SlicerLookUpConstant, SliderActivityConstant } from "../../../shared/util/constant";
import * as  lables from "../../../shared/util/label";
import { useConfirmationModalContext } from "../../../shared/components/ModalConfirmationContext";
import SliderActivityService from "./SliderActivityService";
import { AppEvenType, notifyAppEvent } from "../../../shared/store/component-notification/ComponentNotification";
import { cleareTabsInputs, SliderDataAction, updateSliderData } from "../CustomerSliderContext";
import CustomerDetailService from "../../customer-details/CustomerDetailService";
import { updateCustomers } from "../../customer-slice/Customer-slice";
import CustomerSliderContainerService from "../CustomerSliderContainerService";
import { AbilityContext } from "../../../shared/abilities/AbilityContext";
import * as constant from '../../../shared/util/constant';

export interface ISliderActivity {
    customerId? : string
    navigateTo : (path : string) => void
}

const SliderActivity : React.FunctionComponent<ISliderActivity> = (props) => {
    const dispatch = useDispatch();
    let customerInfo = useSelector((state: RootState) => state?.customerSlice);
    let customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);
    const slider = useSlider();
    const modalContext = useConfirmationModalContext();
    const customerActivityService = new CustomerActivityService();
    const customerDetailService = new CustomerDetailService();
    const sliderActivityService = new SliderActivityService();
    const customerSliderContainerService = new CustomerSliderContainerService();

    const activityTypeList = useSelector((state: RootState) => state?.LookupState.activityTypeList);

    const [activityTypes, setActivityTypes] = useState<SelectOption[]>([]);
    const [customerOptions, setCustomerOptions] = useState<SelectOption[]>([]);
    const [activitiyCustId, setActivitiyCustId] = useState<string>(null);

    /// These two data are dynamic and dont have dedicated store, hence storing as part of activity page info.
    const activityData = customerSliderState.activityPage.activity;
    const formValidation = customerSliderState.activityPage.formValidation;
    const policiesForSelect = [...customerSliderState.activityPage.policiesForSelect];

    const ability = useContext(AbilityContext);
    const canUserCreateCustomerActivity = ability.can('create','Customer Activity',constant.ScreenName.CUSTOMER_ACTIVITY);


    useEffect(() => {
        initializeComponent();
    },[]);

    const initializeComponent = async () => {
        try {

            // Fetch and configure activity types
            if (!activityTypeList || activityTypeList.length == 0) {
                let actTypeData = await customerActivityService.getActivityTypes();
                setActivityTypes(actTypeData);
                dispatch(updateLookupData({data : actTypeData, type : SlicerLookUpConstant.ACTIVITY_TYPE}));
            }
            else {
                setActivityTypes([...activityTypeList]);
            }

            if (!policiesForSelect || policiesForSelect.length == 0) {
                let custPolicyData = await customerActivityService.getPoliciesForCustomer(props.customerId);
                dispatch(updateSliderData({ type : SliderDataAction.ActivityPolicyListUpdate, data : custPolicyData}));
            }

            // Fetch and configure customer options
            let custOptions : SelectOption[] = [];
            let cstId = null;
            if (customerInfo?.customer?.id) {
                custOptions =  [
                    {
                        label : customerInfo?.customer?.displayName,
                        value : customerInfo?.customer?.id
                    }
                ];

                cstId = customerInfo?.customer?.id;
            }
            else {
                let cst = await customerDetailService.fetchCustomerById(props.customerId);
                if (cst) {
                    custOptions =  [
                        {
                            label : cst.displayName,
                            value : cst.id
                        }
                    ];

                    cstId = cst.id;

                    dispatch(updateCustomers(cst));
                }
            }

            setCustomerOptions(custOptions);
            setActivitiyCustId(cstId);
        }
        catch(ex) {
            toast.error(SliderActivityConstant.ERROR_WHILE_LOADING_ACTIVITY_PAGE);
            Logger.logError(SliderActivityConstant.ERROR_WHILE_LOADING_ACTIVITY_PAGE);
        }
    }

    const onEntityFieldChange = (propName: string, value: string | Date|boolean) => {
        let updatedActivityData = {
            ...activityData,
            [propName]: value,
          };
        dispatch(updateSliderData({ type : SliderDataAction.ActivityUpdate, data : updatedActivityData}));
    }

    let lableSize = 4;
    let inputSize = 8;
    const isEditable = true;

    const onCancelClick = async () => {
        let isAnyChange = customerSliderContainerService.isAnyUnsavedChangeInActivityTab(customerSliderState);

        if (isAnyChange) {
            const result = await modalContext.showConfirmation(SliderActivityConstant.ARE_YOU_SURE_TO_EXIT);
            if(!result){
                return;
            }
        }
        
        dispatch(cleareTabsInputs());
        slider.hideSlider();
    }

    const onSaveClick = async () => {
        try {

            let validationError = sliderActivityService.validateActivity(activityData);
            dispatch(updateSliderData({ type : SliderDataAction.ActivityFormValidationUpdate, data : validationError}));
            if (Object.keys(validationError).length > 0) {
                toast.warn(SliderActivityConstant.FORM_VALIDATION_ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            let requestDto = sliderActivityService.getActivityRequest(activityData, props.customerId);
            let activityCreated = await customerActivityService.addNewActivity(requestDto);
            toast.success(SliderActivityConstant.ACTIVITY_SAVED_SUCCESSFULLY);
            dispatch(notifyAppEvent({ eventType : AppEvenType.NewCustomerActivityCreated, eventData : activityCreated}));
            dispatch(cleareTabsInputs());
            slider.hideSlider();
        }
        catch (ex) {
            if ((ex.status == 422 && ex.data?.error == constant.CustomerTaskConstants.CUSTOMER_DELETED) || ex.status == 404) {
                toast.error(constant.CUSTOMER_NOT_FOUND);
                props.navigateTo('/customer/customerfilter');
                return;
            }
            toast.error(SliderActivityConstant.ERROR_WHILE_SAVING_ACTIVITY);
            Logger.logError(SliderActivityConstant.ERROR_WHILE_SAVING_ACTIVITY, ex);
        }
    }

    const mainContennt = <>
        <Row>
            <Column lg={12} md={12} className="sld-col-section-header">
                <span className="sld-act-section-header">{lables.CustomerActivityLabel.NEW_ACTIVITY}</span>
            </Column>
        </Row>
        <Row>
            <Column lg={12} md={12}>
                <DropDownWithLabelInline
                    id='action'
                    label={lables.CustomerActivityLabel.ACTIVITY_TYPE}
                    options={activityTypes}
                    defaultSelected={activityTypes?.filter(({ value }) => value === activityData.activityTypeId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('activityTypeId', values[0])
                        }
                    }
                    isRequired={true}
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable  inlineLable-form'
                    placeholder={lables.CustomerActivityLabel.PLEASE_SELECT}
                    multiple={false}
                    isInvalid={formValidation['activityTypeId']?.isValid == false}
                    invalidWarning={formValidation['activityTypeId']?.warningText}
                    invalidLableClass="lable-error sld-act-input-error"
                    tabIndex={1}/>
            </Column>
        </Row>
        <Row>
            <Column lg={12} md={12}>
                <TextFieldWithLabelInline
                    name='activityDescription'
                    label={lables.CustomerActivityLabel.DESCRIPTION}
                    placeholder={' '}
                    value={activityData.description}
                    isRequired={true}
                    isEditable={isEditable}
                    onChange={(event) => onEntityFieldChange('description', event.currentTarget.value)}
                    type='text'
                    labelClass='inlineLable sld-inlineLable inlineLable-form'
                    tabIndex={3}
                    lglabel={lableSize}
                    mdlabel={lableSize}
                    lgText={inputSize}
                    mdText={inputSize}
                    maxLength={765}
                    isInvalid={formValidation['description']?.isValid == false}
                    invalidWarning={formValidation['description']?.warningText}
                    invalidLableClass="lable-error sld-act-input-error"
                    className='texbox-input sld-act-descr-input'>
                </TextFieldWithLabelInline>
            </Column>
        </Row>
        <Row>
            <Column lg={12} md={12}>
                <Row>
                    <Column lg={lableSize} md={lableSize} className="sld-act-col-individual-lable">
                        <Label text={lables.CustomerActivityLabel.ACTIVITY_EFFECTIVE_DATE} className="inlineLable sld-inlineLable sld-inlineLable"/>
                    </Column>
                    <Column lg={4} md={4} className="sld-act-col-individual-input">
                        <DateTimePicker
                            dateFormatCalendar="LLLL yyyy"
                            dropdownMode="scroll"
                            selected={activityData.activityEffDate}
                            maxDate={new Date()}
                            tabIndex={9}
                            onChange={(date) => onEntityFieldChange('activityEffDate', date)}/>
                    </Column>
                    <Column lg={3} md={3} className="sld-act-col-individual-input">
                        <DateTimePicker
                            selected={activityData.activityEffDate}
                            tabIndex={9}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            maxDate={new Date()}
                            minTime={new Date(1990, 0, 1)}
                            maxTime={new Date()}
                            dateFormat="h:mm aa"
                            timeCaption="Time"
                            placeholderText="hh:mm"
                            className="sld-act-time-picker"
                            isInvalid={formValidation['activityEffDate']?.isValid == false}
                            invalidWarning={formValidation['activityEffDate']?.warningText}
                            invalidLableClass="lable-error sld-act-input-error"
                            onChange={(date) => onEntityFieldChange('activityEffDate', date)}/>
                    </Column>
                </Row>
            </Column>
        </Row>
        <Row>
            <Column lg={12} md={12} className="sld-col-section-header">
                <span className="sld-act-section-header">{lables.CustomerActivityLabel.CUSTOMER_POLICY_INFORMATION}</span>
            </Column>
        </Row>
        <Row>
            <Column lg={12} md={12}>
                {/* TODO : This dropdown is only for display now as there is no support for applicant. 
                Once applicant details are available, will have to handle changes here */}
                <DropDownWithLabelInline
                    id='action'
                    label={lables.CustomerActivityLabel.CUSTOMER_SELECT}
                    options={customerOptions}
                    defaultSelected={customerOptions?.filter(({ value }) => value == activitiyCustId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('activitiyCustId', values[0])
                        }
                    }
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable inlineLable-form'
                    placeholder={lables.CustomerActivityLabel.PLEASE_SELECT}
                    multiple={false}
                    tabIndex={1}/>
            </Column>
        </Row>
        <Row>
            <Column lg={12} md={12}>
                <DropDownWithLabelInline
                    id='action'
                    label={lables.CustomerActivityLabel.POLICY_SELECT}
                    options={policiesForSelect}
                    defaultSelected={policiesForSelect?.filter(({ value }) => value === activityData.policyId)}
                    onChange={
                        (values) => {
                            onEntityFieldChange('policyId', values[0])
                        }
                    }
                    labelSize={lableSize}
                    dropDownSize={inputSize}
                    labelClass='inlineLable sld-inlineLable inlineLable-form'
                    placeholder={lables.CustomerActivityLabel.PLEASE_SELECT}
                    multiple={false}
                    tabIndex={1}/>
            </Column>
        </Row>
        <Row>
            <Column lg={12} md={12}>
                <TextInputWithLabelInline
                    name='claimNumber'
                    label={lables.CustomerActivityLabel.CLAIM_SELECT}
                    placeholder={' '}
                    value={activityData.claimId}
                    isEditable={isEditable}
                    onChange={(event) => onEntityFieldChange('claimId', event.currentTarget.value)}
                    type='text'
                    labelClass='inlineLable sld-inlineLable'
                    tabIndex={1}
                    lglabel={4}
                    mdlabel={4}
                    lgText={8}
                    mdText={8}
                    maxLength={60}
                    className='texbox-input'>
                </TextInputWithLabelInline>
            </Column>
        </Row>

         {/* Action Row */}
         <Row className="mb-2">
            <Column lg={6} md={6}>
                <Button
                    className='btn btn-outline-tertiary sld-action-btn-base'
                    key="btnActivityCancel"
                    onClick={() => onCancelClick()}
                    id="btnActivityCancel"
                    color='primary'
                    outlined={true}
                >{lables.CANCEL.toLocaleUpperCase()}
                </Button>

            </Column>
            <Column lg={6} md={6} className="text-right">
                <Button
                    className='sld-action-btn-base'
                    key="btnActivitySave"
                    onClick={() => onSaveClick()}
                    tabIndex={21}
                    id="btnActivitySave"
                    color='primary'
                    outlined={true}
                    disabled={!canUserCreateCustomerActivity}
                >{lables.SAVE.toLocaleUpperCase()}
                </Button>
            </Column>
        </Row>
    </>;

    return <div className="slider-tab-content-main-div">
        {mainContennt}
    </div>;
}

export default SliderActivity;