import exp from "constants";

export const CustomerScreenTabs = {
    policies : { key : "policies", text : "POLICIES"},
    tasks : { key : "tasks", text : "TASKS" },
    activity : { key : "activity", text : "ACTIVITY" },
    history : { key : "history", text : "HISTORY" },
    campaigns : { key : "campaigns", text : "CAMPAIGNS" },
    notes : { key : "notes", text : "NOTES" },
    documents : { key : "documents", text : "DOCUMENTS" },
    customer : { key : "customer", text : "CUSTOMER" },
    contacts : { key : "contacts", text : "CONTACTS" },
    losshistory : { key : "losshistory", text : "LOSS HISTORY" }
};

export const AdminScreenTabs = {
    general: {key: "general", text: "GENERAL", submenus: [
        { menuKey: 'lookuptables', menuText: 'Lookup Tables' },
        { menuKey: 'zipcodes', menuText: 'Zip Codes' }
    ]},
    usersetup: {key: "usersetup", text: "USER SETUP"
        //, submenus: [
        // { menuKey: 'usersetupsub', menuText: 'User Setup'},
        // { menuKey: 'taskusergroup', menuText: 'Task User Group'}
    //]
},
    roleprivilege: {key: "roleprivilege", text: "ROLE PRIVILEGE SETUP"},
    security: {key: "security", text: "SECURITY"},
    company: {key: "company", text: "COMPANY", submenus: [
        { menuKey: 'insurance', menuText: 'Insurance' },
        { menuKey: 'mortgage', menuText: 'Mortgage'},
        { menuKey: 'autolosspayee', menuText: 'Auto Loss Payee' },
        { menuKey: 'priorinsurancecompany', menuText: 'Prior Insurance Company' }
    ]},
    agency: {key: "agency", text: "AGENCY", submenus: [
        { menuKey: 'agencysub', menuText: 'Agency'}
    ]}
}

export const DATA_IDs = {
    COMPANIES : {
        "Westwood_Insurance_Agency" : "755c7cb4-a38a-4ca0-8758-ebe7c80eb84e"
    },
    COUNTRIES : {
        "USA" : "68a16c92-4427-42f6-a8b0-06f18c60d38a",
        "CANADA" : "930b8763-eb01-4173-ba6f-6bfd646bfded"
    }
};

export const SUCCESS_MESSAGE='Data submitted successfully.'
export const UPDATE_SUCCESS_MESSAGE='Data updated successfully.'
export const DELETE_SUCCESS_MESSAGE='Data deleted successfully.'
export const QUERY_DELETE_SUCCESS_MESSAGE='Query deleted successfully.'
export const TEMPLATE_DELETE_SUCCESS_MESSAGE='Template deleted successfully.'
export const CAMPAIGN_SEND_TEST_SUCCESS_MESSAGE='Email sent successfully'
export const ERROR_OCCURED="An error occured:"
export const ERROR_MESSAGE='Error occured while submitting the data.'
export const ERROR_MESSAGE_QUERYNAME='Query name is required.'
export const ERROR_MESSAGE_Duplicate='Please update the Query Name.'
export const ERROR_MESSAGE_LOAD='Not able to load data.'
export const ERROR_MESSAGE_SEND_ON_DATE_EMPTY="Send On Date cannot be empty"
export const ERROR_MESSAGE_SEND_ON_DATE_PAST_DATE="Send on Date cannot be in the Past"
export const ERROR_MESSAGE_SEND_ON_DATE_BLACKOUT_DATE="Blackout Date Selected, Select another Date"
export const CAMPAIGN_SEND_TEST_ERROR_MESSAGE='Email failed to send'
export const SEND_TEST_INVALID_EMAIL_FORMAT_WARNING='Invalid Email format, Valid email format(eg: abc12@defg.com)'
export const SAVE_NOTE_SUSSESS='Note Saved successfully.'
export const UPDATE_NOTE_SUSSESS='Note updated successfully.'
export const DELETE_NOTE_SUSSESS='Note Deleted successfully.'
export const UNAUTHORISED_USER='You are not authorised to perform this action.'
export const INACTIVE='Inactive'
export const INACTIVATE='Inactivate'
export const CURRENT_USER='current user'
export const SAVED='Saved'
export const REJECTED= 'Rejected'
export const SENT='Sent'
export const Waiting_Approval='Waiting Approval'
export const APPROVED = 'Approved'
export const CONFIRM_BLACKOUT_DATE_SELECTION ="Date Selected is a Blackout Date, do you want to continue?"
export const CONFIRM_INACTIVE_CAMPAIGN_SELECTION="Campaign is being set to Inactive,this can not be reversed.Do you want to continue?"
export const CONFIRM_CAMPAIGN_DELETION='Are you sure you want to delete the Campaign?'
export const DELETE_CAMPAIGN_SUSSESS='Campaign Deleted successfully.'
export const EMPTY_SELECT ="Field should not be left empty."
export const FIELD_MIN_CHAR ="Field should have more than {} characters."
export const FIELD_MAX_CHAR ="Field should have more than {} characters."
export const VALUE_NOT_CHANGED ="Field value not changed."
export const CAMPAIGN_DASHBOARD_DESCRIPTION ="Current Month and next month campaigns"
export const SELECT_STATUS ="Please select the status."
export const CONFIRM_QUERY_DELETION="Are you sure you want to delete the Query?"
export const CONFIRM_EXIT="Do you want to leave without saving?"
export const DESCRIPTION_REQUIRED="Description is required."
export const TYPE_SENDGRID_TEMPLATE="Type/ Sendgrid Template"
export const SELECT_TYPE="Please select the Type."
export const TEMPLATE_TYPE="Select template type."
export const SELECT_SENGRID_TEMPLATE="Select Sendgrid Template"
export const FEEDBACK_SELECT_SENGRID_TEMPLATE="Please select the Sendgrid Template."
export const SELECT_CATEGORY="Please select the Category."
export const CONFIRM_TEMPLATE_DELETION="Are you sure you want to delete the Template?"
export const UNIQUE_QUERY_ERROR="Query Name must be unique"
export const EMPTY_QUERY_ERROR="Query Name should not be left blank."
export const EMPTY_TEMPLATE_ERROR="Template Name should not be left blank."
export const MAX_QUERY_LEN_ERROR="Field require 3 to 150 characters"
export const MAX_TEMPLATE_LEN_ERROR="Field require 3 to 150 characters"
export const MIN_QUERY_LEN_ERROR="Field require 3 to 150 characters"
export const MIN_TEMPLATE_LEN_ERROR="Field require 3 to 150 characters"
export const MIN_DESC_LEN_ERROR="Field require 3 to 250 characters"
export const MAX_DESC_LEN_ERROR="Field require 3 to 250 characters"
export const EMPTY_DESC_ERROR="Description should not be left blank."
export const EMPTY_DB_FIELD_ERROR="Field should not be left blank."
export const EMPTY_LOGIC_FIELD_ERROR="Condition should not be left blank."
export const EMPTY_FRIENDLY_NAME_FIELD_ERROR="Friend name should not be left blank."
export const EMPTY_FIELD_TYPE_FIELD_ERROR="Field type should not be left blank."
export const EMPTY_DATA_TYPE_LOGIC_ERROR="Data type logic should not be left blank."
export const EMPTY_SQL_FIELD_ERROR="Query should not be left blank."
export const MIN_SQL_FIELD_ERROR="Query requires more than 10 characters"
export const EMPTY_STATUS_FIELD_ERROR="Status should not be left blank."
export const FIELD_VALUE_SQL="Dropdown-SQL"
export const FIELD_VALUE_LIST="Dropdown-List"
export const FIELD_VALUE_LIST_INSERT_TEXT="Please enter value separated by ;"
export const FIELD_VALUE_SQL_INSERT_TEXT="Please enter the SQL query"
export const TEMPLATE_TYPE_EMPTY="Type should not be empty."
export const SENDGRID_TEMPLATE_EMPTY="Sendgrid template should not be empty."
export const TEMPLATE_CATEGORY_EMPTY="Category should not be empty."
//export const TEMPLATE_NAME_EXIST="Template name must be unique"
export const SAVE='Save'
export const ACTIVE='Active'
export const SAVE_AS='Save As'
export const TEMPLATE_TYPE_MARKETING='Marketing'
export const TEMPLATE_TYPE_TRANSCTIONAL='Transactional'
export const TEMPLATE_TYPE_ALL='ALL'
export const DEFAULT_ERROR='Something went wrong. Not able to fulfill your request.'
export const CAN_DELETE_TEMPLATE='Template can not be deleted, because it is being used by a Campaign.'
export const UNIQUE_TEMPLATE_NAME='Template Name must be unique'
export const FIELD_BLANK='Field cannot be blank'
export const UNIQUE_CAMPAIGN_ERROR ='Campaign Name must be unique'
export const NAME_LEN_ERROR ='Field require 3 to 150 characters'
export const DESCRIPTION_LEN_ERROR ='Field require 3 to 250 characters'
export const FIELD_INCORRECT='Please select the correct value'
export const SHOW_SCROLL_BUTTON_POSITION=500;
export const SQL='SQL';
export const DEFAULT_GUID='00000000-0000-0000-0000-000000000000'
export const PLEASE_SELECT='--Please Select--'
export const CONFIRM_NOTE_DELETION = "Are you sure you want to delete the Note?"
export const AUTO_CLOSE=2000;
export const QUERY_SUCCESS_MESSAGE='Query submitted successfully.'
export const QUERY_UPDATE_SUCCESS_MESSAGE='Query updated successfully.'
export const TEMPLATE_SUCCESS_MESSAGE='Template submitted successfully.'
export const TEMPLATE_UPDATE_SUCCESS_MESSAGE='Template updated successfully.'
export const CAMPAIGN_REPORT='Campaign Report'
export const FILTER_CRITERIA_CONSOLIDATED_ERROR='Fix highlighted errors'
export const FILTER_CRITERIA_ZIP_NUMBER_CURRENCY_PLACEHOLDER = 'Only no.s separated by ; allowed'
export const REQUEST_CAMPAIGN_SUCCESS_MESSAGE = 'Campaign Requested and Email has been Sent.'
export const REQUEST_CAMPAIGN_ERROR_MESSAGE = 'Request Campaign Failed'
export const PLACEHOLDER_CURRENCY_RANGE_TO='To Currency Range'
export const PLACEHOLDER_CURRENCY_RANGE_FROM='From Currency Range'
export const PLACEHOLDER_NUMBER_RANGE_TO='To Number Range'
export const PLACEHOLDER_NUMBER_RANGE_FROM='From Number Range'
export const PLACEHOLDER_DATE_RANGE_FROM='From Date mm/dd/yyyy'
export const PLACEHOLDER_DATE_RANGE_TO='To Date mm/dd/yyyy'
export const INACTIVE_QUERY_SELECTED_WARNING='Active query must be selected'
export const INACTIVE_TEMPLATE_SELECTED_WARNING= 'Active template must be selected'
export const ERROR_MESSAGE_CAMPAIGN_FROM_EMAIL_EMPTY="From Email cannot be empty"
export const MAX_YEAR = 2050
export const MAX_DATE = new Date("12/31/2050")


//Constant IDs
//3rd Party Template
export const EXTERNAL_TEMPLATE_INACTIVE='0644C9AB-9FEA-4539-8499-FDE607EBCB40'
export const TEMPLATE_TYPE_MARKETING_ID='0A5D4DA6-B452-456C-B19D-700BBEEC2243'
export const TEMPLATE_TYPE_TRANSCTIONAL_ID='F939D9D9-4F8B-4EEA-A178-F2BD3C85D809'
export const STATUS_SAVED='641004B8-3A6E-4731-9070-BF1E50B9DC3C'
export const STATUS_ACTIVE='71DC46A1-5A2A-422F-963A-78BBF78A263E'
export const STATUS_INACTIVE='1CCA1674-9F92-44CE-B8E0-AA1DED814BDF'

//Queries
export const QUERY_STATUS_SAVED='03E80277-7096-465C-B0CA-CEBC807ED9A7'
export const QUERY_STATUS_ACTIVE='9DC70078-3FAD-4E30-BDC8-F7718AC98765'
export const QUERY_STATUS_INACTIVE='0644C9AB-9FEA-4539-8499-FDE607EBCB40'
export const DROPDOWN_SQL_ID='5aeadd77-0a24-4219-910d-15e406e1bd08'
export const DROPDOWN_LIST_ID='1e9adae2-fbf1-42d9-bf6b-de1af266e60c'


// //Campaign Filter Data Type Ids

export const Data_Type_Dropdown_SQL="5aeadd77-0a24-4219-910d-15e406e1bd08"
export const Data_Type_ZipCode="ff729d37-63f0-4aa6-9af2-457871867254"
export const Data_Type_Currency_Range="47994ac7-5453-42d6-84d3-73f2ab13d325"
export const Data_Type_Number_Range="63bad727-bce0-48b7-9932-779003362140"
export const Data_Type_Currency="ecc27e57-ceb1-4ef3-ab56-7797b08be47e"
export const Data_Type_Date_Range="9dcc86f9-e74a-4e0b-a620-79cc42b9c05d"
export const Data_Type_Date="0d269253-5fdf-42c0-8ded-dd58a220c3e2"
export const Data_Type_Dropdown_List="1e9adae2-fbf1-42d9-bf6b-de1af266e60c"
export const Data_Type_Number="89c5122c-b3d5-478b-910f-f47608204f41"
export const DATA_TYPE_FREEFORM="dde2fd39-2313-4114-ba7d-04beb993ffd9"



// Customer Module
export const CUSTOMER_NOT_FOUND = "Customer not found."
// Customer Policies List.
export const CUSTOMER_POLICY_LIST_ERROR_WHILE_FETCHING_POLICIES ='We encountered an issue while fetching customer policies. Please try again in a moment. If the problem persists, contact support team for assistance.'
export const CUSTOMER_MAIN_PORTAL_INITIALIZE_ERROR ='We encountered an issue while initializing customer main view. Please try again in a moment. If the problem persists, contact support team for assistance.'
// Customer Standard Search.
export const CUSTOMER_STANDARD_SEARCH_ERROR_WHILE_SEARCHING ='We encountered an issue searching for customers with given filters. Please try again in a moment. If the problem persists, contact support team for assistance.'
export const CUSTOMER_STANDARD_SEARCH_NO_RESULT_FOUND =`We couldn't find any customers matching the search criteria. Please adjust your search filters and try again.`
export const CUSTOMER_DETAIL_ERROR_WHILE_LOADING_INITIAL_DATA ='We encountered an issue while fetching customer details. Please try again in a moment. If the problem persists, contact support team for assistance.'
export const CONFIRM_DEPENDENT_DELETE="Are you sure to remove dependent?"
export const CONFIRM_CONTACT_DELETE="Are you sure to remove contact?"
export const CUSTOMER_SAVED_SUCCESSFULLY="Customer information saved successfully.";
export const CUSTOMER_FORM_VALIDATION_ERROR_MESSAGE = "There are few errors in page. Please check the highlighted inputs.";
export const CUSTOMER_SPECIFY_CUSTOMER_DETAILS = "Please specify customer details.";
export const CUSTOMER_MANDATORY_FIELDS_ERROR = "Either First or Last Name or Display name must be entered";
export const CUSTOMER_MANDATORY_DEPEDENT_FIELDS_ERROR = "Either First or Last name must be entered";
export const CUSTOMER_FIRST_NAME_MIN_LENGTH = "Field require 2 to 60 characters";
export const CUSTOMER_LAST_NAME_MIN_LENGTH = "Field require 2 to 60 characters";
export const CUSTOMER_DISPLAY_NAME_MIN_LENGTH = "Field require 2 to 100 characters";
export const CUSTOMER_INVALID_EMAIL = "Specify a valid email";
export const CUSTOMER_AGENCY_MANDATORY = "Field cannot be blank";
export const CUSTOMER_YEAR_EMPLOYED_NUMERIC = "Year employed must be numeric";
export const CUSTOMER_YEAR_EMPLOYED_RANGE = "Year employed cannot be less than 1990";
export const CUSTOMER_ADDRESS1_MIN_LENGTH = "Field require 2 to 100 characters";
export const CUSTOMER_CITY_MIN_LENGTH = "Field require 2 to 100 characters";
export const CUSTOMER_ZIP_MIN_LENGTH = "Field require 2 to 9 characters";
export const CONTACT_NO_RANGE = "Field require 10 digits";

export const CUSTOMER_DELETE_ERROR = "We encountered an issue while deleting customer. Please try again in a moment. If the problem persists, contact support team for assistance.";
export const CUSTOMER_DELETE_ACTIVE_POLICIES = "Cannot delete customer: Active policies must be Canceled or Expired first.";
export const CUSTOMER_DELETE_INACIVE_POLICY_IN_LAST_ONE_YEAR = "Cannot delete customer: Policies canceled or expired within the last 12 months.";
export const CUSTOMER_DELETE_ACTIVE_TASK = "Cannot delete customer: All open tasks must be completed first.";
export const CUSTOMER_DELETE_SUCCESS = "The customer has been deleted.";
export const CUSTOMER_DELETE_CONFIRM = "Are you sure to delete customer?";
export const CUSTOMER_DELETE_CONFIRM_TITLE = "Delete Customer";
export const CUSTOMER_DELETE_CONFIRM_TEXT = "Enter DELETE here to confirm";
export const CUSTOMER_DELETE_CONFIRMATION_TEXT = "DELETE";
export const CUSTOMER_DELETE_INCOMPLETE_TASKS_RESPONSE = "INCOMPLETE_TASKS";
export const CUSTOMER_DELETE_ACTIVE_POLICIES_RESPONSE = "ACTIVE_POLICIES";
export const CUSTOMER_DELETE_INACTIVE_POLICIES_IN_LAST_ONE_YEAR_RESPONSE = "INACTIVE_POLICIES_IN_LAST_ONE_YEAR";
export const CUSTOMER_DELETE_ACTIVITY_EXISTS = "CUSTOMER_ACTIVITY_EXISTS";
export const CUSTOMER_DELETE_ACTIVITY_EXISTS_MESSAGE = "Customer cannot be deleted as there are associated Activity(ies).";


//Employee and User in Admin
export const ADMIN_EMPLOYEE_MANDATORY = "Field cannot be blank"
export const ADMIN_EMPLOYEE_COUNTRYCODE = "Only two characters allowed"


export const CUSTOMER_ERROR_WHILE_SAVING="We encountered an issue while saving customer information. Please try again in a moment. If the problem persists, contact support team for assistance.";

export const SliderContactConstants = {
    NO_CHANGES_TO_SAVE : "No changes have been made."
}

export const CustomerPolicy = {
    UNKNOWN : "UNKNOWN"
}

export const AcivityPageConstants = {
    ERROR_WHILE_INITIALIZING_DASHBOARD : "We encountered an issue while loading page. Please try again in a moment. If the problem persists, contact support team for assistance.",
}

export const SliderActivityConstant = {
    ARE_YOU_SURE_TO_EXIT: "Do you want to leave without saving?",
    ERROR_WHILE_LOADING_ACTIVITY_PAGE : "We encountered an issue while loading page. Please try again in a moment. If the problem persists, contact support team for assistance.",
    FORM_VALIDATION_ERROR_MESSAGE : "There are few errors in page. Please check the highlighted inputs.",
    ACTIVITY_SAVED_SUCCESSFULLY : "Acivity saved successfully.",
    ERROR_WHILE_SAVING_ACTIVITY : "We encountered an issue while saving activity. Please try again in a moment. If the problem persists, contact support team for assistance.",
    DATE_CAN_NOT_BE_A_FUTURE_DATE : "Date & time cannot be in future"
}

export const CustomerTaskConstants = {
    ERROR_WHILE_LOADING_TASK_GRID_PAGE : "We encountered an issue while loading page. Please try again in a moment. If the problem persists, contact support team for assistance.",
    ERROR_WHILE_LOADING_TASK_LIST : "We encountered an issue while fetching tasks. Please try again in a moment. If the problem persists, contact support team for assistance.",
    ERROR_WHILE_INITIALIZING_TASK_SLIDER_COMPONENT : "We encountered an issue while loading page. Please try again in a moment. If the problem persists, contact support team for assistance.",
    ARE_YOU_SURE_TO_EXIT: "Do you want to leave without saving?",
    FORM_VALIDATION_ERROR_MESSAGE : "There are few errors in page. Please check the highlighted inputs.",
    INVALID_TASK_DATE :"Invalid task Date",
    TASK_SAVED_SUCCESSFULLY : "Task saved successfully.",
    NO_HISTORY_AVAILABLE : "No history available",
    TASK_DIALOG_TITLE : "Task History",
    CUSTOMER_DELETED : "CUSTOMER_IS_DELETED",
    ERROR_WHILE_FETCHGIN_TASK_HISTORY : "We encountered an issue while fetching task history. Please try again in a moment. If the problem persists, contact support team for assistance."
}

export const APP_IDs = {
    CS2_APP_ID : '549CD2E9-2A3E-4E18-B23E-4AADA2A5A79C'
};

export const CustomerLossHistoryConstant = {
    ADD_LOSS : "ADD LOSS",
    PLEASE_SELECT : "Please Select",
    COMPANY_COLUMN_HEADER : "Company",
    DATE_OF_LOSS_COLUMN_HEADER : "Date of Loss",
    STATUS_COLUMN_HEADER : "Status",
    KIND_OF_LOSS_COLUMN_HEADER : "Kind of Loss",
    LOSS_HISTORY_DELETE_CONFIRMATION : "Are you sure to remove this loss history record?",
    ERROR_OCCURED_WHILE_FETCHING_LOSS_HISTORY : "We encountered an issue while fetching customer loss history. Please try again in a moment. If the problem persists, contact support team for assistance.",
    ERROR_OCCURED_WHILE_INIT_COMPONENT : "We encountered an issue while loading customer loss history. Please try again in a moment. If the problem persists, contact support team for assistance.",
    DETAIL_REQURIED : "Customer loss history details are required.",
    FIELD_CANNOT_BE_BLANK : "Field cannot be blank",
    FORM_VALIDATION_ERROR_MESSAGE : "There are few errors in page. Please check the highlighted inputs.",
    SAVE_CUSTOMER_LOSS_ERROR_MESSAGE: "We encountered an issue while saving customer loss record. Please try again in a moment. If the problem persists, contact support team for assistance.",
    DELETE_CUSTOMER_LOSS_ERROR_MESSAGE: "We encountered an issue while removing customer loss record. Please try again in a moment. If the problem persists, contact support team for assistance.",
    SAVE_CUSTOMER_LOSS_SUCCESS : "Loss history record save successfully.",
    ARE_YOU_SURE_TO_EXIST : "Do you want to leave without saving?"
}

export const CustomerNotesConstant =
{
    BLANK_DESCRIPTION : "Field cannot be blank",
    INVALID_NOTE_DATE : "Invalid note Date",
    DISCRIPTION_LENGTH : "Field require 3 to 2000 characters",
    NOTE_UPDATE_ERROR : "We encountered an issue while submitting data.",
    NOTE_SUCCESS_MESSAGE : "Note Submitted successfuly.",
    NOTE_DATE_EMPTY : "Field cannot be blank",
    FORM_VALIDATION_ERROR_MESSAGE : "There are few errors in page. Please check the highlighted inputs.",
    ARE_YOU_SURE_TO_EXIT: "Do you want to leave without saving?"
}

export const CustomerCampaignConstant = {
    ERROR_WHILE_INITIALIZING_VIEW: "Error while initializing customer campaign view."
};

export const SlicerLookUpConstant =
{
    MARITAL_STATUS :"Marital Status",
    OCCUPATION : "Occupation",
    INDUSTRY : "Industry",
    RELATIONSHIP : "Relationship",
    AGENCY : "Agency",
    CLAIMS_STATUS :"Claim Status",
    LOSS_TYPE :"Loss Type",
    ACTIVITY_TYPE :"Activity Type",
    TASK_PRIORITY :"TaskPriority",
    TASK_STATUS :"TaskStatus",
    EMPLOYEE_LIST :"EmployeeList",
    COMPANY_LIST :"CompanyList",
    STATE : "State",
    COUNTRY: "Country",
    US_STATE: " US State",
    CANADA_STATE: "Canada State"
}
 export const CompanyContact ={
    COMPANY_CONATCT : "Company Contact",
    BLANK_FIELD : "Field cannot be blank",
    NAME_LENGTH_RANGE : "Field require 2 to 75 characters",
    TITLE_LENGTH_RANGE : "Field require 2 to 20 characters",
    WORK_PHONE_RANGE : "Field require 10 digits",
    ZIP_RANGE : "Field require 5 to 9 characters"
 }

 export const RolePrivilege = 
 {
     ROLE_SAVED_SUCCESSFULLY : "Role information saved successfully.",
     ROLE_VALIDATION_ERROR_MESSAGE : "There are few errors in page. Please check the highlighted inputs.",
     ROLE_ERROR_WHILE_SAVING : "We encountered an issue while saving role information. Please try again in a moment. If the problem persists, contact support team for assistance.",
     DELETE_ELEMENT_ROW: "Are you sure you want to delete Special Privilege?",
     DELETE_PRIVIELGE_GRID: "Are you sure you want to delete Screen Privilege and it's respective Special Privilege(s) if any?",
     GRID_DELETED_SUCCESS_MESSAGE :"Grid cleared successfully"
 }

 export const ScreenName={
    CAMPAIGN:"Campaign",
    NOTE:"Notes",
    HISTORY:"History",
    CS2_Main_Menu:'CS2 Main Menu',
    Campaign_Query: 'Campaign Query',
    Campaign_Calendar: 'Campaign Calendar',
    CAMAPIGN_TEMAPLATE: 'Campaign Template',
    CAMPAIGN_APPROVAL: 'Campaign Approval',
    CUSTOMER: 'Customer',
    CUSTOMER_TASK: 'Customer Task',
    CUSTOMER_HISTORY: 'Customer History',
    CUSTOMER_ACTIVITY: 'Customer Activity',
    CUSTOMER_CAMPAIGNS: 'Customer Campaigns',
    CUSTOMER_LOSS_HISTORY: 'Customer Loss History',
 }

 export const CustomerScreenName={
    CUSTOMER_NOTES: 'Customer Notes'
 }

 export const CampaignPrivileges={
    SAVE_AS:'Save As',
    INACTIVE: 'Inactive',
    RESULT:'Results',
    CREATE:'Create'
 }

 export const SidebarPrivileges={
    Admin_Management: 'Admin Management',
    Campaign_Management: 'Campaign Management',
    Customer_Management: 'Customer Management',
    Documate_Scan: 'Documate Scan',
    Documate_Indexing: 'Documate Indexing',
    Documate_Search : 'Documate Search'
 }

 export const QueryPrivileges={
    SAVE_AS:'Save As',
    ADD_RULE:'Add Rule',
    STATUS: 'Status',
    TEST_QUERY: 'Test Query'
 }

 export const CampaignCalendarPrivileges={
    CAMPAIGN_INTERACTION: 'Campaign Interaction'
 }

 export const TemplatePrivileges={
    SAVE_AS:'Save As',
    STATUS: 'Status',
 }

 export const ApprovalPrivileges={
    SEND_TEST: 'Send Test',
    APPROVE: 'Approve',
    REJECT: 'Reject'
 }

  //documate constants
  export const Documate={
    UnAssignedFolderID: '0FC85543-385F-4314-8C12-BC951C64C504',
    RescanFolderID: 'BF52A1F7-FFC3-459D-BA6B-C19BBE657366'
 }