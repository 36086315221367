import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { baseURL } from "tinymce";


 var requestCount=0;
 var responseCount=0;
enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}
 
const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Credentials": true,
  "X-Requested-With": "XMLHttpRequest",
};
 
// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const token = localStorage.getItem("accessToken");
    requestCount++
    document.getElementById('LoadingDiv').classList.remove('d-none')
    document.body.classList.add('d-block')
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error) {
    document.getElementById('LoadingDiv').classList.add('d-none')
    document.body.classList.remove('d-block')
    throw new Error(error);
  }
};
 
class Client {
  private instance: AxiosInstance | null = null;
 
  private get client(): AxiosInstance {
    return this.instance != null ? this.instance : this.initClient(baseURL);
  }

  initClient(baseURL:string) {
    const client = axios.create({
      baseURL: baseURL,
      headers,
      withCredentials: false,
    });

    client.interceptors.request.use(injectToken, (error) => Promise.reject(error)
    
    );
 
    client.interceptors.response.use(
      (response) => {
        responseCount++
        if(responseCount>=requestCount){
          responseCount=0;
          requestCount=0;
          document.getElementById('LoadingDiv')?.classList.add('d-none')
          document.body.classList.remove('d-block')
        } else {
          document.getElementById('LoadingDiv')?.classList.add('d-block')
          document.body.classList.remove('d-none')
        }
        return response},
      (error) => {
        const { response } = error;
        responseCount++
        document.getElementById('LoadingDiv')?.classList.add('d-none')
        document.body.classList.remove('d-block')
        return this.handleError(response??'');
      }
    );
 
    this.instance = client;
    return client;
  }

  constructor () {
    const baseURL = process.env.REACT_APP_API_BASE_URL_CAMPAIGN
    this.initClient(baseURL)
    
  }

  setBaseURLForService(service:string) {
    switch(service){
      case 'campaign':
        this.initClient(process.env.REACT_APP_API_BASE_URL_CAMPAIGN)
        break;
      case 'admin':
        this.initClient(process.env.REACT_APP_API_BASE_URL_ADMIN)
        break;
      case 'customer':
        this.initClient(process.env.REACT_APP_API_BASE_URL_CUSTOMER)
        break;
      case 'policy':
        this.initClient(process.env.REACT_APP_API_BASE_URL_POLICY)
        break;
      case 'aggregator':
          this.initClient(process.env.REACT_APP_API_BASE_URL_CS2AGGREGATOR)
        break;
      case 'security':
          this.initClient(process.env.REACT_APP_API_BASE_URL_SECURITY)
        break;
      case 'task':
          this.initClient(process.env.REACT_APP_API_BASE_URL_Task)
        break;
        case 'docmate':
          this.initClient(process.env.REACT_APP_API_BASE_URL_DOCMATE)
          break;
    case 'docmateapp':
            this.initClient(process.env.REACT_APP_API_BASE_URL_DOCMATE_APP)            
            break;
        default:
          break;
    }
  }
  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.client.request(config);
  }
 
  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.client.get<T, R>(url, config);
  }
 
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.client.post<T, R>(url, data, config);
  }
 
  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.client.put<T, R>(url, data, config);
  }
 
  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.client.delete<T, R>(url, config);
  }
 
  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error) {
    const { status } = error;
 
    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
    }
 
    return Promise.reject(error);
  }
}
 
export const client = new Client();