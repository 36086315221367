import {Row, Column } from '../../components'
import React, { useEffect, useState, useCallback } from 'react'
import '../customer.css'
import SearchPanel from './SearchPanel'
import { ErrorBoundary } from 'react-error-boundary'
//import CustomerFilterRequest from '../model/CustomerFilterRequest'


// interface IsearchProp {
//   onSearching: (filterRequest: CustomerFilterRequest) => void,
//   onAllowingSearching: (isSearchingAllowed: boolean) => void
// }

  const CustomerFilterForm = (props) => {
    const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false
  });
  const [StandardSearch, SetStandardSearch]=useState(true)
  const [AdvanceSearch, SetAdvanceSearch]=useState(false)
  //const {onSearching, onAllowingSearching } = props
  const GetAdvanceSearchState=(AdvanceSearch:boolean):void=>{
    SetAdvanceSearch(AdvanceSearch)
  }

 useEffect(() => {
//console.log(StandardSearch,AdvanceSearch)
},[StandardSearch,AdvanceSearch])

    // const [showGrid, setShowGrid] = useState(false);
    // const [filter, setfilter] = useState<CustomerFilterRequest>();
  
  
    // const onSearch = useCallback((newFilter: CustomerFilterRequest) => {
    //   console.log('Customer Search')
    //   setfilter(newFilter)
    // }, [])
  
    // const onAllowingSearchChange = useCallback((gridVisible: boolean) => {
    //   console.log('Customer Search')
    //   setShowGrid(gridVisible);
    // }, [])

    const FallbackComponent = ({ error, resetErrorBoundary }) => {
      console.log("An error occurred:");
      console.log(error.message);
      return (
        <div>
          <h1>An error occurred: {error.message}</h1>
          <button onClick={resetErrorBoundary}>Try again</button>
        </div>
      );
    };

  return (

        <Row className="bg-light">

          <Column lg={12} md={12}>

          <ErrorBoundary  FallbackComponent={FallbackComponent}>   
            <SearchPanel collapsible >
           </SearchPanel>
          </ErrorBoundary>
          {/* : <p>You are not authorised user for this application. Please contact admin</p> */}
       </Column>
    </Row>
  )
  }

  export default CustomerFilterForm