import { SelectOption } from "../../shared/components/input";
import { CustomerActivityAddRequest } from "../model/CustomerActivityAddRequest";
import { addNewActivityForCustomer, getAllCustomerActivities, getPoliciesForDropdown } from "../services/CustomerAPI";
import { getActivityTypesForDropdown, getTransactionTypesForDropdown } from "../services/LookupAPI";


class CustomerActivityService
{

    getActivites = async (customerId : string, pageNumber: number, userId? : string, pageSize? : number) => {
        var activities =  await getAllCustomerActivities(customerId, pageNumber, userId, pageSize)
        return activities;
    }

    getActivityTypes = async(): Promise<SelectOption[]> => {
        var activityTypes = await getActivityTypesForDropdown();
        return activityTypes.map(activityType => ({
            label: activityType.lookupValue,
            value: activityType.id,
            isInactive: activityType.inactive === 1 ? true : false
          }));
    }

    getTransactionTypes = async(): Promise<SelectOption[]> => {
        var transactionTypes = await getTransactionTypesForDropdown();
        return transactionTypes.map(transactionType => ({
            label: transactionType.lookupValue,
            value: transactionType.id,
            isInactive: transactionType.inactive === 1 ? true : false
          }));
    }

    addNewActivity = async(addRequest: CustomerActivityAddRequest) => {
        var res = await addNewActivityForCustomer(addRequest);
        return res;
    }

    getPoliciesForCustomer = async(custId: string): Promise<SelectOption[]> => {
        var policiesForDropdown = await getPoliciesForDropdown(custId);
        return policiesForDropdown.map(policy => ({
            label: policy.policyNumber,
            value: policy.policyId,
            isInactive: false,
            originalData : policy
          }));
    }
}
export default CustomerActivityService;