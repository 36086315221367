import { getCustomerCampaignList } from "../../Campaign/services/CampaignAPI";
import { CommonUtility } from "../../Services/CommonUtility";
import { Link } from "react-router-dom";


class CustomerCampaignViewService {

    public getCustomerCampaigns = async (customerId : string) => {
     let data = await getCustomerCampaignList(customerId);   
     if (data) {
        data.forEach(item => this.enrichCustomerCampaingData(item));
     }
     return data;
    }

    public enrichCustomerCampaingData = (campaignData) => {
        if (campaignData.sentDate) {
            campaignData.sentDate = CommonUtility.getDisplayDateString(campaignData.sentDate);
        }
    }

    public getCustomerCampaignGridColumns = () => {
        return [
            {
                Header: "Campaign Name",
                accessor: 'campaignName',
                width: 350,
                Cell:  cell =>
                    <div>
                        <Link
                            to={{
                            pathname: '/Campaign/CampaignDetails',
                            state: { entityId:cell.row?.original?.campaignId, type: '2' },
                            search: "?id="+cell.row?.original?.campaignId
                                }}
                            title={cell.value}>{cell.row?.original?.campaignName}
                        </Link>
                    </div>,
            },
            {
                Header: "Type",
                accessor: 'templateName',
                width:250
            },
            {
                Header: "Category",
                accessor: 'categoryName',
                width:250
            },
            {
                Header: "Send On Date",
                accessor: 'sentDate',
                width:250
            }
        ];
    }
}

export default CustomerCampaignViewService;