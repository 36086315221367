import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './InsuranceProductList.scss';

import insuranceProducts from './InsuranceProductList.json';
import type { InsuranceProduct } from './InsuranceProductList.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import InsuranceProductService, { policyStatusColorMap } from './InsuranceProductListService';

const products = insuranceProducts as InsuranceProduct[];

const InsuranceProductList: React.FC = () => {

  const insuranceProductService = new InsuranceProductService();
  let customerInfo = useSelector((state: RootState) => state?.customerSlice);
  const [policyProducts, setPolicyProducts] = useState<InsuranceProduct[]>(JSON.parse(JSON.stringify(products)));
  
  useEffect(() => {
      if (customerInfo && customerInfo.policies && customerInfo.policies.length > 0) {
          let productColorMapping = insuranceProductService.getPolicyIconsColors(customerInfo.policies);
          let updatedPolicyProducts = insuranceProductService.getPolicyProductsWithUpdatedColor(policyProducts, productColorMapping);
          setPolicyProducts(updatedPolicyProducts);
      }
      else {
        setPolicyProducts(JSON.parse(JSON.stringify(products)));
      }
  },[customerInfo.policies]);

  const renderTooltip = (text: string) => (
    <Tooltip id={`tooltip-${text}`} placement='bottom'>
      {text}
    </Tooltip>
  ); 

  const renderProducts = (products: InsuranceProduct[]) => {
    return products.map(product => (
      <OverlayTrigger
        key={product.id}
        placement="top"
        overlay={renderTooltip(product.name)}
      >
        <div className="insurance-circle"
          style={{backgroundColor : product.color}}>
          <FontAwesomeIcon
            icon={product.icon as IconProp}
            className={`insurance-icon`}
          />
        </div>
      </OverlayTrigger>
    ));
  };

  let groupedPrds = Object.keys(policyStatusColorMap).map(colorId => {
    let prds = policyProducts.filter(prd => prd.color == policyStatusColorMap[colorId]);
    if (prds.length > 0) {
      return <>
              <div className="product-section">
                {renderProducts(prds)}
              </div>
              <div className="separator" />
            </>;
    }

    return null;

  }).filter(item => item);

  return (
    <div className="insurance-product-list">
      {groupedPrds}
    </div>
  );
};

export default InsuranceProductList;