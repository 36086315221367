import { classNames } from 'primereact/utils';
import { PrimeReactPTOptions } from 'primereact/api';

interface FloatLabelProps {
  invalid?: boolean;
  valid?: boolean;
}

const Bootstrap_PT: PrimeReactPTOptions = {
  button: {
    root: ({ props, context }) => ({
      className: classNames(
        'btn',
        {
          'btn-primary': props.severity === null && !props.outlined,
        },
        {
          'btn-secondary': props.severity === 'secondary',
          'btn-success': props.severity === 'success',
          'btn-info': props.severity === 'info',
          'btn-warning': props.severity === 'warning',
          'btn-purple': props.severity === 'help',
          'btn-danger': props.severity === 'danger',
        },
        {
          'btn-lg': props.size === 'large',
          'btn-sm': props.size === 'small',
          'shadow-lg': props.raised,
        },
        {
          'btn-outline-primary': props.outlined && props.severity === null,
          'btn-outline-secondary': props.outlined && props.severity === 'secondary',
          'btn-outline-success': props.outlined && props.severity === 'success',
          'btn-outline-info': props.outlined && props.severity === 'info',
          'btn-outline-warning': props.outlined && props.severity === 'warning',
          'btn-outline-danger': props.outlined && props.severity === 'danger',
          'btn-outline-purple': props.outlined && props.severity === 'help',
          'btn-outline-light': props.outlined && props.text,
          'btn-outline-dark': props.outlined && props.text,
        },
        {
          'shadow-lg': props.raised,
        },
        {
          'opacity-25 disabled': context.disabled,
        }
      ),
    }),
    icon: ({ props }) => ({
      className: classNames(
        props.icon,
        'me-1',
        {
          'ms-1 me-0': props.iconPos === 'right',
          'me-0': props.label == null
        }
      )
    }),
    label: ({ props }) => ({
      className: classNames({
        'ms-2': props.icon && props.iconPos === 'left',
        'me-2': props.icon && props.iconPos === 'right'
      })
    })
  },
  inputtext: {
    root: {
      className: 'm-0 form-control text-base',
    },
  },
  inputtextarea: {
    root: {
      className: 'm-0 form-control text-base',
    },
  },
  card: {
    root: {
        className: 'card'
    },
    header: { className: 'card-img-top' },
    body: { className: 'card-body p-4' },
    title: { className: 'card-title h3 mb-0 font-bold' },
    subTitle: { className: 'card-subtitle mb-2 text-muted' },
    content: { className: 'card-text' },
    footer: { className: 'card-footer' }
  },
  panel: {
    root: {
      className: 'rounded border',
    },
    header: {
      className:
        'text-white rounded-top bg-primary p-3 d-flex align-items-center justify-content-between',
      style: { borderBottom: '1px solid #0d6efd' },
    },
    content: {
      className: 'p-3  d-flex align-items-center justify-content-between',
    },
    toggler: {
      className: classNames(
        'd-inline-flex align-items-center justify-content-center position-relative text-white btn btn-primary border-0 '
      ),
    },
  },
  progressbar: {
    root: {
      className: 'position-relative overflow-hidden border-0 rounded',
      style: { height: '1.5rem', backgroundColor: '#edf2f7' },
    },
    value: {
      className: classNames(
        'border-0 bg-primary position-absolute align-items-center border-0 d-flex h-100 justify-content-center overflow-hidden'
      ),
    },
    label: {
      className: 'd-inline-flex text-white small',
    },
  },
  menu: {
    root: {
      className: 'py-1 bg-white border rounded',
      style: { width: '12rem' },
    },
    menu: {
      className: 'm-0 p-0',
      style: { listStyle: 'none' },
    },
    action: () => ({
      className: classNames('text-dark py-2 px-3 d-flex align-items-center'),
      style: { textDecoration: 'none', cursor: 'pointer' },
    }),
  },
  dialog: {
    mask: ({ state }) => ({
      class: classNames('modal fade opacity-0', {
        'show opacity-100': state.containerVisible,
      }),
      style: {
        backgroundColor: 'rgba(0, 0, 0, .5)',
      },
    }),
    root: { className: 'modal-dialog modal-content' },
    header: { className: 'modal-header' },
    content: { className: 'modal-body' },
    closeButton: { className: 'btn' },
  },
  floatlabel: {
    root: ({ props }) => ({
      className: classNames(
        'form-floating mb-3',
        {
          'has-validation': props.invalid,
          'is-invalid': props.invalid,
          'is-valid': props.valid
        }
      ),
      children: {
        input: {
          className: classNames(
            'form-control',
            {
              'is-invalid': props.invalid,
              'is-valid': props.valid
            },
            
          ),
          placeholder: props.placeholder
        },
      }
      
    }),
    // label: ({ props }) => ({
    //   className: classNames(
    //     'form-label',
    //     {
    //       'text-danger': props.invalid,
    //       'text-success': props.valid
    //     }
    //   )
    // }),
    // input: {
    //   root: {
    //     className: 'form-control'
    //   }
    // }
  },
  tooltip: {
    root: {
      className: 'tooltip show bs-tooltip-auto',
      style: { 
        '--bs-tooltip-bg': '#000',
        '--bs-tooltip-color': '#fff'
      }
    },
    arrow: {
      className: 'tooltip-arrow',
      style: {
        '--bs-tooltip-arrow-bg': '#000'
      }
    },
    text: {
      className: 'tooltip-inner',
      style: {
        maxWidth: '200px',
        padding: '0.25rem 0.5rem',
        fontSize: '0.875rem',
        borderRadius: '0.25rem'
      }
    }
  }
};

export default Bootstrap_PT;
