import { List, ListItem, Icon, Button } from '../../../components'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import Permissions from '../../model/Permissions'
import { RootState } from '../../store'
// import { updateSidebar } from '../component-slice'
import './Sidebar.css';
import * as label from '../../../shared/util/label'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { Badge, Dropdown, ListGroup } from 'react-bootstrap'
import AppContext from "../AppContext";
import { useConfirmationModalContext } from "../ModalConfirmationContext";
import * as constant from '../../../shared/util/constant';
import CustomerSliderContainerService from '../../../customer/customer-slider-content/CustomerSliderContainerService'
import { cleareTabsInputs } from '../../../customer/customer-slider-content/CustomerSliderContext'
import useSlider from '../Slider/useSlider'
import * as docuMateLabel from '../../../DocuMate/shared/util/docuMateLabel';
import { AbilityContext } from '../../abilities/AbilityContext'

interface Props {
  handleCallBack:Function,
  userName: string,
  onLogout: Function
}

const Sidebar = (props:Props) => {
  const customerSliderContainerService = new CustomerSliderContainerService();
  const customerSliderState = useSelector((state: RootState) => state?.CustomerSliderState);
  const originalCustomerObj = useSelector((state: RootState) => state?.customerSlice).customer;

  // const dispatch = useDispatch()
  // const { sidebarCollapsed } = useSelector((state: RootState) => state.components)
  const [sidebarCollapsed,setSidebarCollpased] = useState(true);
  const [pinsideBar,setPinSidebar] =useState(false)
  // const permissions = useSelector((state: RootState) => state.user.permissions)
  const [classNameForListItems, setClassNameForListItems] = useState("sideBarListItemsExpanded");
  const [classNameForLogInUser, setClassNameForLogInUser] = useState("sideBarLogInUserExpanded");
  const path = useLocation()
  const history = useHistory()
  const dispatch = useDispatch();
  const slider = useSlider();
  const { pathname } = path
  const splittedPath = pathname.split('/')
  const modalContext = useConfirmationModalContext();
  const myContext = useContext(AppContext);
  const ability = useContext(AbilityContext);
  const ab_subject = "CS2 Main Menu"
  const isUserPermitToViewAdmin = ability.can('view', ab_subject ,constant.SidebarPrivileges.Admin_Management)
  const isAdminMenuEnableForUser = ability.can('read', ab_subject,constant.SidebarPrivileges.Admin_Management)
  const isUserPermitToViewCampaign = ability.can('view', ab_subject ,constant.SidebarPrivileges.Campaign_Management)
  const isCampaignMenuEnableForUser = ability.can('read', ab_subject,constant.SidebarPrivileges.Campaign_Management)
  const isUserPermitToViewCustomer = ability.can('view', ab_subject ,constant.SidebarPrivileges.Customer_Management)
  const isCustomerMenuEnableForUser = ability.can('read', ab_subject,constant.SidebarPrivileges.Customer_Management)
  const cursorStyleForListItem  = !isAdminMenuEnableForUser ? { cursor: 'auto' } : {};
  const navigateTo = async(location: string) => {
    const changes = customerSliderContainerService.getUnSavedChanges(customerSliderState, originalCustomerObj);
    if (Object.keys(changes).length > 0) {
      const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
          if(!result){
            return;
          }

        dispatch(cleareTabsInputs());
        slider.hideSlider();
    }


    if(!myContext.formSavedStatus){
      const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
          if(result){
            history.push(location)
          }
      }
      else{
        history.push(location)
      }

    
  }
  const { userName } = props
   const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (sidebarRef.current &&  !sidebarRef.current.contains(event.target) && !pinsideBar){
      setSidebarCollpased(true);
      }
    };
    if (sidebarCollapsed) {

      setClassNameForListItems("sideBarListItemsCollapsed");
      setClassNameForLogInUser("sideBarLogInUserCollapsed");
    }
    else {
      setClassNameForListItems("sideBarListItemsExpanded");
      setClassNameForLogInUser("sideBarLogInUserExpanded");
        }

    {pinsideBar?setSidebarCollpased(false):null}

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };


  }, [pinsideBar,sidebarCollapsed,sidebarRef])

const handlePinClick =() =>{
  setPinSidebar(!pinsideBar);

}

const handleBurgerMenuClick = () => {
  if(!pinsideBar){
    setSidebarCollpased(!sidebarCollapsed);
  }
}

  // const listItemStyle: CSSProperties = {
  //   cursor: 'pointer',
  //   fontSize: 'small', 
  //   textAlign:sidebarCollapsed?'center':'left' ,
  //    paddingLeft: sidebarCollapsed ? '10px' : '10px' ,
  //    paddingRight:sidebarCollapsed? '1rem': '2rem'
   
    
  // }

  const [expandedItem, setExpandedItem] = useState(
    splittedPath[1].includes('customers')
      ? 'customer'
      : splittedPath[1].includes('campaigns')
      ? 'campaign'
      : splittedPath[1].includes('maintenance')

      ? 'maintenance'
      : splittedPath[1].includes('imaging')
      ? 'imaging'
      : splittedPath[1].includes('admin')
      ? 'admin'
      : splittedPath[1].includes('scan')
      ? 'scan'
      : splittedPath[1].includes('indexing')
      ? 'indexing'
      : splittedPath[1].includes('search')
      ? 'search'
      : splittedPath[1].includes('refi')
      ? 'refi'
      : splittedPath[1].includes('policyfile')
      ? 'policydashboard'
      : 'none'
  )
  const setExpansion = (item: string) => {
    if (expandedItem === item) {
      setExpandedItem('none')
      return
    }

    setExpandedItem(item.toString())
  }

  const getDashboardLink = () => {
    return getLink(
      '/',
      'dashboard',
      'Dashboard',
      '2x',
      pathname === '/',
      setExpansion,
      sidebarCollapsed,
      classNameForListItems
    );
  }

  const getCustomerLinks = () => {
    return getLink(
      '/customer/customerfilter',
      'customer',
      label.CUSTOMER,
      'lg',
      splittedPath[1].includes('customer'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems
    );
  }

  const getPolicyFileLinks = () => {
    return getLink(
      '/policyfile/PolicyAssigment/unmatched-policy',
      'folderOpen',
      label.POLICY_FILE,
      'lg',
      splittedPath[1].includes('policyfile'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems
    );
  };

  const getCampaignLinks = () => {
    return getLink(
      '/Campaign/CampaignDashboard',
      'campaign',
      label.CAMPAIGN,
      'lg',
      splittedPath[1].includes('Campaign'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems,
      // 'New' // Replace with actual badge content if needed
    );
  };
  
  const getMaintenanceLinks = () => {
    return getLink(
      '/maintenance',
      'setting',
      'Maintenance',
      'lg',
      splittedPath[1].includes('maintenance'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems
    );
  };
  
  const getAdminLinks = () => {
    return getLink(
      '/admin',
      'admin',
      label.ADMIN,
      'lg',
      splittedPath[1].includes('admin'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems,
      null,
      isAdminMenuEnableForUser
    );
  };
  
  const getUserProfile = () => (
    <>
     
      
    </>
  )

  const getImagingLinks = () => {
    return getLink(
      '/imaging',
      'imaging',
      label.DOCUMATE,
      'lg',
      splittedPath[1].includes('imaging'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems
    );
  };

  const getInitials = name => {
    var initials = name.split(' ').map(word => word.charAt(0).toUpperCase()).join('');
    return initials;
  };

  const getDocuMateScanLinks = () => {
    return getLink(
      '/documate-scan',
      'documateScan',
      docuMateLabel.DOCUMATE_SCAN,
      'lg',
      splittedPath[1].includes('scan'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems
    );
  };

  const getDocuMateIndexingLinks = () => {
    return getLink(
      '/documate-indexing',
      'documateIndexing',
      docuMateLabel.DOCUMATE_INDEXING,
      'lg',
      splittedPath[1].includes('indexing'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems
    );
  };

  const getDocuMateSearchLinks = () => {
    return getLink(
      '/documate-search',
      'documateSearch',
      docuMateLabel.DOCUMATE_SEARCH,
      'lg',
      splittedPath[1].includes('search'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems
    );
  };

  const getDocuMateRefiLinks = () => {
    return getLink(
      '/documate-refi',
      'documateRefi',
      docuMateLabel.DOCUMATE_REFI,
      'lg',
      splittedPath[1].includes('refi'),
      setExpansion,
      sidebarCollapsed,
      classNameForListItems,
      // 'New' // Replace with actual badge content if needed
    );
  };
  // const getDocuMateRefiLinks = () => (
  //   <>
  //     <ListItem
  //     active={splittedPath[1].includes('refi')}
  //     onClick={() => {
  //       navigateTo('/documate-refi')
  //       setExpansion('none')
  //     }}
  //     className={classNameForListItems}
  //     >
  //       <Icon icon="documateRefi" />
  //       {sidebarCollapsed?<br></br>:" "}
  //       {docuMateLabel.DOCUMATE_REFI}
  //     </ListItem>
  //   </>
  // )

  const getMainDashboardLinks = () => {
    return getLink(
      '/',
      'home',
      label.HOME,
      'lg',
      splittedPath[1].includes('/') || pathname == '/',
      setExpansion,
      sidebarCollapsed,
      classNameForListItems,
      // 'New' // Replace with actual badge content if needed
    );
  };

  const getLink = (path, icon, label, size, isActive, setExpansion, sidebarCollapsed, classNameForListItems, badgeContent = null, isEnabled = true) => {
    return (
      isEnabled && (
        <ListGroup.Item
          active={isActive}
          onClick={() => {
            navigateTo(path)
            setExpansion('none')
          }}
          action
          className={`text-light font-bold border-bottom-0 py-3 px-1 ${classNameForListItems}`}
        >
          {sidebarCollapsed ? (
            <div className="d-flex flex-column align-items-center">
              <Icon icon={icon} size={size} className='pb-2' />
              <span>{label}</span>
            </div>
          ) : (
            <div className="d-flex justify-content-between align-items-center px-2">
              <div className="d-flex align-items-center">
                <div className='pe-1' style={{ width: '36px', textAlign: 'center' }}>
                  <Icon icon={icon} size={size} className='' />
                </div>
                <span className="ml-2">{label}</span>
              </div>
              {badgeContent && <Badge bg="secondary">{badgeContent}</Badge>}
            </div>
          )}
        </ListGroup.Item>
      )
    );
  };
 

  return (
    <><div ref={sidebarRef}>
      <nav
        className={"d-none d-md-block sidebar"}
        style={{ width: sidebarCollapsed ? '100px' : '250px' }}
      >
        <List layout="flush" className="nav d-flex flex-column flex-shrink-0 h-100">
          <ListGroup.Item action
            onClick={handleBurgerMenuClick}
            className={`d-block text-light border-bottom-0 ps-2 pe-3 ${classNameForListItems}`}
          >
            <div className="burgerMenu d-flex flex-row align-items-center  px-3">
              <div className='ps-2' style={{ width: '24px', textAlign: 'center' }}>
                <Icon size={'lg'} icon='menu' />
              </div>
              <span className='fs-6 font-bold ps-2'>CS²</span>
            </div>
          </ListGroup.Item>
          {/* {getDashboardLink()} */}
          {getMainDashboardLinks()}
          {isUserPermitToViewCustomer && getCustomerLinks()}
          {/* {getImagingLinks()} */}
          {isUserPermitToViewCampaign && getCampaignLinks()}
          {/* {getMaintenanceLinks()}  */}

          {isUserPermitToViewAdmin && getAdminLinks()}
          {/* { getAdminLinks()} */}
          {getDocuMateScanLinks()}
          {getDocuMateIndexingLinks()}
          {getDocuMateRefiLinks()}
          {getPolicyFileLinks()}
          {getDocuMateSearchLinks()}
          {getUserProfile()}

          <hr className='border border-light mt-auto' />

          <div className={`${classNameForLogInUser}`}>
            <Dropdown drop='end'>
            <Dropdown.Toggle as="div" id="dropdown-basic" className="userDetails">
            {sidebarCollapsed ? (
              <div className="d-flex flex-column align-items-center mb-4 mt-3">
                <div className="user-initials-circle">
                  {getInitials(userName)}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between align-items-center px-3">
                <div className="d-flex align-items-center">
                  <div className="user-initials-circle">
                    {getInitials(userName)}
                  </div>
                  <span className="logInUserName ml-2">{userName}</span>
                </div>
              </div>
            )}
          </Dropdown.Toggle>
              <Dropdown.Menu className="sidebar-dropdown-menu">
                <Dropdown.Header>Welcome, {userName}</Dropdown.Header>
                <Dropdown.Divider />

                <Dropdown.Item className="sidebar-dropdown-item" onClick={() => props.onLogout()}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </List>

        {/* start */}

        {/* end */}
      </nav>
    </div><div> {props.handleCallBack(sidebarCollapsed)}</div></>
  )
}

export default Sidebar
