import { Column, Row } from '../../components'
//import { useCallback, useState } from 'react'
//import CustomerFilterForm from './CustomerStandardSearch'
import ViewCustomerResults from './ViewCustomerResults'
import CustomerFilterForm from './CustomerSearch'
import CustomerFilterRequest from '../model/CustomerFilterRequest'
import React, { useState, createContext} from 'react'
import { ErrorBoundary } from 'react-error-boundary'

export const showGridContext = createContext(
    {
        IsGridVisible:false,
        FilterData:{}
    }
);
const customerSearch=()=>{

    const initalFilterState: CustomerFilterRequest = {
        ApplicantFirstName: '',
        ApplicantLastName: '',
        ApplicantEmail : '',
        ApplicantPhone: '',
        ApplicantOccupation: '',
        ApplicantSelfemployed: '',
        ApplicantDateofBirthFrom: null,
        ApplicantDateofBirthTo: null,
        CoApplicantFirstName: '',
        CoApplicantLastName: '',
        CoApplicantEmail : '',
        CoApplicantPhone: '',
        CoApplicantOccupation: '',
        CoApplicantSelfemployed: '',
        CoApplicantDateofBirthFrom: null,
        CoApplicantDateofBirthTo: null,
        LastDateSolicited:null,
        SolicitedCampaignsTimeframe:'',
        DaySinceLastSolicited:'',
        ByCampaigns:'',
        Unsubscribe:'',
        DoNotEmail:'',
        DoNotMail:'',
        EDD:'',
        SMSText:'',
        PropertyAppSuit:'',
        PropertyCity:'',
        PropertyCountry:'',
        PropertyState:'',
        PropertyZip:'',
        MailingStreetAddress:'',
        MailingAppSuit:'',
        MailingCity:'',
        MailingCountry:'',
        MailingState:'',
        MailingZip:'',
        RoofUpdateYearFrom:null,
        RoofUpdateYearTo:null,
        RoofType:'',
        RoofShape:'',
        RoofMaterial:'',
        ConstructionType:'',
        Pool:'',
        Trampoline:'',
        DogBreed:'',
        Carrier:'',
        WritingCompany:'',
        YearAsCustomer:'',
        Agency:'',
        PolicyType:'',
        PremiumFrom:'',
        PremiumTo:'',
        LoanNumber:'',
        NotationCode:'',
        CoverageARangeFrom:'',
        CoverangeARangeTo:'',
        YearBuiltRangeFrom:'',
        YearBuiltRangeTo:'',
        IntialPolicyEffectiveDateFrom:null,
        InitialPolicyEffectiveDateTo:null,
        CurrentPolicyEffectiveDateFrom:null,
        CurrentPolicyEffectiveDateTo:null,
        PolicyStatus:'',
        LastNoteEnteredBy:'',
        AllPerilDeductiblesFrom:null,
        AllPerilDeductibleTo:null,
        WindHailDeductiblesFrom:null,
        WindHailDeductiblesTo:null,
        HurricaneDeductiblesFrom:null,
        HurricaneDeductiblesTo:null,
        DwellingCoverageFrom:null,
        DwellingCoverageTo:null,
        PolicyNumber:'',
        AMSNumber:'',
        IsApplicantSearch:false,
        IsCoApplicantSearch:false,
        IsPropertySearch:false,
        IsMailingSearch:false,
        IsQuickSearch:false,
        QuickSearchString:''
      }


    
    //const searchilter=createContext<CustomerFilterRequest>(null);
    const [GridContext, setGridContext] = useState({
        IsGridVisible:true,
        FilterData:initalFilterState
    });
     const [showGrid, setShowGrid] = useState(GridContext.IsGridVisible);
    const [searchfilter, setInputFilter] = useState<CustomerFilterRequest>(GridContext.FilterData);
    const [searchResult, setsearchResult] = useState([]);
    //console.log('Customer filter')
   // console.log(showGrid)
    //console.log(searchfilter)
  
    // const onSearch = useCallback((newFilter: CustomerFilterRequest) => {
    //   setfilter(newFilter)
    //   console.log('Customer filter')
    // }, [])
  
    // const onAllowingSearchChange = useCallback((showGrid: boolean) => {
    //   setShowGrid(showGrid);
    //   console.log('Customer filter')
    // }, [])

    const FallbackComponent = ({ error, resetErrorBoundary }) => {
        console.log("An error occurred:");
        console.log(error.message);
        return (
          <div>
            <h1>An error occurred: {error.message}</h1>
            <button onClick={resetErrorBoundary}>Try again</button>
          </div>
        );
      };

    return (
        // <showGridContext.Provider value={{GridVisibility:[showGrid, setShowGrid], InputFilter:[searchfilter, setInputFilter]}}>
        <showGridContext.Provider value={{GridVisibility:[showGrid, setShowGrid], InputFilter:[searchfilter, setInputFilter], searchResultData : {searchResult, setsearchResult}}}>
        <div>
            {/* <Container style={{
                paddingLeft: "0px",
                paddingRight: "0px",
            }}> */}
                <Row>
                    <Column md={12} lg={12}>
                  
                    <ErrorBoundary  FallbackComponent={FallbackComponent}>   
                        <CustomerFilterForm />
                    </ErrorBoundary>
                    {/* : <p>You are not authorised user for this application. Please contact admin</p> */}
                    </Column>
                </Row>

                <Row>
                     <Column md={12} lg={12} className='cstTable'> 
                     <ErrorBoundary  FallbackComponent={FallbackComponent}>   
                    {searchResult && searchResult.length > 0 && <ViewCustomerResults filterRequest={searchfilter} />}
                    <br/>
                    </ErrorBoundary>
                    {/* : <p>You are not authorised user for this application. Please contact admin</p> */}
                     </Column> 
                </Row>
            {/* </Container> */}
        </div>
        </showGridContext.Provider>
    )

}
export default customerSearch