export const CustomerLossHistoryScreenLables = {
  general : "GENERAL",
  company : "Company",
  employeeName : "Employee Name",
  description : "Description",
  lossDetail : "LOSS DETAIL",
  policyNumber : "Policy Number",
  claimNumber : "Claim Number",
  kindOfLoss : "Kind of Loss",
  dateOfLoss : "Date of Loss",
  effectiveDate : "Effective Date",
  dateReported : "Date Reported",
  expirationDate : "Expiration Date",
  amountToPay : "Amount To be Paid",
  claimStatus : "Claim Status",
  closeDate : "Close Date",
  LOSS_HISOTRY : "LOSS HISTORY",
};

export const TaskScreenLables = {
  taskDescription : 'Description',
  dueDate : 'Due Date',
  taskType : 'Action',
  priority : 'Priority',
  status : 'Status',
  completionDate : 'Completion Date',
  cc : 'CC / Notify',
  createdBy : 'Created By',
  assignedTo : 'Assigned To',
  customerName : 'Customer Name',
  policyNumber : 'Policy Number',
  claimNumber : 'Claim Number',
  company : 'Company', 
  tasks: 'Tasks', 
  rowexpander : 'Row Expander',
  pleaseSelect : "Please Select",
  newTask : "New Task",
  description : "Description",
  dateTime : "Date Time",
  field : "Field",
  oldValue : "Old Value",
  newValue : "New Value",
  user : "User"
}


export const CustomerPolicyListScreenLables = {
    PROPERTY_ADDRESS : "Property Address",
    STATUS : "Status",
    AGENCY_NOTATION : "Agency Notation",
    APLUS_REFERENFCE : "A+ Reference",
    INSURANCE_COMPANY : "Insurance Company",
    FORM_TYPE : "Form Type",
    LINE_OF_BUSINESS : "Line of Business",
    POLICY_NUMBER : "Policy Number",    
    POLICY_TYPE : "CS2 Policy Type",
    POLICY_TYPE_LOB : "Policy Type LOB",
    PREMIUM : "Premium",
    TERM : "Term",
    LAT_LONG : "LAT LONG",
    SOURCE : "Source",
    PRODUCER : "Producer",
    DESCRIPTION : "Description"
}

export const CustomerScreentLables =
{
    FIRST_NAME : "First Name",
    MIDDLE_NAME : "Middle Name",
    LAST_NAME : "Last Name",
    DISPLAY_NAME : "Display Name",
    VIP : "VIP",
    INDUSTRY : "Industry",
    OCCUPATION : "Occupation",
    YEAR_EMPLOYED : "Year Employed",
    GENDER : "Gender",
    AGENCY : "Agency",
    MARITAL_STATUS : "Marital Status",
    COUNTRY: "Country",
    STREET : "Street",
    APARTMENT_OR_UNIT : "Apt / Unit",
    CITY : "City",
    STATE : "State",
    ZIP : "Zip",
    SAME_AS_MAILING : "Set Billing the same as Mailing",
    RELATIONSHIP : "Relationship",
    DO_NOT_MAIL : "Do Not Mail",
    DO_NOT_EMAIL : "Do Not Email",
    SMS_OPT_OUT : "SMS Opt-Out",
    DO_NOT_CALL : "Do Not Call",
    EDD_OPT_IN : "EDD Opt-In",
    CELL_PHONE : "Cell Phone",
    HOME_PHONE : "Home Phone",
    WORK_PHONE : "Work Phone",
    EMAIL_1 : "Email 1",
    EMAIL_2 : "Email 2",

    // Panel Lables
    CUSTOMER_INFORMATION : "Customer Information",
    ADDITIONAL_CUSTOMER_INFORMATION : "Additional Customer Information",
    CONTACT_INFORMATION : "Contact Information",
    MAILING_ADDRESS : "Mailing Address",
    BILLING_ADDRESS : "Billing Adress",

    ADD_ADDITIONAL_CUSTOMER_BUTTON : "+ ADD ADDITIONAL CUSTOMER",

    ADD_COMPANY_CONTACT : "+ ADD COMPANY CONTACT",

    // Customer Company lable
    NAME :"Name",
    TITLE :"Title",
    FORMAL_SALUTATION :"Formal Salutation",
    PHONE_NUMBER : "Phone Numbers",
    EXT :"Ext",
    EMAIL : "Email",
    ADDRESS_1 : "Address 1",
    ADDRESS_2 : "Address 2",
    WEB_ADDRESS : "Web Address",
    COUNTY : "County"
};

export const CustomerNotesLabel= 
{
  CUSTOMER_NOTE :"Customer Notes",
  NOTE_DATE : "Note Date",
  DESCRIPTION : "Description",
  ADD_NEW_NOTE : "Add Note",
  NEW_NOTE : "New Note",
}

export const StandardSearchLable =
{
  SEARCH_INSURED :"Search Insured",
  SEARCH_CO_INSURED : "Search CoInsured",
  ACCOUNT : "Account Number",
  POLICY_NUMBER : "Policy Number",
  FIRST_NAME : "First Name",
  LAST_NAME : "Last Name",
  EMAIL : "Email",
  PHONE : "Phone",
  SEARCH_PROPERTY : "Search Property",
  SEARCH_MAILING : "Search Mailing",
  STREET_ADDRESS : "Street Address",
  APRARTMENT : "Apartment/ Unit",
  CITY: "City",
  STATE : "State",
  ZIP : "Zip",
  CLEAR : "Clear",
  SEARCH :"Search",
  NAME : "Name",
  ADDRESS : "Address",
  AGENCY: "Agency"
}

export const CustomerActivityLabel = {
  NEW_ACTIVITY : 'New Activity',
  CUSTOMER_ACTIVITY: "Customer Activities",
  ADD_CUSTOMER_ACTIVITY: "Add Activity",
  ACTION_TYPE: "Action Type",
  TIME: "Time",
  TRANSACTION: "Transaction",
  DESCRIPTION: "Description",
  POLICY_NUMBER: "Policy Number",
  EFFECTIVE_DATE: "Effective Date",
  CSR: "CSR",
  ACTIVITY_TYPE: "Action",
  CUSTOMER_SELECT: "Customer",
  POLICY_SELECT: "Policy Number",
  CLAIM_SELECT: "Claim Number",
  ACTIVITY_EFFECTIVE_DATE : 'Effective Date',
  CUSTOMER_POLICY_INFORMATION : 'Customer Policy Information',  
  PLEASE_SELECT : "Please Select"
}

export const CustomerHistoryLabel = {
  FIELD : "Field",
  OLD_VALUE : "Old Value",
  NEW_VALUE: "New Value",
  USER: "User",
  TIMESTAMP: "Timestamp",
  ACTION: "Action",
  ENTITY: "Entity"
}

export const RolePrivilegeSetupLabels = {
  ROLE: "Role",
  NEW_ROLE : "NEW ROLE",
  ROLE_NAME:"Role Name",
  ADD_SCREEN_PRIVILEGE: "ADD SCREEN PRIVILEGE GROUP",
  PRIVILEGE : "PRIVILEGE",
  VIEW:"VIEW",
  CREATE:"CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  READ: "READ"
}

export const HOME='Home'
export const CUSTOMER='Customer'
export const CUSTOMERS='Customers'
export const CAMPAIGN='Campaign'
export const DOCUMATE='DocuMate+'
export const ADMIN='Admin'
export const CAMPAIGNS='Campaigns'
export const DASHBOARD='Dashboard'
export const FILTER='Filters'
export const CALENDAR='Calendar'
export const APPROVAL='Approval'
export const QUERIES='Queries'
export const EXTERNAL_TEMPLATE='3rd Party Template'
export const CAMPAIGN_DETAILS='Campaign Details'
export const CAMPAIGN_APPROVAL='Campaign Approval'
export const CAMPAIGN_NAME='Campaign Name'
export const CAMPAIGN_DESCRIPTION='Campaign Description'
export const TEMPLATE_TYPE='Template Type'
export const TEMPLATE_NAME='Template Name'
export const TEMPLATE_DESCRIPTION='Template Description'
export const TEMPLATE_CATEGORY='Template Category'
export const QUERY_NAME='Query Name'
export const QUERY_DESCRIPTION='Query Description'
export const FILTER_CRITERIA='Filter Criteria'
export const SEND_ON_DATE='Send on Date'
export const REQUESTED='Requested'
export const REQUESTED_BY='Requested By'
export const UPDATED_BY='Updated By'
export const LAST_UPDATED='Last Updated'
export const STATUS='Status'
export const APPROVED='APPROVED'
export const SAVE='Save'
export const EDIT='Edit'
export const SAVE_AS='Save As'
export const DELETE='Delete'
export const CANCEL='Cancel'
export const INACTIVE='Inactive'
export const RESULTS='Results'
export const TEST_QUERY='Test Query'
export const CAMPAIGN_SETTING ="Campaign Settings"
export const PLEASE_SELECT ="Please Select"
export const TEMPLATE_INACTIVE ="Template is inactive"
export const QUERY_INACTIVE ="Query is inactive"
export const SELECT_CONDITION ="Select Condition"
export const SELECT_VALUE ="Select Value"
export const CURRENCY ="currency"
export const NUMERIC ="numeric"
export const ZIP_CODE ="zip code"
export const CURRENCY_RANGE ="currency range"
export const NUMERIC_RANGE ="numeric range"
export const TO ="To"
export const AND ="And"
export const COLON =":"
export const DELETE_CAMPAIGN ="Delete Campaign"
export const CAMPAIGN_DASHBOARD ="Campaign Dashboard"
export const CAMPAIGN_ID ="Campaign ID"
export const NAME ="Name"
export const CampaignType ="Type"
export const SEND_ON ="Send on"
export const CREATED_UPDATED ="Created/Updated"
export const Query ="Query"
export const CAMPAIGN_LIST ="Campaign List"
export const NEW_CAMPAIGN ="New Campaign"
export const CAMPAIGN_QUERIES ="Campaign Queries"
export const NEW_QUERY ="New Query"
export const QUERY_ID ="Query ID"
export const CREATED_ON ="Created on"
export const CREATED_BY ="Created By"
export const DESCRIPTION ="Description"
export const QUERY_DETAIL ="Query Detail"
export const QUERY_REQUIRED ="Query is required."
export const DATABASE_FIELD ="Database Field"
export const LOGIC ="Condition"
export const FRIENDLY_NAME ="Friendly Name"
export const FIELD_TYPE ="Field Type"
export const ADD_RULE="ADD RULE"
export const DELETE_QUERY="Delete Query"
export const NEW_EXTERNAL_TEMPLATE="New 3rd Party Campaign"
export const TEMPLATE_ID="Template ID"
export const CATEGORY="Category"
export const SUMMARY="Summary"
export const TEMPLATE_DETAILS="Template Details"
export const CATEGORY_SUBCATEGORY="Category/Sub Category"
export const CREATED_REQUESTED_BY="Created/Requested By"
export const UPDATED_REQUESTED_BY="Updated/Requested By"
export const CREATED="Created"
export const INACTIVATE="Inactivate"
export const ACTIVATE="Activate"
export const CAMPAIGN_CALENDAR="Campaign Calendar"
export const Search_CALENDAR="Search by Campaign Name"
export const UNIQUE_KEY="UNIQUE KEY"
export const UNIQUE="UNIQUE"
export const YES="Yes"
export const NO="No"
export const EXPORT="Export"
export const SHOW_FILTER="Show Filter"
export const HIDE_FILTER="Hide Filter"
export const UPDATED_ON="Updated On"
export const SCROLL_TOP="Go to Top"
export const CONFIRM="Confirm"
export const DELETE_NOTE = "Delete Note"
export const UPDATED="Updated"
export const NOTES="Notes"
export const HISTORY="History"
export const ADD_NEW_NOTE="+ Add Note"
export const INCLUDE_INACTIVE_QUERIES="Include inactive queries"
export const EXPORT_CSV='Export to csv'
export const INCLUDE_INACTIVE_CAMPAIGNS="Include inactive campaigns"
export const TRY_AGAIN="Try again"
export const OVERIDE_SEND_ON_DATE="Override Send On Date?"
export const INACTIVE_CAMPAIGN ="Inactive the campaign?"
export const CUSTOMER_NUMBER ="Customer Number"
export const POLICY_NUMBER="Policy Number"
export const FIRST_NAME="First Name"
export const LAST_NAME="Last Name"
export const ADDRESS="Address"
export const CITY ="City"
export const STATE="State"
export const COUNTY="County"
export const ZIP="Zip"
export const  AGENCY="Agency" 
export const SOURCE="Source"
export const PHONE_NUMBER = "Phone Number"
export const EMAIL="Email"
export const RATED_CARRIER="Rated Carrier"
export const SEND_TEST="SEND TEST"
export const Send_Test="Send Test"
export const REQUEST="REQUEST"
export const CONFIRM_CONTINUE= "Do you wish to continue?"
export const FORCED_CAMPAIGN_REQUEST="Below campaigns are within 10 days within your campaign"
export const HAS_SEND_ON="Has send on date of"
export const TOTAL="Total"
export const FROM ="from"
export const RUN_QUERY='Run Query'
export const RECORD_COUNT='Record Count'
export const DELETE_TEMPLATE="Delete Template"
export const SEND_TEST_EMAIL_LABEL= 'Send test to the Following emails'
export const SEND_TEST_EMAIL_PLACEHOLDER='Enter email(s) separated by ;'
export const SEND='SEND'
export const APPROVAL_INFORMATION='Approval Information'
export const APPROVED_BY ='Approved By'
export const APPROVAL_DATE ='Approval Date'
export const REJECT= 'REJECT'
export const APPROVE='APPROVE'
export const REJECT_REASON= 'Rejection Reason'
export const REJECT_REASON_PLACEHOLDER ='Enter a Rejection Reason'
export const CAMPAIGNSENTDATE='Campaigns Sent'
export const CAMPAIGN_FROM_EMAIL_PLACEHOLDER = "Please select 'from' email"
export const FROM_EMAIL ='From Email'
export const EmployeeId ='EmployeeID'
export const LastLogin ='Last Login'
export const UserSetupCompleted ='User Setup'
export const EMPLOYEE_SETUP='Employee Setup'
export const NEW_EMPLOYEE="New User"
export const USER_SEARCH_FILTER="User Search Filter"
export const USER_SEARCH_DETAILS="User Search Details"
export const USER_SETUP="User Setup"
export const HISTORY_TAB="History"
export const POLICY_FILE='Files'