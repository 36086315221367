import { Row, Column, Button, Spinner, Panel, Label, TextField, Icon, Modal } from '../../../../components';
import { ErrorBoundary } from 'react-error-boundary'
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import HistoryDetail from '../model/HistoryDetail';
import { notesUtils } from '../hooks/ManageNotesOperations'
import { toast } from 'react-toastify';
import * as constant from '../../../util/constant'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFormSaved } from '../../../components/formSaved-slice';
import { Container } from 'react-bootstrap';


const HistoryCard = () => {
    const historydetails: HistoryDetail = {
        HistoryID: 0,
        EntityID: 0,
        EntityType: '',
        ChangedField: '',
        OldValue: '',
        NewValue: '',
        CreatedOn: new Date(),
        CreatedBy: '',
        UpdatedOn: new Date(),
        UpdatedBy: ''
    }

    const location = useLocation();
    const state = location.state as { entityId: string, type: string };
    const urlParam = new URLSearchParams(location.search)
    const queryId = state?.entityId ?? urlParam.get('id');
    const type = location.state as { type: string };
    const noteType = state?.type;
    const [queryHistory, getHistory] = useState([]);
    const dispatch = useDispatch();
    const formSaved = useSelector(state => state.formSavedSlice.formSaved);

    function cleanupProperties(data) {
        const resData = { ...data };
        if(resData.fieldName.startsWith('_')) {
            resData.fieldName = resData.fieldName.charAt(1).toUpperCase() + resData.fieldName.slice(2);
        }
        return resData;
    }
    
    function GetHistoryByQueryId() {
        if (queryId !== undefined && queryId !== '' && queryId !== null) {
            notesUtils.GetHistoryByIdType(queryId).then((res) => {

                if (res.data !== undefined && res.data.length > 0) {
                    const cleanedProperties = res.data.map(cleanupProperties);
                    getHistory(cleanedProperties);
                }

                if (res.status >= 200 && res.status < 300) {
                    //Some Code here if required
                }
                else {
                    toast.error(constant.ERROR_MESSAGE_LOAD, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 10000
                    });
                }
            }).catch(exception => {
                toast.error(constant.ERROR_MESSAGE, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 10000
                });
            })
        }
    }


    useEffect(() => {
        GetHistoryByQueryId();
        if(formSaved) {
          dispatch(toggleFormSaved())
        }
    }, [formSaved])

    return (
        <>
            {queryHistory.map((data, index) => {

                return (
                    <Container key={index} fluid>
                        <Row className='my-2' style={{ fontSize: '12px' }}>
                            <Column lg={12} md={12} className='px-0'>
                                <Label text={"FIELD:"} className="font-bold" /> &nbsp;
                                <Label text={data.fieldName} className="mb-2" />
                            </Column>
                            <Column lg={12} md={12} className='px-0'>
                                <Row>
                                    <Column lg={6} md={6} className="">
                                        <Label text={"OLD VALUE:"} className="d-block font-bold mb-0" />
                                        <Label text={data.oldValue} className="normalText nomargin-top historyPanel-normalText text-break" />
                                    </Column>
                                    <Column lg={6} md={6} className="">
                                        <Label text={"NEW VALUE:"} className="d-block font-bold mb-0" />
                                        <Label text={data.newValue} className="normalText nomargin-top historyPanel-normalText text-break" />
                                    </Column>
                                </Row>
                            </Column>
                            <Column lg={12} md={12} className='border-bottom mb-2 pb-3 px-0'>
                                <small title={moment.tz(data.changedDate.toString(), 'America/New_York')
                                    .local()
                                    .format("MMMM Do YYYY, h:mm:ss a z")}>
                                    By {data.changedBy}, &nbsp;
                                    {moment.tz(data.changedDate.toString(), 'America/New_York')
                                        .local()
                                        .fromNow()}
                                </small>
                            </Column>
                        </Row>
                    </Container>
                    
                )
            })}

        </>
    )


}
export default HistoryCard