import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './DocuMateScanPortal.scss';
import { Column, Icon } from '../../components';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import * as docuMateLabel from '../shared/util/docuMateLabel';
import * as docuMateConstant from '../shared/util/docuMateConstant';
import { ConfirmModal } from '../../shared/components/confirmModal';
import DocViewer from './../shared/components/DocViewer';
import { GroupListDto } from '../model/GroupListDto';
import DocuMateScanService from './DocuMateScanService';
import { GetDocumentListQuery } from '../model/GetDocumentListQuery';
import { GetFolderListByGroupIdQuery } from '../model/GetFolderListByGroupIdQuery';
import { toast } from 'react-toastify';
import Logger from '../../shared/services/Logger';
import React from 'react';
import * as constant from '../../shared/util/constant';
import { Alert, Badge, Container, Row } from 'react-bootstrap';
import { Card } from 'primereact/card';

const initialData: DocImage[] = [];
const sampleGroups: GroupListDto[] = [];
const foldersList = [];

const Rescan = {
    id: constant.Documate.RescanFolderID,
    name: "Rescan",
    files: []
}

const DocuMateScanPortal = () => {
    const documateServiceObj = new DocuMateScanService();
    const getUnAssignedDocumentRequestModel: GetDocumentListQuery = {
        DocQueueId: constant.Documate.UnAssignedFolderID
    }

    const getRescanDocumentRequestModel: GetDocumentListQuery = {
        DocQueueId: constant.Documate.RescanFolderID
    }    

    const [unassignedFiles, setUnassignedFiles] = useState(initialData);
    const [destinationFolders, setDestinationFolders] = useState([]);
    const [rescanFolder, setRescanFolder] = useState(Rescan);
    const [groups, setGroups] = useState<GroupListDto[]>([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [expandedGroups, setExpandedGroups] = useState({});
    const [showConfirmAssign, setShowConfirmAssign] = useState(false);
    const [showEmptyRescan, setShowEmptyRescan] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [pendingDragDop, setPendingDragDrop] = useState(null);
    const [confirmAssignBody, setConfirmAssignBody] = useState(null);
    const [docViewerOpen, setDocViewerOpen] = useState(false);
    const [docId, setDocId] = useState('');
    const [fileToDelete, setFileToDelete] = useState('');
    const [fileName, setFileName] = useState('');
    const getConfirmDeleteBody = (fileName) => (
        <>   
        Are you sure you want to delete <strong>{fileName}</strong> from the <strong>Rescan</strong> folder?
        </>
    );   

    const FetchUnAssignedDocData = async () => {
        try {
            const data = await documateServiceObj.getUnAssignedDocument(getUnAssignedDocumentRequestModel);
            setUnassignedFiles(data);
        }
        catch (error) {
            toast.error(constant.ERROR_MESSAGE_LOAD, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });

            Logger.logError(error.toString());
            setUnassignedFiles([]);
        }
    }

    const FetchGroupData = async () => {
        try {
            const groupData = await documateServiceObj.getListOfDestinationFoldersData();
            setGroups(groupData);
        }
        catch (error) {
            toast.error(constant.ERROR_MESSAGE_LOAD, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });

            Logger.logError(error.toString());
            setGroups([]);
        }
    }

    const FetchRescanData = async () => {
        const data = await documateServiceObj.getReScanDocument(getRescanDocumentRequestModel);
        const rescanFinal = {
            id: constant.Documate.RescanFolderID,
            name: "Rescan",
            files: data
        }
        setRescanFolder(rescanFinal);
    }

    useEffect(() => {
        initializeComponent();
    }
        , []);

    useEffect(() => {
        if (selectedFiles.length > 1) {
            handleDocViewerClose();
        }
    }
        , [selectedFiles]);

    const initializeComponent = () => {
        // make api call to fetch the groups here         
        setGroups(sampleGroups);
        setDestinationFolders(foldersList);
    }

    const fetchFolders = async (groupId: string) => {

        const getFolderListByGroupIdRequestModel: GetFolderListByGroupIdQuery = {
            GroupId: groupId
        }
        try{
            if (destinationFolders.length == 0) {            
                const data = await documateServiceObj.getFolderListByGroupId(getFolderListByGroupIdRequestModel);
                setDestinationFolders(data);           
            }
        else if (destinationFolders.filter(folder => folder.groupId === groupId).length <= 0) {          
                const data = await documateServiceObj.getFolderListByGroupId(getFolderListByGroupIdRequestModel);          
                data.map((item) => (
                    destinationFolders.push(item)
                ));

                setDestinationFolders(destinationFolders);
            }
        }
        catch (error) {
            toast.error(constant.ERROR_MESSAGE_LOAD, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: constant.AUTO_CLOSE
            });

            Logger.logError(error.toString());
            setDestinationFolders([]);
        }

        setExpandedGroups(prevState => ({
                ...prevState,
                [groupId]: !prevState[groupId]
            }));
        }

    const confirmDeleteBody = getConfirmDeleteBody(fileName);

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || destination.droppableId === source.droppableId) {
            return;
        }
        let selectedFilesToMove = selectedFiles.includes(result.draggableId) ? selectedFiles : [result.draggableId];
        if (destination.droppableId === 'Rescan') {
            setConfirmAssignBody(<span>Are you sure you want to assign {selectedFilesToMove.length} scans to the <b>Rescan</b> folder?</span>);
        } else {
            const destFolder = getFolderById(destination.droppableId);
            let folderGrp = groups.find(group => group.GroupId === destFolder.groupId);
            let folderName = destFolder.name;
            setConfirmAssignBody(<span>Are you sure you want to assign {selectedFilesToMove.length} scans to the <b>{folderGrp.GroupName} Group "{folderName}"</b> folder?</span>);
        }
        if (selectedFilesToMove.length > 1) {
          // If more than one file is selected, open the modal
          setPendingDragDrop(result); // Store the result in state
          setShowConfirmAssign(true);
        } else {
          // If only one file is selected, move it immediately
          moveFiles(result, selectedFilesToMove);
        }
      }

    const moveFiles = (result, selectedFilesToMove) => {
        const { source, destination } = result;
        let updatedUnassignedFiles = [...unassignedFiles];
        let updatedFolders = { ...destinationFolders };
        let updatedRescanFolder = { ...rescanFolder };

        const filesToMove = source.droppableId === 'unassigned'
            ? unassignedFiles.filter(file => selectedFilesToMove.includes(file.id))
            : source.droppableId === 'Rescan'
                ? rescanFolder.files.filter(file => selectedFilesToMove.includes(file.id))
                : getFolderById(source.droppableId).files.filter(file => selectedFilesToMove.includes(file.id));

        if (source.droppableId === 'unassigned') {
            updatedUnassignedFiles = unassignedFiles.filter(file => !selectedFilesToMove.includes(file.id));
        } else if (source.droppableId === 'Rescan') {
            updatedRescanFolder.files = rescanFolder.files.filter(file => !selectedFilesToMove.includes(file.id));
        } else {
            const sourceFolder = getFolderById(source.droppableId);
            sourceFolder.files = sourceFolder.files.filter(file => !selectedFilesToMove.includes(file.id));
        }

        if (destination.droppableId === 'Rescan') {
            updatedRescanFolder.files.push(...filesToMove);
        } else {
            const destFolder = getFolderById(destination.droppableId);
            destFolder.files.push(...filesToMove);
        }

        setUnassignedFiles(updatedUnassignedFiles);
        setRescanFolder(updatedRescanFolder);
        setSelectedFiles([]);
    }

    const getFolderById = (id: string) => {
        let destFolder = destinationFolders.find(folder => folder.id === id);
        return destFolder;
    }

    const handleCheckBoxChange = (fileId) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.includes(fileId) ? prevSelectedFiles.filter(selectedFile => selectedFile !== fileId) : [...prevSelectedFiles, fileId])
    }

    const handleSelectAll = () => {
        if (selectedFiles.length === unassignedFiles.length) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(unassignedFiles.map(file => file.id));
        }
    }

    const handleCancelAssign = () => {
        setPendingDragDrop(null);
        setShowConfirmAssign(false);
    }

    const handleConfirmAssign = () => {
        if (pendingDragDop) {
            let selectedFilesToMove = selectedFiles.includes(pendingDragDop.draggableId) ? selectedFiles : [pendingDragDop.draggableId];
            moveFiles(pendingDragDop, selectedFilesToMove);
            setPendingDragDrop(null);
            setConfirmAssignBody(null);
          }
          setShowConfirmAssign(false);
    }

    const deleteFileFromRescan = () => {
        setRescanFolder((prevRescanFolder) => {
            const updatedFiles = prevRescanFolder.files.filter(file => file.id !== fileToDelete);
            return {
                ...prevRescanFolder,
                files: updatedFiles
            };
        });
        if(docId === fileToDelete) {
            setDocId('');
            setDocViewerOpen(false);
        }
        setShowConfirmDelete(false);
    }

    const emptyRescanFolder = () => {
        if (rescanFolder.files.some(file => file.id === docId)) {
            setDocId('');
            setDocViewerOpen(false);
        }
        setRescanFolder((prevRescanFolder) => ({
            ...prevRescanFolder,
            files: []
        }));
    }

    const handleCancelEmptyRescan = () => {
        setShowEmptyRescan(false);
    }

    const handleCancelDelete = () => {
        setShowConfirmDelete(false);
    }

    const handleEmptyRescan = () => {
        emptyRescanFolder();
        setShowEmptyRescan(false);
    }

    const RescanFolder = ({ folderId, folder }) => (
        <Droppable droppableId={folderId} key={folderId}>
            {(provided) => (
                <div
                    className="folder"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    <div style={{ fontWeight: 'bold' }}>
                        {folder.files.length > 0 ? (
                            <Icon icon="folderOpen" outline="false" className="folder-icon" />
                        ) : (
                            <Icon icon="folder" outline="false" className="folder-icon" />
                        )}
                        {folder.name}{folder.files.length > 0 && (<span onClick={() => setShowEmptyRescan(true)}><Icon icon="trash" className="trash-icon"/></span>)}
                        {folder.files.length > 0 && (
                            <span className="count">{folder.files.length}</span>
                        )}
                    </div>
                    {folder.files.map((file, index) => (
                        <Draggable key={file.id} draggableId={file.id} index={index}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`dest-file-item ${snapshot.isDragging ? 'dragging' : ''} ${docId === file.id ? 'clicked' : ''}`}
                                    onClick={() => handleFileClick(file.id)}
                                >
                                    {file.fileName}
                                    {!snapshot.isDragging && (
                                        <span 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowConfirmDelete(true);
                                            setFileToDelete(file.id);
                                            setFileName(file.fileName);}}>
                                            <Icon icon="trash" className="trash-icon"/>
                                        </span>
                                    )}
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
      );

    const getGroupCount = (group) => {
        let count = 0;
        destinationFolders.forEach(folder => {
            if(folder.groupId === group.GroupId) {
                count += folder.files.length;
            }
        });
        return count;
    }

    const handleFileClick = (docId) => {
        if (selectedFiles.length > 1) {
          // More than one file selected, do not open the doc viewer
          setDocId('');
          setDocViewerOpen(false);
        } else if (selectedFiles.length === 1 && selectedFiles[0] !== docId) {
          // Only one file selected and it's the same as the clicked file, open the doc viewer
          setDocId('');
          setDocViewerOpen(false);
        } else {
          setDocId(docId);
          setDocViewerOpen(true);
        }
      };

    const handleDocViewerClose = () => {
        setDocViewerOpen(false);
        setDocId('');
    }

    const handleToggleGroup = (groupId: string) => {
        // fetch the folders for the group from the backend
        fetchFolders(groupId);
    };

    const renderGroups = (groups) => {
        return groups.map((group) => (
            <div key={group.GroupId} className="folder">
                <div onClick={() => handleToggleGroup(group.GroupId)} style={{ fontWeight: 'bold' }}>
                    {expandedGroups[group.GroupId] ? (
                        <FaChevronDown className="folder-icon" />
                    ) : (
                        <FaChevronRight className="folder-icon" />
                    )}
                    {group.GroupName}
                    {getGroupCount(group) > 0 && (
                        <span className="count">{getGroupCount(group)}</span>)}
                </div>
                {expandedGroups[group.GroupId] && renderFolders(group.GroupId)}
            </div>
        ));
    };

    const renderFolders = (groupId: string) => {
        const folders = destinationFolders.filter(folder => folder.groupId === groupId);
        return folders.map((folder) => (
            <Droppable droppableId={folder.id} key={folder.id}>
                {(provided) => (
                    <div
                        className="subfolder"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <div>
                            {folder.files?.length > 0 ? <Icon icon="folderOpen" outline='false' className='subfolder-icon' /> : <Icon icon="folder" outline='false' className='subfolder-icon' />}
                            {folder.name}
                            {folder.files.length > 0 && (
                                <span className="count">{folder.files.length}</span>
                            )}
                        </div>
                        {folder.files.map(
                            (file: { id: string, fileName: string }, index: number) => (
                                <Draggable key={file.id} draggableId={file.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`dest-file-item ${snapshot.isDragging ? 'dragging' : ''} ${docId === file.id ? 'clicked' : ''}`}
                                            onClick={() => handleFileClick(file.id)}
                                        >
                                            {file.fileName}
                                        </div>
                                    )}
                                </Draggable>
                            )
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        ));
    };

    useEffect(() => {
        FetchUnAssignedDocData()
        FetchGroupData()
        FetchRescanData()
    }, [])

    return (
        <>
            <Container fluid className="d-flex flex-column pb-3" style={{ height: 'calc(100vh - 50px)' }}>
                <Row>
                    <Column>
                        <Alert key={'primary'} variant={'primary'}>
                            Selected {selectedFiles.length} of {unassignedFiles.length} for bulk actions
                        </Alert>
                    </Column>
                </Row>
                <Row className="flex-grow-1 overflow-auto">
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        {/* Unassigned Files Panel */}
                        <Column lg={3} md={3} className='left-panel'>
                            <Card className="h-100">
                                <div className="panel-header">
                                    <label>
                                        <input
                                            type="checkbox"
                                            onChange={handleSelectAll}
                                            checked={selectedFiles.length === unassignedFiles.length && unassignedFiles.length > 0}
                                        />
                                        {docuMateLabel.DOCUMATE_UNASSIGNED.toUpperCase()} 
                                    </label>
                                    <Badge bg="info">{unassignedFiles.length}</Badge>

                                </div>
                                <Droppable droppableId="unassigned">
                                    {(provided) => (
                                        <div
                                            className="file-list"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {unassignedFiles.map((file, index) => (
                                                <Draggable key={file.id} draggableId={file.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`file-item ${snapshot.isDragging ? 'dragging' : ''} ${selectedFiles.includes(file.id) ? 'selected' : ''} ${docId === file.id ? 'clicked' : ''}`}
                                                            onClick={() => handleFileClick(file.id)}
                                                        >
                                                            {!snapshot.isDragging && (
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedFiles.includes(file.id)}
                                                                    onChange={() => handleCheckBoxChange(file.id)}
                                                                    onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the div click
                                                                />
                                                            )}
                                                            {snapshot.isDragging && selectedFiles.length > 1
                                                                ? (
                                                                    <>
                                                                        {file.fileName}{' '}<span style={{ fontWeight: 'bold' }}>+ {selectedFiles.length - 1} more</span>
                                                                    </>
                                                                )
                                                                : file.fileName}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </Card>

                        </Column>
                        {/* Destination Folders Panel */}
                        <Column lg={3} md={3} className="right-panel">
                            <Card className="h-100">
                                <div className="panel-header">
                                    <label>{docuMateLabel.DOCUMATE_DEST_FOLDER.toUpperCase()}</label>
                                </div>
                                {renderGroups(groups)}
                                {/* Rescan Folder */}
                                <RescanFolder folderId="Rescan" folder={rescanFolder} />
                            </Card>

                        </Column>
                        {docViewerOpen &&
                            <div className='doc-view-container'>
                                <DocViewer docId={docId} handleDocViewerClose={handleDocViewerClose} />
                            </div>
                        }
                    </DragDropContext>
                    <ConfirmModal
                        modaltitle={docuMateLabel.CONFIRM}
                        showConfirmation={showConfirmAssign}
                        setConfirmation={(isModalOpen) => setShowConfirmAssign(isModalOpen)}
                        body={confirmAssignBody}
                        closeButtonClick={() => handleCancelAssign()}
                        successButtonClick={() => handleConfirmAssign()}
                        closeButtonLabel={docuMateLabel.CONFIRM_NO.toUpperCase()}
                        successButtonLabel={docuMateLabel.CONFIRM_YES.toUpperCase()}
                    />
                    <ConfirmModal
                        modaltitle={docuMateLabel.CONFIRM}
                        showConfirmation={showEmptyRescan}
                        setConfirmation={(emptyRescanOpen) => setShowEmptyRescan(emptyRescanOpen)}
                        body={docuMateConstant.CONFIRM_EMPTY_RESCAN}
                        closeButtonClick={() => handleCancelEmptyRescan()}
                        successButtonClick={() => handleEmptyRescan()}
                        closeButtonLabel={docuMateLabel.CONFIRM_NO.toUpperCase()}
                        successButtonLabel={docuMateLabel.CONFIRM_YES.toUpperCase()}
                    />
                </Row>
                <ConfirmModal
                    modaltitle={docuMateLabel.CONFIRM}
                    showConfirmation={showConfirmDelete}
                    setConfirmation={(showConfirmDelete) => setShowConfirmDelete(showConfirmDelete)}
                    body={confirmDeleteBody}
                    closeButtonClick={() => handleCancelDelete()}
                    successButtonClick={() => deleteFileFromRescan()}
                    closeButtonLabel={docuMateLabel.CONFIRM_NO.toUpperCase()}
                    successButtonLabel={docuMateLabel.CONFIRM_YES.toUpperCase()}
                />
            </Container>

        </>
    );
};

export default DocuMateScanPortal;