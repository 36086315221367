 import {Chart,CategoryScale,LinearScale,BarController, BarElement, LineController, LineElement, PointElement,Title,Legend} from 'chart.js'
import { FallbackComponent } from '../../shared/components/FallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'
import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { Tooltip } from 'chart.js'
import * as label from '../../shared/util/label'

Chart.register([CategoryScale, LinearScale, BarController, BarElement, LineController, LineElement, PointElement,Tooltip,Title,Legend])
interface Props {
    sendonDateData:any
}


//Loading Bar graph for sent on date.
const SentonDateGraph = (prop: Props) => {
    const [sentonDateData, setSentonDateData] = useState(prop.sendonDateData);

     useEffect(() => {
        setSentonDateData(prop.sendonDateData)
     },[prop.sendonDateData])

    return (
        <>
         <ErrorBoundary FallbackComponent={FallbackComponent}>
         <Bar 
                    data={{
                        // Name of the variables on x-axies for each bar
                        labels: sentonDateData.map(e=>e.months+"-"+e.year),
                        datasets: [
                            {
                                // Label for bars
                                label: label.CAMPAIGNSENTDATE,
                                // Data or value of your each variable
                                data: sentonDateData.map(e=>e.count),
                                // Color of each bar
                                 backgroundColor:  "#36a2eb",
                                 barThickness:30,
                                // // Border color of each bar
                                borderWidth: 0.5,
                            },
                        ],
                    }}
                    // Height of graph
                    height={400}
                    options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                            position:'top',
                            align:'center',
                            onClick: () => {},
                            title:{
                                display: false,
                                text: label.CAMPAIGNSENTDATE,
                                font: {
                                    size: 14,
                                    weight: 'bold'
                             },
                            }
                          },
                          tooltip: { 
                            enabled: true, 
                            backgroundColor: "#343a40", 
                            titleColor: "#fdfffc", 
                            bodyColor: "#fdfffc", 
                            titleFont: { weight: 'bold' }, 
                            padding: 10, 
                            cornerRadius: 10, 
                            borderColor: "#042a0b", 
                            xAlign: "left" 
                        }, 
                        },
                        maintainAspectRatio: false,
                        scales: {
                            y: 
                                {
                                    suggestedMin:0,
                                    suggestedMax:70,
                                    beginAtZero: true,
                                    ticks: {
                                        stepSize: 5,
                                    },
                                },
                                x:
                                {
                                  
                                },
                            },
                    }}
                />
         </ErrorBoundary>
         </>
         )
}

export default SentonDateGraph