import reportWebVitals from './reportWebVitals';
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PublicClientApplication, EventType  } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authconfig";

import { PrimeReactProvider } from 'primereact/api';
import Bootstrap_PT from './shared/util/bootstrapPrime';

import './index.css'
import App from './App' 
import './shared/config/i18n'
import store from './shared/store'
import './cs2common.css'

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      
    }
  });


  ReactDOM.render(
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <PrimeReactProvider value={{ unstyled: true, pt: Bootstrap_PT }}>
          <App />
        </PrimeReactProvider>
      </MsalProvider>
    </Provider>,
    document.getElementById('root'),
  )
});
reportWebVitals();
