import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import Customer from '../model/Customer'
import { PolicyDtoRead } from '../model/PolicyDtoRead'



interface CustomerState{
    customer:Customer,
    policies:PolicyDtoRead[],
    isFetchingPolicyList : boolean
}

const initialState : CustomerState = {
    customer:{},
    policies : [],
    isFetchingPolicyList : false
}


const customerSlice = createSlice({
    name : "customers",
    initialState,
    reducers : {
        updateCustomers(state, action: PayloadAction<Customer>){
           const customers= action.payload;
            state.customer = customers;
          
        },
        storeCustomerPolicies(state, action: PayloadAction<PolicyDtoRead[]>) {{
            const policies = action.payload;
            state.policies = policies;
            state.isFetchingPolicyList = false;
        }},
        startFetchingCustomerPolicies(state) {{
            state.isFetchingPolicyList = true;
        }},
    }
})

export const { updateCustomers, storeCustomerPolicies, startFetchingCustomerPolicies } = customerSlice.actions;
export default customerSlice.reducer;