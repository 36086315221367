import { useContext, useEffect, useState } from "react";
import { Button, Column, DateTimePicker, Icon, Label, Panel, Row, TextField } from "../../components";
import * as label  from '../../shared/util/label';
import Grid from "../../components/components/Grid/Grid";
import { Link } from "react-router-dom";
import { Collapse, Table } from "react-bootstrap";
import React from "react";
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import './CustomerActivity.scss';
import CustomerActivityService from "./CustomerActivityService";
import DropDownWithLabelInline from "../../shared/components/input/DropDownWithLabelInline";
import DatePicker from "react-datepicker";
import moment from "moment";
import DateTimePickerWithLabelFormGroup from "../../shared/components/input/DateTimePickerWithLabelFormGroup";
import { SelectOption } from "../../shared/components/input";
import { CustomerActivityAddRequest } from "../model/CustomerActivityAddRequest";
import * as constant from '../../shared/util/constant';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../shared/store";
import { AppEvenType, clearAppEvent } from "../../shared/store/component-notification/ComponentNotification";
import { useConfirmationModalContext } from "../../shared/components/ModalConfirmationContext";
import TextInputWithLabelInline from "../../shared/components/input/TextInputWithLabelInline";
import CustomerDetailService from "../customer-details/CustomerDetailService";
import { updateCustomers } from "../customer-slice/Customer-slice";
import { AbilityContext } from "../../shared/abilities/AbilityContext";

const CustomerActivity = (props) => {

    const defaultAddActivityRequest: CustomerActivityAddRequest = {
        custId: '',
        activityTypeId: '',
        tranTypeId: '',
        policyId: '',
        activityEffDate: new Date(),
        description: '',
        claimId: null
    }

    const modalContext = useConfirmationModalContext();
    let customerInfo = useSelector((state: RootState) => state?.customerSlice);
    const customerDetailService = new CustomerDetailService();
    const [isEffectiveDateInvalid, setIsEffectiveDateInvalid] = useState(false);
    const [effectiveDateValidError, setEffectiveDateValidError] = useState(null);
    const eventState = useSelector((state: RootState) => state?.eventState);
    const dispatch = useDispatch();
    const [isFormSaved, setIsFormSaved] = useState(true);
    const [isActivityDescriptionEmpty, setIsActivityDescriptionEmpty] = useState(false);
    const [isActivityTypeEmpty, setIsActivityTypeEmpty] = useState(false);
    const [isAddActivityPanelVisible, setIsAddActivityPanelVisible] = useState(false);
    const [customerActivities, setCustomerActivities] = useState([]);
    const [customerId, setCustomerId] = useState(props.customerId);
    const [pageNumber, setPagenumber] = useState(1);
    const [activityTypes, setActivityTypes] = useState<SelectOption[]>([]);
    const [transactionTypes, setTransactionTypes] = useState<SelectOption[]>([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [customerDependentsForSelect, setCustomerDependentsForSelect] = useState<SelectOption[]>([]);
    const [claimsForSelect, setClaimsForSelect] = useState<SelectOption[]>([]);
    const [policiesForSelect, setPoliciesForSelect] = useState<SelectOption[]>([]);
    const customerActivityService = new CustomerActivityService();
    const [addActivityRequest, setAddActivityRequest] = useState<CustomerActivityAddRequest>(defaultAddActivityRequest);
    const ab_subject = "Customer Activity";
    const ability = useContext(AbilityContext);
    const canUserCreateCustomerActivity = ability.can('create',ab_subject,constant.ScreenName.CUSTOMER_ACTIVITY);

    useEffect(() => {
        if (eventState.eventType == AppEvenType.NewCustomerActivityCreated) {
            fetchActivities(customerId);
            dispatch(clearAppEvent());
        }
    }, [eventState.eventType]);


    const fetchActivities = async(customerId) => {
        try 
        {
            let custActivities = await customerActivityService.getActivites(customerId, pageNumber);
            if(pageNumber === 1) {
                setCustomerActivities(custActivities);
            }
            else {
                setCustomerActivities(prevData => [...prevData, ...custActivities]);
            }
        }
        catch(ex) {
             if (ex.data?.error?.includes(constant.CustomerTaskConstants.CUSTOMER_DELETED)) {
                toast.error(constant.CUSTOMER_NOT_FOUND);
                props.navigateTo('/customer/customerfilter');
                return;
            }
        }
    };

    useEffect(() => {
        fetchActivities(customerId);
  }, [pageNumber]);

  useEffect(() => {
    customerActivityService.getActivityTypes().then(res => {
        setActivityTypes(res);
    })

    customerActivityService.getTransactionTypes().then(res => {
        setTransactionTypes(res);
    })

    customerActivityService.getPoliciesForCustomer(customerId).then(res => {
        setPoliciesForSelect(res);
    })

    fetchCustomerOptions();

  }, []);

    const fetchCustomerOptions = async () => {
        
        // Fetch and configure customer options
        let custOptions : SelectOption[] = [];
        if (customerInfo?.customer?.id) {
            custOptions =  [
                {
                    label : customerInfo?.customer?.displayName,
                    value : customerInfo?.customer?.id
                }
            ];
        }
        else {
            let cst = await customerDetailService.fetchCustomerById(props.customerId);
            if (cst) {
                custOptions =  [
                    {
                        label : cst.displayName,
                        value : cst.id
                    }
                ];

                dispatch(updateCustomers(cst));
            }
        }

        setCustomerDependentsForSelect(custOptions);
    }

    const [open, setOpen] = useState({});
    const handleToggle = (id) => {
        setOpen((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const loadMoreActivities = () => {
        setPagenumber(prevPageNum => prevPageNum + 1); // Increment page
    };

    const handleCancel = async () => {
        const result = await modalContext.showConfirmation(constant.SliderActivityConstant.ARE_YOU_SURE_TO_EXIT);
        if(!result){
            return;
        }

        setIsEffectiveDateInvalid(false);
	    setEffectiveDateValidError(null);

        setIsActivityDescriptionEmpty(false);

        setIsAddActivityPanelVisible(false);
        setAddActivityRequest(defaultAddActivityRequest)
        setIsActivityTypeEmpty(false);
    }

    const handleAddNewActivity = () => {
        setAddActivityRequest({...addActivityRequest, custId : customerId});
        setIsAddActivityPanelVisible(true);
        setSelectedDate(new Date());
        setSelectedTime(new Date());
    }

    const handleDateChange = date => {
        setSelectedDate(date);
    };
    
    const handleTimeChange = time => {
        setSelectedTime(time);
    };

    const onFieldChange = (name: string, value: string | Date) => {
    setAddActivityRequest({
        ...addActivityRequest,
        [name]: value,
    })
    setIsFormSaved(false);
    }

    const validateForSave = () => {
        const isDescriptionEmpty = !addActivityRequest.description || addActivityRequest.description.length === 0;
        const isActivityTypeEmpty = addActivityRequest.activityTypeId === null || addActivityRequest.activityTypeId === undefined || addActivityRequest.activityTypeId.trim() === "";
        setIsActivityDescriptionEmpty(isDescriptionEmpty);
        setIsActivityTypeEmpty(isActivityTypeEmpty);
    
        let isEffectiveDateInValid = false;
        if (!addActivityRequest.activityEffDate) {
            setIsEffectiveDateInvalid(true);
            setEffectiveDateValidError(constant.FIELD_BLANK);
            isEffectiveDateInValid = true;
        }
        else if (addActivityRequest.activityEffDate > new Date()) {
            setIsEffectiveDateInvalid(true);
            setEffectiveDateValidError(constant.SliderActivityConstant.DATE_CAN_NOT_BE_A_FUTURE_DATE);
            isEffectiveDateInValid = true;
        }
        else {
            setIsEffectiveDateInvalid(false);
            setEffectiveDateValidError(null);
        }
    
        const isInvalid = isDescriptionEmpty || isActivityTypeEmpty || isEffectiveDateInValid;
        return isInvalid;
    }

    const submitAddActivity = async (addActivityRequest: CustomerActivityAddRequest) => {
        try {
            await customerActivityService.addNewActivity(addActivityRequest);
            setPagenumber(1);
            setIsFormSaved(true);
            fetchActivities(customerId);
            setAddActivityRequest(defaultAddActivityRequest)
        }
        catch (error) {
            if (error.status == 404) {
                toast.error(constant.CUSTOMER_NOT_FOUND, {
                    position: toast.POSITION.TOP_RIGHT
                });
                props.navigateTo('/customer/customerfilter');
            }
            else {
                toast.error(error.data, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: constant.AUTO_CLOSE
                });
            }
        }
    }

    const handleSaveActivity = () => {
        addActivityRequest.custId = customerId;
        addActivityRequest.activityEffDate = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            selectedTime.getHours(),
            selectedTime.getMinutes()
        );
        addActivityRequest.policyId = addActivityRequest.policyId === '' ? null : addActivityRequest.policyId;
        addActivityRequest.claimId = addActivityRequest.claimId === '' ? null : addActivityRequest.claimId;
        addActivityRequest.tranTypeId = addActivityRequest.tranTypeId === '' ? null : addActivityRequest.tranTypeId;
        if(!validateForSave()){
            addActivityRequest.claimId = null; /// AS currently, claims are not persisted.
            submitAddActivity(addActivityRequest);
            setIsAddActivityPanelVisible(false);
        }

    }


    var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    var timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

    const AddNewActivityButton = <>
        <Button
        className='btn btn-primary bt-main-action mb-2 ml-n5'
        key="filter"
        tabIndex={21}
        id="btnAddActivity"
        color='primary'
        outlined={true}
        onClick={handleAddNewActivity}
        disabled={!canUserCreateCustomerActivity}
        ><b><span><Icon icon={"add"} className="fa fa-add icon-color-blue"
        ></Icon>&nbsp;</span>{label.CustomerActivityLabel.ADD_CUSTOMER_ACTIVITY}</b>
        </Button>
    </>
    return (
        <>
        <Column lg={12} md={12} className="pt-3">
        <Row>
            <Column>
            <div style={{textAlign: "right"}}>
            {AddNewActivityButton}
            </div>
            {isAddActivityPanelVisible && (
            <div>
                <Row style={{ padding: '10px' }}>
                    <Column lg={8} md={8} style={{ boxSizing: "border-box", borderRight: '2px solid grey' }}>
                    <Row>
                        <Column lg={12} md={12} className="col-section-header"><span className="new-entry-main-title">NEW ACTIVITY</span></Column>
                    </Row>
                    <Row>
                        <Column lg={5} md={5}>
                            <DropDownWithLabelInline
                                label={label.CustomerActivityLabel.ACTIVITY_TYPE}
                                labelClass='inlineLable entry-form-inlineLable'
                                id='activityTypeSelect'
                                placeholder={label.PLEASE_SELECT}
                                options={activityTypes}
                                isRequired
                                defaultSelected={activityTypes.filter(({ value }) => value?.toLowerCase() === addActivityRequest.activityTypeId?.toLowerCase())}
                                onChange={
                                  (values) => {
                                    onFieldChange('activityTypeId', values[0])
                                  }
                                }
                                isInvalid={isActivityTypeEmpty}
                                invalidWarning={isActivityTypeEmpty ? constant.FIELD_BLANK : ""}
                                invalidLableClass="lable-error actity-type-label-error"
                                errorLableDivClassName="activity-action-select">
                            </DropDownWithLabelInline>
                        </Column>
                        <Column lg={5} md={5}>
                            <Row >
                                <Column lg={4} md={4}>
                                    <Label text={label.CustomerActivityLabel.ACTIVITY_EFFECTIVE_DATE} htmlFor={'id'} className={'inlineLable  entry-form-inlineLable'} style={{whiteSpace: 'nowrap'}}/>
                                </Column>
                                <Column  lg={5} md={5}>
                                    <DateTimePicker
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        className="activityDatePicker"
                                        minTime={new Date(1990, 0, 1)}
                                        maxDate={new Date()}
                                        isInvalid={isEffectiveDateInvalid == true}
                                        invalidWarning={effectiveDateValidError ? effectiveDateValidError : ''}
                                        invalidLableClass="lable-error"
                                    />
                                </Column>
                                <Column  lg={3} md={3}>
                                    <DateTimePicker
                                        selected={selectedTime}
                                        onChange={handleTimeChange}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        dateFormat="h:mm aa"
                                        timeCaption="Time"
                                        placeholderText="hh:mm"
                                        maxDate={new Date()}
                                        minTime={new Date(1990, 0, 1)}
                                        maxTime={new Date()}
                                        className="activityTimePicker"
                                        isInvalid={isEffectiveDateInvalid == true}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Row>
                    <Row>
                        <Column lg={5} md={5}>
                            <DropDownWithLabelInline
                                label={label.CustomerActivityLabel.TRANSACTION}
                                labelClass='inlineLable entry-form-inlineLable'
                                id='transactionTypeSelect'
                                placeholder={label.PLEASE_SELECT}
                                options={transactionTypes}
                                defaultSelected={transactionTypes.filter(({ value }) => value?.toLowerCase() === addActivityRequest.tranTypeId?.toLowerCase())}
                                onChange={
                                  (values) => {
                                    onFieldChange('tranTypeId', values[0])
                                  }
                                }>
                            </DropDownWithLabelInline>
                        </Column>
                    </Row>
                    <Row>
                    <Column lg={12} md={12}>
                            <Label text={'Description'} htmlFor={'description'} className={'labelClass'} isRequired/>
                            <TextField
                                // feedback={feedback}
                                id={'description'}
                                // isInvalid={isActivityDescriptionEmpty}
                                value={addActivityRequest.description}
                                // disabled={!isEditable}
                                onChange={(event) => onFieldChange('description', event.currentTarget.value)}
                                // placeholder={placeholder || label}
                                // className={isActivityDescriptionEmpty ? 'feedback-row' : ''}
                                // style={inputStyle}
                                // maxLength={maxLength}
                                // tabIndex={tabIndex}
                                // isEmpty={isEmpty}
                                // isTooShort={isTooShort}
                                // errorText={errorText}
                                // invalidWarning={isActivityDescriptionEmpty ? constant.DESCRIPTION_REQUIRED : ""}
                                title={addActivityRequest.description}
                                isInvalid={isActivityDescriptionEmpty}
                                invalidWarning={isActivityDescriptionEmpty ? constant.FIELD_BLANK : ""}
                                invalidLableClass="lable-error description-lable-error"
                                rows={4}
                            />
                        </Column>
                    </Row>
                    <Row>
                        <Column lg={6} md={6}>
                        <div className="text-left">
                            <Button
                                className='btn btn-outline-tertiary action-btn-base'
                                key="filter"
                                onClick={() => handleCancel()}
                                tabIndex={21}
                                id="btnNotesCancel"
                                color='primary'
                                outlined={true}
                            >{label.CANCEL.toLocaleUpperCase()}
                            </Button>
                        </div>
                        </Column>
                        <Column lg={6} md={6}>
                        <div className="text-right">
                            <Button
                            className='btn btn-outline-primary action-btn-base'
                            key="filter"
                            onClick={() =>handleSaveActivity()}
                            tabIndex={21}
                            id="btnNotesAdd"
                            color='primary'
                            outlined={true}
                        >{label.SAVE.toLocaleUpperCase()}
                        </Button></div>
                        </Column>
                    </Row>
                    </Column>
                    <Column lg={4} md={4}>
                    <Row>
                        <Column lg={12} md={12} className="col-section-header"><span className="new-entry-main-title">CUSTOMER/ POLICY INFORMATION</span></Column>
                    </Row>
                        <Row>
                            <Column lg={8} md={8}>
                                <DropDownWithLabelInline
                                    label={label.CustomerActivityLabel.CUSTOMER_SELECT}
                                    labelClass='inlineLable entry-form-inlineLable'
                                    id='externalTemplateSelect'
                                    placeholder={' '}
                                    options={customerDependentsForSelect}
                                    defaultSelected={customerDependentsForSelect?.filter(({ value }) => value == addActivityRequest.custId)}
                                    onChange={
                                    (values) => {
                                        onFieldChange('custId', values[0])
                                    }}>
                                    </DropDownWithLabelInline>
                            </Column>
                        </Row>
                        <Row>
                            <Column lg={8} md={8}>
                                <DropDownWithLabelInline
                                    label={label.CustomerActivityLabel.POLICY_SELECT}
                                    labelClass='inlineLable entry-form-inlineLable'
                                    id='externalTemplateSelect'
                                    placeholder={label.PLEASE_SELECT}
                                    options={policiesForSelect}
                                    defaultSelected={policiesForSelect.filter(({ value }) => value?.toLowerCase() === addActivityRequest.policyId?.toLowerCase())}
                                    onChange={
                                    (values) => {
                                        onFieldChange('policyId', values[0])
                                    }}>
                                </DropDownWithLabelInline>
                            </Column>
                        </Row>
                        <Row>
                            <Column lg={8} md={8}>
                                <TextInputWithLabelInline
                                    name='company'
                                    label={label.CustomerActivityLabel.CLAIM_SELECT}
                                    placeholder={' '}
                                    value={addActivityRequest.claimId}
                                    isEditable={true}
                                    onChange={(event) => onFieldChange('claimId', event.currentTarget.value)}
                                    type='text'
                                    labelClass='inlineLable entry-form-inlineLable'
                                    tabIndex={1}
                                  
                                    maxLength={30}
                                    className='texbox-input'>
                                </TextInputWithLabelInline>
                            </Column>
                        </Row>
                    {/* </div> */}
                    </Column>
                </Row>
            </div>
            )}
            </Column>
        </Row>
            <Row>
                <Column>
                    <Table className="activityTable">
                        <thead className="tableHeader">
                            <tr>
                                <th className="actionTypeCol tbl-header">Action Type</th>
                                <th className="dateCol tbl-header">Date</th>
                                <th className="timeCol tbl-header">Time</th>
                                <th className="transTypeCol tbl-header">Transaction</th>
                                <th className="descCol tbl-header">Description</th>
                                <th className="policyCol tbl-header">Policy Number</th>
                                <th className="effDateCol tbl-header">Effective Date</th>
                                <th className="csrCol tbl-header">CSR</th>
                            </tr>
                        </thead>
                        <tbody>
                                {/* {customerActivityies.map(({ date, customerActivities }) => (
                        <React.Fragment key={date}> */}
                            {/* <tr>
                            <td colSpan={10}><strong>{date}</strong></td>
                            </tr> */}
                                    {customerActivities.map((activity, index) => (
                        <React.Fragment key={activity.id}>
                            <tr onClick={() => handleToggle(activity.id)} style={{ cursor: 'pointer' }} className={`activityRow non-collapsible ${index % 2 === 0 ? 'alternate' : ''}`}>
                                <td style={{color: "#207ab7"}}>
                                    {open[activity.id] ? <FaChevronDown style={{color: "#207ab7"}}/> : <FaChevronRight style={{color: "#207ab7"}}/>} {activity.activityType}
                                </td>
                                <td>{new Date(activity.enteredDate).toLocaleDateString('en-US', dateOptions)}</td>
                                <td>{new Date(activity.enteredDate).toLocaleTimeString('en-US', timeOptions)}</td>
                                <td>{activity.transactionType}</td>
                                <td className="truncateDesc">{activity.description}</td>
                                <td>{activity.policyNumber || 'N/A'}</td>
                                <td>{activity.polEffDate}</td>
                                <td>{activity.csr || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td colSpan={10} style={{ padding: 0 }}>
                                <Collapse in={open[activity.id]}>
                                <div id={`collapse-${activity.id}`} style={{ padding: '10px' }}>
                                    <Row>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>CSR</strong>
                                        <div>{activity.csr || 'N/A'}</div>
                                        </Column>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Action</strong>
                                        <div>{activity.activityType}</div>
                                        </Column>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Customer</strong>
                                        <div>{activity.customer}</div>
                                        </Column>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Company</strong>
                                        <div>{'N/A'}</div>
                                        </Column>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Action Date/Time</strong>
                                        <div>{`${new Date(activity.activityEffDate).toLocaleDateString('en-US', dateOptions)} 
   ${new Date(activity.activityEffDate).toLocaleTimeString('en-US', timeOptions)}`}</div>
                                        </Column>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Transaction</strong>
                                        <div>{activity.transactionType}</div>
                                        </Column>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Policy Effective Date</strong>
                                        <div>{activity.polEffDate}</div>
                                        </Column>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Claim #</strong>
                                        <div>{activity.claimNumber || 'N/A'}</div>
                                        </Column>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Saved Date/Time</strong>
                                        <div>{`${new Date(activity.enteredDate).toLocaleDateString('en-US', dateOptions)} 
   ${new Date(activity.enteredDate).toLocaleTimeString('en-US', timeOptions)}`}</div>
                                        </Column>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Policy #</strong>
                                        <div>{activity.policyNumber || 'N/A'}</div>
                                        </Column>
                                        <Column lg={3}>
                                        <strong style={{color: "#878686"}}>Policy Expiration Date</strong>
                                        <div>{activity.polExpDate}</div>
                                        </Column>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Column lg={6} className="wrapDesc">
                                        <strong style={{color: "#878686"}}>Description</strong>
                                        <div className="descScroll">{activity.description}</div>
                                        </Column>
                                    </Row>
                                </div>
                                </Collapse>
                                </td>
                            </tr>
                        </React.Fragment>
                                    ))}
                        {/* </React.Fragment>
                                ))} */}
                        </tbody>
                    </Table>
                    <Button style={{marginBottom: '1rem'}} onClick={loadMoreActivities}>Load More</Button>
                </Column>
            </Row>
        {/* </Panel> */}
        </Column>
        </>
    )
}

export default CustomerActivity;
