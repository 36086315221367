import { Button, Column, Row } from '../../../components'
import { useContext, useEffect, useState } from 'react';
import { RolePrivilegeSetupLabels } from '../../../shared/util/label'
import RolesSideBar from '../RolesSideBar/RolesSideBar';
import { FallbackComponent } from '../../../shared/components/FallbackComponent'
import { ErrorBoundary } from 'react-error-boundary'
import ScreenPrivilege from '../ScreenPrivilegeSetup/ScreenPrivilege';
import './RolePrivilegeMain.scss';
import { useHistory, useParams } from 'react-router-dom';
import AppContext from '../../../shared/components/AppContext';
import * as constant from '../../../shared/util/constant'
import { useConfirmationModalContext } from '../../../shared/components/ModalConfirmationContext';
import { Card, Container, Navbar } from 'react-bootstrap';

const RolePrivilegeMain = () => {

    const { roleId } = useParams<{roleId : string | undefined}>();

    const [selectedRoleId, setSelectedRoleId] = useState<string>(null);

    const [showRoleDetailsOnLoad, setShowRoleDetailsOnLoad] = useState<boolean>(false);

    const modalContext = useConfirmationModalContext();

    const privielgeContext = useContext(AppContext);

    const [reloadSidebar, setReloadSidebar] = useState(false)

    const history = useHistory();

    const handleNewRoleClick = async () => {
        if (!privielgeContext.formSavedStatus) {
            const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
            if (result) {
                setSelectedRoleId(null);
                 setShowRoleDetailsOnLoad(true);
                 privielgeContext.formSavedStatus = true
                 history.push(`/admin/roleprivilege`);
            }
        }
        else {
            setSelectedRoleId(null);
            setShowRoleDetailsOnLoad(true);
            history.push(`/admin/roleprivilege`);
        }
    };

    const handleCancelClick = async () => {
        setSelectedRoleId(null);
        setShowRoleDetailsOnLoad(false);
        history.push(`/admin/roleprivilege`);
    };

    const handleSave = async (newRoleId) => {
        history.push(`/admin/roleprivilege/${newRoleId}`);
        setReloadSidebar(!reloadSidebar)

    };
   

    useEffect(() => {
        if (roleId) 
        {
            setSelectedRoleId(roleId);
            setShowRoleDetailsOnLoad(true);            
        }
    }, [roleId]);

    return (
        <>
            <Container fluid className='px-4'>
                <Row className="role-privilege-main">
                    <Column lg={12} md={12}>
                        <ErrorBoundary FallbackComponent={FallbackComponent}>
                        <Row>
                                    <Column lg={12}>
                                        <Navbar className="bg-transparent">
                                            <Navbar.Brand>Role Privileges</Navbar.Brand>

                                            <Button className='ms-auto' color='primary' outlined={true} onClick={handleNewRoleClick}>{RolePrivilegeSetupLabels.NEW_ROLE} </Button>

                                        </Navbar>
                                    </Column>
                                </Row>
                            {/* <Card> */}
                                <Container fluid>
                               
                                <Row>
                                    <Column lg={12} className='role-details'>
                                        <Row>
                                            <Column lg={3} className='roles-sidebar ps-0'>
                                                <ErrorBoundary FallbackComponent={FallbackComponent}>
                                                    <div className={`active card shadow-sm`}>
                                                        <RolesSideBar reload={reloadSidebar} />
                                                    </div>
                                                </ErrorBoundary>
                                            </Column>
                                            
                                            {showRoleDetailsOnLoad &&
                                                <Column lg={9} className={'screen-privilege'}>
                                                    {/* <Card> */}
                                                    <ErrorBoundary FallbackComponent={FallbackComponent}>
                                                        <div className='p-0'>
                                                            <ScreenPrivilege roleId={selectedRoleId} onCancel={handleCancelClick} onSave={handleSave}></ScreenPrivilege>
                                                        </div>
                                                    </ErrorBoundary>
                                            {/* </Card> */}

                                                </Column>
                                            }
                                            
                                        </Row>
                                    </Column>
                                </Row>
                                </Container>
                               
                            {/* </Card> */}

                        </ErrorBoundary>
                    </Column>
                </Row>
            </Container>

        </>
    )
}

export default RolePrivilegeMain;