export const CONFIRM_EMPTY_RESCAN = (
    <>
        Are you sure you want to <strong>delete</strong> the contents of the <strong>Rescan</strong> folder?
    </>
);
export const PLEASE_SELECT = 'Please Select';
export const INDEXED = 'currently being indexed';
export const SOFT_LOCKED = 'soft-locked';
export const HARD_LOCKED = 'hard-locked';

// Refi Module
export const DOCUMENT_NOT_FOUND = "Document not found."
export const REFI_APPROVAL_ERROR_WHILE_LOADING_INITIAL_DATA ="We encountered an issue while fetching customer details. Please try again in a moment. If the problem persists, contact support team for assistance."
export const DOCUMENT_FORM_VALIDATION_ERROR_MESSAGE = "Please check the highlighted fields."
export const CUSTOMER_NAME_MANDATORY_FIELDS_ERROR = "Either First/ Last Name or Company Name must be entered";
export const CUSTOMER_NAME_MIN_LENGTH = "Field require 2 to 60 characters";
export const REQUESTOR_INVALID_EMAIL = "Specify a valid email";
export const MANDATORY_FIELDS = "Field cannot be blank";
export const CUSTOMER_ADDRESS1_MIN_LENGTH = "Field require 2 to 100 characters";
export const CUSTOMER_CITY_MIN_LENGTH = "Field require 2 to 60 characters";
export const CUSTOMER_ZIP_MIN_LENGTH = "Field require 2 to 9 characters";
export const DOCUMENT_APPROVED = 'Document Approved Successfully.';