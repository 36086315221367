import { AxiosResponse } from "axios";
import { client } from "../../shared/services/client";
import { GetDocumentRequestModel } from "../model/GetDocumentRequestModel";
import { GetDocumentResponseDto } from "../model/GetDocumentResponseDto";
import { RefiTodoListDto } from "../model/RefiiTodoListDto";
import { RefiDocument } from "../model/RefiDocument";
import { DocQueueDocumentListResponseDto } from "../model/DocQueueDocumentListResponseDto";
import { DocQueueFolderListResponseDto } from "../model/DocQueueFolderListResponseDto";
import { GetDocumentListQuery } from "../model/GetDocumentListQuery";
import { DocQueueGroupListResponseDto } from "../model/DocQueueGroupListResponseDto";
import { GetFolderListByGroupIdQuery } from "../model/GetFolderListByGroupIdQuery";
import { RefiApprovalDropdownDto } from "../model/RefiApprovalDropdownDto";

export const getListOfGroup = async (): Promise<DocQueueGroupListResponseDto[]> => {
    client.setBaseURLForService("docmate");
    const res: AxiosResponse<DocQueueGroupListResponseDto[]> = await client.get("/GetListOfGroups");    
    return res.data;
}

export const getListOfFolderByGroupId = async (getFolderListByGroupIdQuery : GetFolderListByGroupIdQuery): Promise<DocQueueFolderListResponseDto[]> => {
    client.setBaseURLForService("docmate");
    const groupId=getFolderListByGroupIdQuery.GroupId;
    const res: AxiosResponse<DocQueueFolderListResponseDto[]> = await client.get("/GetListOfFoldersByGroupId?GroupId=" + groupId);
    return res.data;
}

export const getListOfFolder = async (): Promise<DocQueueFolderListResponseDto[]> => {
    client.setBaseURLForService("docmate");
    const res: AxiosResponse<DocQueueFolderListResponseDto[]> = await client.get("/GetListOfFolders");
    return res.data;
}

export const getUnAssignedDocument = async (getUnAssignedDocumentRequestModel: GetDocumentListQuery): Promise<DocQueueDocumentListResponseDto[]> => {
    client.setBaseURLForService("docmate");
    const config ={params: getUnAssignedDocumentRequestModel};
    const res: AxiosResponse<DocQueueDocumentListResponseDto[]> = await client.get("/GetListOfDocument", config);
    return res.data;
}

export const getDocument = async (getDocumentRequestModel : GetDocumentRequestModel): Promise<GetDocumentResponseDto> => {
    client.setBaseURLForService("documate");
    const config ={params: getDocumentRequestModel};
    const res: AxiosResponse<GetDocumentResponseDto> = await client.get("https://localhost:7208/api/DocStorage/GetDocumentSampleApi", config);
    return res.data;
}

export const getDocumentSample = async (docId: string ): Promise<Blob> => {
    client.setBaseURLForService("documate");
    const res: AxiosResponse<Blob> = await client.get("https://localhost:7208/api/DocStorage/GetDocumentSampleApi?StorageAccountUrl=aaaa&BlobContainer=aaaaa", {
        responseType: 'blob',  // This tells Axios to treat the response as a Blob
    });
    return res.data;
}

export const fetchRefiTodoList = async() => {
    client.setBaseURLForService("docmateapp");
    const res: AxiosResponse<RefiTodoListDto[]> = await client.get<RefiTodoListDto[]>("/api/DocumateRefiApproval/GetRefiList");
    return res.data;
}

export const getRefiDocument = async(id: string) => {
    client.setBaseURLForService("documate");
    const res: AxiosResponse<RefiDocument> = await client.get<RefiDocument>(`https://localhost:7208/api/DocStorage/GetDocumentSampleApi/${id}`);
    return res.data;
}

export const fetchListOfVestingDropdown = async (): Promise<RefiApprovalDropdownDto[]> => {
    client.setBaseURLForService("docmateapp");
    const res: AxiosResponse<RefiApprovalDropdownDto[]> = await client.get("api/DocumateRefiApproval/GetVestingDropdowns");
    return res.data;
}