import { Column, Navbar } from  '../components' 
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as label  from '../shared/util/label';
import * as constant from '../shared/util/constant';
import useTranslator from '../shared/hooks/useTranslator'
import { RootState } from '../shared/store'
import AppContext from "../shared/components/AppContext";
import { useConfirmationModalContext } from "../shared/components/ModalConfirmationContext";


const PolicyNavbar = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const modalContext = useConfirmationModalContext();
  const myContext = useContext(AppContext);
  
  var tabLink = document.querySelectorAll(".nav-link");
  
  const navigateTo = async(location: string, event) => {
    

    if(!myContext.formSavedStatus){
      const result = await modalContext.showConfirmation(constant.CONFIRM_EXIT)
          if(result){
            tabLink.forEach(function (item) {
              item.classList.remove("active");
            });
            event.currentTarget.classList.toggle('active');
            history.push(location)
          }
      }
      else{
        tabLink.forEach(function (item) {
          item.classList.remove("active");
        });
        event.currentTarget.classList.toggle('active');
        history.push(location)
      }

   
  }

  

  return (
       
    <Navbar
      bg='light'
      variant='light'
      className='py-0'
      title={''} id={''}
      children={''}
      navItems=
      {
        [
          {
            type: 'header',
            alignRight: true,
            children: null,
            className: 'cstHeaderMenu nav-add-new d-none d-md-block',
            iconClassName: 'align-bottom',
            label: 'Files',
            name: 'Policy Assignment',
            size: 'lg',
            onClick:(event)=>{
              navigateTo('/policyfile/PolicyAssigment/unmatched-policy',event)}
          },
          {
            type: 'link',
            alignRight: true,
            children: null,
            className: 'cstHeaderMenu nav-add-new d-none d-md-block',
            iconClassName: 'align-bottom',
            label: 'Policy Assignment',
            name: 'Policy Assignment',
            size: '10x',
            onClick:(event)=>{
              navigateTo('/policyfile/PolicyAssigment/unmatched-policy',event)}
          }
        ]
      }
    />
  )
}
export default PolicyNavbar
