import React from 'react';
import './main-dashboard.scss';
import TaskDashboard from './tasks-dashboard/taskdashboard';
import ActivityDashboard from './activity-dashboard/ActivityDashboard';
import { Column, Row } from '../components';
import { Container } from 'react-bootstrap';

export interface IMainDashboard {
    navigateTo : (path : string) => void
}

const MainDashboard : React.FunctionComponent<IMainDashboard> = (props) => {

    return (
        <div className='bg-light pt-5'>
            <Container fluid>
                <Row>
                    <Column lg={6} md={6}>
                        <TaskDashboard navigateTo={props.navigateTo}></TaskDashboard>
                    </Column>
                    <Column lg={6} md={6}>
                        <ActivityDashboard></ActivityDashboard>
                    </Column>
                </Row>
            </Container>

        </div>
    )
}

export default MainDashboard