import React from 'react';
import { CustomerTaskDtoReadExtended } from '../../customer/model/CustomerTaskDtoRead';
import './TaskSummaryItem.scss';
import { Column, Icon, Row } from '../../components';
import { IconType } from '../../components/components/Icon/interfaces';
import { LookupDetailConstants } from '../../shared/util/LookupDetailConstants';

export interface ITaskSummaryItem {
    taskItem : CustomerTaskDtoReadExtended,
    navigateTo : (path : string) => void
}

export const PolicyIconMapping = {
    [LookupDetailConstants.LOB_CODE.HOMEOWNERS] : 'home',
    [LookupDetailConstants.LOB_CODE.PRIVATE_PASSENGER_AUTO] : 'auto',
    [LookupDetailConstants.LOB_CODE.UMBRELLA_P] : 'umbrella',
}

const TaskSummaryItem : React.FunctionComponent<ITaskSummaryItem> = (props) => {

    const dueDateClass = props.taskItem.dueDays < 0 ? "task-summary--due-days--past" : "";

    const iconType = PolicyIconMapping[props.taskItem.lobCodeId] ? PolicyIconMapping[props.taskItem.lobCodeId] : null;


    return <div className='task-summary--main-div'>
            <Row noGutters={true}>
                <Column lg={1} md={1}>
                    <div className='task-summary--icon-div'>
                        <div>
                            {iconType && 
                            <Icon icon={iconType as IconType} 
                                className={'task-summary--item-icon'}
                                title="home"/>
                            }
                        </div>
                    </div>
                </Column>
                <Column lg={5} md={5}>
                    <div className='task-summary--detail'>
                        <div className='task-summary--customer'>
                            {props.taskItem.custDescr}
                        </div>
                        <div className='task-summary--tasktype'>
                            {props.taskItem.taskTypeDescr}
                        </div>
                    </div>
                </Column>
                <Column lg={3} md={3}>
                    <div className='task-summary--due-date-detail'>
                        <div className='task-summary--due-date'>
                            Due {props.taskItem.dueDaysFormatted}
                        </div>
                        <div className={`task-summary--due-days ${dueDateClass}`}>
                            {props.taskItem.dueDaysDetail}
                        </div>
                    </div>
                </Column>
                <Column lg={2} md={2}>
                    <div className='task-summary--priority-div'>
                        <div className="default-badge-div"><span className={`badge default-badge task-summary--task-badge--priority-${props.taskItem.priorityCode}`}>{props.taskItem.priorityDescr}</span></div>
                    </div>
                </Column>
                <Column lg={1} md={1}>
                    <div className='task-summary--task-screen-arrow-div'>
                        <div>
                            <Icon icon={'right-arrow' as IconType} onClick={() => props.navigateTo(`/customer-view/tasks/${props.taskItem.custId}`)}
                                className={'task-summary--item-icon'}/>
                        </div>
                    </div>
                </Column>
            </Row>
        </div>;
}

export default TaskSummaryItem