import { useEffect, useState } from 'react';
import CustomerActivityService from '../../customer/customer-activity/CustomerActivityService';
import './ActivityDashboard.scss'
import Logger from '../../shared/services/Logger';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { CustomerActivityModel } from '../../customer/model/CustomerActivityModel';
import ActivitySummaryItem from './ActivitySummaryItem';
import ActivityDashboardService from './ActivityDasboardService';
import { AcivityPageConstants } from '../../shared/util/constant';
import { Card } from 'primereact/card';

const ActivityDashboard = (props) => {
    const activityDashboardPageSize = 7;
    const customerActivityService = new CustomerActivityService();
    const activityDashboardService = new ActivityDashboardService();
    const userDetails = useSelector((state : any) => state.userContextSlice);
    const [activitiesToShow, setActivitiesToShow] = useState<CustomerActivityModel[]>([]);

    useEffect(() => {
        initComponent();
    },[userDetails.userContext]);

    const initComponent = async () => {
        try {
            if (!userDetails.userContext) {
                return;
            }

            let userId = userDetails.userContext.id;

            const activities = await customerActivityService.getActivites(null, 1, userId, activityDashboardPageSize);
            const formattedActivites = [];
            activities.forEach(item => {
                formattedActivites.push(activityDashboardService.enrichActivityItem(item));
            });
            
            setActivitiesToShow(formattedActivites);
        }
        catch(ex) {
            Logger.logError(AcivityPageConstants.ERROR_WHILE_INITIALIZING_DASHBOARD, ex);
            toast.error(AcivityPageConstants.ERROR_WHILE_INITIALIZING_DASHBOARD, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const activityItems = activitiesToShow.map(item => {
        return <ActivitySummaryItem activityItem={item}></ActivitySummaryItem>;
    });

    return (
        <Card title={`${activityItems.length}`} subTitle="Recent Activity" className="md:w-25rem">
            <div className='activity-dashboard-card--content'>
                <div>
                    {activityItems}
                </div>
            </div>
        </Card>
    );
}

export default ActivityDashboard;