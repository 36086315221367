import React from 'react';
import { CustomerActivityModelExtended } from '../../customer/model/CustomerActivityModel';
import './ActivitySummaryItem.scss'
import { PolicyIconMapping } from '../tasks-dashboard/TaskSummaryItem';
import { Column, Icon, Row } from '../../components';
import { IconType } from '../../components/components/Icon/interfaces';

export interface IActivitySummaryItem {
    activityItem : CustomerActivityModelExtended
}

const ActivitySummaryItem : React.FunctionComponent<IActivitySummaryItem>  = (props) => {

    const iconType = PolicyIconMapping[props.activityItem.lobCodeId] ? PolicyIconMapping[props.activityItem.lobCodeId] : null;

    return <div className='activity-summary--main-div'>
            <Row noGutters={true}>
                <Column lg={1} md={1}>
                    <div className='activity-summary--icon-div'>
                        <div>
                            {iconType && 
                            <Icon icon={iconType as IconType} 
                                className={'activity-summary--item-icon'}
                                title="home"/>
                            }
                        </div>
                    </div>
                </Column>
                <Column lg={11} md={11}>
                    <div className='activity-summary--detail'>
                        <div className='activity-summary--header'>
                            <div className='activity-summary--customer'>
                                {props.activityItem.customer}
                            </div>
                            <div className='activity-summary--secondary-content'>
                                {props.activityItem.activityEffDateFormatted}
                            </div>
                        </div>
                        <div className='activity-summary--content activity-summary--secondary-content'>
                            {props.activityItem.descriptionFormatted}
                        </div>
                    </div>
                </Column>
            </Row>
        </div>;
}

export default ActivitySummaryItem;