import {  Label, Row, Column,Select } from '../../../components'
import {InputHTMLAttributes} from 'react'
import { TypeaheadLabelKey } from 'react-bootstrap-typeahead'
import { isEmpty } from 'lodash'


interface SelectOption<T> {
    label: string
    value: T
  }
  
  interface Props<T> {
    id: string
    options: SelectOption<T>[]
    defaultSelected?: SelectOption<T>[]
    onChange?: (values: T[]) => void
    placeholder?: string
    multiple?: boolean
    disabled?: boolean
    isValid?: boolean
    isInvalid?: boolean
    feedback?: string
    inputProps?:InputHTMLAttributes<HTMLInputElement>,
    tabIndex?:number,
    label?: string,
    isRequired?:boolean,
    labelClass?:string,
    labelSize?:number,
    labelColClassName?:string,
    dropDownSize?:number,
    invalidWarning?:string,
    invalidLableClass?:string,
    labelKey?:TypeaheadLabelKey<string>
    dropdownDivClass?:string,
    dropup? : boolean,
    errorLableDivClassName? : string;
    showAsFloatingLabel?: boolean
    //isEmpty?:boolean
    //errorText?:string
  }
  


function DropDownWithLabelInline<T> (props: Props<T>)  {
  const {
    id,
    options,
    defaultSelected,
    onChange,
    placeholder,
    multiple,
    disabled,
    isValid,
    isInvalid,
    feedback,
    inputProps,
    tabIndex,
    isRequired,
    label,
    labelClass,
    labelSize,
    labelColClassName,
    dropDownSize,
    invalidWarning,
    invalidLableClass,
    labelKey,
    dropdownDivClass,
    dropup,
    errorLableDivClassName,
    showAsFloatingLabel
    //isEmpty,
    //errorText
      } = props
  return (
    <Row >
     {label &&<Column sm ={labelSize} className={labelColClassName}> <Label text={label} htmlFor={id} isRequired={isRequired} className={labelClass}/></Column>}
     <Column sm={dropDownSize} className={dropdownDivClass===(''||null||undefined)?'':dropdownDivClass}>
        <Select
   id={id}
   options={options as any}
   dropup={dropup}
   defaultSelected={defaultSelected}
   onChange={onChange}
   placeholder={placeholder}
   multiple={multiple}
   disabled={disabled}
   isInvalid={isInvalid}
   feedback={feedback}
   inputProps={inputProps}
   tabIndex={tabIndex}
   invalidWarning={invalidWarning}
   invalidLableClass={invalidLableClass}
   labelKey={labelKey}
   isRequired={isRequired}
   errorLableDivClassName={errorLableDivClassName}
   className=''
   showAsFloatingLabel={showAsFloatingLabel}
   //isEmpty={isEmpty}
   //errorText={errorText}
        />
     </Column>    
    </Row>
  )
}




export default DropDownWithLabelInline
