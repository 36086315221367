import { GetDocumentRequestModel } from "../model/GetDocumentRequestModel";
import { getDocument } from "../services/DocuMateAPI";
import { GetDocumentListQuery } from "../model/GetDocumentListQuery";
import { getUnAssignedDocument, getListOfFolderByGroupId } from "../services/DocuMateAPI";
import { getListOfGroup } from "../services/DocuMateAPI";
import { GetFolderListByGroupIdQuery } from "../model/GetFolderListByGroupIdQuery";
import { GroupListDto } from "../model/GroupListDto";
import { DocQueueGroupListResponseDto } from "../model/DocQueueGroupListResponseDto";

class DocuMateScanService {
    getUnAssignedDocument = async (getUnAssignedDocumentRequestModel: GetDocumentListQuery) => {
        var unassignedDocResponse = await getUnAssignedDocument(getUnAssignedDocumentRequestModel);
        const res = unassignedDocResponse.map((item) => ({
            id: item.documentId,
            fileName: item.name,
            folderId: getUnAssignedDocumentRequestModel.DocQueueId
        }));

        return res;
    }

    getReScanDocument = async (getRescanDocumentRequestModel: GetDocumentListQuery) => {
        var doc =  await getUnAssignedDocument(getRescanDocumentRequestModel);
       
       const res = doc.map((item) => ({             
              id: item.documentId,
              fileName : item.name           
          }));
        
          return res;
    }
    
    getListOfDestinationFoldersData = async (): Promise<GroupListDto[]> => {
        var GroupList = await getListOfGroup();
        const groupList = GroupList.map((item: DocQueueGroupListResponseDto) => (
            {
                GroupId: item.id,
                GroupName: item.groupName,
                GroupCode: item.groupCode
            }));

        return groupList;
    }

    getFolderListByGroupId = async (getFolderListByGroupIdQuery: GetFolderListByGroupIdQuery) => {
        var doc = await getListOfFolderByGroupId(getFolderListByGroupIdQuery);
        return doc.map((item) => ({

            id: item.id,
            name: item.folderName,
            groupId: getFolderListByGroupIdQuery.GroupId,
            files: []
        }));
    }

    getDocument = async (getDocRequestModel: GetDocumentRequestModel) => {
        var doc =  await getDocument(getDocRequestModel);
        return doc;
    }
}

export default DocuMateScanService;