import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(far)
library.add(fas)

export * from './components/Toaster'
export * from './components/Spinner'
export * from './components/Graph'
export * from './components/Button'
export * from './components/Dropdown'
export * from './components/Icon'
export * from './components/Image'
export * from './components/Badge'
export * from './components/Panel'
export * from './components/Pill'
export * from './components/Checkbox'
export * from './components/Navbar'
export * from './components/TextField'
export * from './components/Switch'
export * from './components/Radio'
export * from './components/Alert'
export * from './components/Modal'
export * from './components/TextInput'
export * from './components/Select'
export * from './components/RichText'
export * from './components/List'
export * from './components/DateTimePicker'
export * from './components/VideoPlayer'
export * from './components/Layout'
export * from './components/Label'
export * from './components/Breadcrumb'
export * from './components/Tab'
export * from './components/Typeahead'
export * from './components/Typography'
export * from './components/Calendar'
export * from './components/Table'
export * from './components/Callout'

import '../scss/main.scss'
