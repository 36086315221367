import CustomerNavbar from './policynavbar'
import { Select, Label, Button, Container, Row, Column,Panel,Spinner } from '../components'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from '../shared/components/PrivateRoute'
import TextInputWithLabelInline from '../../src/shared/components/input/TextInputWithLabelInline'
import { createContext, useEffect, useState, useRef } from 'react'
import '../../src/cs2common.css'
import InputGroup from 'react-bootstrap/InputGroup'
import { Icon } from '../../src/components/components/Icon/index'
import { FloatingLabel, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PolicyNavbar from './policynavbar'


interface Props {
  isSideBarCollapsed: boolean
}




const PolicyDashboard = (props:Props) => {
    const history = useHistory()
    const [highlightedMenu,setHighlightedMenu] = useState('unmatched-Policy');
    function highlightMenu(){
        var tabLink = document.querySelectorAll(".nav-link");
        tabLink.forEach(function (item) {
          item.classList.remove("active");
        });
        var activeMenu=location.pathname.slice(location.pathname.lastIndexOf('/')+1)
        var path = window.location.pathname;
        if(activeMenu==='unmatched-policy')
        {
          activeMenu='Policy Assignment'
        }
        tabLink.forEach(function (item) { 
          if(item.id===activeMenu){
            item.classList.add("active");
          }
        });
        history.push(location)
        setHighlightedMenu(activeMenu)
      }
    
       useEffect(()=>{
         setTimeout(() => {
           highlightMenu()
       }, 2000)
         
       },[])
 
  useEffect (() => {
    const root =document.documentElement;
    root.style.setProperty('--marginLeftCustomerRow', props.isSideBarCollapsed ? '100px' : '250px')
  },[props.isSideBarCollapsed]);

    return (
        <div className='bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <PolicyNavbar />
          </div>

          <Container fluid className='px-4 mt-2'>
            <Row className='bg-light'>
              <Column lg={12}>

                <Switch>
                  
                  <Route
                    exact
                    path="/policyfile/PolicyAssigment/unmatched-policies"
                    component={"unmatched-policies"}
                  />
                </Switch>
              </Column>
            </Row>
          </Container>

          

          {/* <div className="row">
        <div className="col-lg-12">
        <CustomerDashboardView/>
        </div>
    </div> */}
        </div>
   
   
     
    )
   
}

export default PolicyDashboard