import { Link } from "react-router-dom";
import { Icon } from "../../components";
import { TODOLIST_GRID_LABELS } from "../shared/util/docuMateLabel";

export const getRefiGridColumns = (handleDocumentClick, toggleLockDocument) => {
    let gridcolumns = [
        {
            Header: TODOLIST_GRID_LABELS.fileName,
            accessor: 'fileName',
            Cell: row => {
              return (
                  <div className="todoList-grd-cell">
                      <Link
                      to={{
                          pathname: '',
                          state: { scanId: row.row?.original?.fileName, id: row.row?.original?.refiRequestId }                            
                      }}
                      onClick={(event) => {
                        event.preventDefault(); 
                        handleDocumentClick(row.row?.original?.refiRequestId)
                      }}
                      title="row.value"
                      style={{ marginRight: '0.5em' }}
                      ><span title={row.row?.original?.fileName}>{row.row?.original?.fileName}</span></Link>
                      <div>
                      {
                        (() => {
                            const data = row.row?.original;
                            switch (true) {
                                case data?.isLocked:
                                    return <Icon icon="lock" className='grd-cell-icon--hardlock' 
                                            onClick = {(event) => {
                                            event.preventDefault();
                                            toggleLockDocument(data?.refiRequestId, data?.isLocked);}}/>;
                                case !data?.isLocked:
                                    return <span>
                                              <Icon icon="lock" className='grd-cell-icon--lock' 
                                              onClick = {(event) => {
                                              event.preventDefault();
                                              toggleLockDocument(data?.refiRequestId, data?.isLocked);
                                              }}/>
                                          </span>;
                                default:
                                  return null;
                            }
                        })()
                    }
                      </div>
                  </div>
              )
            },
            width:400
        },
        {
          Header: TODOLIST_GRID_LABELS.docDescription,
          accessor: 'docDesc',
          Cell: row => {
            return (
              <div className='todoList-grd-cell'>
                  <span title={row.row?.original?.docDesc}>{row.row?.original?.docDesc}</span>
              </div>
            )
          },
          width:700
        },
        {
          Header: TODOLIST_GRID_LABELS.dateInitiated,
          accessor: 'dateInitiated',
          Cell: row => {
            return (
              <div className='todoList-grd-cell'>
                  <span title={row.row?.original?.dateInitiated}>{row.row?.original?.dateInitiated}</span>
              </div>
            )
          },
          width:350
        },
        {
          Header: TODOLIST_GRID_LABELS.dateAvailable,
          accessor: 'dateAvailable',
          Cell: row => {
            return (
              <div className='todoList-grd-cell'>
                  <span title={row.row?.original?.dateAvailable}>{row.row?.original?.dateAvailable}</span>
              </div>
            )
          },
          width:350
        }
      ];
    return gridcolumns;
}
