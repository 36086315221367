import {AxiosRequestConfig, AxiosResponse} from 'axios'
import { client } from "../../shared/services/client"
import Customer from '../../customer/model/Customer'
import CustomerFilterRequest from '../../customer/model/CustomerFilterRequest';
import { CustomerNote } from '../model/CustomerNoteModel';
import { CustomerFilterViewMoel } from '../model/CustomerFilterViewModel';
import { CustomerNoteRead } from '../model/CustomerNoteReadDto';
import { CustomerActivityModel } from '../model/CustomerActivityModel';
import  CustomerHistoryModel  from '../model/CustomerHistory';
import { CustomerActivityAddRequest } from '../model/CustomerActivityAddRequest';
import { CustomerLossDto, CustomerLossReadDto } from '../model/CustomerLossDto';

 
export const fetchCustomers = async (): Promise<Customer[]> => {

  const res: AxiosResponse<Customer[]> =  await client.get<Customer[]>("/customers");

  return res.data;

};


export const fetchCustomer = async (id : string): Promise<CustomerFilterViewMoel> => {

    client.setBaseURLForService("customer")
    const config ={}
    const res: AxiosResponse<Customer> =  await client.get(`/api/customers/${id}`, config);
    return res.data;
  };

  export const getCustomerSummary = async (id : string): Promise<Customer> => {

    client.setBaseURLForService("customer")
    const config ={}
    const res: AxiosResponse<Customer> =  await client.get(`/api/customers/getCustomerSummary/${id}`, config);
    return res.data;
  };

  
export const filterCustomers = async (filter:CustomerFilterRequest): Promise<Customer[]> => {
    const config ={params:filter}
    const res: AxiosResponse<Customer[]> =  await client.get<Customer[]>("http://localhost:5000/customers",config);
    return res.data;
  
  };

  export const SearchCustomers = async (filter:CustomerFilterRequest): Promise<Customer[]> => {
    client.setBaseURLForService("customer")
    const config ={}
    const res: AxiosResponse<Customer[]> =  await client.post("/api/Customers/searchCustomers", filter, config);
    return res.data;
  };
  
export const createCustomer = async (customer: Customer): Promise<Customer> => {
    client.setBaseURLForService("customer")
    const res: AxiosResponse<Customer>= await client.post<Customer>("/api/customers", customer);
    return res.data;
};

export const updateCustomer = async (customer: Customer): Promise<Customer> => {
  client.setBaseURLForService("customer")
  const res:AxiosResponse<Customer>= await client.put<Customer>(`/api/customers/${customer.id}`, customer);
  return res.data;
};

export const deleteCustomer = async (id : string): Promise<string> => {
    client.setBaseURLForService("customer")
    const res:AxiosResponse<string>=  await client.delete<string>(`/api/customers/${id}`);
    return res.data;
};

export const canDeleteCustomer = async (id: string): Promise<string> => {
    client.setBaseURLForService("customer")
    const res: AxiosResponse<string> = await client.get<string>(`/api/customers/CanDeleteCustomer/${id}`);
    return res.data;
};

export const fetchCustomerLossHistory = async (customerId : string): Promise<CustomerLossReadDto[]> => {

  client.setBaseURLForService("customer")
  const config ={}
  const res: AxiosResponse<CustomerLossReadDto[]> =  await client.get(`/api/Customers/${customerId}/Losses`, config);
  return res.data;
};

export const createCustomerLossHistory = async (customerLossDto : CustomerLossDto): Promise<CustomerLossReadDto> => {

  client.setBaseURLForService("customer")
  const config ={}
  const res: AxiosResponse<CustomerLossReadDto> =  await client.post(`/api/Customers/${customerLossDto.custId}/Losses`, customerLossDto, config);
  return res.data;
};

export const updateCustomerLossHistory = async (customerLossDto : CustomerLossDto): Promise<CustomerLossReadDto> => {

  client.setBaseURLForService("customer")
  const config ={}
  const res: AxiosResponse<CustomerLossReadDto> =  await client.put(`/api/Customers/${customerLossDto.custId}/Losses/${customerLossDto.id}`, customerLossDto, config);
  return res.data;
};

export const deleteCustomerLossHistory = async (customerId : string, customerLossId : string): Promise<CustomerLossReadDto> => {

  client.setBaseURLForService("customer");
  const res: AxiosResponse<CustomerLossReadDto> =  await client.delete(`/api/Customers/${customerId}/Losses/${customerLossId}`);
  return res.data;
};


export const updateNote = async (note : CustomerNote) =>{
  client.setBaseURLForService("customer")
  const res:AxiosResponse=  await client.put(`/api/customers/${note.custId}/Notes/${note.id}`, note);
    return res.status;
}

export const createNote = async (note : CustomerNote) : Promise<Customer> =>{
  client.setBaseURLForService("customer")
  const res:AxiosResponse<Customer>=  await client.post<Customer>(`/api/customers/${note.custId}/Note`, note);
    return res.data;
}

export const getAllNotesList = async (customerId : string) : Promise<CustomerNoteRead> =>{
  client.setBaseURLForService("customer")
  const res:AxiosResponse<CustomerNoteRead>=  await client.get<CustomerNoteRead>(`/api/customers/${customerId}/Notes`);
    return res.data;
}

export const getAllCustomerActivities = async (customerId : string, pageNumber: number, userId? : string, pageSize? : number) : Promise<CustomerActivityModel[]> => {
  client.setBaseURLForService("customer")
  const queryParams = [];
  if (customerId) {
    queryParams.push(`CustomerId=${customerId}`);
  }

  if (userId) {
    queryParams.push(`UserId=${userId}`);
  }

  if (pageNumber) {
    queryParams.push(`PageNumber=${pageNumber}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  const queryParamStr = queryParams.join('&');
  const res:AxiosResponse<any> = await client.get<CustomerActivityModel[]>(`/api/Customers/GetAllCustomerActivities?${queryParamStr}` );
  return res.data;
}

export const getCustomerHistory = async (customerId:string) : Promise<CustomerHistoryModel[]> => {
  client.setBaseURLForService("customer")
  const res:AxiosResponse<any> = await client.get<CustomerHistoryModel[]>(`/api/Customers/GetCustomerHistory?id=${customerId}`);
  console.log(res.data)
  return res.data
}
export const addNewActivityForCustomer = async (addActivityRequest: CustomerActivityAddRequest) => {
  client.setBaseURLForService("customer")
  const res:AxiosResponse<any> = await client.post(`/api/Customers/AddCustomerActivity`, addActivityRequest);
  return res.data;
}

export const getPoliciesForDropdown = async (custId: string) => {
    client.setBaseURLForService("customer")
    const res:AxiosResponse<any> = await client.get(`/api/Customers/GetPoliciesForDropdown?custId=${custId}`);
    return res.data;
}

// export const getClaimsForDropdown = async () => {
//     // client.setBaseURLForService("customer")
//     const res:AxiosResponse<any> = await client.get(`https://localhost:7059/api/Customers/GetPoliciesForDropdown?custId=`);
//     return res.data;
// }
